.YandexStatusTableColumn {
  width: 120px;
  border-radius: @pd-4;

  span {
    &:extend(.PaddingLeft12);
    &:extend(.PaddingTop6);
  }
}

.YandexStatusTableComment {
  &:extend(.PaddingLeft6);
  &:extend(.PaddingTop6);
  &:extend(.PaddingRight6);

  border-top-right-radius: @pd-4;
  border-bottom-right-radius: @pd-4;
  cursor: pointer;
}

.YandexStatus {
  &-uploaded {
    background-color: @green-10;

    span {
      color: @green-600 !important
    }

    .YandexStatusTableComment {
      background-color: @green-100;

      svg {
        path {
          fill: @green-500 !important
        }
        circle {
          stroke: @green-500 !important
        }
      }
    }
  }

  &-absent {
    background-color: @red-10;

    span {
      color: @red-600 !important
    }

    .YandexStatusTableComment {
      background-color: @red-100;

      svg {
        path {
          fill: @red-500 !important
        }
        circle {
          stroke: @red-500 !important
        }
      }
    }

  }
}

.YandexContentTitleWrapper{
  display: flex;
  align-items: center;
  padding-bottom: 16px !important;

  &.YandexContentTitleWrapperExcel{
    padding-bottom: 0 !important;
  }

  .TitleTooltip{
    margin-left: 5px;
  }

  .TitleDescription{
    min-height: 48px;
  }

  .ButtonDefaultWrapper .ant-btn, .ButtonPrimaryWrapper .ant-btn{
    width: 141px;
    height: 40px;
  }

  @media screen and (max-width: 994px){
    .TitleText{
      margin-left: 0 !important;
    }
  }


  @media screen and (max-width: 920px){
    min-height: 95px;
    align-items: flex-start;

    .TitleText{
      margin-bottom: 16px;
    }

    .TitleWrapper{
      padding-bottom: 8px !important;
    }

    .TitleRightAdditional{
      margin-left: 0 !important;
    }
  }

}

.YandexMainPageContentWrapper{
  .MailingContentTabsItem{
    letter-spacing: 0.2px;
  }
  .StatusText{
    font-family: "Gilroy", sans-serif !important;
  }

  .MailingContentTabsRestriction{
    margin-bottom: 24px;
  }

  .MainPageContentRestrictions{
    margin-left: 0;
  }

  .YandexContentTitleWrapperExcel .TitleDescription .MailingContentTitleRight{
    margin-left: 12px;
  }

  .MainPageContentWrapper{
    padding: 0 !important;
    background: transparent !important;
  }

  .NewTableComponentWrapper{
    max-height: 80vh;
    overflow-y: auto;
  }

  .PaginationTableComponentWrapper .PaginationTableComponentTotalRow td.ant-table-cell{
    background-color: transparent !important;
  }

  @media screen and (max-width: 920px){
    .MailingContentTabs{
      padding-left: 16px;
    }

    .MailingContentTabsRestriction{
      margin-bottom: 12px;
    }
  }
}

.YandexFileName{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &>div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path{
      fill: @grey-4;
    }
  }
}

.YandexPayrollTableEditItem{
  min-width: 20px;

  svg path{
    fill: @grey-4;
  }
}

.YandexFileAttachedModal{
  .FileItemWrapper{
    width: 100%;
    margin: 16px 0 0 0;
  }
  .FileItemTextSize{
    font-size: 12px;
  }

  .ModalFormClose svg{
    width: 24px;
    height: 24px;
  }
}

.YandexModalAddFile{
  .ModalFormContent{
    padding: 40px;
  }

  .ant-picker{
    height: 48px !important;
  }

  @media screen and (max-width: 920px){
    .ModalFormContent{
      min-height: 440px;
      padding: 24px 16px;
    }
    
    .PaymentModalContentWrapper{
      overflow: unset;
    }

    .ant-form-item{
      margin-bottom: 16px !important;
    }

    .PaymentModalBottom{
      border: none;
      margin-top: 0;
    }

    .ButtonPrimaryWrapper{
      order: 2;
    }

    .YandexModalFileDescription{
      order: 1;
    }
  }
}