@import "src/_main";

@wiki-gray: #CED4DA;
@wiki-blue: #1C7ED6;


.WikiContainer {
  padding: 100px 77px;

  .ant-breadcrumb-link {
    font-family: Gilroy;
    font-size: 14px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: @wiki-blue !important;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .ant-breadcrumb  {
    li:last-child a {
      color: @wiki-gray !important;
    }

    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
    }
  }

  summary:hover {
    cursor: pointer;
  }
}

.WikiSearchSelect {
  margin-top: 24px;
  margin-bottom: 62px;
  max-width: 785px;

  .ant-select-arrow {
    transform: none !important;
  }

  .ant-select-clear {
    margin-right: 4%;
  }

}

@media screen and (max-width: 921px) {
  .WikiContainer {
    padding: 62px 16px;
  }

  .WikiSearchSelect {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}

.WikiDashboardCard {
  display: flex;
  flex: 0 0 32%;
  padding: 0 10px 10px 0;
  margin-bottom: 15px;
}

.WikiDashboardCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 24px;
}

.WikiDashboardCardLinksContainer {
  div {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
  margin-top: 8px;
  a {
    color: black;
  }
  a:hover {
    color: black !important;
    opacity: 90;
    div {
      color: black !important;
    }
  }
}

.WikiDashboardIcon {
  margin-right: 21px;
  width: 55px;
}

.WikiReportContainer {
  .MainPageContentWrapper {
    padding: 0 !important;
    background: #fff !important;
  }
  background: #fff !important;
  .BaseLayoutContentFooter {
    background: #fff !important;
  }
}

.WikiContainerDetails {
  padding-top: 31px !important;
  max-width: 1080px;
}

.WikiMarkdownContainer {
  margin-top: 24px;
  h1 {
    margin-bottom: 10px !important;
  }
}

.WikiFilterWrapper {
  padding: 32px 31px;
}

.WikiSearchSelectFilter {
  margin-top: 0 !important;
  margin-bottom: 24px !important;

  .ant-select-clear {
    margin-right: 10%;
  }
}

.WikiFilterLinkContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.WikiFilterLink:hover {
  cursor: pointer;
  color: @wiki-blue;
}

.WikiLinksList {
  a {
    color: @wiki-blue;
  }
}

.WikiListSubheader {
  margin-top: 32px;
  margin-bottom: 14px;
}

.BaseLayoutLeftMenuContent_Wiki {
   max-height: calc(100vh - 200px);
 }

.WikiGroupHeader {
  margin-bottom: 17px;
}

.fig img {
  width: 100% !important;
  height: auto !important;
}

@media screen and (min-width: 768px) {
  .frame {
    width: 50% !important;
  }
}

@media screen and (min-width: 1024px) {
  .frame {
    width: 25% !important;
  }
}

@media (max-width: 767px) {
  .WikiDashboardCard {
    flex: 0 0 100%;
  }

  .WikiSearchSelectFilter {
    margin-top: 15px !important;
  }
}

@media (max-width: 919px) {
  .ant-breadcrumb {
    margin-top: 15px !important;
  }
}

.MainFilterMobileCloseWiki {
  top: 15px;
}

.WikiHomeBreadcrumb {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}

.WikiDashboardCardName {
  &:extend(.SubtitleFirstText);
  font-weight: 700 !important;
}

.WikiFilterLink_Active {
  color: @wiki-blue !important;
}

.SubtitleFirstTextWikiFilter {
  &:extend(.SubtitleFirstText);
  margin-bottom: 6px;
  font-weight: 700 !important;
}


//TODO move this styles.less to the outer
.WikiMarkdownContainer {
  /*   Раскрывающийся список  */
  details > summary {
    max-width: 100%;
    height: 25px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #1C7ED6;
    flex: none;
    order: 0;
    flex-grow: 0;
    list-style: none;
  }
  details {
    position: relative;
  }
  details summary::before {
    content: url(/Containers/Wiki/icons/icon_cl.png);
    position: absolute;
    right: 0px;
  }
  details[open] summary::before {
    content: url(/Containers/Wiki/icons/icon_op.png);
    position: absolute;
    right: 0px;
  }
  /*   Контейнер с изображением  */
  .container {
    border: none;
  }
  .container img {
    display: inline;
    vertical-align: middle;
  }
  /*   Стандартный текст  */
  p {
    line-height: 1.5;
  }
  /*   Маркированный список первого уровня  */
  .disc {
    padding: 0;
    margin-left: 30px;
    line-height: 200%;
  }
  /*   Маркированный список второго уровня  */
  .circle {
    padding: 0;
    margin-left: 35px;
    line-height: 200%;
  }
  /*   Нумерованный список  */
  ol {
    padding: 0;
    margin-left: 30px;
    line-height: 24px;
  }
  /*   Отступ без маркера  */
  ul {
    padding: 0;
    margin-left: 20px;
    line-height: 200%;
  }
  /*   Выступ в маркированном списке  */
  .test1 {
    margin-left: 15px;
  }
  /*   Блок Внимание!  */
  .outline1 {
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    max-width: 100%;
    background: #FFF5F5;
    border-radius: 16px;
    flex: none;
    order: 2;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  /*   Блок Примечание */
  .outline2 {
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    max-width: 100%;
    background: #F4F4F4;
    border-radius: 16px;
    flex: none;
    order: 2;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  /*   Интерлиньяж  */
  .dline {
    line-height: 1.5;
  }
  /*   Выравнивание изображений по центру  */
  .fig img {
    margin: 0 auto;
  }
  /*   Рамка для изображений  */
  .frame {
    box-sizing: border-box;
    height: 696px;
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    border-radius: 9px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 15px;
  }
  /*   Заголовок первого уровня  */
  h1 {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #212529;
    left: 100px;
    margin-bottom: 15px;
  }
    /*   Заголовок четвертого уровня  */
  h4 {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 39px;
    color: #212529;
    left: 100px;
    margin-bottom: 15px;
  }
  hr {
    height: 1px;
    background: #DEE2E6;
    flex: none;
    order: 0;
    border: none;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  /*   Адаптивность изображений  */
  .mv-100 {
    max-width: 100%;
  }
  /*   Основной текст  */
  body {
    height: 24px;
    background-color: #FFFFFF;
    /* Subtitle 2 */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #212529;
    align-items: center;
    letter-spacing: 0.2px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}