[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  flex: 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #1890ff;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 4px 0 4px 16px;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #1890ff;
  border-radius: 2px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 56px;
  padding: 16px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 64px;
  padding: 18.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 48px;
  padding: 12px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #096dd9;
  background: #096dd9;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #1890ff;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 56px;
  height: 56px;
  padding: 14.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 64px;
  height: 64px;
  padding: 16.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 48px;
  height: 48px;
  padding: 12px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 56px;
  padding: 16px 28px;
  font-size: 14px;
  border-radius: 56px;
}
.ant-btn-round.ant-btn-lg {
  height: 64px;
  padding: 18.4px 32px;
  font-size: 16px;
  border-radius: 64px;
}
.ant-btn-round.ant-btn-sm {
  height: 48px;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 48px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 56px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 64px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 48px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 54px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 62px;
}
a.ant-btn-sm {
  line-height: 46px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
  z-index: 2;
}
.ant-btn-compact-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #40a9ff;
  content: ' ';
}
.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #40a9ff;
  content: ' ';
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #1890ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #1890ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #1890ff;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  user-select: none;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #1890ff;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #e6f7ff;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}
.ant-cascader-compact-item:hover,
.ant-cascader-compact-item:focus,
.ant-cascader-compact-item:active {
  z-index: 2;
}
.ant-cascader-compact-item[disabled] {
  z-index: 0;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 16px 11px 16px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 12px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 18.5px 11px 18.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 12px 7px 12px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}
.ant-picker-compact-item:hover,
.ant-picker-compact-item:focus,
.ant-picker-compact-item:active {
  z-index: 2;
}
.ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}
.ant-picker-compact-item[disabled] {
  z-index: 0;
}
.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #1890ff;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #1890ff;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #1890ff;
}
.ant-picker-today-btn:hover {
  color: #40a9ff;
}
.ant-picker-today-btn:active {
  color: #096dd9;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #7ec1ff;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 2px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: none;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #1890ff;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain-warning {
  color: #faad14;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 48px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 48px;
}
.ant-form-large .ant-form-item-label > label {
  height: 64px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 64px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 56px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0px;
}
.ant-form-item-explain-connected {
  width: 100%;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.ant-form-item-feedback-icon-warning {
  color: #faad14;
}
.ant-form-item-feedback-icon-validating {
  color: #1890ff;
}
.ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}
.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}
.ant-show-help-leave {
  opacity: 1;
}
.ant-show-help-leave-active {
  opacity: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
}
.ant-image-preview-switch-left:hover,
.ant-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon,
.ant-image-preview-switch-left-disabled:hover > .anticon,
.ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 8px;
}
.ant-image-preview-switch-right {
  right: 8px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 12px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 12px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -17px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 12px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 64px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 48px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 56px;
}
.ant-input[type='color'].ant-input-lg {
  height: 64px;
}
.ant-input[type='color'].ant-input-sm {
  height: 48px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-compact-item:hover,
.ant-input-compact-item:focus,
.ant-input-compact-item:active {
  z-index: 2;
}
.ant-input-compact-item[disabled] {
  z-index: 0;
}
.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #40a9ff;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #40a9ff;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 56px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 64px;
}
.ant-input-search-small .ant-input-search-button {
  height: 48px;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input,
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover,
.ant-input-search.ant-input-compact-item > .ant-input:hover,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus,
.ant-input-search.ant-input-compact-item > .ant-input:focus,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active,
.ant-input-search.ant-input-compact-item > .ant-input:active,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover,
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #40a9ff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #40a9ff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 56px;
  }
  .ant-input-lg {
    height: 64px;
  }
  .ant-input-sm {
    height: 48px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 12px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 12px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -17px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 12px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 64px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 48px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 54px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  appearance: textfield !important;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 62px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 46px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-number-compact-item:hover,
.ant-input-number-compact-item:focus,
.ant-input-number-compact-item:active {
  z-index: 2;
}
.ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}
.ant-input-number-compact-item[disabled] {
  z-index: 0;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #1890ff;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 12px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 54px;
  margin: 0;
  padding: 16px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #1890ff;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #1890ff;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #1890ff;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #1890ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #1890ff;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #1890ff;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1890ff;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #1890ff;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.ant-page-header-back-button:focus-visible,
.ant-page-header-back-button:hover {
  color: #40a9ff;
}
.ant-page-header-back-button:active {
  color: #096dd9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 56px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 56px;
  margin-right: 8px;
  line-height: 54px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 56px;
  height: 56px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 54px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #1890ff;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item:focus-visible {
  border-color: #1890ff;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #1890ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus-visible {
  border-color: #40a9ff;
}
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-item-active:focus-visible a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 56px;
  height: 56px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 56px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 56px;
  margin-left: 8px;
  line-height: 56px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 16px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 56px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 56px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 18.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 12px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 12px 7px;
  width: 44px;
  height: 48px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  display: flex;
  align-items: center;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 56px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 54px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 64px;
  font-size: 16px;
  line-height: 62px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 48px;
  padding: 0 7px;
  line-height: 46px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}
.ant-segmented-item-label {
  min-height: 52px;
  padding: 0 11px;
  line-height: 52px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented-item-icon + * {
  margin-left: 6px;
}
.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 60px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 60px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 44px;
  padding: 0 7px;
  line-height: 44px;
}
.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 54px;
  transition: all 0.3s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 56px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 54px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 54px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 46px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 46px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 7px 16px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 8px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 16px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: -5px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}
.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.ant-select-compact-item:hover > *,
.ant-select-compact-item:focus > *,
.ant-select-compact-item:active > * {
  z-index: 2;
}
.ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}
.ant-select-compact-item[disabled] > * {
  z-index: 0;
}
.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}
.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 16px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 112px;
  min-width: 112px;
  height: 56px;
  line-height: 56px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 56px;
  min-width: 56px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 56px;
  min-width: 56px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 56px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 128px;
  min-width: 128px;
  height: 64px;
  line-height: 64px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 64px;
  min-width: 64px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 64px;
  min-width: 64px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 64px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 96px;
  min-width: 96px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 48px;
  min-width: 48px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 48px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 280px;
  min-width: 280px;
  height: 56px;
  line-height: 56px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 320px;
  min-width: 320px;
  height: 64px;
  line-height: 64px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 240px;
  min-width: 240px;
  height: 48px;
  line-height: 48px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #1890ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 56px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 28px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #1890ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #1890ff;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6f7ff;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #1890ff;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}
.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf4ff;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #1890ff;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1890ff;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #1890ff;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #bae7ff;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  user-select: none;
}
.ant-table-row-expand-icon:focus-visible,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-left-all::after {
  display: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #40a9ff;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #40a9ff;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #096dd9;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #1890ff;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #096dd9;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #40a9ff;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  position: relative;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-tabpane {
  outline: none;
}
.ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-switch-appear,
.ant-tabs-switch-enter {
  transition: none;
}
.ant-tabs-switch-appear-start,
.ant-tabs-switch-enter-start {
  opacity: 0;
}
.ant-tabs-switch-appear-active,
.ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.ant-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.ant-tabs-switch-leave-start {
  opacity: 1;
}
.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1890ff;
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer-status-error .ant-transfer-list {
  border-color: #ff4d4f;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list {
  border-color: #faad14;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 6px 12px;
  line-height: 44px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  position: relative;
  color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf4ff;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #e6f7ff;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #1890ff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #1890ff;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #1890ff;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #1890ff;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #1890ff;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl .ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6f7ff;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #1890ff;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #1890ff;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #1890ff;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #1890ff;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  word-break: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus {
  color: #d9363e;
}
a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus-visible,
.ant-typography a:focus-visible,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #40a9ff;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #096dd9;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus-visible,
.ant-typography-edit:focus-visible,
.ant-typography-copy:focus-visible,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #40a9ff;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #096dd9;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -17px;
  margin-bottom: calc(1em - 16px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.Blue900Color {
  color: #1864AB !important;
}
.Blue800Color {
  color: #1971C2 !important;
}
.Blue700Color {
  color: #1C7ED6 !important;
}
.Blue600Color,
.LinkText,
.IncreaseModalLimitText,
.LinkText,
.SportboomTableMobileItemBetNumber,
.SportboomTableMobileItemBetNumber {
  color: #228BE6 !important;
}
.Blue500Color {
  color: #339AF0 !important;
}
.Blue400Color {
  color: #4DABF7 !important;
}
.Blue300Color {
  color: #74C0FC !important;
}
.Blue200Color {
  color: #A5D8FF !important;
}
.Blue100Color {
  color: #D0EBFF !important;
}
.Blue10Color {
  color: #E7F5FF !important;
}
.Blue900Background {
  background-color: #1864AB !important;
}
.Blue800Background {
  background-color: #1971C2 !important;
}
.Blue700Background {
  background-color: #1C7ED6 !important;
}
.Blue600Background {
  background-color: #228BE6 !important;
}
.Blue500Background {
  background-color: #339AF0 !important;
}
.Blue400Background {
  background-color: #4DABF7 !important;
}
.Blue300Background {
  background-color: #74C0FC !important;
}
.Blue200Background {
  background-color: #A5D8FF !important;
}
.Blue100Background {
  background-color: #D0EBFF !important;
}
.Blue10Background,
.IncreaseModalLimit {
  background-color: #E7F5FF !important;
}
.Blue900Border {
  border-color: #1864AB !important;
}
.Blue800Border {
  border-color: #1971C2 !important;
}
.Blue700Border {
  border-color: #1C7ED6 !important;
}
.Blue600Border {
  border-color: #228BE6 !important;
}
.Blue500Border {
  border-color: #339AF0 !important;
}
.Blue400Border {
  border-color: #4DABF7 !important;
}
.Blue300Border {
  border-color: #74C0FC !important;
}
.Blue200Border {
  border-color: #A5D8FF !important;
}
.Blue100Border {
  border-color: #D0EBFF !important;
}
.Blue10Border {
  border-color: #E7F5FF !important;
}
.Cyan900Color {
  color: #0B7285 !important;
}
.Cyan800Color {
  color: #0C8599 !important;
}
.Cyan700Color {
  color: #1098AD !important;
}
.Cyan600Color {
  color: #15AABF !important;
}
.Cyan500Color {
  color: #22B8CF !important;
}
.Cyan400Color {
  color: #3BC9DB !important;
}
.Cyan300Color {
  color: #66D9E8 !important;
}
.Cyan200Color {
  color: #99E9F2 !important;
}
.Cyan100Color {
  color: #C5F6FA !important;
}
.Cyan10Color {
  color: #E3FAFC !important;
}
.Cyan900Background {
  background-color: #0B7285 !important;
}
.Cyan800Background {
  background-color: #0C8599 !important;
}
.Cyan700Background {
  background-color: #1098AD !important;
}
.Cyan600Background {
  background-color: #15AABF !important;
}
.Cyan500Background {
  background-color: #22B8CF !important;
}
.Cyan400Background {
  background-color: #3BC9DB !important;
}
.Cyan300Background {
  background-color: #66D9E8 !important;
}
.Cyan200Background {
  background-color: #99E9F2 !important;
}
.Cyan100Background {
  background-color: #C5F6FA !important;
}
.Cyan10Background,
.RegistrationCheckMobileTableHeader {
  background-color: #E3FAFC !important;
}
.Cyan900Border {
  border-color: #0B7285 !important;
}
.Cyan800Border {
  border-color: #0C8599 !important;
}
.Cyan700Border {
  border-color: #1098AD !important;
}
.Cyan600Border {
  border-color: #15AABF !important;
}
.Cyan500Border {
  border-color: #22B8CF !important;
}
.Cyan400Border {
  border-color: #3BC9DB !important;
}
.Cyan300Border {
  border-color: #66D9E8 !important;
}
.Cyan200Border {
  border-color: #99E9F2 !important;
}
.Cyan100Border {
  border-color: #C5F6FA !important;
}
.Cyan10Border {
  border-color: #E3FAFC !important;
}
.Grape900Color {
  color: #862E9C !important;
}
.Grape800Color {
  color: #9C36B5 !important;
}
.Grape700Color {
  color: #AE3EC9 !important;
}
.Grape600Color {
  color: #BE4BDB !important;
}
.Grape500Color {
  color: #CC5DE8 !important;
}
.Grape400Color {
  color: #DA77F2 !important;
}
.Grape300Color {
  color: #E599F7 !important;
}
.Grape200Color {
  color: #EEBEFA !important;
}
.Grape100Color {
  color: #F3D9FA !important;
}
.Grape10Color {
  color: #F8F0FC !important;
}
.Grape900Background {
  background-color: #862E9C !important;
}
.Grape800Background {
  background-color: #9C36B5 !important;
}
.Grape700Background {
  background-color: #AE3EC9 !important;
}
.Grape600Background {
  background-color: #BE4BDB !important;
}
.Grape500Background {
  background-color: #CC5DE8 !important;
}
.Grape400Background {
  background-color: #DA77F2 !important;
}
.Grape300Background {
  background-color: #E599F7 !important;
}
.Grape200Background {
  background-color: #EEBEFA !important;
}
.Grape100Background {
  background-color: #F3D9FA !important;
}
.Grape10Background {
  background-color: #F8F0FC !important;
}
.Grape900Border {
  border-color: #862E9C !important;
}
.Grape800Border {
  border-color: #9C36B5 !important;
}
.Grape700Border {
  border-color: #AE3EC9 !important;
}
.Grape600Border {
  border-color: #BE4BDB !important;
}
.Grape500Border {
  border-color: #CC5DE8 !important;
}
.Grape400Border {
  border-color: #DA77F2 !important;
}
.Grape300Border {
  border-color: #E599F7 !important;
}
.Grape200Border {
  border-color: #EEBEFA !important;
}
.Grape100Border {
  border-color: #F3D9FA !important;
}
.Grape10Border {
  border-color: #F8F0FC !important;
}
.Gray900Color {
  color: #212529 !important;
}
.Gray800Color {
  color: #343A40 !important;
}
.Gray700Color {
  color: #495057 !important;
}
.Gray600Color,
.SportboomTableMobileItemBetStart,
.DetailTableComponentRowItemLabel {
  color: #868E96 !important;
}
.Gray500Color {
  color: #ADB5BD !important;
}
.Gray400Color {
  color: #CED4DA !important;
}
.Gray300Color {
  color: #DEE2E6 !important;
}
.Gray200Color {
  color: #E9ECEF !important;
}
.Gray100Color {
  color: #F1F3F5 !important;
}
.Gray10Color {
  color: #F8F9FA !important;
}
.Gray900Background {
  background-color: #212529 !important;
}
.Gray800Background {
  background-color: #343A40 !important;
}
.Gray700Background {
  background-color: #495057 !important;
}
.Gray600Background {
  background-color: #868E96 !important;
}
.Gray500Background,
.SportboomTableStatusInGameActive {
  background-color: #ADB5BD !important;
}
.Gray400Background,
.SportboomTableMobileItemArrowOpen {
  background-color: #CED4DA !important;
}
.Gray300Background {
  background-color: #DEE2E6 !important;
}
.Gray200Background,
.SportboomTableInsideEmpty,
.SportboomTableMobileItemDetail {
  background-color: #E9ECEF !important;
}
.Gray100Background,
.SportboomTableStatusInGame,
.SportboomCompareCancel,
.SportboomTableMobileItemPending .SportboomTableMobileItem,
.SportboomTableMobileItemArrowClose,
.NotifyStatusTableButtons .ButtonNotifiWrapper button {
  background-color: #F1F3F5 !important;
}
.Gray10Background,
.SportboomCompareCancel:hover,
.PayrollFilesItemWrapper,
.PaymentFilesItemWrapper,
.ButtonNotifiGray .ant-btn {
  background-color: #F8F9FA !important;
}
.Gray900Border {
  border-color: #212529 !important;
}
.Gray800Border {
  border-color: #343A40 !important;
}
.Gray700Border {
  border-color: #495057 !important;
}
.Gray600Border {
  border-color: #868E96 !important;
}
.Gray500Border {
  border-color: #ADB5BD !important;
}
.Gray400Border,
.ButtonNotifiGray .ant-btn,
.DetailTableComponentFirstRow .DetailTableComponentRowItem,
.DetailTableComponentRowSmallWrapper {
  border-color: #CED4DA !important;
}
.Gray300Border {
  border-color: #DEE2E6 !important;
}
.Gray200Border {
  border-color: #E9ECEF !important;
}
.Gray100Border {
  border-color: #F1F3F5 !important;
}
.Gray10Border {
  border-color: #F8F9FA !important;
}
.Green900Color {
  color: #2B8A3E !important;
}
.Green800Color {
  color: #2F9E44 !important;
}
.Green700Color {
  color: #37B24D !important;
}
.Green600Color,
.SportboomTableStatusLoose {
  color: #40C057 !important;
}
.Green500Color {
  color: #51CF66 !important;
}
.Green400Color {
  color: #69DB7C !important;
}
.Green300Color {
  color: #8CE99A !important;
}
.Green200Color {
  color: #B2F2BB !important;
}
.Green100Color {
  color: #D3F9D8 !important;
}
.Green10Color {
  color: #EBFBEE !important;
}
.Green900Background {
  background-color: #2B8A3E !important;
}
.Green800Background {
  background-color: #2F9E44 !important;
}
.Green700Background {
  background-color: #37B24D !important;
}
.Green600Background {
  background-color: #40C057 !important;
}
.Green500Background {
  background-color: #51CF66 !important;
}
.Green400Background,
.SportboomTableStatusLooseActive {
  background-color: #69DB7C !important;
}
.Green300Background {
  background-color: #8CE99A !important;
}
.Green200Background {
  background-color: #B2F2BB !important;
}
.Green100Background {
  background-color: #D3F9D8 !important;
}
.Green10Background,
.SportboomTableStatusLoose {
  background-color: #EBFBEE !important;
}
.Green900Border {
  border-color: #2B8A3E !important;
}
.Green800Border {
  border-color: #2F9E44 !important;
}
.Green700Border {
  border-color: #37B24D !important;
}
.Green600Border {
  border-color: #40C057 !important;
}
.Green500Border {
  border-color: #51CF66 !important;
}
.Green400Border {
  border-color: #69DB7C !important;
}
.Green300Border {
  border-color: #8CE99A !important;
}
.Green200Border {
  border-color: #B2F2BB !important;
}
.Green100Border {
  border-color: #D3F9D8 !important;
}
.Green10Border {
  border-color: #EBFBEE !important;
}
.Indigo900Color {
  color: #364FC7 !important;
}
.Indigo800Color {
  color: #3B5BDB !important;
}
.Indigo700Color {
  color: #4263EB !important;
}
.Indigo600Color {
  color: #4C6EF5 !important;
}
.Indigo500Color {
  color: #5C7CFA !important;
}
.Indigo400Color {
  color: #748FFC !important;
}
.Indigo300Color {
  color: #91A7FF !important;
}
.Indigo200Color {
  color: #BAC8FF !important;
}
.Indigo100Color {
  color: #DBE4FF !important;
}
.Indigo10Color {
  color: #EDF2FF !important;
}
.Indigo900Background {
  background-color: #364FC7 !important;
}
.Indigo800Background {
  background-color: #3B5BDB !important;
}
.Indigo700Background {
  background-color: #4263EB !important;
}
.Indigo600Background {
  background-color: #4C6EF5 !important;
}
.Indigo500Background {
  background-color: #5C7CFA !important;
}
.Indigo400Background {
  background-color: #748FFC !important;
}
.Indigo300Background {
  background-color: #91A7FF !important;
}
.Indigo200Background {
  background-color: #BAC8FF !important;
}
.Indigo100Background {
  background-color: #DBE4FF !important;
}
.Indigo10Background {
  background-color: #EDF2FF !important;
}
.Indigo900Border {
  border-color: #364FC7 !important;
}
.Indigo800Border {
  border-color: #3B5BDB !important;
}
.Indigo700Border {
  border-color: #4263EB !important;
}
.Indigo600Border {
  border-color: #4C6EF5 !important;
}
.Indigo500Border {
  border-color: #5C7CFA !important;
}
.Indigo400Border {
  border-color: #748FFC !important;
}
.Indigo300Border {
  border-color: #91A7FF !important;
}
.Indigo200Border {
  border-color: #BAC8FF !important;
}
.Indigo100Border {
  border-color: #DBE4FF !important;
}
.Indigo10Border {
  border-color: #EDF2FF !important;
}
.Lemon900Color {
  color: #8A7500 !important;
}
.Lemon800Color,
.SportboomTableStatusReturnBet {
  color: #AA9200 !important;
}
.Lemon700Color {
  color: #C9AE00 !important;
}
.Lemon600Color,
.DetailTooltipContentTitle {
  color: #E4C800 !important;
}
.Lemon500Color {
  color: #FCE000 !important;
}
.Lemon400Color {
  color: #FFED37 !important;
}
.Lemon300Color {
  color: #FFF450 !important;
}
.Lemon200Color {
  color: #FFF450 !important;
}
.Lemon100Color {
  color: #FFFB9F !important;
}
.Lemon10Color {
  color: #FFFFBB !important;
}
.Lemon900Background {
  background-color: #8A7500 !important;
}
.Lemon800Background {
  background-color: #AA9200 !important;
}
.Lemon700Background,
.SportboomTableStatusReturnBetActive {
  background-color: #C9AE00 !important;
}
.Lemon600Background {
  background-color: #E4C800 !important;
}
.Lemon500Background,
.SportboomCompareApply,
.PayrollClearButtonCount,
.PaymentClearButtonCount {
  background-color: #FCE000 !important;
}
.Lemon400Background,
.SportboomCompareApply:hover {
  background-color: #FFED37 !important;
}
.Lemon300Background {
  background-color: #FFF450 !important;
}
.Lemon200Background {
  background-color: #FFF450 !important;
}
.Lemon100Background {
  background-color: #FFFB9F !important;
}
.Lemon10Background,
.SportboomTableStatusReturnBet {
  background-color: #FFFFBB !important;
}
.Lemon900Border {
  border-color: #8A7500 !important;
}
.Lemon800Border {
  border-color: #AA9200 !important;
}
.Lemon700Border {
  border-color: #C9AE00 !important;
}
.Lemon600Border {
  border-color: #E4C800 !important;
}
.Lemon500Border {
  border-color: #FCE000 !important;
}
.Lemon400Border {
  border-color: #FFED37 !important;
}
.Lemon300Border {
  border-color: #FFF450 !important;
}
.Lemon200Border {
  border-color: #FFF450 !important;
}
.Lemon100Border {
  border-color: #FFFB9F !important;
}
.Lemon10Border {
  border-color: #FFFFBB !important;
}
.Lime900Color {
  color: #5C940D !important;
}
.Lime800Color {
  color: #66A80F !important;
}
.Lime700Color {
  color: #74B816 !important;
}
.Lime600Color {
  color: #82C91E !important;
}
.Lime500Color {
  color: #94D82D !important;
}
.Lime400Color {
  color: #A9E34B !important;
}
.Lime300Color {
  color: #C0EB75 !important;
}
.Lime200Color {
  color: #D8F5A2 !important;
}
.Lime100Color {
  color: #E9FAC8 !important;
}
.Lime10Color {
  color: #F4FCE3 !important;
}
.Lime900Background {
  background-color: #5C940D !important;
}
.Lime800Background {
  background-color: #66A80F !important;
}
.Lime700Background {
  background-color: #74B816 !important;
}
.Lime600Background {
  background-color: #82C91E !important;
}
.Lime500Background {
  background-color: #94D82D !important;
}
.Lime400Background {
  background-color: #A9E34B !important;
}
.Lime300Background {
  background-color: #C0EB75 !important;
}
.Lime200Background {
  background-color: #D8F5A2 !important;
}
.Lime100Background {
  background-color: #E9FAC8 !important;
}
.Lime10Background {
  background-color: #F4FCE3 !important;
}
.Lime900Border {
  border-color: #5C940D !important;
}
.Lime800Border {
  border-color: #66A80F !important;
}
.Lime700Border {
  border-color: #74B816 !important;
}
.Lime600Border {
  border-color: #82C91E !important;
}
.Lime500Border {
  border-color: #94D82D !important;
}
.Lime400Border {
  border-color: #A9E34B !important;
}
.Lime300Border {
  border-color: #C0EB75 !important;
}
.Lime200Border {
  border-color: #D8F5A2 !important;
}
.Lime100Border {
  border-color: #E9FAC8 !important;
}
.Lime10Border {
  border-color: #F4FCE3 !important;
}
.Orange900Color {
  color: #D9480F !important;
}
.Orange800Color {
  color: #E8590C !important;
}
.Orange700Color {
  color: #F76707 !important;
}
.Orange600Color {
  color: #FD7E14 !important;
}
.Orange500Color {
  color: #FF922B !important;
}
.Orange400Color {
  color: #FFA94D !important;
}
.Orange300Color {
  color: #FFC078 !important;
}
.Orange200Color {
  color: #FFD8A8 !important;
}
.Orange100Color {
  color: #FFE8CC !important;
}
.Orange10Color {
  color: #FFF4E6 !important;
}
.Orange900Background {
  background-color: #D9480F !important;
}
.Orange800Background {
  background-color: #E8590C !important;
}
.Orange700Background {
  background-color: #F76707 !important;
}
.Orange600Background {
  background-color: #FD7E14 !important;
}
.Orange500Background {
  background-color: #FF922B !important;
}
.Orange400Background {
  background-color: #FFA94D !important;
}
.Orange300Background {
  background-color: #FFC078 !important;
}
.Orange200Background {
  background-color: #FFD8A8 !important;
}
.Orange100Background {
  background-color: #FFE8CC !important;
}
.Orange10Background,
.OrangeHeaderTable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  background-color: #FFF4E6 !important;
}
.Orange900Border {
  border-color: #D9480F !important;
}
.Orange800Border {
  border-color: #E8590C !important;
}
.Orange700Border {
  border-color: #F76707 !important;
}
.Orange600Border {
  border-color: #FD7E14 !important;
}
.Orange500Border {
  border-color: #FF922B !important;
}
.Orange400Border {
  border-color: #FFA94D !important;
}
.Orange300Border {
  border-color: #FFC078 !important;
}
.Orange200Border {
  border-color: #FFD8A8 !important;
}
.Orange100Border {
  border-color: #FFE8CC !important;
}
.Orange10Border {
  border-color: #FFF4E6 !important;
}
.Pink900Color {
  color: #A61E4D !important;
}
.Pink800Color {
  color: #C2255C !important;
}
.Pink700Color {
  color: #D6336C !important;
}
.Pink600Color {
  color: #E64980 !important;
}
.Pink500Color {
  color: #F06595 !important;
}
.Pink400Color {
  color: #F783AC !important;
}
.Pink300Color {
  color: #FAA2C1 !important;
}
.Pink200Color {
  color: #FCC2D7 !important;
}
.Pink100Color {
  color: #FFDEEB !important;
}
.Pink10Color {
  color: #FFF0F6 !important;
}
.Pink900Background {
  background-color: #A61E4D !important;
}
.Pink800Background {
  background-color: #C2255C !important;
}
.Pink700Background {
  background-color: #D6336C !important;
}
.Pink600Background {
  background-color: #E64980 !important;
}
.Pink500Background {
  background-color: #F06595 !important;
}
.Pink400Background {
  background-color: #F783AC !important;
}
.Pink300Background {
  background-color: #FAA2C1 !important;
}
.Pink200Background {
  background-color: #FCC2D7 !important;
}
.Pink100Background {
  background-color: #FFDEEB !important;
}
.Pink10Background {
  background-color: #FFF0F6 !important;
}
.Pink900Border {
  border-color: #A61E4D !important;
}
.Pink800Border {
  border-color: #C2255C !important;
}
.Pink700Border {
  border-color: #D6336C !important;
}
.Pink600Border {
  border-color: #E64980 !important;
}
.Pink500Border {
  border-color: #F06595 !important;
}
.Pink400Border {
  border-color: #F783AC !important;
}
.Pink300Border {
  border-color: #FAA2C1 !important;
}
.Pink200Border {
  border-color: #FCC2D7 !important;
}
.Pink100Border {
  border-color: #FFDEEB !important;
}
.Pink10Border {
  border-color: #FFF0F6 !important;
}
.Red900Color {
  color: #C92A2A !important;
}
.Red800Color {
  color: #E03131 !important;
}
.Red700Color {
  color: #F03E3E !important;
}
.Red600Color,
.SportboomTableStatusWin {
  color: #FA5252 !important;
}
.Red500Color {
  color: #FF6B6B !important;
}
.Red400Color {
  color: #FF8787 !important;
}
.Red300Color {
  color: #FFA8A8 !important;
}
.Red200Color {
  color: #FFC9C9 !important;
}
.Red100Color {
  color: #FFE3E3 !important;
}
.Red10Color {
  color: #FFF5F5 !important;
}
.Red900Background {
  background-color: #C92A2A !important;
}
.Red800Background {
  background-color: #E03131 !important;
}
.Red700Background {
  background-color: #F03E3E !important;
}
.Red600Background {
  background-color: #FA5252 !important;
}
.Red500Background {
  background-color: #FF6B6B !important;
}
.Red400Background,
.SportboomTableStatusWinActive {
  background-color: #FF8787 !important;
}
.Red300Background {
  background-color: #FFA8A8 !important;
}
.Red200Background {
  background-color: #FFC9C9 !important;
}
.Red100Background {
  background-color: #FFE3E3 !important;
}
.Red10Background,
.SportboomTableStatusWin {
  background-color: #FFF5F5 !important;
}
.Red900Border {
  border-color: #C92A2A !important;
}
.Red800Border {
  border-color: #E03131 !important;
}
.Red700Border {
  border-color: #F03E3E !important;
}
.Red600Border {
  border-color: #FA5252 !important;
}
.Red500Border {
  border-color: #FF6B6B !important;
}
.Red400Border {
  border-color: #FF8787 !important;
}
.Red300Border {
  border-color: #FFA8A8 !important;
}
.Red200Border {
  border-color: #FFC9C9 !important;
}
.Red100Border {
  border-color: #FFE3E3 !important;
}
.Red10Border {
  border-color: #FFF5F5 !important;
}
.Teal900Color {
  color: #087F5B !important;
}
.Teal800Color {
  color: #099268 !important;
}
.Teal700Color {
  color: #0CA678 !important;
}
.Teal600Color {
  color: #12B886 !important;
}
.Teal500Color {
  color: #20C997 !important;
}
.Teal400Color {
  color: #38D9A9 !important;
}
.Teal300Color {
  color: #63E6BE !important;
}
.Teal200Color {
  color: #96F2D7 !important;
}
.Teal100Color {
  color: #C3FAE8 !important;
}
.Teal10Color {
  color: #E6FCF5 !important;
}
.Teal900Background {
  background-color: #087F5B !important;
}
.Teal800Background {
  background-color: #099268 !important;
}
.Teal700Background {
  background-color: #0CA678 !important;
}
.Teal600Background {
  background-color: #12B886 !important;
}
.Teal500Background {
  background-color: #20C997 !important;
}
.Teal400Background {
  background-color: #38D9A9 !important;
}
.Teal300Background {
  background-color: #63E6BE !important;
}
.Teal200Background {
  background-color: #96F2D7 !important;
}
.Teal100Background {
  background-color: #C3FAE8 !important;
}
.Teal10Background {
  background-color: #E6FCF5 !important;
}
.Teal900Border {
  border-color: #087F5B !important;
}
.Teal800Border {
  border-color: #099268 !important;
}
.Teal700Border {
  border-color: #0CA678 !important;
}
.Teal600Border {
  border-color: #12B886 !important;
}
.Teal500Border {
  border-color: #20C997 !important;
}
.Teal400Border {
  border-color: #38D9A9 !important;
}
.Teal300Border {
  border-color: #63E6BE !important;
}
.Teal200Border {
  border-color: #96F2D7 !important;
}
.Teal100Border {
  border-color: #C3FAE8 !important;
}
.Teal10Border {
  border-color: #E6FCF5 !important;
}
.Violet900Color {
  color: #5F3DC4 !important;
}
.Violet800Color {
  color: #6741D9 !important;
}
.Violet700Color {
  color: #7048E8 !important;
}
.Violet600Color,
.SportboomTableStatusRedeem {
  color: #7950F2 !important;
}
.Violet500Color {
  color: #845EF7 !important;
}
.Violet400Color {
  color: #9775FA !important;
}
.Violet300Color {
  color: #B197FC !important;
}
.Violet200Color {
  color: #D0BFFF !important;
}
.Violet100Color {
  color: #E5DBFF !important;
}
.Violet10Color {
  color: #F3F0FF !important;
}
.Violet900Background {
  background-color: #5F3DC4 !important;
}
.Violet800Background {
  background-color: #6741D9 !important;
}
.Violet700Background {
  background-color: #7048E8 !important;
}
.Violet600Background {
  background-color: #7950F2 !important;
}
.Violet500Background {
  background-color: #845EF7 !important;
}
.Violet400Background,
.SportboomTableStatusRedeemActive {
  background-color: #9775FA !important;
}
.Violet300Background {
  background-color: #B197FC !important;
}
.Violet200Background {
  background-color: #D0BFFF !important;
}
.Violet100Background {
  background-color: #E5DBFF !important;
}
.Violet10Background,
.SportboomTableStatusRedeem {
  background-color: #F3F0FF !important;
}
.Violet900Border {
  border-color: #5F3DC4 !important;
}
.Violet800Border {
  border-color: #6741D9 !important;
}
.Violet700Border {
  border-color: #7048E8 !important;
}
.Violet600Border {
  border-color: #7950F2 !important;
}
.Violet500Border {
  border-color: #845EF7 !important;
}
.Violet400Border {
  border-color: #9775FA !important;
}
.Violet300Border {
  border-color: #B197FC !important;
}
.Violet200Border {
  border-color: #D0BFFF !important;
}
.Violet100Border {
  border-color: #E5DBFF !important;
}
.Violet10Border {
  border-color: #F3F0FF !important;
}
.Yellow900Color {
  color: #E67700 !important;
}
.Yellow800Color {
  color: #F08C00 !important;
}
.Yellow700Color {
  color: #F59F00 !important;
}
.Yellow600Color {
  color: #FAB005 !important;
}
.Yellow500Color {
  color: #FCC419 !important;
}
.Yellow400Color {
  color: #FFD43B !important;
}
.Yellow300Color {
  color: #FFE066 !important;
}
.Yellow200Color {
  color: #FFEC99 !important;
}
.Yellow100Color {
  color: #FFF3BF !important;
}
.Yellow10Color {
  color: #FFF9DB !important;
}
.Yellow900Background {
  background-color: #E67700 !important;
}
.Yellow800Background {
  background-color: #F08C00 !important;
}
.Yellow700Background {
  background-color: #F59F00 !important;
}
.Yellow600Background {
  background-color: #FAB005 !important;
}
.Yellow500Background {
  background-color: #FCC419 !important;
}
.Yellow400Background {
  background-color: #FFD43B !important;
}
.Yellow300Background {
  background-color: #FFE066 !important;
}
.Yellow200Background {
  background-color: #FFEC99 !important;
}
.Yellow100Background {
  background-color: #FFF3BF !important;
}
.Yellow10Background {
  background-color: #FFF9DB !important;
}
.Yellow900Border {
  border-color: #E67700 !important;
}
.Yellow800Border {
  border-color: #F08C00 !important;
}
.Yellow700Border {
  border-color: #F59F00 !important;
}
.Yellow600Border {
  border-color: #FAB005 !important;
}
.Yellow500Border {
  border-color: #FCC419 !important;
}
.Yellow400Border {
  border-color: #FFD43B !important;
}
.Yellow300Border {
  border-color: #FFE066 !important;
}
.Yellow200Border {
  border-color: #FFEC99 !important;
}
.Yellow100Border {
  border-color: #FFF3BF !important;
}
.Yellow10Border {
  border-color: #FFF9DB !important;
}
.Shark800Color {
  color: #212529 !important;
}
.Shark500Color,
.SportboomTableStatusInGame,
.ButtonNotifiGray .ant-btn,
.ButtonNotifiGray .ant-btn div span {
  color: #0D0F10 !important;
}
.Shark800Background {
  background-color: #212529 !important;
}
.Shark500Background {
  background-color: #0D0F10 !important;
}
.Shark800Border {
  border-color: #212529 !important;
}
.Shark500Border {
  border-color: #0D0F10 !important;
}
.White800Color {
  color: #F4F4F4 !important;
}
.White100Color,
.SportboomTableStatusWinActive,
.SportboomTableStatusLooseActive,
.SportboomTableStatusInGameActive,
.SportboomTableStatusRedeemActive,
.SportboomTableStatusReturnBetActive,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig .SportboomContentTableConfigCount {
  color: #FFFFFF !important;
}
.White800Background {
  background-color: #F4F4F4 !important;
}
.White100Background,
.SuperwinsGameListWrapper,
.SportboomTableMobileItem,
.SportboomTableMobileListPagination,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig,
.TooltipComponentModal .ant-popover-content .ant-popover-inner {
  background-color: #FFFFFF !important;
}
.White800Border {
  border-color: #F4F4F4 !important;
}
.White100Border {
  border-color: #FFFFFF !important;
}
.BBYellowColor {
  color: #F8E800 !important;
}
.BBRed500Color {
  color: #FF0025 !important;
}
.BBYellowBackground {
  background-color: #F8E800 !important;
}
.BBRedBackground {
  background-color: #FF0025 !important;
}
.BBYellowBorder {
  border-color: #F8E800 !important;
}
.BBRed500Border {
  border-color: #FF0025 !important;
}
.MarginAuto {
  margin: auto;
}
.MarginLeftAuto {
  margin-left: auto;
}
.MarginRightAuto {
  margin-right: auto;
}
.MarginTopAuto {
  margin-top: auto;
}
.MarginBottomAuto {
  margin-bottom: auto;
}
.Padding2 {
  padding: 2px;
}
.PaddingLeft2 {
  padding-left: 2px;
}
.PaddingRight2 {
  padding-right: 2px;
}
.PaddingTop2 {
  padding-top: 2px;
}
.PaddingBottom2 {
  padding-bottom: 2px;
}
.Margin2 {
  margin: 2px;
}
.MarginLeft2,
.SportboomTableActiveRow .ant-table-selection-column .ant-checkbox-wrapper {
  margin-left: 2px;
}
.MarginRight2 {
  margin-right: 2px;
}
.MarginTop2,
.SportboomTableInsideEmpty div svg {
  margin-top: 2px;
}
.MarginBottom2 {
  margin-bottom: 2px;
}
.Gap2 {
  gap: 2px;
}
.Padding4 {
  padding: 4px;
}
.PaddingLeft4 {
  padding-left: 4px;
}
.PaddingRight4,
.TableConfigModalListItem {
  padding-right: 4px;
}
.PaddingTop4,
.SportboomTableMobileItemBetNumber,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig span {
  padding-top: 4px;
}
.PaddingBottom4 {
  padding-bottom: 4px;
}
.Margin4 {
  margin: 4px;
}
.MarginLeft4 {
  margin-left: 4px;
}
.MarginRight4 {
  margin-right: 4px;
}
.MarginTop4 {
  margin-top: 4px;
}
.MarginBottom4 {
  margin-bottom: 4px;
}
.Gap4 {
  gap: 4px;
}
.Padding6 {
  padding: 6px;
}
.PaddingLeft6,
.PayrollStatusTableComment,
.QiwiStatusTableComment,
.PaymentStatusTableComment,
.YandexStatusTableComment {
  padding-left: 6px;
}
.PaddingRight6,
.PayrollStatusTableComment,
.QiwiStatusTableComment,
.PaymentStatusTableComment,
.YandexStatusTableComment {
  padding-right: 6px;
}
.PaddingTop6,
.SportboomTableMobileItemBetStart,
.PayrollStatusTableColumn span,
.PayrollStatusTableComment,
.QiwiStatusTableColumn span,
.QiwiStatusTableComment,
.PaymentStatusTableColumn span,
.PaymentStatusTableComment,
.YandexStatusTableColumn span,
.YandexStatusTableComment {
  padding-top: 6px;
}
.PaddingBottom6 {
  padding-bottom: 6px;
}
.Margin6 {
  margin: 6px;
}
.MarginLeft6 {
  margin-left: 6px;
}
.MarginRight6 {
  margin-right: 6px;
}
.MarginTop6,
.TableConfigModalClose,
.SportboomTableMobileItemDetailStatus {
  margin-top: 6px;
}
.MarginBottom6 {
  margin-bottom: 6px;
}
.Gap6,
.TableConfigModalTemplateWrapper {
  gap: 6px;
}
.Padding8,
.PayrollFilesItemWrapper,
.PaymentFilesItemWrapper {
  padding: 8px;
}
.PaddingLeft8 {
  padding-left: 8px;
}
.PaddingRight8 {
  padding-right: 8px;
}
.PaddingTop8 {
  padding-top: 8px;
}
.PaddingBottom8,
.DetailTooltipContentTitle {
  padding-bottom: 8px;
}
.Margin8 {
  margin: 8px;
}
.MarginLeft8,
.SportboomTableRow .ant-table-selection-column .ant-checkbox-wrapper,
.SportboomTableMobileWrapper {
  margin-left: 8px;
}
.MarginRight8,
.SportboomTableMobileWrapper {
  margin-right: 8px;
}
.MarginTop8 {
  margin-top: 8px;
}
.MarginBottom8 {
  margin-bottom: 8px;
}
.Gap8,
.TableConfigModalListItem,
.SportboomConfigRemoveModal .SportboomConfigRemoveModalButtons,
.SportboomTableInsideEmpty div,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig {
  gap: 8px;
}
.Padding10 {
  padding: 10px;
}
.PaddingLeft10 {
  padding-left: 10px;
}
.PaddingRight10 {
  padding-right: 10px;
}
.PaddingTop10,
.TableConfigModalList {
  padding-top: 10px;
}
.PaddingBottom10 {
  padding-bottom: 10px;
}
.Margin10 {
  margin: 10px;
}
.MarginLeft10 {
  margin-left: 10px;
}
.MarginRight10 {
  margin-right: 10px;
}
.MarginTop10 {
  margin-top: 10px;
}
.MarginBottom10 {
  margin-bottom: 10px;
}
.Gap10 {
  gap: 10px;
}
.Padding12 {
  padding: 12px;
}
.PaddingLeft12,
.PayrollStatusTableColumn span,
.QiwiStatusTableColumn span,
.PaymentStatusTableColumn span,
.YandexStatusTableColumn span {
  padding-left: 12px;
}
.PaddingRight12 {
  padding-right: 12px;
}
.PaddingTop12 {
  padding-top: 12px;
}
.PaddingBottom12,
.StatusNotifyHallPicker {
  padding-bottom: 12px;
}
.Margin12 {
  margin: 12px;
}
.MarginLeft12 {
  margin-left: 12px;
}
.MarginRight12 {
  margin-right: 12px;
}
.MarginTop12 {
  margin-top: 12px;
}
.MarginBottom12 {
  margin-bottom: 12px;
}
.Gap12,
.TableConfigModalList,
.TableConfigModalTemplateAddForm {
  gap: 12px;
}
.Padding14 {
  padding: 14px;
}
.PaddingLeft14 {
  padding-left: 14px;
}
.PaddingRight14 {
  padding-right: 14px;
}
.PaddingTop14 {
  padding-top: 14px;
}
.PaddingBottom14 {
  padding-bottom: 14px;
}
.Margin14 {
  margin: 14px;
}
.MarginLeft14 {
  margin-left: 14px;
}
.MarginRight14 {
  margin-right: 14px;
}
.MarginTop14 {
  margin-top: 14px;
}
.MarginBottom14 {
  margin-bottom: 14px;
}
.Gap14 {
  gap: 14px;
}
.Padding16 {
  padding: 16px;
}
.PaddingLeft16 {
  padding-left: 16px;
}
.PaddingRight16 {
  padding-right: 16px;
}
.PaddingTop16 {
  padding-top: 16px;
}
.PaddingBottom16 {
  padding-bottom: 16px;
}
.Margin16,
.TableConfigModalTemplateAddForm {
  margin: 16px;
}
.MarginLeft16 {
  margin-left: 16px;
}
.MarginRight16 {
  margin-right: 16px;
}
.MarginTop16,
.TableConfigModalTemplateWrapper {
  margin-top: 16px;
}
.MarginBottom16 {
  margin-bottom: 16px;
}
.Gap16,
.SportboomConfigRemoveModal,
.SportboomTableDetailInfo,
.SportboomCompareWrapper,
.SportboomTableMobileItemDetailStatus {
  gap: 16px;
}
.Padding18 {
  padding: 18px;
}
.PaddingLeft18 {
  padding-left: 18px;
}
.PaddingRight18 {
  padding-right: 18px;
}
.PaddingTop18 {
  padding-top: 18px;
}
.PaddingBottom18 {
  padding-bottom: 18px;
}
.Margin18 {
  margin: 18px;
}
.MarginLeft18 {
  margin-left: 18px;
}
.MarginRight18 {
  margin-right: 18px;
}
.MarginTop18 {
  margin-top: 18px;
}
.MarginBottom18 {
  margin-bottom: 18px;
}
.Gap18 {
  gap: 18px;
}
.Padding20 {
  padding: 20px;
}
.PaddingLeft20 {
  padding-left: 20px;
}
.PaddingRight20 {
  padding-right: 20px;
}
.PaddingTop20 {
  padding-top: 20px;
}
.PaddingBottom20 {
  padding-bottom: 20px;
}
.Margin20 {
  margin: 20px;
}
.MarginLeft20 {
  margin-left: 20px;
}
.MarginRight20 {
  margin-right: 20px;
}
.MarginTop20 {
  margin-top: 20px;
}
.MarginBottom20 {
  margin-bottom: 20px;
}
.Gap20 {
  gap: 20px;
}
.Padding24,
.StatusNotifyUserCrudButtons {
  padding: 24px;
}
.PaddingLeft24 {
  padding-left: 24px;
}
.PaddingRight24 {
  padding-right: 24px;
}
.PaddingTop24,
.TableConfigModalListWrapper,
.StatusNotifyHallPicker {
  padding-top: 24px;
}
.PaddingBottom24 {
  padding-bottom: 24px;
}
.Margin24 {
  margin: 24px;
}
.MarginLeft24,
.StatusNotifyHallPicker {
  margin-left: 24px;
}
.MarginRight24 {
  margin-right: 24px;
}
.MarginTop24,
.TableConfigModalApply {
  margin-top: 24px;
}
.MarginBottom24 {
  margin-bottom: 24px;
}
.Gap24 {
  gap: 24px;
}
.Padding32 {
  padding: 32px;
}
.PaddingLeft32 {
  padding-left: 32px;
}
.PaddingRight32 {
  padding-right: 32px;
}
.PaddingTop32 {
  padding-top: 32px;
}
.PaddingBottom32 {
  padding-bottom: 32px;
}
.Margin32 {
  margin: 32px;
}
.MarginLeft32 {
  margin-left: 32px;
}
.MarginRight32 {
  margin-right: 32px;
}
.MarginTop32 {
  margin-top: 32px;
}
.MarginBottom32 {
  margin-bottom: 32px;
}
.Gap32,
.SportboomContentSwitcherWrapper {
  gap: 32px;
}
.Padding40 {
  padding: 40px;
}
.PaddingLeft40 {
  padding-left: 40px;
}
.PaddingRight40 {
  padding-right: 40px;
}
.PaddingTop40 {
  padding-top: 40px;
}
.PaddingBottom40 {
  padding-bottom: 40px;
}
.Margin40 {
  margin: 40px;
}
.MarginLeft40 {
  margin-left: 40px;
}
.MarginRight40 {
  margin-right: 40px;
}
.MarginTop40 {
  margin-top: 40px;
}
.MarginBottom40 {
  margin-bottom: 40px;
}
.Gap40 {
  gap: 40px;
}
.Padding64 {
  padding: 64px;
}
.PaddingLeft64 {
  padding-left: 64px;
}
.PaddingRight64 {
  padding-right: 64px;
}
.PaddingTop64 {
  padding-top: 64px;
}
.PaddingBottom64 {
  padding-bottom: 64px;
}
.Margin64 {
  margin: 64px;
}
.MarginLeft64 {
  margin-left: 64px;
}
.MarginRight64 {
  margin-right: 64px;
}
.MarginTop64 {
  margin-top: 64px;
}
.MarginBottom64 {
  margin-bottom: 64px;
}
.Gap64 {
  gap: 64px;
}
.FullSize {
  width: 100%;
  height: 100%;
}
.FullSizeWidth {
  width: 100%;
}
.FullSizeHeight {
  height: 100%;
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Semibold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Bold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 900;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Extrabold.otf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}
body {
  margin: 0 !important;
  font-family: Lato, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  min-width: 375px !important;
  color: #1d1f21;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
  font-family: Gilroy, sans-serif;
}
.App h1,
.App .Title-h1 {
  font-size: 30px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;
}
.App h2 {
  font-size: 24px;
}
.App h3 {
  font-size: 20px;
}
.App h4 {
  font-size: 16px;
}
.App img {
  display: block;
  max-width: 100%;
}
.App span {
  font-family: Lato, sans-serif;
}
.App input {
  font-family: Lato, sans-serif !important;
  font-weight: 500;
}
.App input::placeholder {
  color: #ADB5BD !important;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
iframe {
  display: none !important;
}
.HeadlineFirstText,
.NominalsModalSuccessTitle,
.NominalsTemplateCard_NumbersTable_Cell_Currency {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
}
.HeadlineSecondText,
.SportBoomMobileTitle,
.RaceErrContainerTextMain,
.HistoryModalContainer__header {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.HeadlineThirdText,
.DetailTooltipContentTitle,
.SportBoomWalletModalEmptyWrapper_title {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextOne,
.TextOneBold,
.SuperwinListItem .SuperwinListMainRow,
.SportBoomWalletModalEmptyWrapper_subtitle,
.HistoryBetsDevelopment,
.HistoryLiveCell,
.TextOneBold,
.ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
  font-family: 'Lato' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.TextOneBold {
  font-weight: 700 !important;
}
.TextTwo,
.TextTwoBold,
.TextTwoBold {
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}
.TextTwoBold {
  font-weight: 700 !important;
}
.SubtitleFirstText,
.WikiDashboardCardName,
.SubtitleFirstTextWikiFilter {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.SubtitleSecondText,
.SportBoomWalletModalMobileTableCellValue .SportBoomWalletModalMobileTableCellValueInner,
.HistoryModalContainer__body,
.ContentPeriodDescription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
}
.BodyFirstText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}
.BodySecondText,
.SportboomTableMobileItemBetNumber,
.ChangeTabTennisContainer_title,
.ChangeTab_Nominals-mock-title .TitleDescription .TitleText span,
.NominalsTemplateCard_Title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
}
.ChartDecodingText,
.ButtonNotifiGray .ant-btn div span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #212529;
}
.TextDefault,
.ChangeTabTennisContainer_tooltip_text,
.ChangeTabColWrapper_TitleImageContainer_Text-secondary,
.ChangeTabColNominalChange_Title,
.NominalsTemplateCard_RightTitle,
.HistoryBetsDateTimeCellDate {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold,
.SportboomWalletCellWrapper div,
.ChangeTabColWrapper_TitleImageContainer_Text,
.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max,
.NominalsTemplateCard_Cell_SubTitle_Value,
.MobileTableCardLimitsToggleContainer .MobileTableCardLimitsToggleContainer_Text,
.NominalsTemplateCard_NumbersTable_Cell_Value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
  color: #212529;
}
.Text1,
.RaceExpandedTableCell__Text,
.RaceDetailSubtitle,
.Geoservices_Photos__Info_Text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold,
.SportboomWalletCellWrapper div,
.ChangeTabColWrapper_TitleImageContainer_Text,
.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max,
.NominalsTemplateCard_Cell_SubTitle_Value,
.MobileTableCardLimitsToggleContainer .MobileTableCardLimitsToggleContainer_Text,
.NominalsTemplateCard_NumbersTable_Cell_Value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}
.TableHeaderText,
.StaticDashboardHallsSingleHall,
.StaticDashboardHallsSingleHall_number {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.StatusText,
.DashboardItemDetailsButton,
.SportboomTableStatus,
.RaceStatus,
.NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile,
.NominalStatus,
.SportboomTableStatusWin,
.SportboomTableStatusWinActive,
.SportboomTableStatusLoose,
.SportboomTableStatusLooseActive,
.SportboomTableStatusInGame,
.SportboomTableStatusInGameActive,
.SportboomTableStatusRedeem,
.SportboomTableStatusRedeemActive,
.SportboomTableStatusReturnBet,
.SportboomTableStatusReturnBetActive {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.TableHeader,
.TableHeader > span,
.NominalsTemplateCard_NumbersTable_Numbers .NominalsTemplateCard_NumbersTable_Cell,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th,
thead tr th.ant-table-cell,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.CashboxTableHeaderItem {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}
.CaptionText,
.SportboomTableMobileItemBetStart,
.RaceModalEmpty .RaceModalEmptyText,
.NominalsTemplateCard_Cell_Title,
.NominalsTemplateCard_Cell_SubTitle_Currency,
.NominalsTemplateCard_NumbersTable_MinMaxHead,
.NominalsTemplateCard_NumbersTable_Cell_Currency,
.MobileTableCardContainer .MobileTableCardRowItemTitle,
.MobileTableCardContainer .ChangeTabColWrapper_TitleImageContainer_Text-secondary {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
}
.CaptionTextBold,
.MobileTableCardLimitsSwitchContainer .MobileTableCardLimitsSwitchItem {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #212529;
}
.OverlineText,
.SportBoomWalletModalMobileTableCellTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
}
.Overline {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #212529;
}
.ButtonTextFirst,
.ant-picker-ok > button.ant-btn > span:after {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.ButtonTextSecond,
div.RaceWinnersButton span,
.PayrollsTitleButtons .ButtonFadedWrapper button span,
.PaymentTitleButtons .ButtonFadedWrapper button span,
.ButtonNotifiWrapper .ant-btn div span,
.ButtonNotifiBlue .ant-btn div span,
.ButtonOutlinedWrapper .ant-btn div span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}
.TextLabelText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
}
.Rectangle {
  height: 1px;
  background: #E5E5EA;
  width: 100%;
}
.Uppercase {
  text-transform: uppercase;
}
.HideScroll::-webkit-scrollbar {
  display: none !important;
}
.CapitalizeText {
  text-transform: capitalize;
}
.HoverItem:hover {
  cursor: pointer;
  opacity: 0.8;
}
.TextAlignCenter {
  text-align: center;
}
.TextUnderline {
  text-decoration: underline;
}
.RelativePosition {
  position: relative;
}
.HideBlock {
  display: none !important;
}
.ButtonSecondary {
  background-color: #F1F3F5 !important;
  border: transparent !important;
}
.DisplayNoneElement {
  display: none !important;
}
.LoginWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #212529;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LoginHeader {
  width: 100%;
  background-color: #2C2C2E;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.LoginFormWrapper {
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  max-width: 485px;
}
.LoginFormLogoLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17.5px;
  line-height: 22px;
  color: #E9ECEF;
  padding-bottom: 38px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-height: 800px) {
  .LoginFormLogoLabel {
    margin-top: calc(50vh - 400px);
  }
}
@media screen and (max-height: 799px) {
  .LoginFormLogoLabel {
    margin-top: 20px;
  }
}
.LoginFormLogoLabel svg {
  margin-left: 24px;
  margin-bottom: 20px;
}
.LoginFormLogoLabel div span {
  padding-left: 8px;
  padding-top: 4px;
}
.LoginFormLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 22px;
  color: #212529;
  padding-top: 60px;
  padding-bottom: 32px;
}
.LoginFormFieldWrapper {
  display: grid;
  place-items: center;
}
.LoginFormField {
  width: 320px;
  padding-top: 8px;
}
.ErrorMessage {
  display: grid;
  place-items: center;
  color: #ff0025;
  padding: 5px 0;
}
.LoginFormButtons {
  display: grid;
  place-items: center;
  padding-bottom: 69px;
}
.LoginFormEnter {
  width: 240px;
  height: 48px !important;
  color: #0d0d0d !important;
}
.LoginFormEnter:hover {
  background-color: #ebdb00 !important;
  border-color: #ebdb00 !important;
}
.BaseLayoutLeftMenuContent {
  max-height: calc(100vh - 450px);
  overflow-y: auto;
  margin-right: 4px;
}
.BaseLayoutLeftMenuContent::-webkit-scrollbar {
  width: 8px;
  background-color: #1C1C1E;
  border-radius: 20px;
}
.BaseLayoutLeftMenuContent::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #8E8E93;
  border-radius: 20px;
}
.BaseLayoutLeftMenuContent::-webkit-scrollbar-thumb {
  background-color: #8E8E93;
  border-radius: 20px;
  margin-right: 4px;
}
.BaseLayoutLeftMenuContent::-webkit-scrollbar-track {
  background-color: #1C1C1E;
}
@media screen and (max-width: 920px) {
  .LeftMenuDesktop {
    display: none !important;
  }
  .BaseLayoutLeftMenuContent {
    max-height: 100% !important;
    overflow-y: inherit !important;
  }
  .MenuToggleMobile {
    position: absolute;
    left: 177px;
    top: 8px;
  }
  .MenuToggleMobileClose {
    left: 0 !important;
    top: 0 !important;
    background-color: #1A1B1F;
    border-bottom-right-radius: 8px;
    padding: 8px;
    z-index: 10;
  }
  .BaseLayoutLeftMenuDisappear {
    display: none !important;
  }
  .MenuToggleMobileClose svg path {
    fill: #F8E800;
  }
}
.BaseLayoutLeftMenuLink {
  color: #fff;
}
.BaseLayoutLeftMenuLink:hover {
  color: #F8E800 !important;
}
@media screen and (min-width: 921px) {
  .MenuToggleMobile {
    display: none !important;
  }
  .LeftMenuMobile {
    display: none !important;
  }
}
.HallPickerPageWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
.HallPickerPageContainer {
  width: 830px;
  margin-left: auto;
  margin-right: auto;
}
.HallPickerPageContent {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.HallPickerPageTitle {
  padding: 84px 0 44px 12px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0D0D0D;
}
.HallPickerLogout {
  padding-top: 3px;
}
.HallPickerLogout:hover {
  cursor: pointer;
}
.HallPickerPageTitle span {
  padding-left: 8px;
}
.HallPickerPageSearchWrapper {
  margin-left: auto;
  padding-top: 84px;
  margin-right: 24px;
  width: 250px;
}
.HallPickerPageSearchWrapper .ant-input-affix-wrapper {
  background-color: #fff !important;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E5E5EA;
  width: 250px;
  padding: 12px !important;
}
.HallPickerPageSearchWrapper input {
  background-color: #fff;
}
.HallPickerPageSearchWrapper > .ant-input-affix-wrapper:hover {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}
.HallPickerPageSearchWrapper > .ant-input:focus,
.HallPickerPageSearchWrapper > .ant-input-focused,
.HallPickerPageSearchWrapper > .ant-input-affix-wrapper-focused,
.HallPickerPageSearchWrapper > .ant-input:focus,
.HallPickerPageSearchWrapper > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}
.HallPickerPageSearchWrapper #placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #AAABAD;
  width: 100%;
}
.HallPickerPageItem {
  padding-left: 24px;
  padding-top: 18px;
  width: 208px;
  height: 56px;
  margin: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.HallPickerPageItem:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}
.HallPickerPageItemDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #8E8E93;
  padding-top: 2px;
}
.HallPickerPageItemLabel {
  padding-left: 6px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.HallPickerPageDisabledItem {
  background-color: #F8F8F8;
}
.HallPickerPageDisabledItem:hover {
  cursor: not-allowed !important;
}
@media screen and (max-width: 720px) {
  .HallPickerPageWrapper,
  .HallPickerPageContainer {
    width: 100vw !important;
  }
  .HallPickerPageTitle {
    padding: 32px 16px !important;
  }
  .HallPickerPageSearchWrapper {
    padding: 0 16px 32px 16px !important;
    margin: 0 !important;
  }
  .HallPickerPageItem {
    width: 90px !important;
    padding-left: 16px !important;
  }
}
.MessagesHeader {
  width: 544px;
  padding: 40px 32px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}
.MessagesHeader span {
  padding-top: 3px;
}
.MessageTextWrapper {
  max-height: 260px;
  height: 260px;
  overflow-y: auto;
  padding: 0 32px;
  margin-right: 4px;
  margin-bottom: 24px;
}
.MessageTextWrapper::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}
.MessagesFooter {
  width: 100%;
  border-top: 1px solid #E5E5EA;
  padding: 16px 16px 16px 32px;
}
.MessageCount {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #8E8E93;
  padding-top: 16px;
}
.MessageCountTotal {
  color: #000 !important;
}
.MessagesFooter > .ButtonPrimaryWrapper {
  padding-left: 224px;
}
.MessagesFooterButtons {
  gap: 8px;
  margin-left: auto;
}
.MainSuperWinWrapper {
  margin-top: 20px;
  border-radius: 8px;
}
.MainSuperWinWrapper .ant-collapse {
  border-radius: 8px;
}
.MainSuperWinWrapper .ant-collapse .ant-collapse-item {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px !important;
  box-sizing: border-box !important;
}
.MainSuperWinWrapper .ant-collapse .ant-collapse-item:hover {
  border: 1px solid #CED4DA;
}
.MainSuperWinWrapper .ant-collapse .ant-collapse-item-active:hover {
  border: 1px solid #E9ECEF !important;
}
.MainSuperWinWrapper .ant-collapse .ant-collapse-item .ant-collapse-header {
  display: grid;
  place-items: center;
}
.ant-collapse-content-active {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.MainSuperWinWrapper > div > div > .ant-collapse-content {
  border-top: 1px solid #E5E5EA !important;
  background-color: #fff !important;
}
.MainSuperHeaderWrapper {
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 6px 0;
}
.MainSuperGamesWrapper {
  padding: 8px 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
}
.MainSuperGamesLogo .MainSuperGamesLogoImg {
  margin-top: 8px;
}
.MainSuperGamesLogoImg {
  width: 28px;
}
.MainSuperGamesLogo span {
  color: #3A3A3C;
  padding-left: 8px;
  padding-top: 12px;
}
.MainSuperGamesHeader {
  justify-content: space-between;
  padding-right: 20px;
  margin-left: 16px;
}
.MainSuperButtonWrapper {
  padding-top: 5px;
}
.MainSuperButtonWrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1590px) {
  .MainSuperGamesHeader {
    flex-wrap: wrap;
  }
  .MainSuperButtonWrapper {
    padding-bottom: 24px;
  }
}
.MainSuperButtonLabel svg path {
  fill: #fff;
}
.MainSuperButtonLabel span {
  padding-left: 8px;
  padding-top: 4px;
  color: #fff !important;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.MainSuperHeaderHallWrapper {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #8E8E93;
  padding-left: 8px;
}
.MainSuperTablesWrapper {
  overflow-x: auto;
  padding-right: 20px;
}
.MainSuperTableWrapper {
  min-width: 954px;
  border-radius: 8px;
  border: 1px solid #E9ECEF;
}
.MainSuperTableHeaderWrapper {
  margin-bottom: 6px;
}
.MainSuperGamesHeader,
.MainSuperTableWrapper {
  background: #fff;
}
.MainSuperTableWrapper {
  margin: 0 0 16px 16px;
}
.MainSuperTableRow {
  width: 100%;
}
.MainSuperTableHeaderCell {
  background: #E3FAFC;
  padding: 20px 13px 12px 0;
  text-align: left;
  height: 54px;
}
.MainSuperTableHeaderCell,
.MainSuperTableMobileTitleMain {
  background: #E3FAFC;
}
.MainSuperTableMobileTitleVip,
.MainSuperTableHeaderVip {
  background: #FFF4E6 !important;
}
.MainSuperTableFirstCell {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 18px 32px 18px 0;
  width: 100%;
  text-align: left;
  height: 54px;
}
.MainSuperTableLeftCell {
  text-align: left !important;
  min-width: 176px;
  padding-left: 24px;
}
.MainSuperTableCellBordered {
  border-bottom: 1px solid #E5E5E5;
}
.MainSuperWinSpinWrapper {
  margin: 32px;
}
@media screen and (max-width: 920px) {
  .MainSuperButtonWrapper {
    padding-left: 0 !important;
  }
  .MainSuperTableWrapper {
    display: none;
  }
  .MainSuperTableMobileWrapper {
    border: 1px solid #E9EAEA;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .MainSuperTableMobileTitle {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 1px solid #E5E5E5;
  }
  .MainSuperTableMobileHeader {
    background-color: #FBFBFB;
    border-bottom: 1px solid #E9EAEA;
  }
  .MainSuperTableMobileHeaderItem,
  .MainSuperTableMobileHeaderItemLast {
    padding: 12px;
    width: calc((100vw - 40px) / 3);
    display: grid;
    place-items: center;
    text-align: center;
  }
  .MainSuperTableMobileBodyItem,
  .MainSuperTableMobileBodyItemLast {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2B2D33;
    padding: 12px;
    width: calc((100vw - 40px) / 3);
    display: grid;
    place-items: center;
    text-align: center;
  }
  .MainSuperTableMobileHeaderItem,
  .MainSuperTableMobileBodyItem {
    border-right: 1px solid #E9EAEA;
  }
  .MainSuperTableMobileBodyRow {
    border-bottom: 1px solid #E9EAEA;
  }
  .MainSuperTableMobileBodyRowLast {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom: none !important;
  }
}
.MainFilterWrapper {
  position: fixed;
  top: 0;
  width: 320px;
  background: #FFFFFF;
  z-index: 5;
  transition: right 500ms ease;
  border-left: 1px solid #E5E5EA;
}
.MainFilterWrapperUnActive {
  right: -298px;
  box-sizing: border-box;
  box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.08);
}
@media screen and (min-width: 921px) and (max-width: 1680px) {
  .MainFilterWrapperActive {
    box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.08) !important;
  }
}
.MainFilterWrapperActive {
  right: 0;
}
.MainFilterHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  justify-content: space-between;
  padding: 24px 32px 0 32px;
}
@media screen and (min-width: 921px) {
  .MainFilterMobileClose {
    display: none !important;
  }
  .MainFilterWrapper {
    height: 100vh;
  }
  .MainFilterContent {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 169px);
    margin-right: 4px;
  }
}
.MainFilterContent {
  padding: 16px 28px 32px 32px;
  display: flex;
  flex-direction: column;
}
.MainFilterContentLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 12px;
}
.MainFilterGmtWrapper {
  padding: 12px 0 30px 0;
}
.MainFilterLocationLabel,
.MainFilterGmtLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.MainFilterGmtLabel {
  opacity: 0.5;
}
.MainFilterGmtHourWrapper {
  padding-top: 12px;
}
.MainFilterGmtHour {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  padding-left: 8px;
}
.MainFilterGmtHourMoscow {
  opacity: 0.5;
}
.MainFilterApplyWrapper {
  border-top: 1px solid #E5E5EA;
  padding: 32px;
  margin-top: auto;
}
@media screen and (max-width: 920px) {
  .MainFilterWrapper {
    display: none;
  }
  .MainFilterApplyWrapper {
    border-bottom: 1px solid #E5E5EA;
    margin-bottom: 120px;
  }
  .MainFilterMobileClose {
    position: absolute;
    right: 16px;
  }
  .MainFilterWrapperActive::-webkit-scrollbar,
  .MainFilterMobile::-webkit-scrollbar {
    display: none !important;
  }
}
.MainFilterApplyWrapper button {
  min-width: 256px !important;
  width: 100%;
}
MainFilterMobile .MainFilterApplyWrapper .ButtonPrimaryWrapper button {
  min-width: 208px;
}
.ComponentDashboardItemSkeleton {
  background-color: #F4F4F4;
}
.ComponentDashboardItemSkeleton .ant-skeleton-content {
  width: 224px !important;
  height: 84px !important;
}
.DashboardItemTitleContainer {
  display: flex;
  flex-direction: column;
}
.DashboardItemDetailsButton {
  margin-top: 20px;
  display: flex;
  padding: 3px 12px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #CED4DA;
  width: 89px;
  height: 24px;
}
.MainPageContentWrapper {
  padding: 40px 32px;
  transition: width 500ms ease;
}
.MainPageContentRestrictions {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 920px) {
  .MainPageContentWrapperFilter {
    width: 100vw !important;
  }
  .MainPageFilterButtonWrapper {
    display: none;
  }
  .MainPageContentRestrictions {
    width: 100vw;
  }
  .MainFilterWrapper {
    position: inherit !important;
  }
  .MainPageContentWrapper {
    padding: 56px 0 32px 0 !important;
  }
  .MobileFilterIcon {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 10;
  }
  .MainFilterMobile {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 16px 0 0 0;
    background-color: white;
    height: 100vh;
    overflow-y: auto;
    z-index: 20;
  }
  .MainFilterMobile .MainFilterHeader {
    padding: 0 32px;
  }
  .MainFilterMobile .MainFilterContent {
    padding: 16px 32px 32px 32px;
  }
  .MainFilterMobile .MainFilterApplyWrapper {
    border-bottom: 0 !important;
  }
}
@media screen and (min-width: 1681px) {
  .MainPageContentWrapperFilter {
    width: calc(100vw - 640px);
  }
  .MainPageContentWrapperUnFilter {
    width: calc(100vw - 340px);
  }
}
@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .MainPageContentWrapper {
    width: calc(100vw - 330px) !important;
  }
}
@media screen and (min-width: 921px) {
  .MobileFilterIcon {
    display: none !important;
  }
  .MainFilterMobile {
    display: none !important;
  }
  .MainPageContentWrapper,
  .MainPageContentWrapperUnFilter {
    min-height: calc(100vh - 30px);
  }
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .MainPageContentWrapper {
    width: calc(100vw - 278px) !important;
  }
}
.MainPageFilterButtonWrapper {
  position: fixed;
  top: 32px;
  width: 24px;
  height: 24px;
  transition: all 500ms ease;
  transition-property: right, background-color;
  background: #FFFFFF;
  border: 1px solid #E5E5EA;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 6;
}
.MainPageFilterButtonWrapper svg {
  margin: 3px 0 0 5px;
  transition: transform 500ms ease;
}
.MainPageFilterButtonWrapper svg path {
  transition: fill 500ms ease;
}
.MainPageFilterButtonWrapper:hover {
  cursor: pointer;
}
.MainPageFilterButtonWrapperActive {
  right: 306px;
  background-color: #fff;
}
.MainPageFilterButtonWrapperActive svg {
  transform: rotate(0deg);
}
.MainPageFilterButtonWrapperActive svg path {
  fill: #8E8E93;
}
.MainPageFilterButtonWrapperUnActive {
  right: 10px;
  background-color: black;
}
.MainPageFilterButtonWrapperUnActive svg {
  transform: rotate(180deg);
}
.MainPageFilterButtonWrapperUnActive svg path {
  fill: #fff;
}
.MainPageTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0D0D0D;
  padding: 0 8px 8px 8px;
}
.MainPageHallsWrapper {
  padding: 0 8px 24px 4px;
  max-height: 120px;
  overflow-y: auto;
  margin-bottom: 12px;
  margin-right: 30px;
}
.MainPageHallsItem {
  background: rgba(255, 221, 45, 0.2);
  border: 1px solid #FEE600;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 5px 24px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 4px;
}
.MainPageFilterButtonWrapper {
  margin-top: -8px;
  padding-right: 8px;
  margin-left: auto;
}
.MainSuperWinContent {
  padding-bottom: 24px;
}
.DashboardItemLabelLink div span:first-child {
  text-decoration-line: underline !important;
}
.DashboardItemLabelLink div span {
  color: #007AFF !important;
}
.DashboardItemLabelLink:hover {
  cursor: pointer;
  opacity: 0.8;
}
.SuperWinsColumns_ZoneCell,
.SuperWinsColumns_GameCell,
.SuperWinsColumns_CurrencyCell {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.CashboxFilterComponentWrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.CashboxFilterContentWrapper {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 169px);
}
.CashboxFilterComponentTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 32px;
}
.CashboxFilterApplyButtonWrapper {
  margin-top: auto;
}
.CashboxFilterApplyButton {
  width: 100%;
  height: 48px;
  margin-top: 32px;
}
.CashboxFilterContent {
  padding: 12px 0 30px 0;
}
.FilterContentLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.InfoPanelWithoutShiftTypeSwitcher {
  padding-bottom: 20px;
  margin-left: auto;
}
.InfoPanelWithoutShiftTimeWrapper {
  padding-top: 8px;
}
.CashboxTableHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 0;
  width: 100%;
  background-color: #E6F6F8 !important;
}
.CashboxTableArrowItem {
  padding-left: 11px;
  padding-right: 11px;
}
.CashboxTableArrowHeaderItem {
  padding-left: 24px;
  padding-right: 11px;
}
.CashboxTableLastItem {
  font-weight: bold;
}
.CashboxTableRowWrapper {
  border-bottom: 1px solid #ededf0;
}
.CashboxTableRow,
.CashboxTableLastRow {
  display: flex;
  flex-direction: row;
  padding: 18px 0;
  width: 100%;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E;
  background-color: #fff !important;
}
.CashboxTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.CashboxTableActiveRow {
  font-family: Lato, sans-serif;
  color: #000000 !important;
}
.TableRowItemUp svg {
  transform: rotate(-180deg);
  transition-duration: 0.2s;
}
.TableRowItemDown svg {
  transform: rotate(0deg);
  transition-duration: 0.2s;
}
.CashboxTableRow:hover {
  cursor: pointer;
  background: #F8F8F8 !important;
}
.CashboxTableRowDisabled:hover {
  cursor: no-drop !important;
}
.ReportTableArrowItem {
  padding-left: 16px;
  width: 20px;
}
.CashboxTableWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed {
  min-width: auto !important;
}
.CashboxTableInsideContent {
  padding: 16px 0 16px 36px;
  background-color: #fff !important;
}
.CashboxTableInsideItemTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding-bottom: 10px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 8px;
}
.CashboxTableInsideItemTitle span {
  padding-top: 1px;
}
.ReportInsideHallInfo {
  margin-top: -1px;
}
.ReportInsideHallInfo:hover svg path {
  fill: #808185;
}
.CashboxTableInsideItemTitle svg:hover {
  cursor: pointer;
}
.CashboxInsideRow {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  width: calc(100% - 32px);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E !important;
}
.CashboxInsideRowTotal {
  background-color: #F8F9FA;
  font-weight: bold !important;
}
.CashboxInsideRowTotal > .CashboxTableItem > div {
  margin-top: 2px;
}
.CashboxTableInsideItemDescription {
  padding-left: 8px;
  padding-top: 4px;
}
.CashboxTableInsideWithBorder {
  margin-top: 10px;
  margin-bottom: 18px;
}
.InfoPopoverWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 8px !important;
}
.CashboxInsidePopoverInfo {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding: 0 4px;
  color: #fff;
}
.CashboxTableItemValue {
  padding-top: 5px;
}
.CashboxTableItemNegativeValue {
  color: #FF3B30 !important;
}
@media screen and (max-width: 920px) {
  .CashboxMobileInsideWrapper {
    padding: 16px;
  }
  .CashboxTableInsideContent {
    padding: 16px 0 16px 50px !important;
    background-color: white !important;
  }
  .CashboxInsideRow {
    padding: 8px 16px 8px 0;
  }
}
.CashboxMobileHallTable {
  border: 1px solid #E9EAEA;
  border-radius: 8px;
  margin: 8px;
}
.CashboxMobileHallTableHeader {
  padding: 12px 24px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.CashboxMobileHallTableHeader span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #2B2D33;
  padding-left: 8px;
  padding-top: 2px;
}
.CashboxMobileHallTableItem {
  padding: 16px;
}
.CashboxMobileHallTableItem .CashboxMobileHallTableItemDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #808185;
}
.CashboxMobileHallTableItem .CashboxMobileHallTableItemValue {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
}
.CashboxTableItem {
  min-width: 135px;
}
.CashboxTableItem:first-child {
  max-width: 224px;
}
.CashboxTableItem:first-child {
  width: calc(18% - 10px);
}
.CashboxTableItem:nth-child(2) {
  width: calc(27% + 30px);
}
.CashboxTableItem:nth-child(3) {
  width: calc(27% + 25px);
}
@media screen and (min-width: 1680px) {
  .CashboxTableItem:first-child {
    width: calc(18% - 5px);
  }
}
.CashboxChartsWrapper {
  background-color: #fff;
  padding-top: 50px;
  padding-left: 16px;
}
.highcharts-credits {
  display: none !important;
}
.CahsboxChartsTooltipWrapper {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #FFDD2D !important;
  background-color: #2D8AF1 !important;
}
.tooltip1 {
  background-color: #2D8AF1 !important;
}
.StatisticTypesWrapper {
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 42px;
  padding-left: 16px;
}
.StatisticTypesItem {
  padding: 18px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 12px;
  min-width: 272px;
  width: 30%;
}
.StatisticTypesItemHovered:hover {
  cursor: pointer;
  background-color: #F2F2F7;
}
.TypeItemMarkerWrapper {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 8px;
}
.TypeItemTitle {
  font-weight: bold;
  margin-right: 12px;
}
.CashboxStatisticsTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #8E8E93;
  padding-left: 12px;
}
.CashboxContentTitle {
  padding-bottom: 4px !important;
}
@media screen and (max-width: 920px) {
  .CashboxContentTitle {
    flex-direction: column !important;
  }
}
.CashboxTitleBottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
  padding-top: 0 !important;
}
.CashboxComponentWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.CashboxComponentContentWrapper {
  width: 100%;
}
.ConsolidatedInsideWrapper {
  padding-top: 12px;
  margin-right: 32px;
  background-color: #fff;
  overflow-x: auto;
}
.ReportTableInsideWrapper {
  background-color: #fff;
  padding: 0 60px 24px 60px;
}
.ReportTableInsideContent {
  margin-left: 32px;
  margin-top: 16px;
  box-sizing: border-box;
  display: inline-flex;
}
.ConsolidatedInsideTableBonus {
  border-top: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ConsolidatedInsideTableMoney {
  border-top: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
}
.ConsolidatedInsideTableFreebet {
  border-top: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ConsolidatedInsideTableHeader {
  padding: 16px 0;
}
.ConsolidatedInsideTableHeader svg {
  margin-top: 3px;
}
.ConsolidatedInsideTableHeader span {
  padding-left: 4px;
}
.ConsolidatedInsideHallTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  margin: 12px 32px;
}
.ReportInsideHallInfo {
  margin-left: 10px;
}
.ReportInsideHallInfo:hover {
  cursor: pointer;
}
.ConsolidatedInsideTableItem {
  min-width: 210px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 16px 24px;
}
.ConsolidatedInsideTableItemHeader {
  background: #F8F8F8 !important;
  border-bottom: 1px solid #E9EAEA;
}
@media screen and (max-width: 920px) {
  .ReportTableContentWrapper {
    margin: 0 12px !important;
  }
}
.ReportTableContentWrapper {
  margin: 0 0;
  border-radius: 8px;
  border-bottom: 1px solid #E9EAEA;
}
.ReportTableFixedHeaderItem {
  width: 156px !important;
  position: fixed !important;
  height: 63px;
  z-index: 5;
  background-color: #E6F6F8 !important;
}
.ReportTableFixedHeaderItem span {
  padding-left: 25px;
}
.ReportTableFixedItem > div {
  padding-top: 18px;
}
.ReportTableFixedItem {
  width: 156px !important;
  height: 53px !important;
  position: fixed !important;
  margin-top: -18px;
  z-index: 5;
  background-color: #fff !important;
}
.ReportTableFixedItemShadow {
  box-shadow: 5px 0 5px -5px rgba(43, 45, 51, 0.12);
}
.ReportTableFixedItem span {
  padding-left: 40px;
}
.ReportTableHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  word-spacing: 100vw;
  background-color: #E6F6F8 !important;
  height: 64px;
  margin: auto !important;
}
.ConsolidatedReportTableItem,
.ConsolidatedReportTableBodyItem {
  width: 10%;
  padding: 0 12px;
  float: left;
}
.ReportTableLastItem {
  font-weight: bold !important;
}
.ReportTableHeaderItem {
  min-width: 148px;
  position: relative;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 0 12px;
  float: left;
}
.ReportTableHeaderItem span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ReportTableRowWrapper {
  border-bottom: 1px solid #ededf0;
}
.ReportTableRow,
.ReportTableLastRow {
  display: flex;
  flex-direction: row;
  padding: 18px 0;
  width: 100%;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E;
  background-color: #fff !important;
}
.ReportTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ReportTableActiveRow {
  font-family: Lato, sans-serif;
  color: #000000 !important;
}
.ReportTableRow:hover {
  cursor: pointer;
  background: #F8F8F8 !important;
}
.ReportTableRow:hover .ReportTableFixedItem {
  background: #F8F8F8 !important;
}
.ReportTableRowDisabled:hover {
  cursor: no-drop !important;
}
.ConsolidatedContentPeriodWrapper {
  padding: 0 8px 24px 8px;
}
.GamesListHeader {
  padding-bottom: 10px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.GamesListFilterCheckboxAllWrapper {
  display: flex;
}
.GamesListFilterCheckboxGroupWrapper {
  display: flex !important;
}
.GamesListFilterCheckboxAllLabel {
  padding-top: 1px;
}
.GamesListFilterCheckboxGroupWrapper,
.GamesListFilterCheckboxAllLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
}
.GamesListFilterCheckboxGroupWrapper {
  display: flex;
  flex-direction: column;
}
.GamesListFilterCheckboxGroupWrapper label,
.GamesListFilterCheckboxAllWrapper label {
  padding-bottom: 10px;
}
.GamesListFilterCheckboxGroupWrapper label:last-child {
  border-bottom: none !important;
}
.GameFilterComponentWrapper {
  max-height: calc(100vh - 112px);
  overflow: hidden;
  overflow-y: auto;
}
.GameFilterContentWrapper {
  padding-top: 32px;
}
.MainFilterCheckboxGroup {
  margin-top: -8px;
  padding-bottom: 24px;
}
.MainFilterCheckBoxGroupItem {
  padding: 8px 0;
}
.GamesInsideHall {
  padding-top: 24px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  color: #2B2D33;
}
.GamesInsideHallTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
}
.GamesTableWrapper {
  margin-top: 24px;
}
.GamesDetailWrapper,
.SportDetailWrapper,
.GamesTableWrapper {
  border: 1px solid #E9EAEA;
  border-radius: 8px;
  margin-top: 16px;
  min-width: 1130px;
  width: 100%;
}
.GamesDetailPlayWrapper,
.GamesDetailWinWrapper {
  width: 40%;
}
.GamesDetailPlaySubTitle div:first-child,
.GamesDetailWinSubTitle div:first-child {
  min-width: 264px;
  width: 60%;
}
.GamesDetailPlaySubTitle div:nth-child(2),
.GamesDetailWinSubTitle div:nth-child(2) {
  min-width: 176px;
  width: 40%;
}
.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  width: 100%;
  text-align: center;
  padding: 12px 0;
}
.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailValueBold {
  font-weight: bold !important;
}
.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  border-right: 1px solid #E9EAEA;
}
.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  border-bottom: 1px solid #E9EAEA;
}
.GamesDetailPlayTotal,
.GamesDetailWinTotal {
  width: 10%;
}
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle {
  height: 120px;
}
.GamesDetailPlayTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailPlaySubTitle > div {
  background-color: #FFF4E8;
}
.GamesDetailWinTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div {
  background-color: #E7F8F4;
}
.GamesDetailValueTitle {
  height: 40px;
  min-width: 88px;
  width: 20%;
  background-color: #F8F8F8;
  text-align: center;
  border-right: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
}
.GamesDetailValue {
  height: 48px;
  min-width: 88px;
  width: 100%;
  text-align: center;
  border-right: 1px solid #E9EAEA;
}
.GamesDetailValueBold {
  min-width: 88px;
}
.GamesDetailValueWithoutRightBorder {
  border-right: 0 !important;
}
.GamesTableHeaderItem,
.GamesTableHeaderLastItem,
.GamesTableRowItem,
.GamesTableRowLastItem {
  width: calc(100% / 7);
  min-width: 161.1px;
  padding: 12px 32px;
}
.GamesTableHeaderItem,
.GamesTableRowItem,
.GamesTableRowLastItem {
  border-right: 1px solid #E9EAEA;
}
.GamesTableHeaderItem,
.GamesTableRowItem,
.GamesTableHeaderLastItem {
  border-bottom: 1px solid #E9EAEA;
}
.GamesTableRowItemWithoutRight {
  border-right: 0 !important;
}
.GamesTableHeaderItem,
.GamesTableHeaderLastItem {
  background-color: #F0F0FE;
}
.GamesTableBoldItem {
  font-weight: bold !important;
}
.SportDetailPlayWrapper,
.SportDetailWinWrapper {
  width: 40%;
}
.SportDetailPlayTotal,
.SportDetailWinTotal {
  width: 10%;
}
.SportDetailPlayTitle,
.SportDetailWinTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  min-width: 440px;
  height: 40px;
  padding: 12px 0;
  border-bottom: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}
.SportDetailPlayTitle {
  background-color: #FFF4E8;
  border-top-left-radius: 8px;
}
.SportDetailWinTitle {
  background-color: #E7F8F4;
}
.SportDetailPlaySubTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 12px 24px;
  line-height: 120%;
  color: #2B2D33;
  min-width: 220px;
  width: 50%;
  background: #F8F8F8;
  border-bottom: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}
.SportDetailPlayValue,
.SportDetailTotalValue {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 24px;
  color: #2B2D33;
  min-width: 220px;
  width: 50%;
  border-right: 1px solid #E9EAEA;
}
.SportDetailTotalValue {
  font-weight: bold;
  min-width: 124px !important;
  width: 100%;
  text-align: center;
}
.SportDetailPlayTotalTitle,
.SportDetailWinTotalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  min-width: 124px;
  padding: 12px 0 52px 0;
  border-bottom: 1px solid #E9EAEA;
}
.SportDetailWinTotalTitle {
  border-top-right-radius: 8px;
  background-color: #E7F8F4;
}
.SportDetailPlayTotalTitle {
  border-right: 1px solid #E9EAEA;
  background-color: #FFF4E8;
}
.GamesReportTableWrapper {
  min-width: 1194px;
}
.GamesReportTableItem,
.GamesReportTableBodyItem {
  width: 15%;
  padding: 0 12px;
  float: left;
}
.MainPageTitleWithExel {
  gap: 24px;
}
.CashboxExcelButton {
  margin-left: 24px;
}
.GamesReport_Table_Column .TitleTooltip {
  top: 0 !important;
  margin-left: 4px !important;
}
.NotificationsFilterWrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.NotificationsFilterField {
  width: calc(25% - 20px);
  max-width: 335px;
  margin-right: 20px;
  margin-bottom: 16px;
}
.NotificationsFilterFieldDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #343A40;
  padding-bottom: 4px;
}
.FilterButtonNotifiWrapper {
  margin-top: 22px;
  margin-right: 16px;
}
.NotificationsFilterWrapperMobile,
.FilterButtonNotifiMobileWrapper {
  display: none !important;
}
@media screen and (max-width: 920px) {
  .NotificationsFilterWrapper {
    flex-direction: column !important;
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 100vh;
  }
  .NotificationsFilterField {
    width: calc(100vw - 32px) !important;
    max-width: none !important;
    margin-right: 0 !important;
  }
  .NotificationsFilterWrapperMobile {
    display: inherit !important;
    padding-bottom: 32px;
    padding-top: 16px;
  }
  .NotificationsFilterWrapperMobileTitle {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
  .NotificationsFilterWrapperMobileClose {
    margin-left: auto;
  }
  .FilterButtonNotifiWrapper {
    display: none;
  }
  .FilterButtonNotifiMobileWrapper,
  .FilterButtonNotifiMobileWrapper > .FilterButtonNotifiWrapper {
    display: inherit !important;
  }
  .FilterButtonNotifiMobileWrapper {
    padding-bottom: 120px;
  }
}
.NotifiEmptyTableWrapper {
  display: grid;
  place-items: center;
}
.NotifiEmptyTableTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  padding-top: 40px;
}
.NotifiEmptyTableText {
  max-width: 350px;
  padding-top: 8px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #212529;
}
.NotificationsTable {
  margin-top: 24px;
  padding-bottom: 24px;
}
.NotificationsTable .TableColumnWrapper .TableComponentTopScrollWrapper {
  display: none !important;
}
@media screen and (min-width: 1441px) {
  .NotificationsTableText {
    max-height: 64px;
  }
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .NotificationsTableText {
    max-height: 72px;
  }
}
@media screen and (max-width: 920px) {
  .NotificationsTable {
    margin: 0 8px !important;
    width: calc(100vw - 40px) !important;
  }
  .NotificationsTableText {
    min-width: 400px;
    max-height: 72px;
  }
}
.NotificationsTableTextRow {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.NotificationsTableTextRow p {
  overflow: hidden;
  text-overflow: ellipsis;
}
.NotificationsTableTextRow > span {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NotificationsTableTextRow > span h1,
.NotificationsTableTextRow > span h2,
.NotificationsTableTextRow > span h3,
.NotificationsTableTextRow > span h4,
.NotificationsTableTextRow > span h5 {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NotifiTableDateColumn {
  word-spacing: normal !important;
}
.NotificationsTableTitle {
  padding-right: 2px;
  font-weight: bold !important;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NotificationsRowToolsIcon {
  padding-top: 3px;
}
.NotificationsRowTools {
  gap: 12px;
}
.NotificationsRowTools div:hover > svg path {
  fill: black;
}
.NotificationsTableDate {
  margin-top: -10px !important;
}
.NotificationsTableDate div svg {
  margin-right: 12px;
  margin-top: 5px;
}
.NotificationsTableDate div span {
  margin-top: 5px;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead > tr th {
  font-weight: 600 !important;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead > tr th:first-child {
  padding-left: 22px !important;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row {
  height: 72px !important;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row td:first-child {
  padding-left: 22px !important;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead tr .ant-table-cell .ant-table-selection .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.NotificationsTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody > tr .ant-table-cell .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  height: 18px !important;
  width: 18px !important;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper div div div {
  overflow-x: auto;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper div div div .ant-table-container .ant-table-content table thead tr th div {
  height: 25px;
  width: 30px;
}
.NotificationsTable .TableColumnWrapper .TableComponentWrapper div div div .ant-table-container .ant-table-content table thead tr th div label {
  margin-top: 2px;
}
.NotificationsRowToolsAuthorHistoryIcon {
  width: 32px;
  margin-right: 8px;
  margin-top: 2px;
}
.NotificationsRowToolsAuthorHistoryArea {
  top: calc(50% - 166px);
  left: calc(50% - 326px);
  z-index: 9;
  position: fixed;
  width: 704px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModalClose {
  margin-top: 10px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal {
  width: 652px;
  height: auto !important;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  margin-right: 14px;
  padding: 32px;
  cursor: auto !important;
  overflow-y: inherit !important;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  width: 100%;
  text-align: center;
  color: #212529;
  padding-bottom: 24px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable {
  width: 100%;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  max-height: 332px;
  overflow-y: auto;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow {
  border-bottom: 1px solid #E9ECEF;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow .ModalTableStatus {
  width: 170px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow .ModalTableStatus .ModalTableStatusTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding: 18px 34px 21px 24px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow .ModalTableStatus .ModalTableStatusTitlePrefix {
  color: grey;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow .ModalTableName {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding: 18px 0 21px 41px;
  width: 265px;
}
.NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow .ModalTableDate {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
  padding-top: 18px;
}
@media screen and (max-width: 920px) {
  .NotificationsRowToolsAuthorHistoryArea {
    width: 100vw;
    top: 72px;
    left: 0;
  }
  .NotificationsRowToolsAuthorHistoryArea .HistoryModalClose {
    position: fixed;
    top: 20px;
    right: 20px;
  }
  .NotificationsRowToolsAuthorHistoryArea .HistoryModal {
    margin: 0 !important;
    padding: 32px 0 !important;
  }
  .NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable .ModalTableRow {
    overflow-x: auto;
    min-width: 560px;
  }
  .NotificationsRowToolsAuthorHistoryArea .HistoryModal .ModalTable::-webkit-scrollbar {
    display: none !important;
  }
}
.ant-btn-link.CopyButton {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}
.NotifiCrudModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  min-height: 1080px;
}
@media screen and (min-height: 1081px) {
  .NotifiCrudModalWrapper {
    min-height: 100vh;
    height: 100%;
  }
}
.NotifiCrudModal,
.NotifiCrudModalSuccessWrapper {
  width: 1064px;
  z-index: 10;
  background-color: white;
  margin: 72px 0;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  padding: 32px 40px 40px 40px;
}
.NotifiCrudModalSuccessWrapper {
  display: grid;
  place-items: center;
}
.NotifiCrudModalSuccessWrapper > div {
  margin: auto;
  place-items: center;
}
@media screen and (max-width: 920px) {
  .NotifiCrudModalSuccessWrapper {
    position: fixed;
    top: 72px;
    width: 100vw;
    height: calc(100vh - 72px);
  }
}
.NotifiCrudModalSuccessTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}
.NotifiCrudModalSuccessImg {
  padding: 66px 0 61px 0;
}
.NotifiCrudModalSuccessButton {
  padding-bottom: 80px;
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .NotifiCrudModal,
  .NotifiCrudModalSuccessWrapper {
    max-width: calc(100vw - 320px);
    margin-left: 32px !important;
  }
}
@media screen and (min-width: 1441px) {
  .NotifiCrudModal,
  .NotifiCrudModalSuccessWrapper {
    max-width: calc(100vw - 320px);
    margin-left: calc(50vw - 160px) !important;
    transform: translateX(-50%);
  }
}
.NotifiCrudModalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}
.NotifiCrudModalClose {
  margin-left: auto;
  margin-right: -16px;
  margin-top: 10px;
}
.NotifiCrudModalClose:hover {
  cursor: pointer;
}
.NotifiCrudModalClose:hover svg path {
  fill: black;
}
.NotifiCrudModalBodyHeader > div {
  width: 100%;
}
.NotifiCrudModalBodyHeader {
  padding-top: 35px;
  padding-bottom: 5px;
}
.NotifiCrudModalBodyContentRow,
.NotifiCrudModalBodyContentButtonsRow {
  padding-top: 24px;
}
.NotifiCrudModalCheckbox {
  padding-right: 18px;
}
.NotifiCrudModalButtons {
  margin-right: 18px;
}
.CalendarPopupSendAfterText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #212529 !important;
  text-decoration: none !important;
  text-align: left;
  padding-left: 12px;
}
.NotifiCrudModalFiles {
  padding: 26px 0 8px 0;
  border-bottom: 1px solid #DEE2E6;
}
.NotifiCrudModalBodyLocationFields {
  display: flex;
  flex-direction: row;
}
.FileErrorToastWrapper {
  width: 488px !important;
  padding: 24px !important;
  border: 1px solid #DEE2E6 !important;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.16) !important;
  border-radius: 8px !important;
  border-left: 8px solid #FA5252 !important;
}
.FileErrorToastWrapper .ant-notification-notice-content .ant-notification-notice-with-icon > div {
  margin-left: 70px !important;
}
.FileErrorToastTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #212529;
}
.FileErrorToastTextField {
  max-width: 315px !important;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #495057;
}
.NotifiCrudModalMobileSaveButton {
  display: none;
}
@media screen and (max-width: 920px) {
  .NotifiCrudModal {
    width: 100% !important;
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 24px 16px !important;
  }
  .NotifiCrudModalTitle {
    font-size: 26px;
    line-height: 32px;
  }
  .NotifiCrudModalClose {
    margin-right: 1px;
  }
  .NotifiCrudModal::-webkit-scrollbar,
  .NotifiCrudModalSaveButton {
    display: none !important;
  }
  .NotifiCrudModalBodyLocationFields {
    flex-direction: column !important;
  }
  .NotifiCrudModalBodyLocationItem {
    padding-bottom: 16px !important;
  }
  .NotifiCrudModalBodyContentRow {
    flex-wrap: wrap;
  }
  .NotifiCrudModalMobileSaveButton {
    display: inherit !important;
  }
  .NotifiCrudModalMobileSaveButton .ButtonPrimaryWrapper button {
    padding: 12px !important;
  }
}
.Flex {
  display: flex;
  align-items: center;
}
.Flex.SpaceBetween {
  justify-content: space-between;
}
.NotifiCrudModalDeletedOverlay {
  z-index: 18 !important;
}
.NotifiDeleteModalWrapper {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  z-index: 19 !important;
  top: calc(50% - 120px);
  left: calc(50% - 80px);
  width: 510px;
  padding: 32px;
}
.NotifiDeleteModalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  color: #212529;
  padding-left: 7px;
}
.NotifiDeleteModalText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  width: 360px;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-left: 7px;
}
.NotifiDeleteModalButtons {
  padding-left: 186px;
}
.NotifiDeleteModalButtonsFirst {
  padding-right: 8px;
}
.NotifiDeleteModalSuccessWrapper {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  z-index: 19 !important;
  top: calc(50% - 120px);
  left: calc(50% - 255px);
  width: 510px;
  padding: 32px;
}
.NotifiDeleteModalSuccessTitle {
  width: 100%;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #212529;
  padding-bottom: 24px;
}
.NotifiDeleteModalSuccessIcon {
  margin: 0 auto;
  padding-bottom: 6px;
}
@media screen and (max-width: 920px) {
  .NotifiDeleteModalWrapper {
    left: 8px !important;
    width: calc(100vw - 16px);
  }
  .NotifiDeleteModalButtons {
    padding-left: 0 !important;
  }
}
.NotifiStatisticSearch {
  padding: 24px 0;
}
.NotifiStatisticSearchTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #212529;
  padding-bottom: 8px;
}
.NotifiStatisticSearchSelect .SelectWrapper {
  width: 100%;
  padding-right: 8px;
}
.NotifiStatisticSearchSelect .ButtonPrimaryWrapper .ButtonPrimary {
  padding: 0 56px !important;
}
.NotifiStatisticCurrentHallSearch .InputWrapper {
  width: 100%;
  padding-right: 8px;
}
.NotifiStatisticCurrentHallSearch .ButtonPrimaryWrapper .ButtonPrimary {
  padding: 0 56px !important;
}
.NotifiStatisticTableColumn {
  width: 25%;
  word-spacing: normal !important;
}
.NotificationsStatisticTableRow:hover {
  cursor: auto !important;
}
.NotificationsStatisticTableItem {
  margin-left: 32px !important;
}
.NotificationsStatisticTableItem,
.NotificationsStatisticTableLink {
  margin-top: 10px;
  margin-bottom: 10px;
}
.NotificationsStatisticTableLink {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #228BE6;
}
.NotificationsStatisticTableLink:hover {
  cursor: pointer;
  opacity: 0.7;
}
.NotifiRouteWrapper {
  position: relative;
}
.NotificationMainPageTitle {
  padding-left: 0 !important;
  width: 100%;
}
.NotificationMainPageTitleBack:hover {
  cursor: pointer;
  opacity: 0.7;
}
.NotificationMainPageTitleBack {
  padding-right: 12px;
  padding-top: 2px;
}
.NotificationsHeader {
  width: 100%;
  justify-content: space-between;
}
.NotificationsIconButtons > div > svg {
  margin-right: 8px;
}
.NotificationsIconButtons > div > svg path {
  fill: #868E96;
}
.NotificationsHeaderButtons {
  margin-top: 28px;
  height: 48px;
}
.NotificationsHeaderButtonsItem {
  margin: 0 12px 12px 12px;
}
.NotificationsHeaderTabs {
  margin-top: -12px;
  border-bottom: 1px solid #DEE2E6;
  width: 100%;
}
.NotificationsHeaderTabsItem {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #868E96 !important;
  margin-right: 20px;
  padding-bottom: 15px;
  border-bottom: none !important;
  position: relative;
}
.NotificationsHeaderTabsActiveItem {
  width: 100%;
  height: 2px;
  background: black;
  position: absolute;
}
.NotificationsFilterCounter {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  border-radius: 50%;
  background: #FCE000;
  display: grid;
  place-items: center;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #212529;
}
.NotificationMainPageTitleMobileAdd,
.NotificationMainPageTitleMobileDelete,
.NotificationMainPageTitleMobileClearBasket {
  display: none !important;
}
@media screen and (min-width: 921px) {
  .NotificationsHeaderTabsItem:hover {
    cursor: pointer;
    color: #212529;
  }
  .NotificationsHeaderTabsActiveItemText {
    color: #212529 !important;
  }
  .NotificationsHeaderTabsActiveItem {
    height: 3px;
    background: #FCE000 !important;
    top: 28px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}
@media screen and (max-width: 920px) {
  .NotificationsHeaderButtons {
    display: none !important;
  }
  .NotificationMainPageTitle {
    margin-left: 16px;
    padding-top: 16px;
  }
  .NotificationsHeaderTabs {
    max-height: calc(100vw - 40px) !important;
    margin: 20px 16px 0 0;
    padding: 0 16px;
    overflow-x: auto;
    width: auto !important;
  }
  .NotificationsHeaderTabsActiveItemText {
    color: #212529 !important;
  }
  .NotificationsHeaderTabsActiveItem {
    height: 3px;
    background: #FCE000 !important;
    top: 28px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .NotificationsHeaderTabs::-webkit-scrollbar {
    display: none !important;
  }
  .NotificationMainPageTitleMobileAdd {
    display: inherit !important;
    margin-left: 12px;
    padding: 8px;
    background: #FCE000;
    border-radius: 8px;
  }
  .NotificationMainPageTitleMobileDeleteWrapper {
    margin-left: auto;
  }
  .NotificationMainPageTitleMobileDelete {
    display: inherit !important;
    margin-left: auto;
    margin-right: 8px;
    padding: 8px;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
    height: 40px;
  }
  .NotificationMainPageTitleMobileClearBasket {
    display: inherit !important;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FA5252;
    padding: 12px 10px;
    border: 1px solid #FF8787;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: auto;
    margin-right: 8px;
  }
  .NotificationMainPageTitle span {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #212529;
  }
}
.ChangePhoneEditModal,
.ChangePhoneEditSuccess {
  position: fixed;
  left: calc(50% - 250px);
  top: calc(50% - 250px);
  z-index: 999;
}
.ChangePhoneEditModalWrapper {
  width: 450px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 32px;
}
.ChangePhoneEditModalClose {
  margin: 10px 14px;
}
.ChangePhoneEditModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ChangePhoneEditModalWrapperTitle {
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #212529;
}
.ChangePhoneEditModalWrapperTable {
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
}
.ChangePhoneEditModalWrapperTableHeader {
  background: #E3FAFC;
}
.ChangePhoneEditModalWrapperTableRowWithBorder,
.ChangePhoneEditModalWrapperTableHeader {
  border-bottom: 1px solid #E9ECEF;
}
.ChangePhoneEditModalWrapperTableRow,
.ChangePhoneEditModalWrapperTableHeader {
  padding: 18px 32px;
}
.ChangePhoneEditModalWrapperTableLeft {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  float: left;
  width: 150px;
}
.ChangePhoneEditModalWrapperTableRight {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #212529;
  float: right;
  width: 100%;
}
.ChangePhoneEditModalUserTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ChangePhoneEditModalUser {
  margin-bottom: 32px;
}
.ChangePhoneEditModalUser .InputWrapper input {
  background: #F8F9FA !important;
}
.ChangePhoneEditModalButtons {
  justify-content: space-between;
}
.ChangePhoneEditModalButtons .ButtonNotifiWrapper button,
.ChangePhoneEditModalButtons .ButtonPrimaryWrapper button {
  width: 184px !important;
}
.ChangePhoneEditSuccess {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 16px;
  padding-top: 32px;
  padding-bottom: 38px;
  width: 500px;
}
.ChangePhoneEditSuccessTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #212529;
  padding-bottom: 24px;
}
.ChangePhoneEditSuccessIcon {
  display: grid;
  place-items: center;
}
.ChangePhoneEditModalMobileClose {
  display: none;
}
@media screen and (max-width: 920px) {
  .ChangePhoneEditModalMobileClose {
    margin-left: auto;
    display: inherit;
  }
  .ChangePhoneEditModalClose {
    display: none;
  }
  .ChangePhoneEditModal {
    width: 100vw;
    bottom: 0 !important;
    top: auto !important;
    left: auto !important;
    right: 0 !important;
  }
  .ChangePhoneEditModalWrapper {
    width: 100vw;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ChangePhoneEditModalButtons {
    flex-direction: column !important;
  }
  .ChangePhoneEditModalButtons .ButtonNotifiWrapper button,
  .ChangePhoneEditModalButtons .ButtonPrimaryWrapper button {
    width: calc(100vw - 64px) !important;
  }
  .ChangePhoneEditModalButtons .ButtonNotifiWrapper {
    margin-bottom: 16px;
  }
  .ChangePhoneEditSuccess {
    bottom: 0;
    top: auto;
  }
}
.MainPageTitleHelp {
  padding-left: 10px;
  padding-top: 4px;
}
.MainPageTitleHelp:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ChangePhoneTableWrapper {
  margin-top: 22px;
}
.ChangePhoneStatusWrapper {
  padding: 8px 10px;
  border-radius: 8px;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.ChangePhoneFirstTh {
  padding-left: 42px !important;
}
.ChangePhoneStatusNew {
  color: #212529;
  width: 64px;
}
.ChangePhoneStatusApproved {
  color: #495057;
  width: 90px;
}
.ChangePhoneStatusReject {
  color: #E03131;
  width: 94px;
}
.NotifiChangePhoneTableColumn {
  word-spacing: normal !important;
}
.NotifiChangePhoneTableColumn:before {
  display: none !important;
}
.NotifiChangePhoneTableColumnTime {
  opacity: 0.6;
  padding-left: 8px;
}
.RowTableComponentChangePhoneDisabledRow:hover,
.RowTableComponentChangePhoneDisabledRow {
  cursor: default !important;
}
.GuestDashboardWrapper {
  max-width: 1500px;
  overflow-x: auto;
}
@media screen and (min-width: 921px) {
  .GuestDashboardWrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 920px) {
  .GuestDashboardWrapper::-webkit-scrollbar {
    display: none !important;
  }
}
.ReportDashboardItemWrapper {
  margin-top: 19px;
  padding: 19px;
  border-radius: 16px;
  min-width: 230px;
  margin-right: 16px;
  max-width: 284px;
  width: 100%;
}
.ReportDashboardLabel,
.StatusPromoteLabel {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #212529;
  margin-left: 12px;
}
.ReportDashboardValue {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212529;
  padding-top: 16px;
}
.GuestInfoPromoteSpecify {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #212529;
  padding: 12px 0;
}
.GuestInfoPromoteSpecify:hover,
.GuestInfoPromoteReload:hover {
  cursor: pointer;
  opacity: 0.8;
}
.GuestInfoPromoteValue {
  margin-top: 2px;
}
.GuestInfoPromoteReload {
  margin-left: 16px;
}
.GuestInfoPromoteMax {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding-left: 6px;
  padding-top: 3px;
}
.FilterField {
  padding-bottom: 16px;
}
.FilterField .FilterContentLabel {
  padding-bottom: 8px;
}
.FilterContentLabelDisabled {
  color: #d9d9d9 !important;
}
.ReportHeader span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.GuestInfoReportStatus {
  padding: 5px 11px;
  width: fit-content;
  border-radius: 4px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.GuestInfoReportDate {
  text-transform: capitalize;
  height: 48px;
  padding: 16px 32px 6px 16px;
}
.BalanceTableComment {
  max-width: 318px;
}
.BalanceTableCommentModal {
  width: 544px;
  background: #FFFFFF;
  border-radius: 16px;
}
.BalanceTableCommentModalTitle {
  padding: 26px 32px;
  border-bottom: 1px solid #E5E5EA;
}
.BalanceTableCommentModalBody {
  padding: 12px 32px;
  height: 240px;
  max-height: 240px;
  overflow-y: auto;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.2px;
  color: #3A3A3C;
}
.BalanceTableCommentModalClose {
  padding: 26px 32px;
  border-top: 1px solid #E5E5EA;
}
.BalanceTableCommentModalClose .ButtonPrimaryWrapper {
  margin-left: auto;
  width: 256px;
}
.BalanceTableCommentModalClose .ButtonPrimaryWrapper button {
  width: 256px;
}
.BalanceTableCommentMore {
  color: #228be6;
  padding-left: 2px;
}
.Overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  top: 0;
  left: 0;
}
.StrongOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9998;
  top: 0;
  left: 0;
}
.ReportModalWrapper {
  padding: 40px 21px;
  z-index: 1000;
  background-color: white;
  position: absolute;
  width: 900px;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 16px 0 0 16px;
}
.NoncashPageTitleHeader {
  white-space: pre-line;
}
@media screen and (max-width: 994px) {
  .ReportModalWrapper {
    width: 100%;
  }
  .TableColumnWrapper {
    width: calc(100vw - 10px);
  }
  .NoncashPageTitle {
    width: 100% !important;
  }
}
.ReportModalWrapper .CloseModalIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ReportModalWrapper .InfoModalIcon {
  margin-left: 10px;
}
.ReportModalWrapper .NoncashPageTitle {
  font-size: 24px;
}
.ReportModalWrapper .CashboxContentPeriod {
  padding-left: 0;
  color: #808185;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}
.ReportModalWrapper thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
  color: #eea84e !important;
}
.ReportModalWrapper .ant-table-column-sorter-inner .active svg path {
  fill: #eea84e !important;
}
.ReportModalWrapper .ant-table-column-has-sorters .ant-table-column-sorters,
.ReportModalWrapper .ant-table-thead > tr > th {
  background-color: #FFF4E6 !important;
  padding: 12px !important;
}
.ReportModalWrapper .NoncashTableColumn .ant-table-column-sorters {
  padding-left: 0 !important;
}
.ReportModalWrapper .NoncashPageTitleDescription {
  width: 100% !important;
}
.ReportModalWrapper .NoncashPageHeader {
  width: 100% !important;
}
.ReportModalWrapper .NoncashPageTitle {
  width: 100% !important;
}
.ant-table-column-sorters {
  padding-left: 0 !important;
}
.NoncashTableColumn {
  padding: 12px !important;
  word-spacing: normal !important;
}
.CloseModalIcon {
  margin-left: auto;
}
.NoncashTableDate {
  padding-left: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.NoncashTableTypeValue {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding-left: 8px;
  padding-top: 1px;
}
.NoncashTableTime {
  opacity: 0.5;
  padding-left: 8px;
}
.NoncashPageHeaderDescriptionWrapper {
  width: 100%;
}
.ant-table-thead > tr > th:first-child {
  padding-left: 12px !important;
}
.ant-table-column-sorters {
  justify-content: initial !important;
}
.ant-table-column-title {
  flex: 0 auto !important;
}
.ant-table-column-sorter {
  margin-left: 6px !important;
}
.TransactionEnterPin {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}
.TransactionSuccess {
  color: #40C057;
  background: #EBFBEE;
  border-radius: 4px;
  padding: 6px 12px 8px;
}
.TransactionUnsuccessful {
  color: #FA5252;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}
.TransactionPending {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}
.TransactionContact {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}
.NoncashPageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 32px;
}
.NoncashPageTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}
@media screen and (max-width: 1500px) {
  .MainPageContentWrapperUnFilter .MainPageContentRestrictions .ReportLegoComponentContentWrapper div .NoncashPageHeader div div .NoncashPageTitle {
    max-width: 400px;
  }
}
@media screen and (max-width: 1800px) {
  .MainPageContentWrapperFilter .MainPageContentRestrictions .ReportLegoComponentContentWrapper div .NoncashPageHeader div div .NoncashPageTitle {
    max-width: 400px;
  }
}
@media screen and (max-width: 920px) {
  .NoncashPageTitleDescription {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 921px) {
  .NoncashPageTitleDescription {
    display: flex;
    flex-direction: row;
  }
}
.NoncashContentPeriodWrapper {
  padding-top: 8px;
}
.NoncashPageTitleInfo {
  margin-left: 10px;
  margin-top: 4px;
}
.NoncashPageTitleInfo:hover {
  cursor: pointer;
  opacity: 0.8;
}
.NoncashPageTitleExel {
  margin-left: 12px;
}
.Noncash .NoncashPageTitleExel {
  display: flex;
  align-items: center;
}
.Noncash .NoncashPageTitleExel .ButtonIconWrapper .ant-btn.ButtonIcon_excel {
  height: 32px;
}
.Noncash .NoncashPageTitleExel .ButtonIconWrapper .ant-btn.ButtonIcon_excel svg {
  top: 5px;
}
.NoncashPageTitleQiwiWrapper {
  margin-left: auto;
}
.NoncashPageTitleQiwiWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}
.NoncashPageTitleQiwiIcon {
  padding-right: 8px;
}
.NoncashPageTitleQiwiTitle {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  padding-top: 2px;
  padding-right: 24px;
  width: 128px;
}
.NoncashPageTitleQiwiValue {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212529;
}
.NoncashContentPeriodWrapper {
  align-items: flex-end;
}
.NoncashContentPeriodWrapper .ButtonIconWrapper {
  display: none;
}
.NoncashContentPeriodWrapper .ButtonIconWrapper button span {
  display: none;
}
.NoncashContentPeriodWrapper .ButtonIconWrapper button svg {
  display: none;
}
.NoncashContentPeriodWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):hover,
.NoncashContentPeriodWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):focus,
.NoncashContentPeriodWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):active {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.NoncashContentPeriodWrapper .ContentPeriodWrapper {
  align-items: center;
}
@media screen and (max-width: 1600px) {
  .MainPageContentWrapperFilter .NoncashPageHeader {
    display: block !important;
  }
  .MainPageContentWrapperFilter .NoncashPageTitleQiwiWrapper {
    display: inline-flex !important;
    margin-top: 10px;
  }
  .ReportLegoComponentContentWrapper {
    margin-left: 5px;
  }
  .NoncashPageTitleExel {
    margin-left: 7px;
  }
}
@media screen and (max-width: 1200px) {
  .NoncashContentPeriodWrapper .ButtonIconWrapper {
    display: block;
  }
}
@media screen and (max-width: 910px) {
  .NoncashComponentContentWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .NoncashComponentContentWrapper .TableColumnWrapper {
    max-width: 100%;
  }
  .NoncashPageTitle {
    font-size: 24px;
    max-width: 320px !important;
  }
  .ReportModalWrapper .NoncashPageTitle {
    max-width: 400px !important;
    margin-left: 0 !important;
  }
  .NoncashTitleInfo {
    margin-left: 0;
  }
  .NoncashTitleInfo svg {
    width: 20px;
    height: 20px;
  }
  .NoncashPageHeader {
    display: block !important;
  }
  .NoncashTitleQiwiWrapper {
    display: inline-flex !important;
  }
  .NoncashPeriodWrapper {
    margin-top: 10px;
    justify-content: space-between;
  }
  .NoncashContentPeriodWrapper .ContentPeriodWrapper {
    display: block;
  }
  .NoncashContentPeriodWrapper .ContentPeriodWrapper .ContentPeriod,
  .NoncashContentPeriodWrapper .ContentPeriodWrapper .ContentPeriodMain {
    padding-left: 0;
  }
  .ContentPeriodWrapper {
    flex-direction: column !important;
  }
  .ContentPeriodWrapper .ContentPeriodDescription {
    padding-left: 8px;
  }
}
.MessagesRouteFilterContent {
  padding-top: 24px;
}
.MainFilterReset {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  margin-left: auto;
  padding-top: 3px;
}
.MainFilterReset:hover {
  cursor: pointer;
  opacity: 0.7;
}
.MessagesComponentIdBack {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #495057;
}
.MessagesComponentIdBack {
  padding-bottom: 16px;
}
.MessagesComponentIdBack:hover {
  cursor: pointer;
  opacity: 0.7;
}
.MessagesComponentIdBack > div {
  padding-left: 4px;
  padding-top: 2px;
}
.MessagesComponentIdDataTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #212529;
  padding-bottom: 8px;
}
.MessagesComponentIdDataDate {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
  padding-bottom: 12px;
}
.MessagesComponentIdDataHallsWrapper {
  padding-bottom: 24px;
}
.MessagesComponentIdDataWrapper {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 40px;
}
@media screen and (max-width: 920px) {
  .MessagesComponentIdDataWrapper {
    margin: 0 16px;
  }
  .MessagesComponentIdBack {
    margin-top: 16px;
    margin-left: 16px;
  }
}
.MessagesComponentIdDataFiles {
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid #DEE2E6;
  max-width: 984px;
}
.MessagesComponentIdDownloadAllInfo {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}
.MessagesComponentIdDownloadAllLink {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #228BE6;
  padding-left: 8px;
}
.MessagesComponentIdDownloadAllLink:hover {
  cursor: pointer;
  opacity: 0.7;
}
.MessagesComponentIdDownloadAll {
  padding-bottom: 20px;
}
.MessagesIdRouteListItemHalls {
  font-weight: normal;
  color: #212529;
  overflow: hidden;
  text-overflow: fade !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.MessagesIdRouteListItemHallsMore {
  margin-top: auto;
}
.MessagesIdRouteListItemHallsMore span {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #228BE6;
  margin-left: 8px;
}
.MessagesIdRouteListItemHallsMore svg {
  margin-top: 8px;
  margin-left: 6px;
}
.MessagesIdRouteListItemHallsMoreUp svg {
  transform: rotate(0deg);
  transition-duration: 0.4s;
}
.MessagesIdRouteListItemHallsMoreDown svg {
  transform: rotate(-180deg);
  transition-duration: 0.4s;
}
.MessagesIdRouteListItemHallsMore:hover {
  opacity: 0.7;
  cursor: pointer;
}
.PinIconWrapper {
  padding-left: 4px;
  padding-top: 2px;
}
.SmallPinIconWrapper {
  padding-top: 1px;
  padding-right: 8px;
}
.UnPinnedIconWrapper svg path {
  fill: #CED4DA;
}
.PinnedIconWrapper svg path {
  fill: #FF922B;
}
.UnPinnedIconWrapper:hover svg path {
  fill: #ADB5BD;
}
.PinIconWrapper:hover {
  cursor: pointer;
}
.MessagesRouteList {
  background: #fff;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
}
.MessagesPageTitle {
  padding-left: 0 !important;
}
.MessagesHeaderTabs {
  padding-top: 16px;
}
.NotificationsHeaderTabsItem span {
  min-width: max-content;
}
@media screen and (max-width: 920px) {
  .MessagesPageTitle {
    padding-left: 16px !important;
  }
  .MessagesHeaderTabs {
    padding-top: 0;
    margin-right: 0 !important;
  }
  .MessagesRouteListItemRow {
    max-width: none !important;
  }
  .MessagesRouteIconWrapper {
    margin-left: 8px;
  }
  .MessagesRouteListItemDataMobile {
    max-width: calc(100vw - 160px);
  }
}
.MessagesRouteListItem {
  padding: 16px 18px;
  width: 100%;
  border-bottom: 1px solid #E9ECEF;
}
.MessagesRouteIconWrapper {
  padding-top: 19px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.MessagesRouteListTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #495057;
  padding-left: 8px;
}
.MessagesRouteListFirstItem {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.MessagesRouteListLastItem {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none !important;
}
.MessagesRouteListItem:hover {
  background: #F8F9FA;
  cursor: pointer;
}
@media screen and (min-width: 921px) {
  .MessagesRouteListItem > div > .UnPinnedIconWrapper {
    visibility: hidden;
  }
  .MessagesRouteListItem:hover > div > .UnPinnedIconWrapper {
    visibility: inherit !important;
  }
}
.MessagesRouteListPendingItem {
  background: #F8F9FA !important;
  cursor: not-allowed !important;
}
.MessagesRouteListUnreadItem {
  padding-left: 40px !important;
}
.MessagesRouteListItemReadCircle {
  background: #FCE000;
  width: 8px !important;
  height: 8px !important;
  margin-right: 16px;
  margin-top: 6px;
  border-radius: 8px;
}
.MessagesRouteListItemTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.MessagesRouteListItemText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding-left: 8px;
  color: #495057;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.MessagesRouteListItemRow {
  max-width: calc(100vw - 432px);
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .MessagesRouteListItemRow {
    max-width: calc(100vw - 368px);
  }
}
.MessagesRouteListItemRight {
  margin-left: auto;
  margin-top: auto;
}
.MessagesRouteListItemAttach {
  margin-top: -2px;
  padding: 0 24px;
}
.MessagesRouteListItemAttach svg path {
  fill: #868E96;
}
.MessagesRouteListItemDate {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}
.MessagesRouteListItemHallsTitle,
.MessagesRouteListItemHalls {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
}
.MessagesRouteListItemHallsTitle {
  color: #1098AD;
  padding-right: 8px;
  padding-left: 24px;
  font-weight: bold;
}
.MessagesRouteListItemHalls {
  font-weight: normal;
  color: #212529;
  padding-right: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.MessagesRouteListItemText > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  width: 100%;
}
.MessagesRouteListItemText > span h1,
.MessagesRouteListItemText > span h2,
.MessagesRouteListItemText > span h3,
.MessagesRouteListItemText > span h4,
.MessagesRouteListItemText > span h5 {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.MessagesRouteListPinWrapper {
  padding-top: 2px;
  padding-right: 8px;
}
.MailingDeleteContent {
  padding: 8px;
}
.MailingDeleteDescription {
  padding-top: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #212529;
}
.MailingDeleteButtons {
  width: 252px;
  padding-top: 34px;
  justify-content: space-between;
  margin-left: auto;
}
.MailingDeleteButtons .ButtonDefaultWrapper {
  margin-right: 8px;
}
.MailingHallDetailWrapper {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 1228px;
  z-index: 999;
  height: 100vh;
  background: #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  padding: 54px 24px;
}
.ant-table-thead tr > .MailingDetailTableCell {
  background: #FFF4E6 !important;
}
.MailingDetailTableThCell {
  word-spacing: 0 !important;
  word-wrap: unset !important;
  color: #212529 !important;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.MailingHallDetailHeaderCloseMobile {
  display: none;
}
@media screen and (max-width: 1228px) {
  .MailingHallDetailWrapper {
    width: 100vw;
    border-radius: 0px;
    padding: 0px;
  }
  .MailingHallDetailHeader {
    padding-top: 54px;
  }
  .MailingHallDetailHeader,
  .MailingHallDetailPeriod,
  .MailingDetailTableWrapper > .MailingHallDetailLabelSpan,
  .MailingDetailTableButtons {
    padding: 0 16px;
  }
  .MailingHallDetailHeaderClose {
    display: none !important;
  }
  .MailingHallDetailHeaderCloseMobile {
    display: flex !important;
    flex-direction: row;
    padding: 18px 0 19px 16px;
  }
  .MailingDetailTable {
    width: 100vw !important;
    margin: 0 !important;
  }
}
@media screen and (min-width: 1229px) {
  .MailingHallDetailWrapper {
    width: 1228px;
  }
}
.MailingHallDetailHeader {
  justify-content: space-between;
}
.MailingHallDetailTitle span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.MailingHallDetailTitle svg {
  margin-top: 2px;
  margin-left: 8px;
}
.MailingHallDetailTitleDate {
  padding-top: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #808185;
}
.MailingHallDetailHeaderClose:hover {
  cursor: pointer;
  opacity: 0.8;
}
.MailingHallDetailPeriodTitle {
  padding-top: 36px;
}
.MailingHallDetailLabelSpan {
  margin-right: 6px;
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.MailingHallDetailPeriodRadio {
  display: flex !important;
  padding-top: 8px;
}
.MailingHallDetailPeriodRadio .RadioGroupOption {
  width: 240px;
}
.MailingDetailTableWrapper {
  padding-top: 32px;
}
.MailingDetailTableWrapper .MailingHallDetailLabelSpan {
  padding-bottom: 6px;
}
.MailingDetailTableHeaderCell {
  word-spacing: 0 !important;
  word-wrap: unset !important;
  color: #212529 !important;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.MailingDetailTableCell {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}
.MailingDetailTableSmallCell {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}
.MailingDetailTablePhoneCell {
  padding-bottom: 2px;
}
.MailingDetailTableTime {
  opacity: 0.4;
  padding-left: 8px;
}
.MailingDetailTableStatus {
  text-align: center;
  width: fit-content;
  border-radius: 4px;
  padding: 6px 12px 8px;
  min-width: 100px;
}
.MailingDetailTableButtons {
  margin-top: 32px;
}
.MailingDetailTableButtons .ButtonFadedWrapper,
.MailingDetailTableButtons .MailingDetailTableButtonsAdd .ButtonFadedWrapper {
  margin-right: 16px;
}
.MailingDetailTableButtons .ButtonFadedWrapper button,
.MailingDetailTableButtons .MailingDetailTableButtonsAdd .ButtonFadedWrapper button {
  padding-top: 10px !important;
}
@media screen and (max-width: 920px) {
  .MailingDetailTableButtons {
    padding-top: 8px;
  }
  .MailingDetailTableButtons .MailingDetailTableButtonsAdd,
  .MailingDetailTableButtons .ButtonFadedWrapper {
    margin-top: 16px;
  }
}
.MailingDetailTableButtonItem span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px !important;
  color: #212529;
  padding-left: 8px;
  padding-top: 6px;
}
.MailingDetailTableButtonsDescription {
  margin-left: 18px;
  margin-top: 8px;
}
.MailingDetailTableButtonsDescription span {
  padding-top: 3px;
  padding-left: 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}
.MailingDetailTableActionsPopover {
  padding: 12px 16px 0 16px;
}
.MailingDetailTableActionsPopover .FlexRow {
  padding-bottom: 12px;
}
.MailingDetailTableActionsPopover .FlexRow span {
  padding-left: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
}
.MailingDetailTableActionsPopover .FlexRow:hover {
  opacity: 0.8;
  cursor: pointer;
}
.MailingEmptyTableWrapper {
  width: 100%;
  margin-left: calc(25% - 127px);
  margin-top: 105px;
}
.MailingHallContentWrapper {
  gap: 16px;
  width: 100%;
}
@media screen and (min-width: 1884px) {
  .MailingHallContentWrapper .MailingDataItemWrapper {
    max-width: calc((100% - 32px)/3) !important;
  }
  .MailingHallContentOnlyTwoWrapper .MailingDataItemWrapper {
    max-width: calc((100% - 16px)/2) !important;
  }
}
@media screen and (min-width: 1630px) {
  .MailingHallContentWithoutFilterWrapper .MailingDataItemWrapper {
    max-width: calc((100% - 32px)/3) !important;
  }
  .MailingHallContentOnlyTwoWrapper .MailingDataItemWrapper {
    max-width: calc((100% - 16px)/2) !important;
  }
}
@media screen and (max-width: 1113px) {
  .MailingHallContentWithoutFilterWrapper .MailingDataItemWrapper {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1505px) {
  .MailingHallContentWithFilterWrapper .MailingDataItemWrapper {
    max-width: 100% !important;
  }
}
.MailingDataItemWrapper {
  min-width: 393px;
  width: 100%;
  max-width: calc((100% - 16px)/2);
}
.MailingHallItem {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
  min-width: 393px;
  width: 100%;
  padding: 20px 14px;
  margin-top: 18px;
}
.MailingHallItem:hover {
  cursor: pointer;
  opacity: 0.8;
}
.MailingHallItemLeft {
  float: left;
}
.MailingHallItemField {
  height: 58px;
  padding-bottom: 16px;
}
.MailingHallItemField div:first-child {
  padding-bottom: 4px;
}
.MailingHallItemTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  height: 32px !important;
}
.MailingHallItemValue {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.MailingHallItemValueInactiveColor {
  opacity: 0.4;
}
.MailingHallItemDescription {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #868E96;
}
.MailingHallItemActive {
  padding: 4px 26px;
  margin-top: 8px;
  background: #EBFBEE;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #40C057;
}
.MailingHallUserAttentionActiveColor {
  margin-left: 4px;
}
.MailingHallUserAttentionActiveColor path {
  fill: #FA5252;
}
.MailingHallUserAttentionInactiveColor {
  margin-left: 4px;
}
.MailingHallUserAttentionInactiveColor path {
  fill: #ADB5BD;
}
.MailingHallItemInactive {
  margin-top: 8px;
  padding: 4px 26px 6px 26px;
  background: #ADB5BD;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
}
.MailingHallItemDate {
  margin-bottom: 24px;
  text-align: right;
}
.MailingHallAdditional {
  margin-left: 8px;
}
.MailingHallItemRight {
  margin-left: auto;
}
.MailingHallUsersWrapper {
  justify-content: end;
}
.MailingHallUser {
  width: fit-content;
  padding: 8px 12px !important;
  background: #F4F4F4;
  border-radius: 24px;
}
.MailingHallUser span {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #212529;
  margin-top: 3px;
  margin-left: 4px;
}
.MailingHallAddUserText {
  max-width: 96px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.MailingHallUserText {
  max-width: 174px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.MailingHallUserActiveColor {
  background: #F4F4F4;
}
.MailingHallUserActiveColor span {
  color: #212529;
}
.MailingHallUserEmptyActiveColor {
  background: #FFF5F5;
}
.MailingHallUserEmptyActiveColor span {
  color: #FA5252;
}
.MailingHallUserInactiveColor {
  background: #F4F4F4;
  opacity: 0.4;
}
.MailingHallUserInactiveColor span {
  color: #212529;
}
.MailingHallUserEmpty {
  background: #FFF5F5 !important;
}
.MailingHallUserEmpty span {
  color: #FA5252 !important;
}
.MailingHallItemFlags {
  justify-content: end;
  margin-top: 2px;
}
.MailingHallItemFlags div:first-child {
  margin-right: 12px;
}
.MailingHallItemFlagInactiveColor path {
  fill: #212529;
}
.MailingHallItemFlagActiveColor path {
  fill: #40C057;
}
@media screen and (max-width: 450px) {
  .MailingHallItem {
    width: calc(100vw - 32px);
  }
}
@media screen and (max-width: 920px) {
  .MailingEmptyTableWrapper {
    margin-top: 50px;
    width: 100%;
    margin-left: 0;
  }
}
.MailingFilterContentStatus {
  margin-top: 12px;
  padding-bottom: 24px;
}
.MailingFilterContentStatus .CheckboxWrapper:first-child {
  margin-bottom: 10px;
}
.MailingFilterContentSearch {
  padding-top: 8px;
}
.MailingContentTitleRight {
  margin-left: auto;
}
.MailingContentTitleWrapper {
  padding-bottom: 12px !important;
}
.MailingContentTabs {
  width: 100%;
}
.MailingContentTabsItem {
  margin-right: 24px;
  margin-bottom: 11px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}
.MailingContentTabsInactiveItem:hover {
  cursor: pointer;
  color: #212529;
}
.MailingContentTabsItemActive {
  color: #212529;
}
.MailingContentTabsActiveRestriction {
  margin-right: 24px;
  background: #FCE000;
  height: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.MailingContentTabsRestriction {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
}
.MailingModalTitle {
  padding-top: 16px;
  padding-left: 32px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}
.MailingModalContent {
  padding: 36px 24px 14px 24px;
  max-height: calc(100vh - 164px);
  overflow-y: auto;
}
.MailingModalFields {
  justify-content: space-between;
}
.MailingModalLeft,
.MailingModalRight {
  width: 100%;
}
.MailingModalLeft {
  margin-right: 34px;
}
.MailingModalRectangle {
  height: 1px;
  width: 100%;
  background: #DEE2E6;
  margin-bottom: 36px;
}
.MailingModalFieldLabelWrapper .ant-form-item {
  margin-bottom: 0 !important;
}
.MailingModalCheckboxFieldWrapper {
  margin-bottom: 0 !important;
}
.MailingModalCheckboxFieldWrapper .ant-form-item-control .ant-form-item-explain {
  display: none !important;
}
.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .MailingModalCheckboxField .CheckboxWrapper .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-color: #ff4d4f;
}
.MailingModalFieldLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.MailingModalCheckboxField {
  padding-bottom: 8px;
}
.MailingModalSuccess {
  margin: 200px 0;
}
.MailingModalSuccess span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
}
.MailingModalSuccess svg {
  margin-top: 24px;
}
.MailingHistoryPeriodWrapper {
  padding-top: 16px;
}
.MailingHistoryPeriodWrapper span {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #495057;
}
.MailingHistoryPeriodWrapper .MailingHistoryDateDescriptionDef {
  padding-right: 6px;
}
.MailingHistoryDateDescriptionBold {
  font-weight: bold !important;
  padding-right: 6px;
}
.MailingHistoryTable {
  padding-top: 16px;
}
.MailingHistoryTable .TableColumnWrapper .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  min-width: 900px;
}
.MailingHistoryThCell {
  word-spacing: 0 !important;
}
.MailingHistoryTdDate,
.MailingHistoryTdDateDescription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}
.MailingHistoryTdDate,
.MailingHistoryThCellDate {
  padding-left: 20px;
}
.MailingHistoryTdDateDescription {
  padding-left: 6px;
  opacity: 0.6;
}
.MailingHistoryTdType span {
  padding-left: 8px;
  padding-top: 1px;
}
@media screen and (max-width: 920px) {
  .MailingWrapper {
    margin: 0 16px;
  }
}
.GuestsHistoryActiveDayLabel {
  position: relative;
}
.GuestsHistoryActiveDayLabel {
  position: absolute;
  bottom: -2px;
  left: calc(50% - 2px);
  z-index: 2;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #40C057;
}
.GuestHistoryCommentWrapper {
  margin-top: 21px;
  margin-bottom: 12px;
  padding: 5px 8px 5px 4px;
  border: 1px solid #FFF;
  cursor: pointer;
}
.GuestHistoryCommentEditWrapper {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
.GuestHistoryCommentCrossWrapper {
  display: none;
  margin-left: auto;
  padding-top: 6px;
}
.GuestHistoryCommentEditWrapper > .GuestHistoryCommentCrossWrapper {
  display: inherit !important;
  cursor: pointer;
}
.GuestHistoryCommentActiveWrapper .GuestHistoryCommentText {
  color: #339AF0 !important;
}
.GuestHistoryCommentActiveWrapper .GuestHistoryCommentIcon path {
  fill: #339AF0;
}
.GuestHistoryCommentText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #DEE2E6;
  max-width: calc(100vw - 770px);
  overflow-x: hidden;
  padding-top: 4px;
  padding-left: 14px;
}
.GuestHistoryCommentChangeModal {
  padding: 46px 56px;
}
.GuestHistoryCommentChangeModalTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #212529;
}
.GuestHistoryCommentChangeModalLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-top: 24px;
  padding-bottom: 6px;
}
.GuestHistoryCommentChangeModalDataRow {
  padding-bottom: 12px;
}
.GuestHistoryCommentChangeModalDataItem {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #212529;
  max-width: 310px;
  overflow-x: hidden;
  padding-left: 11px;
}
.GuestHistoryCommentChangeModalDataItemDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #868E96;
  padding-left: 32px;
}
.GuestHistoryCommentChangeModalButtons {
  padding-top: 19px;
  gap: 16px;
}
.GuestHistoryCommentChangeModalButtons .ButtonPrimaryWrapper button {
  width: 213px;
}
.GuestHistoryCommentChangeModalMobileBack {
  display: none;
}
.GuestHistoryCommentEmptyHistory {
  padding-bottom: 126px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryCommentText,
  .GuestHistoryCommentChangeModalDataItem {
    max-width: calc(100vw - 130px);
    overflow-x: hidden;
  }
  .GuestHistoryCommentChangeModal {
    padding: 18px;
    width: 100vw;
  }
  .GuestHistoryCommentEmptyHistory {
    margin-bottom: auto;
  }
  .GuestHistoryCommentChangeModalMobileBack {
    display: flex;
    width: fit-content;
    padding-bottom: 20px;
  }
  .GuestHistoryCommentChangeModalMobileBack svg path {
    fill: #228BE6;
  }
  .GuestHistoryCommentChangeModalMobileBack span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #228BE6;
    padding-left: 6px;
    padding-top: 2px;
  }
}
.FreebetsDetailWrapper {
  padding: 40px 24px;
}
.FreebetsDetailTable {
  margin-top: 16px;
}
.FreebetsDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
.GuestHistoryCardWrapper {
  gap: 32px;
}
.GuestHistoryCardInfo {
  padding: 24px;
  width: calc(100% - 324px);
  border: 1px solid #DEE2E6;
  border-radius: 16px;
  background: #FFFFFF;
}
.GuestHistoryCardInfoHeader {
  gap: 16px;
}
.GuestHistoryCardInfoHeaderTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #212529;
}
.GuestHistoryCardInfoDescription {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: #868E96;
}
.GuestHistoryStatus {
  display: flex;
  padding: 6px 18px;
  border-radius: 4px;
}
.GuestHistoryStatus span {
  padding-top: 2.5px;
  padding-left: 4px;
}
.GuestHistoryCardInfoDescriptionWalletStatus {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 50%;
}
.GuestHistoryCardInfoDescriptionWalletStatusTooltip {
  padding: 0 !important;
}
.GuestHistoryCardInfoDescriptionWalletStatusTooltip .ant-popover-content {
  margin-left: -4px !important;
}
.GuestHistoryCardInfoDescriptionWalletStatusTooltip .ant-popover-content .ant-popover-arrow {
  display: none !important;
}
.GuestHistoryCardInfoDescriptionActiveWallet {
  background: #40C057;
}
.GuestHistoryCardInfoDescriptionInactiveWallet {
  background: #E03131;
}
.GuestHistoryActive {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  padding: 6px 12px;
  background: #EBFBEE;
  border-radius: 4px;
}
.GuestHistoryCardDate {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 16px;
}
.GuestHistoryCardDateData {
  padding: 24px 18px 17px 25px;
}
.GuestHistoryCardDateStick {
  margin-right: 24px;
}
.GuestHistoryCardDateDataTitleWrapper {
  padding-left: 12px;
}
.GuestHistoryCardDateTitle {
  padding-bottom: 12px;
  position: relative;
}
.GuestHistoryCardDateTitleTooltip {
  position: absolute;
  left: 200px;
  top: 0;
}
.GuestHistoryCardDateTitleTooltip .TooltipComponentTargetWrapper {
  height: 24px !important;
}
.GuestHistoryCardDateDataTitle,
.GuestHistoryCardDateTitle {
  color: #212529;
}
.CalculateBetweenTextDate {
  padding-left: 4px;
}
.GuestHistoryCardDateDataDescription {
  color: #868E96;
}
.GuestHistoryCardDateDataGrey {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;
  margin-bottom: 18px;
  padding: 10px 12px;
  background: #F1F3F5;
  border-radius: 8px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryCardInfo {
    width: calc(100vw - 32px) !important;
    margin-left: 16px;
    margin-right: 16px !important;
    padding: 20px 14px !important;
  }
  .GuestHistoryCardDate {
    margin-left: 16px;
    width: calc(100vw - 32px);
  }
  .GuestHistoryCardDateStick {
    margin-left: auto;
  }
  .GuestHistoryCardDateData {
    width: 100%;
  }
  .GuestHistoryCardInfoHeaderTitle {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}
.GuestMetricTableTitle {
  margin-top: 32px;
  margin-bottom: 16px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
}
.GuestMetricTableWrapper {
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 8px;
}
.GuestMetricTableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.GuestMetricTableItem {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 22px 16px;
  border-right: 1px solid #CED4DA;
}
.GuestMetricTableRowFirst > .GuestMetricTableItem {
  border-bottom: 1px solid #CED4DA;
}
.GuestMetricTableItemValue {
  color: #212529;
}
.GuestMetricTableItemValue .TooltipComponentTargetWrapper {
  padding-left: 8px;
}
.GuestMetricTableItemDescription {
  color: #868E96;
  padding-top: 2px;
}
.GuestMetricTableLast {
  border-right: none !important;
}
.GuestMetricTableItemLast {
  border-right: none !important;
}
@media screen and (max-width: 920px) {
  .GuestMetricTableItem {
    width: 100%;
    border-right: none !important;
  }
  .GuestMetricTableRow > .GuestMetricTableItem {
    border-bottom: 1px solid #CED4DA;
  }
  .GuestMetricTableRow {
    flex-direction: column;
  }
  .GuestMetricTableItemLastMobile {
    border-bottom: none !important;
  }
}
.GuestMetricTableItemMaxCashback {
  padding-right: 4px;
}
.ChartsWrapper {
  background-color: #fff;
  padding-left: 16px;
}
@media screen and (max-width: 920px) {
  .highcharts-scrolling::-webkit-scrollbar {
    display: none !important;
  }
}
.highcharts-credits {
  display: none !important;
}
.GuestHistoryMetricChartHeader {
  padding-top: 32px;
  padding-bottom: 16px;
}
.GuestHistoryMetricChartTitle {
  padding-right: 16px;
  padding-top: 14px;
}
.GuestHistoryMetricChartSwitcher {
  width: 230px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryMetricChartHeader {
    padding-top: 0 !important;
  }
  .GuestHistoryMetricChartTitle {
    padding-bottom: 16px;
  }
}
.GuestHistoryEmptyDataWrapper {
  padding-top: 72px;
  padding-bottom: 72px;
}
.GuestHistoryCashboxTableTitle {
  padding-bottom: 4px;
}
.GuestHistoryCashboxTableDescriptionWrapper {
  display: flex;
  flex-direction: row;
}
.GuestHistoryCashboxTableDescription {
  color: #868E96 !important;
  padding-left: 4px;
  padding-right: 16px;
}
.GuestHistoryCashboxTableHeader {
  padding-bottom: 22px;
}
.GuestHistoryCashboxTableType {
  padding-left: 8px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryCashboxTableDescriptionWrapper {
    flex-direction: column;
  }
}
.GuestHistoryCashboxChartsWrapper {
  padding-top: 32px;
}
.GuestHistoryCashboxChartsPeriod {
  width: 230px;
}
.GuestHistoryCashboxChartsPeriod .SelectWrapper .SelectComponent .ant-select-selector .ant-select-selection-item {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.GuestHistoryCashboxChartsTypes {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
}
.ChartTypeComponentWrapper {
  border-radius: 8px;
  padding: 18px 18px 16px 18px;
  width: 164px;
  height: 48px;
}
.ChartTypeComponentWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ChartTypeComponentDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 7px;
}
.ChartTypeComponentLabel {
  color: #868E96;
}
.ChartTypeComponentActiveLabel {
  color: #212529 !important;
}
@media screen and (max-width: 920px) {
  .GuestHistoryCashboxChartsTypes {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
  }
  .GuestHistoryCashboxChartsHeader {
    flex-direction: column !important;
    gap: 8px;
  }
  .ChartTypeComponentWrapper {
    width: fit-content;
  }
}
.highcharts-tooltip {
  padding: 20px 24px;
}
.highcharts-tooltip span {
  height: auto;
}
.InOutTooltip span {
  width: 376px !important;
  white-space: normal !important;
}
.AttendanceTooltip {
  width: 100px !important;
}
.highcharts-axis-labels > text {
  transform: none !important;
  margin-left: -5px !important;
}
.highcharts-series > rect {
  width: 45px !important;
}
.HideScrollHighcharts div .highcharts-scrolling-parent .highcharts-scrolling::-webkit-scrollbar {
  display: none !important;
}
.GuestHistoryCashboxChartsWrapperAttendance div .highcharts-scrolling-parent .highcharts-scrolling {
  margin-bottom: 16px;
}
.GuestHistoryCashboxWrapper {
  position: relative;
  padding: 32px 0;
}
.GuestHistoryCashboxSwitcher {
  position: absolute;
  top: 32px;
  right: 0;
}
@media screen and (max-width: 920px) {
  .GuestHistoryCashboxSwitcher {
    right: 0;
  }
  .DataSwitcherButton {
    height: 48px !important;
  }
}
.GuestHistoryBetsChartHeader {
  display: flex;
  gap: 8px;
}
.GuestHistoryBetsChartHeader .SelectWrapper {
  width: 230px;
}
.GuestHistoryBetsBySumWrapper {
  padding-top: 27px;
  margin-left: -12px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryBetsChartHeader {
    flex-direction: column;
  }
}
.GuestHistoryBetsByTimeWrapper {
  overflow-x: auto;
  padding-bottom: 24px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryBetsByTimeWrapper::-webkit-scrollbar {
    display: none !important;
  }
}
.GuestHistoryBetsByTimeGameTitle {
  padding-right: 34px;
  text-align: left;
  min-width: 162px;
  padding-top: 15px;
}
.GuestHistoryBetsByTimeGameHourLabel {
  width: 44px;
}
.GuestHistoryBetsByTimeGameTableItemLabelPosition {
  margin-left: -18px;
}
.GuestHistoryBetsByTimeGameHourSumLabel {
  width: 94px;
  margin-left: 14px !important;
}
.GuestHistoryBetsByTimeGameHourSumLabel,
.GuestHistoryBetsByTimeGameHourLabel,
.GuestHistoryBetsByTimeGameTitle {
  color: #868E96;
}
.GuestHistoryBetsByTimeGameTableItemLabel,
.GuestHistoryBetsByTimeGameTableItem {
  height: 45px;
  margin: 1.5px 3px 1.5px 0;
  width: 37px;
}
.GuestHistoryBetsByTimeGameTableItem {
  border-left: 1px dashed #E9ECEF;
}
.GuestHistoryBetsByTimeGameTableItemBet {
  background: #8CE99A;
}
.GuestHistoryBetsByTimeGameTableItemWin {
  background: #FF6B6B;
}
.GuestHistoryBetsByTimeItemTooltip {
  width: 376px;
  padding: 12px 13px 0 13px;
}
.GuestHistoryBetsByTimeItemTooltipHeader {
  color: #FCE000;
}
.GuestHistoryBetsByTimeItemTooltipItem {
  width: 164px;
}
.GuestHistoryBetsByTimeItemTooltipRow {
  gap: 16px;
  padding-bottom: 12px;
}
.GuestHistoryBetsByTimeItemTooltipDescription {
  color: #868E96;
}
.GuestHistoryBetsByTimeItemTooltipValue {
  color: #FFF;
}
.GuestHistoryBetsTableWrapper {
  padding-top: 16px;
}
.GuestHistoryBetsTableHeader {
  gap: 68px;
}
.GuestHistoryBetsTableHeaderDescription {
  gap: 16px;
}
@media screen and (max-width: 920px) {
  .GuestHistoryBetsTableHeaderDescription {
    flex-direction: column !important;
    gap: 8px !important;
  }
  .GuestHistoryBetsTableHeader {
    padding-top: 8px;
    flex-direction: column !important;
    gap: 8px !important;
  }
}
.GuestHistoryBetsTableHeaderDescriptionItem {
  color: #868E96;
}
.GuestHistoryBetsTableHeaderDescriptionItem svg {
  margin-top: 2px;
  margin-right: 4px;
}
.GuestHistoryBetsWrapper {
  position: relative;
  padding: 32px 0;
}
.GuestHistoryBetsDataSwitcher {
  position: absolute;
  top: 32px;
  right: 0;
}
.GuestHistoryReportsWrapper {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 16px;
  padding: 32px 24px;
  margin-top: 32px;
}
.ChartTooltip {
  padding: 20px 24px;
  background-color: #434345 !important;
  margin: -10px !important;
  border-radius: 10px;
}
.ChartTooltipItem {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 12px;
}
.ManageListMobileHallPicker {
  margin: 0 16px;
}
.ManageListMobileHallItem {
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
  padding: 12px 16px;
}
.ManageListMobileHallItemActive {
  border: 2px solid #FCE000 !important;
}
.ManageListMobileHallItem:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ManageListMobileItemWrapper {
  margin: 0 16px;
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
}
.ManageListMobileItemTitle {
  padding: 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ManageListMobileItemTitleVip {
  background: #FFF4E6 !important;
}
.ManageListMobileItemTitleMain {
  background: #E3FAFC !important;
}
.ManageListMobileItemRow {
  border-top: 1px solid #E9ECEF;
}
.ManageListMobileItemRowItem {
  width: 50%;
  padding: 16px 16px 12px 16px;
}
.ManageListMobileItemRowFirst {
  border-right: 1px solid #E9ECEF;
}
.SuperwinListHeader {
  background-color: #E3FAFC !important;
  padding-top: 20px !important;
}
.SuperwinListNominalHeader {
  font-weight: 400 !important;
}
.SuperwinListFirstColumn {
  width: 20% !important;
  min-width: 20% !important;
}
.IncreaseModalLimit {
  border-radius: 8px;
  padding: 12px 16px;
}
.IncreaseModalLimitText {
  text-align: center;
}
.SuperwinListFirstColumn {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #E9ECEF;
  border-left: 1px solid #E9ECEF;
  border-bottom: 1px solid #E9ECEF;
}
.SuperwinListFirstColumn .SuperwinListHeader {
  border-top-left-radius: 8px;
}
.SuperwinListFirstColumn .SuperwinListVipRow {
  border-bottom-left-radius: 8px;
}
.SuperwinListFirstColumn > div {
  padding-left: 32px !important;
}
.SuperwinListColumn {
  border-top: 1px solid #E9ECEF;
  border-bottom: 1px solid #E9ECEF;
  min-width: fit-content;
  width: 100%;
}
.SuperwinListRow {
  overflow-x: scroll;
}
.SuperwinListRow::-webkit-scrollbar {
  width: 10px;
  /* Adjust to your preference */
  background: transparent;
  /* This makes it transparent */
}
.SuperwinListRow::-webkit-scrollbar-track {
  background: transparent !important;
}
.SuperwinListFirstColumn .SuperwinListItem:nth-child(2),
.SuperwinListColumn .SuperwinListItem:nth-child(2) {
  border-bottom: 1px solid #E9ECEF;
}
.SuperwinListlastColumn {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #E9ECEF;
}
.SuperwinListlastColumn .SuperwinListHeader {
  border-top-right-radius: 8px;
}
.SuperwinListlastColumn .SuperwinListItem:last-child {
  border-bottom-right-radius: 8px;
}
.SuperwinListItem {
  padding: 14px 8px;
  height: 56px;
  background-color: #FFFFFF;
}
.SuperwinListMainRowIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}
.IncreaseModalWrapper {
  width: 500px;
  padding: 32px 24px;
  position: relative;
}
.IncreaseModalTitleClose {
  position: absolute;
  right: 16px;
  top: 16px;
}
.SuperwinIncreaseSliderWrapper .SliderComponentWrapper .SliderComponentValueWrapper {
  display: none;
}
.SuperwinIncreaseSliderWrapper {
  padding-top: 8px;
}
.IncreaseModalAlertArea {
  padding: 12px 16px;
  border-radius: 8px;
  background: #F4F4F4;
}
.IncreaseModalApply .ButtonPrimaryWrapper button {
  width: 100%;
}
.IncreaseModalTable {
  border-radius: 8px;
  border: 1px solid #CED4DA;
}
.IncreaseModalTable .IncreaseModalTableFirstRow {
  border-bottom: 1px solid #CED4DA;
}
.IncreaseModalTable .IncreaseModalTableFirstRow > div {
  width: 50%;
}
.IncreaseModalTableFirstRow .IncreaseModalTableItem:first-child {
  border-right: 1px solid #CED4DA;
}
.IncreaseModalTableItem {
  padding: 8px 16px;
}
.IncreaseModalTableItem .Overline {
  color: #868E96;
}
.SuperwinSuccessModalWrapper {
  padding: 32px;
}
.SuperwinSuccessModalWrapper .ButtonPrimaryWrapper button {
  width: 100%;
}
.SuperwinSuccessModalDescription {
  width: 372px;
}
@media screen and (max-width: 920px) {
  .IncreaseModalWrapper {
    width: calc(100vw - 32px);
  }
  .SuperwinSuccessModalDescription {
    width: auto !important;
  }
  .SuperwinSuccessModalWrapper {
    padding: 24px 16px;
  }
  .IncreaseModalAlertArea > div {
    max-width: calc(100vw - 115px);
  }
}
.SuperwinsGameListWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 16px 24px;
}
.SuperwinsGameListItem {
  width: 200px;
  padding: 8px 12px;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
.SuperwinsGameListItem img {
  width: 40px;
  height: 40px;
}
.SuperwinsGameListItem .BodySecondText {
  display: grid;
  place-items: center;
}
@media screen and (max-width: 920px) {
  .SuperwinsGameListItem {
    max-width: 142px;
  }
  .SuperwinsGameListItem .BodySecondText {
    max-width: 100px;
  }
  .SuperwinsGameListWrapper {
    margin: 16px;
  }
}
.SuperwinHistoryContentEmpty {
  padding-top: 10%;
}
.SuperwinHistoryContentEmpty .EmptyTableLabel {
  width: 400px;
}
@media screen and (max-width: 920px) {
  .SuperwinTitleExcelButton {
    position: absolute;
    right: 0;
    top: 52px;
  }
}
.ErrorPageWrapper {
  padding-top: 8%;
}
.ErrorPageTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #0D0D0D;
  padding-bottom: 15px;
}
.ErrorPageDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #3A3A3C;
  padding-bottom: 32px;
}
.ErrorPageButton {
  padding-top: 80px;
  padding-bottom: 40px;
}
.ProposalModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  min-height: 1080px;
}
.ProposalModalSubTitleWrapperSupportMessage {
  white-space: pre-line;
}
@media screen and (min-height: 1081px) {
  .ProposalModalWrapper {
    min-height: 100vh;
    height: 100%;
  }
}
.ProposalModal {
  width: 1064px;
  z-index: 10;
  background-color: white;
  margin: 72px 0;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  padding: 32px 40px 40px 40px;
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: 32px !important;
  }
}
@media screen and (min-width: 1441px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: calc(50vw - 160px) !important;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 920px) {
  .ProposalModal {
    width: 100% !important;
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 24px 16px !important;
  }
}
.ProposalModal::-webkit-scrollbar {
  display: none !important;
}
.ProposalModalTitleClose {
  margin-left: auto;
  margin-right: -25px;
  margin-top: -10px;
}
.ProposalModalTitleClose:hover {
  cursor: pointer;
}
.ProposalModalTitleClose:hover svg path {
  fill: black;
}
.ProposalModalTitleWrapper .ProposalModalTextTitleText {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  margin-right: 12px;
  display: flex;
}
.ProposalModalSubTitleWrapper {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  margin-top: 24px;
}
.ProposalModalButtonsGroup {
  padding-top: 18px;
  display: flex;
}
.ProposalModalButtonsGroup .ButtonIconWrapper {
  margin-right: 16px !important;
}
.ProposalModalButtonsGroup .ButtonPrimaryWrapper button {
  width: 200px;
}
.ProposalModalButtonsGroup .ProposalModalAttachmentButton {
  margin-left: 16px;
  display: flex;
}
.ProposalModalButtonsGroup .ProposalModalAttachmentIcon {
  align-self: center;
}
.ProposalModalButtonsGroup .ant-form-item-explain-error {
  margin-top: 14px;
  margin-left: 17px;
}
.ProposalModalButtonsGroupTopLine {
  border-top: 1px solid #DEE2E6;
  padding-top: 32px;
}
.ProposalModalTitleAndStatus {
  display: flex;
  align-items: center;
}
.ProposalModalSupportCard {
  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px 16px 16px 16px;
  margin-top: 24px;
}
.ProposalModalSupportCard .ProposalModalSubTitleWrapper {
  margin-top: 0;
}
.ProposalModalFilesWrapper {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ProposalModalCloseIcon {
  margin-top: 30px;
  margin-right: 26px;
}
.SupportProposalModal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid #E9ECEF;
}
.SupportProposalModal .ant-modal-body {
  padding: 46px 56px;
}
.SupportProposalModalMobile .ant-modal-body {
  padding: 58px 17px 19px 17px;
}
.SupportProposalModal .ant-form-item-label,
.ProposalModalSubTitleWrapper {
  padding: 0 0 6px !important;
}
.SupportProposalModal .ant-form-item {
  margin-bottom: 0 !important;
}
.ProposalModalPhoneInput {
  max-width: 246px !important;
  min-width: 246px !important;
  width: 246px !important;
}
.ProposalModalPhoneInput input[type=number]::-webkit-inner-spin-button,
.ProposalModalPhoneInput input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ProposalModalTitleViewMode {
  border-bottom: 1px solid #DEE2E6;
  padding-bottom: 18px;
}
.ProposalFormViewMode .ant-form-item-label {
  padding-bottom: 0 !important;
}
.ProposalFormViewMode .ant-form-item-control-input {
  min-height: 14px !important;
}
.ProposalModalContentWrapper {
  margin-bottom: 24px;
}
.ProposalModalTitleAndStatus .ProposalNullishZendeskIdWrapper {
  align-items: unset !important;
}
.ProposalModalTextTitleMain {
  margin-right: 6px;
}
.ProposalModalTitleAndStatus .TooltipComponentTargetWrapper {
  margin-left: 10px !important;
  width: 24px;
  height: 24px;
}
.ProposalModalTitleAndStatus .TooltipComponentTargetWrapper svg {
  width: 24px;
  height: 24px;
}
.SupportProposalSuccess {
  height: 588px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SupportProposalSuccessText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  white-space: pre-line;
  margin-bottom: 20px;
}
.ProposalModalButtonCancel {
  width: 145px;
}
.ProposalModalButtonCancel svg {
  width: 15px !important;
  height: 15px !important;
  left: 28px !important;
  top: 10px !important;
}
.ProposalModalButtonDuplicate {
  width: 197px;
}
.ProposalModalButtonDuplicate svg {
  left: 40px !important;
}
.ProposalModalContentMobileViewBack {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #228BE6;
  margin-bottom: 19px;
  margin-top: 19px;
  display: flex;
  align-items: center;
}
.ProposalModalContentMobileViewBack svg {
  margin-right: 6px;
}
.ProposalMobileCard {
  padding: 16px 14px 19px 14px;
  background-color: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
  margin: 8px;
}
.ProposalMobileCard:hover {
  cursor: pointer;
}
.ProposalMobileCardTitleWrapper {
  display: flex;
  justify-content: space-between;
}
.ProposalMobileCardTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #212529;
  display: flex;
}
.ProposalMobileCardText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  padding-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  /* number of lines to show */
  line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}
.ProposalMobileCardFooter {
  display: flex;
  padding-top: 16px;
}
.ProposalMobileCardElement {
  display: flex;
  padding-left: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;
}
.ProposalMobileCardElementDetails {
  margin-left: auto;
  margin-right: 0;
}
.ProposalMobileCardElementDetails:hover {
  cursor: pointer;
}
.ProposalMobileCardStatus {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.4px;
}
.ProposalMobileCardCounter {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;
  padding-left: 4px;
  padding-bottom: 4px;
}
.ProposalMobileCardTitleDate .FlexRow {
  align-items: center;
}
.ProposalMobileCardTitleText {
  margin-right: 6px;
}
.SupportProposalModalMobile .ProposalModalTextTitleText {
  font-size: 14px;
}
.SupportProposalModalMobile .TooltipComponentTargetWrapper {
  margin-left: 2px !important;
  margin-top: 6px !important;
}
.ProposalModalContentMobileView {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  height: 100vh;
  z-index: 5;
  padding: 0 17px 0 17px;
  border-top: 52px solid #f4f4f4;
}
.ProposalModalContentMobileView .ButtonIconWrapper {
  font-size: 16px !important;
}
.ProposalModalContentMobileView .ProposalModalTextTitleText {
  font-size: 24px !important;
}
.ProposalModalContentMobileView .ProposalModalButtonsGroupTopLine {
  padding-top: 18px !important;
}
.ProposalModalContentMobileView .ProposalModalContentWrapper:first-child {
  margin-top: 18px;
}
.ProposalModalContentMobileView .ProposalModalSubTitleWrapper {
  margin-top: 0 !important;
}
.ProposalModalContentMobileView .ProposalModalButtonsGroup {
  padding-bottom: 19px !important;
}
.ProposalStatus {
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProposalStatusPending {
  background: #F1F3F5;
}
.ProposalStatusCanceled {
  color: #FA5252;
  background: #FFF5F5;
}
.ProposalStatusSuccess {
  color: #40C057;
  background: #EBFBEE;
}
.PopoverGridTools {
  display: flex;
  flex-direction: row;
}
.ProposalIcon:nth-child(2n) {
  margin-left: 15px;
}
.ProposalIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProposalIcon svg {
  width: 16px;
  height: 16px;
  color: #ADB5BD;
}
.ProposalIcon:hover {
  opacity: 0.25;
}
.ProposalIconText {
  color: #ADB5BD;
  size: 14px;
  margin-left: 3px;
}
.ProposalIconDetails {
  margin-left: auto;
}
.ProposalIconDetails:hover {
  cursor: pointer;
  opacity: 0.25;
}
.ProposalPopoverButton {
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 10px;
  border-radius: 12px;
}
.ProposalPopoverButton .ProposalPopoverButtonText {
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.ProposalPopoverButton svg {
  width: 16px;
  height: 16px;
}
.ProposalPopoverButton:hover {
  background-color: #E8E8E8FF;
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 0 !important;
}
.ant-popover-inner {
  border-radius: 12px !important;
}
.ProposalTextColumn {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1600px) {
  .ProposalNewButtonTitleContainer {
    display: inline-flex !important;
    margin-top: 10px;
  }
}
.ProposalNewButtonTitleContainer {
  margin-left: auto;
}
.SupportProposalAdditionalCell {
  word-spacing: 0 !important;
  word-wrap: unset !important;
}
.ProposalTable .ProposalTableCellHeader,
.SupportProposalAdditionalCell {
  color: #212529 !important;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.ProposalNullishZendeskIdIcon {
  width: 16px;
  height: 16px;
}
.ProposalNullishZendeskIdMask {
  color: #CED4DA;
}
.ProposalNullishZendeskIdWrapper {
  display: flex;
  align-items: center;
}
.ProposalNullishZendeskIdWrapper .TooltipComponentTargetWrapper {
  width: 16px;
  height: 16px;
  margin-left: 3px;
}
.ProposalTextColumn .ant-typography mark {
  background-color: #fef9cc;
}
.NewHallContent .EditorTextArea,
.NewHallContent .EditorWrapperView {
  min-height: fit-content !important;
  max-height: fit-content !important;
}
.NewHallContent .EditorWrapperView {
  background: #FFFFFF;
}
.NewHallContent .EditorWrapperRoot {
  padding-bottom: 30px;
}
.NewHallContent .EditorWrapperRoot p {
  margin-bottom: 0 !important;
}
.NewHallContent .EditorWrapperRoot ul,
.NewHallContent .EditorWrapperRoot ol {
  margin-top: 1em !important;
  padding-inline-start: 40px !important;
}
.NewHallContent .EditorWrapperRoot h1,
.NewHallContent .EditorWrapperRoot h2,
.NewHallContent .EditorWrapperRoot h3 {
  margin-bottom: 0.5em !important;
}
.NewHallContent .EditorWrapperRoot p:empty {
  margin-bottom: 1em !important;
}
.NewHallContent .public-DraftStyleDefault-block {
  margin: 0 !important;
}
.NewHallContent .EditorToolbar {
  position: sticky !important;
  bottom: 0 !important;
  left: 0 !important;
  top: 0 !important;
  width: 580px;
}
.NewHallContent .EditorTextArea {
  padding: 14px 16px 14px 16px !important;
}
.NewHallContent .ButtonIconWrapper button {
  border: 1px solid #CED4DA !important;
  display: flex;
  align-items: center;
  width: 40px;
}
.NewHallContent .ButtonIconWrapper button:hover {
  border: 1px solid #CED4DA !important;
  box-shadow: 0 0 0 1px #CED4DA !important;
}
.NewHallContent .NewHallContent_ButtonsGroup {
  display: flex;
}
.NewHallContent .NewHallContent_ButtonsGroup .ButtonPrimaryWrapper {
  margin-right: 10px;
}
.NewHallContent .NewHallContent_ButtonsGroup .ButtonIconWrapper button {
  height: 48px;
  width: 120px;
}
.NewHallContent .NewHallContent_ButtonsGroup .ButtonIconWrapper svg {
  height: 15px;
  width: 15px;
  margin-top: 6px;
  margin-left: 6px;
}
.ProposalModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  min-height: 1080px;
}
@media screen and (min-height: 1081px) {
  .ProposalModalWrapper {
    min-height: 100vh;
    height: 100%;
  }
}
.ProposalModal {
  width: 1064px;
  z-index: 10;
  background-color: white;
  margin: 72px 0;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  padding: 32px 40px 40px 40px;
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: 32px !important;
  }
}
@media screen and (min-width: 1441px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: calc(50vw - 160px) !important;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 920px) {
  .ProposalModal {
    width: 100% !important;
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 24px 16px !important;
  }
}
.ProposalModal::-webkit-scrollbar {
  display: none !important;
}
.ProposalModalTitleClose {
  margin-left: auto;
  margin-right: -25px;
  margin-top: -10px;
}
.ProposalModalTitleClose:hover {
  cursor: pointer;
}
.ProposalModalTitleClose:hover svg path {
  fill: black;
}
.ProposalModalTitleWrapper .ProposalModalTextTitleText {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  margin-right: 12px;
  display: flex;
}
.ProposalModalSubTitleWrapper {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  margin-top: 24px;
}
.ProposalModalButtonsGroup {
  padding-top: 18px;
  display: flex;
}
.ProposalModalButtonsGroup .ButtonIconWrapper {
  margin-right: 16px !important;
}
.ProposalModalButtonsGroup .ButtonPrimaryWrapper button {
  width: 200px;
}
.ProposalModalButtonsGroup .ProposalModalAttachmentIcon {
  align-self: center;
}
.ProposalModalButtonsGroup .ant-form-item-explain-error {
  margin-top: 14px;
  margin-left: 17px;
}
.ProposalModalButtonsGroupTopLine {
  border-top: 1px solid #DEE2E6;
  padding-top: 32px;
}
.ProposalModalTitleAndStatus {
  display: flex;
  align-items: center;
}
.ProposalModalSupportCard {
  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px 16px 16px 16px;
  margin-top: 24px;
}
.ProposalModalSupportCard .ProposalModalSubTitleWrapper {
  margin-top: 0;
}
.ProposalModalFilesWrapper {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.ProposalModalCloseIcon {
  margin-top: 30px;
  margin-right: 26px;
}
.SupportProposalModal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid #E9ECEF;
}
.SupportProposalModal .ant-modal-body {
  padding: 46px 56px;
}
.SupportProposalModalMobile .ant-modal-body {
  padding: 58px 17px 19px 17px;
}
.SupportProposalModal .ant-form-item-label,
.ProposalModalSubTitleWrapper {
  padding: 0 0 6px !important;
}
.SupportProposalModal .ant-form-item {
  margin-bottom: 0 !important;
}
.ProposalModalPhoneInput {
  max-width: 246px !important;
  min-width: 246px !important;
  width: 246px !important;
}
.ProposalModalPhoneInput input[type=number]::-webkit-inner-spin-button,
.ProposalModalPhoneInput input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ProposalModalTitleViewMode {
  border-bottom: 1px solid #DEE2E6;
  padding-bottom: 18px;
}
.ProposalFormViewMode .ant-form-item-label {
  padding-bottom: 0 !important;
}
.ProposalFormViewMode .ant-form-item-control-input {
  min-height: 14px !important;
}
.ProposalModalContentWrapper {
  margin-bottom: 24px;
}
.ProposalModalTitleAndStatus .ProposalNullishZendeskIdWrapper {
  align-items: unset !important;
}
.ProposalModalTextTitleMain {
  margin-right: 6px;
}
.ProposalModalTitleAndStatus .TooltipComponentTargetWrapper {
  margin-left: 10px !important;
  width: 24px;
  height: 24px;
}
.ProposalModalTitleAndStatus .TooltipComponentTargetWrapper svg {
  width: 24px;
  height: 24px;
}
.SupportProposalSuccess {
  height: 588px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SupportProposalSuccessText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  white-space: pre-line;
  margin-bottom: 20px;
}
.ProposalModalButtonCancel {
  width: 145px;
}
.ProposalModalButtonCancel svg {
  width: 15px !important;
  height: 15px !important;
  left: 28px !important;
  top: 10px !important;
}
.ProposalModalButtonDuplicate {
  width: 197px;
}
.ProposalModalButtonDuplicate svg {
  left: 40px !important;
}
.ProposalModalContentMobileViewBack {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #228BE6;
  margin-bottom: 19px;
  margin-top: 19px;
  display: flex;
  align-items: center;
}
.ProposalModalContentMobileViewBack svg {
  margin-right: 6px;
}
.AddEditPenaltyModalFieldsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.AddEditPenaltyModalFieldsRow .ant-form-item:first-child {
  margin-right: 34px;
}
.AddEditPenaltyModalFieldsRow .DatePickerWrapper {
  width: 266px;
}
.AddEditPenaltyModalFieldsRow .InputWrapper {
  width: 266px;
}
.SelectMiddleWrapper {
  height: 48px !important;
}
.SelectMiddleWrapper .ant-select {
  height: 48px !important;
}
.SelectMiddleWrapper .ant-select-selector {
  height: 48px !important;
}
.AddEditPenaltyModalSelectAddInput {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.AddEditPenaltyModalSelectAddInput .InputWrapper {
  width: 100%;
  margin-right: 10px;
}
.AddEditPenaltyModalSelectAddInputWin {
  width: 266px;
}
.AddEditModalPenaltyAttachmentsTooltip {
  margin-left: 16px;
  width: 222px;
  height: 38px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}
.AddEditModalPenaltyAttachmentsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AddEditModalPenaltyAttachmentsButton {
  margin-bottom: 6px;
}
.PenaltyAdminCardHeader {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;
  color: #868E96;
}
.PenaltyAdminCardReasonHeader {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.PenaltyAdminCardDatesWrapper {
  display: flex;
}
.PenaltyAdminCardDatesElement {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 16px 8px;
  width: 112px;
  height: 65px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px -1px;
}
.PenaltyAdminCardDatesElement:not(:last-child) {
  border-right: 1px solid #CED4DA;
}
.PenaltyAdminCard {
  background-color: #FFFFFF;
  padding: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
}
.PenaltyAdminCardReasonHeader:hover,
.PenaltyAdminCardFooterRight:hover,
.MinifiedFileComponent:hover,
.PenaltyAdminCardHeaderDetails:hover,
.PenaltyAdminCardReasonComment:hover {
  cursor: pointer;
}
.PenaltyAdminCardDatesWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 445px;
  height: 65px;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: auto;
}
.PenaltyAdminCardHeader {
  display: flex;
  align-items: center;
}
.PenaltyAdminCardHeaderDate {
  margin-right: 0;
  margin-left: auto;
}
.PenaltyAdminCardHeaderDetails {
  margin-left: 18px;
}
.PenaltyAdminCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.PenaltyAdminCardLeft {
  margin-right: 27px;
}
.PenaltyAdminCardRight {
  min-width: 445px;
}
.PenaltyAdminCardReasonComment {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #3A3A3C;
  margin-top: 4px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
}
.PenaltyAdminCardDatesElementText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #868E96;
}
.PenaltyAdminCardDatesElementDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}
.PenaltyAdminCardDatesFromNow {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
}
.PenaltyAdminCardFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.PenaltyAdminCardFiles .MinifiedFileComponent {
  margin-right: 8px;
  margin-top: 4px;
}
.PenaltyAdminCardFooterCard {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #212529;
  display: flex;
  justify-content: center;
  padding: 6px 12px 8px;
  gap: 10px;
  width: fit-content;
  height: 24px;
  background: #F1F3F5;
  border-radius: 4px;
}
.PenaltyAdminCardFooterLeft {
  display: flex;
  flex-direction: row;
}
.PenaltyAdminCardFooterLeft .PenaltyAdminCardFooterCard:not(:first-child) {
  margin-left: 8px;
}
.PenaltyAdminCardFooterRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PenaltyAdminCardFooterRight div {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #868E96;
}
.PenaltyAdminCardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.PenaltyAdminCardFooterAppealAttach,
.PenaltyAdminCardFooterAppealComment {
  display: flex;
  align-items: center;
  margin-left: 18.5px;
}
.PenaltyAdminCardFooterAppealAttach svg,
.PenaltyAdminCardFooterAppealComment svg {
  margin-right: 4px;
}
.ViewPenaltyModalHeaderCards {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.ViewPenaltyModalHeaderCards .PenaltyAdminCardFooterCard:not(:first-child) {
  margin-left: 8px;
}
.ViewPenaltyModalHeaderDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #868E96;
  margin-top: 8px;
  margin-bottom: 18px;
}
.ViewPenaltyModalSubtitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.ViewPenaltyContentWrapper {
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #E5E5EA;
  border-bottom: 1px solid #E5E5EA;
}
.ViewPenaltyContentLeft {
  flex: 50%;
}
.ViewPenaltyContentRight {
  flex: 50%;
  margin-left: 16px;
}
.ViewPenaltyFilesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ViewPenaltyModalSubtitle {
  margin-top: 24px;
  margin-bottom: 3px;
}
.ViewPenaltyModalSubtitleFirst {
  margin-top: 0 !important;
}
.ViewModalButtonsGroupWrapper {
  display: flex;
  margin-top: 32px;
}
.ViewModalButtonsGroupLeft {
  flex: 50%;
  display: flex;
}
.ViewModalButtonsGroupLeft .ButtonIcon_basic {
  height: 48px;
}
.ViewModalButtonsGroupRight {
  display: flex;
  flex: 50%;
  margin-left: 16px;
}
.ViewModalButtonsGroupRight .ButtonIcon_basic {
  height: 48px;
}
.ViewModalCancelButton,
.ViewModalRejectButton {
  margin-left: 16px;
}
.ViewModalEditButton,
.ViewModalCancelButton,
.ViewModalResolveButton,
.ViewModalRejectButton {
  height: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewModalEditButton svg {
  margin-top: 2px;
}
.ViewModalCancelButton svg {
  margin-top: 5px;
  width: 14px !important;
  height: 14px !important;
  margin-left: 4px;
}
.ViewModalRejectButton svg {
  margin-top: 2px;
}
.ViewPenaltyModalTextContent {
  word-break: break-word;
}
.EditCancelRejectFinalModalButtonsGroup {
  display: flex;
  align-items: center;
}
.EditCancelRejectFinalModalButtonsGroup .ButtonDefaultWrapper {
  margin-left: 16px;
}
.EditCancelRejectFinalModalButton__red {
  background-color: #FA5252 !important;
  border-color: #FA5252 !important;
}
.EditCancelRejectFinalModalButton__red span {
  color: white !important;
}
.ModalDisabledFormItem .ant-form-item-label label div {
  color: #a6a8aa !important;
}
.ModalDisabledFormItem .InputWrapper input {
  color: #b2b4b8 !important;
  background-color: #fdfdfd !important;
  border-color: #f1f3f5 !important;
}
.ModalDisabledFormItem .SelectWrapper .ant-select .ant-select-selector {
  border: 1px solid #f1f3f5 !important;
  background: #fdfdfd !important;
}
.ModalDisabledFormItem .SelectWrapper .ant-select .ant-select-selector .ant-select-selection-item {
  color: #b2b4b8 !important;
}
.ModalDisabledFormItem .SelectWrapper .SelectSuffixIcon path {
  fill: #b2b4b8 !important;
}
.AdminPenaltySubtitleRangePeriod .ContentPeriodDescription {
  text-transform: unset !important;
}
@media screen and (max-width: 1600px) {
  .PenaltyTitleWrapper .TitleText {
    margin-left: 0 !important;
  }
}
.AdminPenaltyCardsWrapper {
  min-width: 815px;
}
.PenaltyUserCard {
  width: 270px;
  height: 206px;
  background: #FFFFFF;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
}
.PenaltyUserCard:hover {
  cursor: pointer;
  opacity: 0.8;
}
.PenaltyUserCardWinReason {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 244px;
  margin-top: 12px;
}
.PenaltyUserCardHeaderWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  justify-content: space-between;
}
.PenaltyUserCardHeaderSetDate {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #868E96;
}
.PenaltyUserCardFooter {
  display: flex;
  margin-top: auto;
}
.PenaltyUserCardPenaltyWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.PenaltyUserCardPenalty {
  margin-right: 5px;
}
.PenaltyUserCardPenaltyResolvedFirst {
  text-decoration-line: line-through;
  color: #868E96;
}
.TextDefaultBoldWinResolved {
  text-decoration-line: line-through;
  color: #868E96;
}
.PenaltyCardWinWinResolved {
  display: flex;
}
.PenaltyCardWinWinResolved .TextDefaultBoldWinResolved {
  margin-left: 4px;
  margin-right: 4px;
}
.PenaltyCardWinWinResolvedColumn {
  display: flex;
  flex-direction: column;
}
.ViewPenaltyModalHeaderCards {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.ViewPenaltyModalHeaderCards .PenaltyAdminCardFooterCard:not(:first-child) {
  margin-left: 8px;
}
.ViewPenaltyModalHeaderDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #868E96;
  margin-top: 8px;
  margin-bottom: 18px;
}
.ViewPenaltyModalSubtitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.ViewPenaltyContentWrapper {
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #E5E5EA;
  border-bottom: 1px solid #E5E5EA;
}
.ViewPenaltyContentLeft {
  flex: 50%;
}
.ViewPenaltyContentRight {
  flex: 50%;
  margin-left: 16px;
}
.ViewPenaltyFilesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ViewPenaltyModalSubtitle {
  margin-top: 24px;
  margin-bottom: 3px;
}
.ViewPenaltyModalSubtitleFirst {
  margin-top: 0 !important;
}
.ViewModalButtonsGroupWrapper {
  display: flex;
  margin-top: 32px;
}
.ViewModalButtonsGroupLeft {
  flex: 50%;
  display: flex;
}
.ViewModalButtonsGroupLeft .ButtonIcon_basic {
  height: 48px;
}
.ViewModalButtonsGroupRight {
  display: flex;
  flex: 50%;
  margin-left: 16px;
}
.ViewModalButtonsGroupRight .ButtonIcon_basic {
  height: 48px;
}
.ViewModalCancelButton,
.ViewModalRejectButton {
  margin-left: 16px;
}
.ViewModalEditButton,
.ViewModalCancelButton,
.ViewModalResolveButton,
.ViewModalRejectButton {
  height: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewModalEditButton svg {
  margin-top: 2px;
}
.ViewModalCancelButton svg {
  margin-top: 5px;
  width: 14px !important;
  height: 14px !important;
  margin-left: 4px;
}
.ViewModalRejectButton svg {
  margin-top: 2px;
}
.ViewPenaltyModalHeaderDateUser {
  margin-top: 2px !important;
}
.ViewModalButtonsGroupWrapperEmpty {
  display: none;
}
.ViewPenaltyContentWrapperEmpty {
  border-bottom: 0 !important;
}
.ViewPenaltyModalComment {
  margin-top: 24px;
  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px;
}
.ViewPenaltyModalComment .ViewPenaltyModalSubtitle {
  margin-top: 0px !important;
}
.ViewPenaltyModalUserCountBack {
  align-self: center;
  margin-left: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #868E96;
}
.ProposalModalTextTitleTextWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.AppealModalButtonsGroupMobile .ButtonDefaultWrapper {
  margin-bottom: 24px;
  width: 100%;
}
.AppealModalButtonsGroupMobile .ButtonDefaultWrapper button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.ProposalModalContentMobileView .ViewModalButtonsGroupWrapper {
  padding-bottom: 21px;
  margin-top: 24px;
}
.ProposalModalContentMobileView .ButtonPrimaryWrapper button {
  width: 231px;
}
.ProposalModalContentMobileView .AppealModalPenaltyTextElement {
  margin-bottom: 24px;
}
.ProposalModalContentMobileView .AppealModalPenaltyTextElementFirst {
  margin-top: 15px;
}
.ModalDisabledFormItem .ant-form-item-label label div {
  color: #a6a8aa !important;
}
.ModalDisabledFormItem .InputWrapper input {
  color: #b2b4b8 !important;
  background-color: #fdfdfd !important;
  border-color: #f1f3f5 !important;
}
.ModalDisabledFormItem .SelectWrapper .ant-select .ant-select-selector {
  border: 1px solid #f1f3f5 !important;
  background: #fdfdfd !important;
}
.ModalDisabledFormItem .SelectWrapper .ant-select .ant-select-selector .ant-select-selection-item {
  color: #b2b4b8 !important;
}
.ModalDisabledFormItem .SelectWrapper .SelectSuffixIcon path {
  fill: #b2b4b8 !important;
}
.UserPenaltyCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
}
.UserPenaltyCardsWrapperMobile {
  justify-content: center;
}
.UserPenaltyCardsWrapperMobile .PenaltyUserCard {
  width: 100%;
  margin-right: 16px;
  margin-left: 16px;
}
.UserPenaltyCardsWrapperMobile .PenaltyUserCardWinReason {
  -webkit-line-clamp: 2;
}
.PenaltyStatus {
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 8px 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.PenaltyStatusGrey {
  background: #F1F3F5;
}
.PenaltyStatusRed {
  color: #FA5252;
  background: #FFF5F5;
}
.PenaltyStatusGreen {
  color: #40C057;
  background: #EBFBEE;
}
.PenaltyUserCardComment,
.PenaltyUserCardAttach {
  margin-left: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;
}
.PenaltyUserCardComment svg,
.PenaltyUserCardAttach svg {
  margin-right: 4px;
}
.PenaltyUserCardAttach svg {
  width: 20px;
  height: 20px;
}
.PenaltyStatusNew {
  width: 102px !important;
}
.PenaltyStatusView {
  width: 102px !important;
}
.PenaltyStatusReqCancel {
  width: 126px !important;
}
.PenaltyStatusReviewed {
  width: 94px !important;
}
.PenaltyStatusCancel {
  width: 102px !important;
}
.PenaltyStatusCons {
  width: 128px !important;
}
.AppealModalPenaltyCheckboxWrapper .ant-checkbox {
  top: 0.4em !important;
}
.AppealModalRulesLink {
  color: #339cf1;
  border-bottom: 1px solid #339cf1;
  width: fit-content;
}
.AppealModalRulesLink:hover {
  cursor: pointer;
}
.UserPenaltyModal {
  margin-bottom: 0px !important;
  margin-top: 6px !important;
}
.AppealModalPenaltyTextElementFirst {
  margin-top: 32px;
}
.AppealModalButtonsGroup {
  padding-top: 32px;
  border-top: 1px solid #DEE2E6;
  display: flex;
  align-items: center;
}
.AppealModalCFormContentWrapper {
  margin-bottom: 24px;
}
.AppealModalSubtitleWrapper {
  padding: 0 !important;
}
.UserPenaltySubtitleRange {
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #545b62;
  margin-top: 3px;
}
.UserPenaltySubtitleRangePeriod {
  margin-left: 2px;
}
.UserPenaltySubtitleRangePeriod .ContentPeriodDescription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #545b62;
  text-transform: lowercase;
}
.UserPenaltySubtitleRangePeriod .ContentPeriod {
  padding-left: 0;
  margin-top: 0;
}
.UserPenaltySubtitleRangePeriod .ContentPeriod {
  font-weight: 700;
}
.UserPenaltySubtitleRangeMobile .ContentPeriodDescription:first-letter {
  text-transform: capitalize;
}
@media screen and (max-width: 450px) {
  .UserPenaltySubtitleRange {
    margin-left: 16px;
  }
  .PenaltyTitleWrapper .TitleText span {
    margin-left: 16px !important;
  }
}
.ContactsPageWrapper {
  margin: 0 auto;
  width: 1440px;
  max-width: 92%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  color: #000000;
}
.ContactsPageWrapper .ant-collapse {
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  background-color: transparent;
}
.ContactsPageWrapper .ant-collapse-content {
  border-top: none;
  background: transparent;
}
.ContactsPageWrapper .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 24px 0;
}
.ContactsPageWrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.ContactsPageWrapper .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 3px;
}
.ContactsPageSection {
  border-radius: 16px;
  border: 1px solid #DEE2E6;
  background: #FFFFFF;
  padding: 33px 0 28px;
  margin-bottom: 32px;
  position: relative;
}
.ContactsPageAnchor {
  position: relative;
  top: -100px;
  height: 1px;
  width: 1px;
  opacity: 0;
}
.ContactsPageSectionNumberWrapper {
  position: absolute;
  left: -40px;
  top: 10px;
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.ContactsPageSectionNumberWrapper > div:nth-of-type(2) {
  text-align: center;
  padding: 13px 0;
}
.ContactsPageSectionNumberUp {
  cursor: pointer;
}
.ContactsPageSectionNumberUp svg {
  transform: rotate(90deg);
}
.ContactsPageSectionNumberDown {
  cursor: pointer;
}
.ContactsPageSectionNumberDown svg {
  transform: rotate(-90deg);
}
.ContactsPageSectionAllBlocksWrapper {
  display: flex;
  flex-wrap: wrap;
}
.ContactsPageSectionAllBlocksWrapper > div {
  flex: 0 0 50%;
}
.ContactsPageSectionBlock {
  padding: 20px 24px 8px;
}
.ContactsPageSectionHeader_inner {
  display: flex;
  align-items: center;
  color: #000000;
  position: relative;
  padding: 0 24px 21px;
}
.ContactsPageEditIcon {
  position: absolute;
  top: -5px;
  right: 16px;
  cursor: pointer;
}
.ContactsPageSectionHeader_icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-right: 12px;
}
.ContactsPageSectionHeader_icon img {
  display: block;
}
.ContactsPageSectionHeader_title {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.ContactsPageSectionHeader_subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
}
.ContactsPageSectionBlock {
  position: relative;
}
.ContactsPageSectionBlock .ContactsPageEditIcon {
  top: 15px;
}
.ContactsPageSectionBlock_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
  padding-right: 15px;
}
.ContactsPageSectionBlock_description,
.ContactsPageSectionTotalStaffWrapper {
  margin-bottom: 12px;
}
.ContactsPageSectionCredits_prefix {
  margin-right: 5px;
}
.ContactsPageSectionPhone a,
.ContactsPageSectionWhatsApp a,
.ContactsPageSectionTelegramm a,
.ContactsPageSectionEmail a {
  text-decoration: underline;
}
.ContactsPageSectionPhone a:hover,
.ContactsPageSectionWhatsApp a:hover,
.ContactsPageSectionTelegramm a:hover,
.ContactsPageSectionEmail a:hover {
  text-decoration: none;
}
.ContactsPageSectionStaffItemWrapper {
  display: flex;
  margin-bottom: 16px;
}
.ContactsPageSectionStaffItemWrapper > div:first-child {
  margin-right: 32px;
}
.ContactsPageSectionStaffItemWrapper > div:nth-child(2) {
  margin-right: 8px;
}
.ContactsPageSectionStaffItemWrapper.ContactsPageSectionStaffItemWrapperEmptyNamePosition {
  margin-bottom: 0;
}
.ContactsPageSectionStaffItemWrapperEmptyNamePosition + .ContactsPageSectionStaffItemWrapper:not(.ContactsPageSectionStaffItemWrapperEmptyNamePosition) {
  margin-top: 16px;
}
.ContactsPageSectionNameAndPosition {
  flex: 0 0 210px;
}
.ContactsPageSectionCredits {
  flex: 0 0 250px;
}
.ContactsPageSectionCredits > div {
  margin-bottom: 4px;
}
.ContactsPageSectionPhoneWithExtWrapper {
  display: flex;
}
.ContactsPageSectionWorkHours {
  flex: 0 0 100px;
}
.ContactsPageSectionStaff_name {
  font-weight: 700;
  letter-spacing: 0.5px;
}
.ContactsPageSection_mapWrapper {
  height: 510px;
  width: 680px;
  width: 50%;
  padding: 20px 24px 20px 20px;
  max-width: 50%;
}
.ContactsPageSectionFooter {
  padding: 24px 24px 0;
}
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn {
  padding: 14px 24px 14px 48px;
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #212529;
  background: #F8F9FA;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  height: auto;
  transition: border-color 0.25s ease-in;
}
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn:not([disabled]):hover,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn:not([disabled]):focus,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn:not([disabled]):active {
  border: 1px solid #495057;
  box-shadow: none;
}
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonIconDisabled,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonFadedDisabled,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn[disabled],
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonIconDisabled:hover,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonFadedDisabled:hover,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn[disabled]:hover,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonIconDisabled:focus,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonFadedDisabled:focus,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn[disabled]:focus,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonIconDisabled:active,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn.ButtonFadedDisabled:active,
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn[disabled]:active {
  background: #F8F9FA;
  border: 1px solid #CED4DA;
  color: #212529;
}
.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn svg {
  top: 14px;
  left: 17px;
}
.ContactsPageSectionBlock_editing .ContactsPageSectionHeader {
  border-bottom: 1px dashed #DEE2E6;
}
.ContactsPageSectionBlock_editing .ContactsPageSectionBlock {
  border-bottom: 1px dashed #DEE2E6;
}
.ContactsPageSectionBlock_editing .ContactsPageSectionBlock:nth-last-of-type(1),
.ContactsPageSectionBlock_editing .ContactsPageSectionBlock:nth-last-of-type(2) {
  border-bottom: none;
}
.ContactsPageSectionBlock_editing .ContactsPageSectionBlock:nth-of-type(odd) {
  border-right: 1px dashed #DEE2E6;
}
.ContactsPageSectionBlock_editing .ContactsPageSectionOuter {
  border-bottom: 1px dashed #DEE2E6;
}
svg.ContactsPageSectionIcon_disabled path {
  fill: #CED4DA;
}
.ContactsPageSectionDescription {
  padding-left: 20px;
  width: 50%;
  text-align: justify;
  padding-bottom: 20px;
}
.ContactsPageSectionHeader_inner + .ContactsPageSectionHeader_subtitle {
  display: none;
}
.ContactsConfirmWrapper .ant-modal-content {
  border-radius: 8px;
}
.ContactsConfirmWrapper div.ant-modal-body {
  padding: 39px;
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  border-radius: 8px;
}
.ContactsConfirmWrapper .anticon-exclamation-circle {
  display: none;
}
.ContactsConfirmWrapper .ant-modal-confirm-title {
  margin-bottom: 15px;
}
.ContactsConfirmWrapper .ant-modal-confirm-title .ContactsConfirmDelete_title {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.ContactsConfirmWrapper .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin: 0;
}
.ContactsConfirmWrapper .ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 34px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.ContactsConfirmWrapper .ant-btn {
  height: 48px;
  border-radius: 8px;
  padding: 0 20px;
  min-width: 115px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.ContactsConfirmWrapper .ant-btn span {
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-default {
  border-color: #CED4DA;
  color: #212529;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-default:hover {
  background: #Fff;
  border-color: #495057;
  box-shadow: none;
  color: #212529;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-primary {
  border-color: #FA5252;
  background: #FA5252;
  text-shadow: none;
  box-shadow: none;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-primary:hover {
  background: #f42626;
  border-color: #f42626;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-primary svg {
  margin-right: 6px;
}
.ContactsConfirmWrapper .ant-btn.ant-btn-primary svg path {
  fill: #fff;
}
@media screen and (min-width: 1751px) {
  .ContactsPageSectionHasMap .ContactsPageSectionAllBlocksWrapper {
    display: block;
    position: relative;
  }
  .ContactsPageSectionHasMap .ContactsPageSectionAllBlocksWrapper:after {
    content: "";
    clear: both;
    display: table;
  }
  .ContactsPageSectionHasMap .ContactsPageSection_mapWrapper {
    float: right;
  }
  .ContactsPageSectionHasMap .ContactsPageSectionBlock {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionHasMap .ContactsPageSectionAllBlocksWrapper:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px dashed #DEE2E6;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionHasMap .ContactsPageSectionBlock {
    border-top: 1px dashed #DEE2E6;
    border-bottom: none;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionHasMap .ContactsPageSectionBlock:nth-of-type(2) {
    border-top: none;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionHasMap .ContactsPageSectionBlock:nth-of-type(odd) {
    border-right: none;
  }
}
@media screen and (max-width: 1750px) {
  .ContactsPageSectionAllBlocksWrapper {
    display: block;
  }
  .ContactsPageSection_mapWrapper {
    width: auto;
    max-width: unset;
  }
  .ContactsPageSectionDescription {
    width: auto;
    padding-right: 20px;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionBlock:nth-of-type(odd) {
    border-right: none;
  }
  .ContactsPageSectionBlock_editing .ContactsPageSectionBlock:nth-last-of-type(2) {
    border-bottom: 1px dashed #DEE2E6;
  }
}
@media screen and (max-width: 1024px) {
  .ContactsPageSectionStaffItemWrapper {
    display: block;
  }
}
@media screen and (max-width: 920px) {
  .ContactsPageWrapper {
    max-width: unset;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .ContactsPageSectionStaffItemWrapper > div:first-child {
    margin-right: 0;
  }
  .ContactsPageSectionStaffItemWrapper > div:nth-child(2) {
    margin-right: 0;
  }
  .ContactsPageSection_mapWrapper {
    height: 350px;
  }
  .ContactsPageSection {
    border: none;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
  }
  .ContactsPageSection_mapWrapper {
    padding: 0;
  }
  .ContactsPageAnchor {
    top: -140px;
  }
  .ContactsPageSectionHeader_inner {
    padding: 0;
  }
  .ContactsPageSectionHeader_title {
    font-size: 20px;
    line-height: 25px;
  }
  .ContactsPageSectionHeader_icon img {
    width: 48px;
    height: 48px;
  }
  .ContactsPageSectionDescription {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
  }
  .ContactsPageSectionHeader_subtitle {
    margin-bottom: 12px;
  }
  .ContactsPageSectionBlock {
    padding: 0;
    margin-bottom: 32px;
  }
  .ContactsPageSectionBlock_title {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 12px;
    padding-right: 0;
  }
  .ContactsPageSectionHeader_inner + .ContactsPageSectionHeader_subtitle {
    display: block;
  }
  .ContactsPageSectionCredits {
    margin-bottom: 8px;
  }
  .ContactsPageSectionCredits > div {
    margin-bottom: 0;
    line-height: 1.4;
  }
  .ContactsPageSectionCredits + .ContactsPageSectionWorkHours {
    position: relative;
    top: -8px;
  }
}
.ContactsPage_addBlockBtnFooter {
  margin-bottom: 60px;
}
.ContactsCollapse {
  padding: 6px 0px;
}
.MainModalWrapper {
  position: fixed;
  top: 0;
  z-index: 20;
  transition: transform 500ms ease, width 500ms ease;
  box-sizing: border-box;
  right: 0;
  transform: translateX(0);
  width: 613px;
}
.MainModalWrapperWide {
  width: calc(100vw - 360px);
  max-width: calc(100vw - 360px);
}
.MainModalWrapperUnActive {
  transform: translateX(100%);
}
.MainModalWrapperActive {
  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 1440px) {
  .MainModalWrapperWide {
    width: calc(100vw - 280px);
    max-width: calc(100vw - 280px);
  }
}
@media screen and (min-width: 921px) {
  .MainModalWrapper {
    height: 100vh;
    overflow-y: auto;
  }
}
.ContactsEdit {
  font-size: 16px;
  background: #FFFFFF;
  border-left: 1px solid #E5E5EA;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.ContactsEdit_top {
  padding: 40px 23px 23px;
}
.ContactsEdit_actionsModalHidden {
  display: none;
}
.ContactsEdit_actionsModalActive {
  display: block;
}
.ContactsEdit {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.ContactsEdit_header {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #212529;
  margin-bottom: 37px;
}
.ContactsEdit_closeIcon {
  position: absolute;
  top: 37px;
  right: 23px;
  cursor: pointer;
}
.ContactsEdit_header_arrBack {
  padding-left: 77px;
}
.ContactsEdit_header_arrBack .ContactsEdit_returnIcon {
  position: absolute;
  top: 36px;
  left: 24px;
}
.ContactsEdit_returnIcon {
  width: 48px;
  height: 48px;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ContactsEdit .CheckboxText,
.ContactsEdit_fieldname,
.CheckboxWrapper span {
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.ContactsEdit_fieldname {
  margin-top: 24px;
  margin-bottom: 6px;
}
.MainModalWrapper .ContactsEdit_position .InputWrapper > textarea.ant-input,
.InputWrapper > textarea.ant-input-number,
.InputWrapper > textarea.ant-input-affix-wrapper,
.InputWrapper textarea.ant-input-number-input {
  min-height: 79px !important;
}
.MainModalWrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.MainModalWrapper .InputWrapper > .ant-input,
.MainModalWrapper .InputWrapper > .ant-input-number,
.MainModalWrapper .InputWrapper > .ant-input-affix-wrapper,
.MainModalWrapper .InputWrapper .ant-input-number-input {
  border-color: #CED4DA;
  background-color: #F8F9FA;
  color: #212529 !important;
  font-size: 16px;
}
.MainModalWrapper .InputWrapper > textarea.ant-input,
.MainModalWrapper .InputWrapper > textarea.ant-input-number,
.MainModalWrapper .InputWrapper > textarea.ant-input-affix-wrapper,
.MainModalWrapper .InputWrapper textarea.ant-input-number-input {
  min-height: 150px !important;
}
div .ContactsEdit_AnchorCheckbox {
  display: flex;
  margin-top: 24px;
}
div .ContactsEdit_AnchorCheckbox .ContactsEdit_fieldname {
  margin-top: 0;
}
.ContactsEdit_btns {
  display: flex;
  padding: 20px 23px 32px;
  border-top: 1px solid #F1F3F5;
}
.ContactsEdit_btns > div {
  margin-right: 16px;
}
.ContactsEdit_btns .ant-btn.ContactsBtn,
.ContactsEdit_btns .ant-btn.ant-btn-primary.ContactsBtn {
  width: 200px;
  height: 48px;
  font-size: 16px;
}
.ContactsEdit_btns .ant-btn.ContactsBtn span,
.ContactsEdit_btns .ant-btn.ant-btn-primary.ContactsBtn span {
  font-size: 16px;
}
.ContactsEdit_btns .ant-btn-primary .ant-btn,
.ContactsEdit_btns .ButtonPrimaryWrapper .ant-btn {
  background: #FCE000;
}
.ContactsEdit_btns .ant-btn-primary .ant-btn:hover,
.ContactsEdit_btns .ButtonPrimaryWrapper .ant-btn:hover,
.ContactsEdit_btns .ant-btn-primary .ant-btn:focus,
.ContactsEdit_btns .ButtonPrimaryWrapper .ant-btn:focus,
.ContactsEdit_btns .ant-btn-primary .ant-btn:active,
.ContactsEdit_btns .ButtonPrimaryWrapper .ant-btn:active {
  background: #FCDA00;
}
.ContactsEdit_btns .ant-btn.ant-btn-default.ContactsBtn {
  background: #F8F9FA;
  border: 1px solid #CED4DA;
}
.ContactsEdit_btns .ant-btn.ant-btn-default.ContactsBtn:hover,
.ContactsEdit_btns .ant-btn.ant-btn-default.ContactsBtn:focus,
.ContactsEdit_btns .ant-btn.ant-btn-default.ContactsBtn:active {
  background: #F8F9FA;
  border: 1px solid #495057;
  box-shadow: none;
}
.ContactsEdit_btns .ButtonIconWrapper .ant-btn {
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContactsEdit_btns .ButtonIconWrapper .ant-btn svg {
  position: static;
  margin-right: 10px;
}
.ant-select-dropdown .ContactSectionModal_dropdown .ant-select-item-option {
  font-size: 16px;
  line-height: 22px;
}
.ant-select-dropdown .ContactSectionModal_dropdown .ButtonDefaultWrapper {
  margin: 12px 0;
}
.ant-select-dropdown .ContactSectionModal_dropdown .ButtonDefaultWrapper .ant-btn {
  background: none;
  border: none;
  display: block;
  color: #228BE6;
  height: auto;
  width: 100%;
  text-align: left;
  padding: 0 20px;
  font-size: 16px;
}
.ant-select-dropdown .ContactSectionModal_dropdown .ButtonDefaultWrapper .ant-btn span {
  color: #228BE6;
  font-size: 16px;
}
.ant-select-dropdown .ContactSectionModal_dropdown .ButtonDefaultWrapper .ant-btn:hover span {
  color: #1D1F21;
}
.ContactsEdit_titleInputWrapper {
  position: relative;
}
.ContactsEdit_titleInputCancel {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.ContactsEdit_titleInputCancel svg {
  width: 24px;
  height: 24px;
}
.ContactsEdit_inputsColumnWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 24px;
}
.ContactsEdit_inputsColumn {
  flex: 0 0 calc(50% - 18px);
  max-width: calc(50% - 18px);
}
.ContactsEdit_inputsColumn .InputWrapper textarea {
  max-width: 566px;
}
.ContactsEdit_table {
  margin-top: 32px;
}
.ContactsEdit_table .ant-table-column-has-sorters .ant-table-column-sorters,
.ContactsEdit_table .ant-table-thead > tr > th {
  background: #FFF4E6 !important;
}
.ContactsEdit_table .ant-table-thead > tr > th:first-child,
.ContactsEdit_table .ant-table-tbody .ant-table-row > td:first-child {
  padding-left: 16px !important;
}
.ContactsEdit_table .ant-table-thead > tr > th:last-child,
.ContactsEdit_table .ant-table-tbody .ant-table-row > td:last-child {
  padding-right: 16px !important;
}
.ContactsEdit_table .ant-table-content table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 8px;
}
.ContactsEdit_table .ant-table-content table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 8px;
}
.ContactsEdit_table .ant-table-content table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px;
}
.ContactsEdit_table .ant-table-content table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px;
}
.ContactsEdit_table .TableComponentWrapper {
  overflow-x: unset;
}
.ContactsEdit_table .TableComponentWrapper .ant-table {
  min-width: 1390px;
}
.ContactsEdit_table .ant-table-thead > tr > th {
  word-spacing: unset;
  height: 56px;
  font-family: 'Gilroy';
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
  padding: 0 6px !important;
}
.ContactsEdit_table .ant-table-tbody .ant-table-row > td {
  height: 56px;
  padding: 0 6px !important;
}
.ContactsEdit_table .ant-table-tbody > tr > td {
  border-bottom-color: #E9ECEF;
  position: relative;
}
.ContactsEdit_table .ant-table-container table > thead th:last-child,
.ContactsEdit_table .ant-table-container table .ant-table-tbody .ant-table-row td:last-child {
  text-align: right;
}
.ContactsEdit_actionsModal {
  position: absolute;
  right: 0;
  top: 19px;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 18px;
}
.ContactsEdit_actionsModal_trigger {
  position: relative;
}
.ContactsEdit_actionsModal_line {
  display: flex;
  align-items: center;
}
.ContactsEdit_actionsModal_line + .ContactsEdit_actionsModal_line {
  margin-top: 25px;
}
.ContactsEdit_actionsModal_line svg {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
.ContactsEdit_hours {
  margin-top: 6px;
}
.ContactsEdit_hours .SelectHoursWrapper {
  justify-content: space-between;
}
.ContactsEdit_hours .SelectHoursWrapper .HourSelector {
  width: auto !important;
  flex: 0 0 calc(50% - 18px);
}
.ContactsTableInner {
  white-space: nowrap;
  width: 8.5vw;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ContactsTableInner_narrow {
  min-width: 113px;
  width: 6.4vw;
}
@media screen and (max-width: 1920px) {
  .ContactsEdit_table .TableComponentWrapper {
    overflow-x: auto;
    padding-bottom: 80px;
  }
}
.ContactsPageSectionEdit_icon {
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.25s ease;
  border-radius: 12px;
  position: relative;
}
.ContactsPageSectionEdit_icon:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -5px;
  right: -7px;
  border-radius: 50%;
}
.ContactsPageSectionEdit_icon.ContactsIcon_selected,
.ContactsPageSectionEdit_icon:hover {
  border-color: #212529;
}
.ContactsPageSectionEdit_icon.ContactsIcon_selected:after {
  background-image: url("/Components/icons/success.svg");
  background-size: contain;
  background-color: #fff;
}
.ContactsMainPageWrapper {
  background: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 29.63%, #F4F4F4 100%);
}
.ContactsPageHeaderMainWrapper {
  min-height: unset;
  margin: 0 auto;
  padding-top: 40px;
  width: 1440px;
  max-width: 92%;
}
.ContactsPageHeader_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  flex: auto;
}
.ContactsPageHeaderWrapper {
  display: flex;
}
.ContactsEmptyPageInfo_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  padding-bottom: 44px;
}
.ContactsEmptyPage_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #808185;
}
.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn {
  font-family: 'Gilroy';
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:hover:disabled,
.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:disabled {
  background-color: #ADB5BD;
  border-color: #ADB5BD;
}
.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:disabled {
  background-color: #ADB5BD;
  border-color: #ADB5BD;
}
.ContactsEmptyPageInfoWrapper {
  text-align: center;
  margin-top: 202px;
}
.ant-btn.ant-btn-primary.ContactsBtn {
  font-family: 'Gilroy';
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.ContactsPageHeader_editBtnWrapper .ButtonPrimaryWrapper {
  display: inline-block;
}
.ContactsPageHeader_saveBtn {
  width: 169px;
}
.ContactsPageHeader_cancelBtn {
  background-color: #CED4DA;
  width: 169px;
}
.ContactsPage_addBlockBtn {
  margin-top: 32px;
  width: 203px;
}
.ContactsPage_addBlockBtn svg {
  margin-right: 9px;
}
.ContactsEmptyPageInfo_startBtn {
  margin-bottom: 44px;
}
.ContactsPage_addBlockBtnWrapper {
  width: 1440px;
  max-width: 95%;
  margin: 0 auto;
  padding-left: 24px;
}
.ContactsEmptyPageInfoNoAccess_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
}
.ContactsPageHeader_tabsWrapper .TooltipComponentTargetWrapper {
  display: inline-block;
  margin-right: 24px;
  margin-top: 20px;
  border-bottom: 3px solid transparent;
  padding-bottom: 11px;
}
.ContactsPageHeader_tabsWrapper .TooltipComponentTargetWrapper:hover {
  color: #212529;
  border-bottom-color: #FCE000;
}
.ContactsPageTooltip .ant-popover-arrow-content::before {
  top: -12px;
}
.ContactsPageHeader_tab {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
  position: relative;
}
.ContactsPageHeader_tab .ContactsPageHeader_tab_text {
  white-space: nowrap;
  max-width: 217px;
  overflow: hidden;
  display: block;
  position: relative;
}
.ContactsPageHeader_tab .ContactsPageHeader_tab_text:after {
  content: "";
  position: absolute;
  left: 170px;
  top: 0;
  bottom: 0;
  width: 49px;
  background: linear-gradient(270deg, #ffffff 8.16%, rgba(255, 255, 255, 0.5) 45.73%, rgba(255, 255, 255, 0) 81.63%);
}
.ContactsPageHeader_tab_active {
  color: #212529;
  border-bottom-color: #FCE000;
}
.ContactsOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(13, 15, 16, 0.56);
  z-index: 12;
}
.ContactsPageHeader_tabsWrapper {
  position: sticky;
  top: 0;
  z-index: 5;
  margin-bottom: 32px;
  background-color: #fff;
}
.ContactsPageHeader_tabsInner {
  width: 1440px;
  max-width: 92%;
  margin: 0 auto;
  border-bottom: 1px solid #DEE2E6;
  min-height: 52px;
}
@media screen and (max-width: 920px) {
  .ContactsMainPageWrapper {
    background: #FFFFFF;
    width: 100vw;
  }
  .ContactsPageHeaderMainWrapper {
    margin-top: 18px;
    max-width: unset;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ContactsPageHeader_tabsWrapper {
    background-color: #FFFFFF;
    z-index: 11;
    border-bottom: none;
  }
  .ContactsPageHeader_tabsInner {
    max-width: unset;
    width: auto;
    padding-left: 16px;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #DEE2E6;
  }
  .ContactsPageHeader_title {
    font-size: 24px;
    line-height: 120%;
  }
  .ContactsPageHeader_tabsWrapper {
    overflow-x: scroll;
    white-space: nowrap;
    margin-bottom: 0;
  }
  .ContactsPageHeader_tabsWrapper::-webkit-scrollbar {
    display: none !important;
  }
}
.ContactsEdit_error {
  color: red;
}
.ContactsPageHeader_tabActive {
  color: #212529;
}
.VisitsContentPeriodWrapper {
  padding: 0 8px 24px 8px;
}
.VisitCalendarShow {
  display: block;
}
.VisitCalendarHidden {
  display: none;
}
.VisitsPageTitle {
  grid-gap: 18px;
  margin-bottom: 8px;
  padding: 0;
  margin-top: 10px;
}
.VisitsPageTitle .VisitsPageTitleInner {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
  display: flex;
}
.VisitsPageTitle .VisitsPageTitleInner .TooltipComponentTargetWrapper svg {
  position: relative;
  top: 4px;
  margin-left: 10px;
}
.VisitsBlock {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
  margin-bottom: 16px;
}
.VisitsBlockSecondary {
  padding: 24px;
}
.VisitsContentPeriodWrapper {
  padding: 0;
  margin-bottom: 16px;
}
.VisitsContentPeriodWrapper .ContentPeriodDescription {
  line-height: 120%;
  color: #868E96;
}
.VisitsContentPeriodWrapper .ContentPeriod {
  color: #212529;
}
.VisitsRegSelection {
  display: flex;
  position: absolute;
  top: 32px;
  right: 17px;
}
.VisitsRegSelection .SwitchComponentLabel {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
  margin-left: 10px;
}
.VisitsRegSelectionTooltip {
  margin-left: 2px;
}
.VisitsRegSelectionTooltip svg {
  width: 17px;
  height: 17px;
  position: relative;
  top: 3px;
}
.VisitsStatisticTypesWrapper {
  max-width: 692px;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 0;
}
.VisitsStatisticTypesItem {
  min-width: 220px;
  margin: 8px;
  padding: 9px;
  border-radius: 8px;
  transition: background-color 0.2s ease-in, border 0.2s ease-in;
}
.VisitsStatisticTypesItem .TypeItemTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  font-weight: 500;
  line-height: 22px;
}
.VisitsBlockContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: space-between;
}
.VisitsBlockContainer .VisitsBlock {
  flex: 0 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
}
.VisitsBlockContainer .VisitsBlock svg {
  max-width: 100%;
}
.VisitsBlockTitle,
.VisitsBlockTitle span {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
  margin-bottom: 8px;
  display: flex;
}
.VisitsBlockTitle svg,
.VisitsBlockTitle span svg {
  width: 20px;
  height: 20px;
  margin-left: 2px;
  position: relative;
  top: 3px;
}
.VisitsBlockTitleWrapper {
  padding-right: 40px;
}
.VisitsCalendarTrigger {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
}
.VisitsCalendarTrigger > svg {
  cursor: pointer;
}
.VisitCalendarShow {
  min-width: 151px;
  position: absolute;
  top: 41px;
  right: 0;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 8px 14px 14px;
}
.VisitCalendarShow .RadioGroupOption {
  line-height: 16px;
  height: auto;
  padding-bottom: 4px;
}
.VisitsCalendarTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  font-weight: 700;
}
.VisitWeekDaysWrapper {
  text-align: center;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group {
  display: inline-flex;
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  font-weight: 700;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #868E96;
  background: #F4F4F4;
  border-radius: 8px;
  padding: 2px;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper {
  border: none;
  background: none;
  border: 1px solid transparent;
  transition: background 0.2s ease-in, border-color 0.2s ease-in, color 0.2s ease-in;
  color: #868E96;
  min-width: 56px;
  height: 28px;
  line-height: 28px;
  position: relative;
  padding: 0 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper:before {
  display: none;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #FFFFFF;
  border-color: #E9ECEF;
  border-radius: 8px;
  color: #212529;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-visible {
  box-shadow: none;
  outline: none;
}
.VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.PeaksHoursMostVisited:after {
  content: "";
  height: 11px;
  width: 11px;
  background-image: url(/Components/icons/favorite.svg);
  background-size: contain;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center center;
}
.RegStatTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
  text-align: center;
}
.RegStatSubTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: #495057;
}
.regStat_legendColor {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.regStat_legendColorNew {
  background: #FFA94D;
}
.regStat_legendColorUnique {
  background: #748FFC;
}
.regStat_legendName {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.regStat_legendBox {
  display: flex;
  justify-content: center;
  gap: 32px;
}
.VisitsBlock .highcharts-text-outline {
  display: none;
}
.regStat_legendInfo {
  display: flex;
  align-items: center;
  gap: 14px;
}
.regStatLabel {
  fill: #FFFFFF;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  font-size: 18px;
}
.VisitRegTooltipWrapper {
  height: 44px;
  width: 68px;
  gap: 4px;
}
.VisitRegPointUp {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  line-height: 18px;
  display: block;
  color: #FFDD2D !important;
  text-align: center;
}
.VisitRegPointDown {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
  color: #D1D1D6 !important;
  text-align: center;
}
.VisitLabel {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  font-weight: 500;
  line-height: 17px;
  color: #868E96;
}
.VisitPeaksLabel {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
  line-height: 14px;
  color: #868E96;
}
.VisitsPageContentWrapper .VisitsDashboardsWrapper {
  background-color: #ffffff;
  padding: 12px 24px;
  border: 1px solid #DEE2E6;
  border-radius: 16px;
  margin-bottom: 16px;
}
@media screen and (max-width: 1680px) {
  .VisitsPageContentWrapper .ComponentDashboardItem {
    padding: 16px 8px 16px 16px;
  }
  .VisitsPageContentWrapper .ComponentDashboardItem .DashboardItemIconWrapper svg,
  .VisitsPageContentWrapper .ComponentDashboardItem .DashboardItemIconWrapper img {
    width: 42px;
  }
}
@media screen and (max-width: 1366px) {
  .VisitsPageTitle {
    align-items: center;
  }
  .VisitsPageTitle .VisitsPageTitleInner {
    font-family: 'Gilroy' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #212529;
  }
  .VisitsPageTitle .VisitsPageTitleInner .TooltipComponentTargetWrapper svg {
    width: 20px;
    height: 20px;
    margin-left: 6px;
    top: 3px;
  }
  .VisitsStatisticTypesItem {
    min-width: 180px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
  }
  .VisitsStatisticTypesItem .TypeItemTitle {
    font-size: 13px;
    line-height: 18px;
  }
  .VisitsStatisticTypesWrapper {
    padding-bottom: 0;
  }
  .TypeItemMarkerWrapper {
    margin-top: 2px;
  }
  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper {
    min-width: 44px;
  }
  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.PeaksHoursMostVisited:after {
    margin-left: 2px;
  }
}
@media screen and (max-width: 1320px) {
  .VisitsBlockContainer {
    display: block;
  }
  .VisitsBlockContainer .VisitsBlock {
    max-width: unset;
  }
  .VisitsBlockContainer .VisitsBlock svg {
    max-width: unset;
  }
}
@media screen and (max-width: 920px) {
  .VisitsRegSelectionTooltip .TooltipComponentTargetWrapper svg {
    top: 1px;
  }
  .VisitLabelYAxis {
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }
  .MainPageContentWrapper.VisitsPageContentWrapper {
    background: #ffffff !important;
  }
  .VisitsPageContentWrapper .VisitsDashboardsWrapper {
    background-color: unset;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  .VisitsPageContentWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel {
    border: none;
    background: transparent url(/Components/icons/excel_small.svg) no-repeat center center;
    background-size: contain;
    padding: 0;
    width: 26px;
    height: 32px;
  }
  .VisitsPageContentWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel svg,
  .VisitsPageContentWrapper .ButtonIconWrapper .ant-btn.ButtonIcon_excel span {
    display: none;
  }
  .VisitsPageTitle {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .VisitsPageTitle .VisitsPageTitleInner .TooltipComponentTargetWrapper svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
  }
  .VisitsContentPeriodWrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;
  }
  .VisitsContentPeriodWrapper .ContentPeriodDescription {
    font-size: 12px;
    line-height: 14px;
  }
  .VisitsRegSelection {
    right: auto;
    left: 16px;
  }
  .VisitsStatisticTypesWrapper {
    max-width: unset;
    width: auto;
    padding-left: 0;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 0;
  }
  .VisitsStatisticTypesItem {
    margin: 0;
    width: calc(100% / 3);
    min-width: unset;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 0;
    border-color: #dee2e6 !important;
  }
  .VisitsStatisticTypesItem:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none !important;
  }
  .VisitsStatisticTypesItem:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none !important;
  }
  .VisitsStatisticTypesItem .TypeItemTitle {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
  .TypeItemMarkerWrapper {
    width: 8px;
    height: 8px;
    margin-top: 4px;
    margin-right: 4px;
  }
  .VisitsRegSelection .SwitchComponentLabel {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #212529;
    margin-left: 6px;
  }
  .VisitsBlock {
    border: none;
    padding: 5px 0 0;
    margin-bottom: 34px;
  }
  .VisitsBlock .CashboxChartsWrapper {
    padding-left: 0;
  }
  .VisitsBlockContainer .VisitsBlock:first-of-type {
    margin-bottom: 50px;
  }
  .VisitsBlockContainer .VisitsBlock .VisitsBlockTitle {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
  }
  .VisitWeekDaysWrapper {
    margin-top: 25px;
  }
  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding: 6px 11px;
  }
  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper {
    flex: 1 1 calc(100% / 7);
  }
  .VisitsBlockTitle svg {
    width: 20px;
    height: 20px;
    top: 4px;
  }
  .VisitsBlockTitle,
  .VisitsBlockTitle span {
    font-size: 16px;
  }
}
.mostVisitedHoursWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
}
.mostVisitedHoursWrapper svg {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.mostVisitedHoursTime {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}
.mostVisitedHoursText {
  color: #868E96;
}
.VisitsDashboardsHours {
  display: flex;
  align-items: flex-end;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  font-size: 13px;
}
.VisitsDashboardsHours span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  font-size: 13px;
}
.VisitsDashboardsHours > svg {
  margin-right: 6px;
  position: relative;
  top: 1px;
}
.VisitsDashboardsHours .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  background-color: unset;
}
.VisitsDashboardsHours .ant-select.VisitTimeShiftSelect.ant-select-single.ant-select-show-arrow.ant-select-focused.ant-select-open .ant-select-selector,
.VisitsDashboardsHours .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  box-shadow: none;
}
.VisitsDashboardsHours .ant-select-arrow {
  display: none;
}
.VisitsDashboardsHours .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.VisitsDashboardsHours .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 2px 4px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  line-height: 17px;
  color: #228BE6;
}
.VisitsDashboardsHours .VisitsDashboardsCity {
  padding-top: 2px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  line-height: 14px;
  color: #228BE6;
}
.VisitsDashboardsHours .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}
.VisitsDashboardsHours .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 17px;
}
.VisitTimeShiftSelect.ant-select {
  display: inline;
  line-height: 17px;
}
.ant-select-dropdown.VisitTimeShiftSelect_dropdown {
  min-width: 120px !important;
  width: 120px !important;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option {
  padding: 9px 16px;
}
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option ant-select-item-option-active {
  background: #F1F3F5 !important;
}
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-content,
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option ant-select-item-option-active .ant-select-item-option-content {
  font-weight: 700;
}
.ant-select-dropdown.VisitTimeShiftSelect_dropdown .ant-select-item.ant-select-item-option .ant-select-item-option-content {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  color: #495057;
}
.VisitChartPeaks_EmptyDataIcon {
  text-align: center;
  padding-top: 30px;
}
.RegStatChartWrapper {
  padding-top: 0px;
}
.VisitAndRegChartsWrapper {
  padding-left: 0px;
}
.EmptyDataPeaksTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
  text-align: center;
}
.EmptyDataPeaksText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  text-align: center;
}
.VisitLabelYAxis {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
  font-weight: 500;
  line-height: 19px;
  color: #868E96;
}
.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper > span {
  min-width: 78px;
  text-align: center;
}
.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper > span span,
.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper > span b {
  text-align: center;
}
.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper > span span:first-of-type,
.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper > span br {
  display: none;
}
.UploadWrapper .ant-upload-list {
  display: flex;
  flex-wrap: wrap;
}
.UploadWrapper .ant-upload-list .ant-upload {
  margin-bottom: 0 !important;
}
.UploadWrapper .ant-upload-list .ant-upload-select-picture-card {
  width: 156px !important;
  height: 100px !important;
  background: none !important;
  border: none !important;
  border-radius: 8px;
}
.UploadWrapper .ant-upload-list .ant-upload-list-picture-card-container {
  width: 156px;
  height: 100px;
  margin: 8px !important;
}
.UploadsNewHideWrapper .ant-upload-list .ant-upload-select-picture-card {
  display: none !important;
}
.UploadViewLargeMode .ant-upload-list .ant-upload-select-picture-card {
  width: 100% !important;
}
.UploadViewMode .ant-upload-list .ant-upload-select-picture-card {
  display: none;
}
.ant-upload-select-picture-card {
  margin: 8px !important;
}
.UploadsNew {
  height: 100px;
  width: 156px;
  background: #F1F3F5;
  border: 1px dashed #868E96;
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding: 24px 0;
}
.UploadsNewHide {
  display: none !important;
}
.UploadsNew:hover {
  cursor: pointer;
}
.UploadsNewText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  color: #212529;
  padding-top: 6px;
}
.UploadsNewSmall {
  width: 156px;
}
.UploadsNewLarge {
  width: 100%;
}
.UploadAttachmentsImage {
  position: relative;
}
.UploadAttachmentsImage .ant-image,
.UploadAttachmentsImage img {
  border-radius: 8px;
  width: 156px;
  height: 100px;
  object-fit: none;
}
.UploadAttachmentsImage:hover {
  cursor: pointer;
}
.UploadLoadingProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.UploadLoadingProgress .ant-progress-inner {
  width: 32px !important;
  height: 32px !important;
}
.UploadLoadingProgress .ant-progress-inner .ant-progress-circle {
  background: rgba(33, 37, 41, 0.6);
}
.UploadLoadingOverlayWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.4);
  border-radius: 8px;
}
.UploadImageBadge {
  position: absolute;
}
.UploadImageBadge:hover {
  cursor: pointer;
  opacity: 0.8;
}
.UploadImageBadgeDelete {
  top: 4px;
  left: 4px;
}
.UploadImageBadgeChange {
  top: 35px;
  left: 4px;
}
.UploaderVideo {
  width: 156px;
  height: 100px;
  border-radius: 8px;
  background-color: #000;
}
.UploadAttachChanged {
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  background: #FCE000;
  border-radius: 8px 0px;
  padding: 2px 8px;
  top: 0;
}
.GuidebookCardWrapper {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding: 28px 24px 24px 24px;
  margin-bottom: 16px;
}
.GuidebookCardWrapperMobile {
  border: none !important;
  padding: 18px 16px 16px 18px !important;
}
.GuidebookCardTitleTextStatusWrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.GuidebookCardTitle,
.GuidebookCardDescription {
  padding-bottom: 16px;
  gap: 12px;
}
.GuidebookCardTitle .HeadlineThirdText,
.GuidebookCardDescription .HeadlineThirdText {
  padding-top: 6px;
}
.GuidebookCardDescription {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.GuidebookCardEditToggle {
  margin-left: auto;
}
.GuidebookCardEditToggle .ButtonDefaultWrapper button {
  padding: 10px 12px !important;
}
.GuidebookCardExample {
  padding-bottom: 20px;
}
.GuidebookCardExample .GuidebookCardExampleLink {
  color: #228BE6 !important;
  padding: 3px 12px 0 4px;
  display: block !important;
  text-align: start !important;
  width: 115px !important;
  height: 20px !important;
}
.GuidebookCardExample .GuidebookCardExampleTextAndIcon {
  display: flex;
}
.GuidebookCardExample .GuidebookCardExampleTextAndIcon:hover {
  cursor: pointer;
}
.GuidebookCardExample .GuidebookCardExampleDescription {
  padding-top: 3px;
}
.GuidebookCardAttachments {
  gap: 12px;
}
.GuidebookCardAttachmentsImage {
  position: relative;
}
.GuidebookCardAttachmentsImage .ant-image,
.GuidebookCardAttachmentsImage img {
  border-radius: 8px;
  width: 156px;
  height: 100px;
}
.GuidebookCardAttachmentsImageEditable {
  border: 1px dashed #FFFFFF;
  position: absolute;
  width: 156px;
  height: 100px;
  top: 0;
}
.GuidebookCardAttachmentsImageStatusBadge {
  position: absolute;
  top: -8px;
  right: -8px;
}
.GuidebookRightPanelWrapper {
  padding: 24px 0;
}
.GuidebookRightPanelWrapperHidden {
  display: none;
}
.GuidebookRightPanelTitle {
  padding: 0 32px 20px 32px;
}
.GuidebookRightPanelTabs {
  gap: 24px;
  padding: 0 32px;
  border-bottom: 1px solid #DEE2E6;
}
.GuidebookRightPanelTab span {
  padding-bottom: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.GuidebookRightPanelTab:hover {
  cursor: pointer;
}
.GuidebookRightPanelCommentHeaderPrimaryText,
.GuidebookRightPanelCommentHeaderSecondaryText {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.GuidebookRightPanelNotActiveTab {
  color: #868E96;
  padding-bottom: 13px !important;
}
.GuidebookRightPanelNotActiveTab:hover {
  cursor: pointer;
  color: #212529;
}
.GuidebookRightPanelActiveTab {
  background: #FCE000;
  height: 3px;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.GuidebookRightPanelMessages {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 87vh;
}
.GuidebookRightPanelComment {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #DEE2E6;
}
.GuidebookRightPanelComment::before {
  height: 6px;
  width: 6px;
  background-color: #FA5252;
  border-radius: 50%;
  display: block;
  position: relative;
  content: "";
  top: 14px;
  left: -13px;
}
.GuidebookRightPanelComment:hover {
  cursor: pointer;
  opacity: 0.7;
}
.GuidebookRightPanelCommentHeader {
  display: flex;
}
.GuidebookRightPanelCommentHeaderSecondaryText {
  color: #868E96;
  margin-left: 3px;
}
.GuidebookRightPanelCommentHeaderDateTime {
  display: flex;
  color: #868E96;
  gap: 6px;
  margin-top: 6px;
}
.GuidebookRightPanelEmptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 105px;
}
.GuidebookRightPanelEmptyText {
  text-align: center;
  white-space: pre-line;
}
.GuidebookRightPanelTabWithBadge {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.GuidebookRightPanelTabBadge {
  padding: 2px 4px 3px;
  width: 22px;
  height: 19px;
  background: #FA5252;
  border-radius: 18px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ChatSendMessageContainer {
  display: flex;
  flex-direction: column;
  padding: 17px 12px;
  gap: 10px;
  border-top: 1px solid #DEE2E6;
}
.ChatSendMessageContainer .InputWrapper {
  width: 100% !important;
}
.ChatSendMessageContainer .InputWrapper textarea {
  border: none !important;
}
.ChatSendMessageContainer .ChatQuoteWrapperContainer {
  margin-left: 43px;
  display: flex;
  flex-direction: row;
}
.ChatSendMessageContainer .ChatQuoteWrapperContainer .ChatQuoteWrapper {
  width: 100%;
}
.ChatSendMessageContainer .ChatQuoteWrapperContainer .ChatQuoteCancelIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.ChatSendMessageContainer .ChatQuoteWrapperContainer .ChatQuoteCancelIcon path {
  fill: #475058;
}
.ChatSendMessageContainer .ChatQuoteWrapperContainer .ChatQuoteCancelIcon:hover {
  cursor: pointer;
}
.ChatWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 87vh;
}
.ChatAttachIcon:hover,
.ChatSendIconActive:hover {
  cursor: pointer;
}
.ChatSendIcon {
  opacity: 0.5;
}
.ChatSendIconActive {
  opacity: 1;
}
.MessagesWrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 23px;
}
.ChatIcon {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 1px;
  text-align: center;
  color: #FFFFFF;
}
.ChatRoleDateTextContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ChatMessageContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.RoleDateWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.ChatRole {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.ChatDate {
  font-family: Lato;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #868E96;
}
.ChatInput {
  width: 100%;
  overflow: visible !important;
}
.ChatTextWrapper {
  background: #F4F4F4;
  border-radius: 0px 16px 16px 16px;
  padding: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.ChatMessageContainerRight {
  align-self: flex-end;
}
.ChatMessageContainerRight .ChatTextWrapper {
  border-radius: 16px 0px 16px 16px;
}
.ChatMessageContainerRight .RoleDateWrapper {
  justify-content: end;
}
.ChatQuoteWrapper {
  border-left: 4px solid #FCE000;
  padding-left: 10px;
}
.ChatTextWrapperQuote {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.ChatTextWrapperRole {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.ChatReplyIconContainer {
  visibility: hidden;
  width: 32px;
  height: 32px;
  background: #F4F4F4;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -17px;
  right: 10px;
}
.ChatReplyIconContainer svg {
  height: 14px;
  width: 16px;
}
.ChatReplyIconContainer:hover {
  cursor: pointer;
}
.ChatTextContainer {
  position: relative;
}
.ChatTextContainer:hover .ChatReplyIconContainer {
  visibility: visible;
}
.ChatSendMessageContainerChildren {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.GuidebookHeaderMobile {
  flex-direction: column !important;
  padding: 10px 16px 0 16px !important;
}
.GuidebookTitle {
  padding: 0 12px 20px 0;
}
.ComponentByStatusWrapper {
  padding: 8px 16px 7px 11px;
  border-radius: 4px;
}
.ComponentByStatusWrapper svg {
  margin-right: 4px;
}
.ComponentByStatusWrapper span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-top: 4px;
}
.ComponentByStatusSectionWrapper {
  padding: 5px 0 4px 0;
  border-radius: 4px;
  width: 120px;
  text-align: center;
}
.ComponentByStatusSectionWrapper span {
  width: 100%;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-top: 4px;
}
.GuidebookTitleDateDescription {
  padding-top: 12px;
  padding-left: 16px;
}
.GuidebookTitleDateDescription span {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #868E96;
}
.GuidebookTitleDateDescriptionMobile {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.GuidebookTitleTimeDescription {
  padding-left: 8px;
}
.GuidebookTitleArchive {
  margin-left: auto;
}
.GuidebookTitleArchive .ButtonDefaultWrapper button {
  height: 40px !important;
  padding: 0 12px !important;
}
.GuidebookTitleArchiveText span {
  padding-left: 8px;
}
.GuidebookAttachModalContainerTop {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.GuideBookAttachModal {
  position: relative;
}
.GuideBookAttachModal .ant-modal-content {
  border-radius: 16px;
}
.GuideBookAttachModal .ant-modal-body {
  padding: 0;
}
.GuideBookAttachModal .ant-modal-body .ant-image {
  border-radius: 16px;
}
.GuidebookCommentTitle {
  display: flex;
  margin-right: 16px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.GuidebookCommentTitleIndex {
  color: #868E96;
  margin-left: 3px;
}
.GuidebookPhotoDateTime {
  display: flex;
  color: #868E96;
  gap: 6px;
  margin-top: 6px;
}
.GuidebookAttachModalContainerRoot {
  padding: 32px 26px;
  display: flex;
  justify-content: space-between;
}
.GuidebookAttachModalImageComment {
  padding: 8px 5px;
}
.GuidebookImageWithCommentContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 720px;
  max-width: 1080px;
  max-height: 720px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.GuidebookImageWithCommentContainer .ant-image-img {
  display: block !important;
  max-width: 1080px !important;
  max-height: 720px !important;
  width: 100% !important;
  height: 100% !important;
}
.GuidebookImageWithCommentContainerClickable {
  position: relative;
}
.GuidebookImageWithCommentContainerClickableVideo {
  width: 100%;
  height: 100%;
}
.GuidebookImageWithCommentContainerClickableVideo video {
  width: 100%;
  height: 100%;
}
.GuidebookAttachModalImageCommentPoint {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background: #FA5252;
  border: 4px solid #FFFFFF;
}
.GuidebookAttachModalImageCommentWrapper {
  position: absolute;
}
.GuidebookAttachModalImageCommentTooltip .ant-popover-content .ant-popover-inner {
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2C2C2E;
  background-color: white !important;
}
.GuidebookAttachModalImageCommentTooltip .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0 !important;
  max-width: 500px !important;
  color: black !important;
}
.GuidebookAttachModalImageCommentTooltip .ant-popover-arrow-content::before {
  background: white !important;
}
.GuidebookCommentIconContainer {
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.GuidebookCommentIconContainer:hover {
  opacity: 0.5;
}
.GuidebookCommentIconContainer_Active {
  background: #FCE000;
}
.GuidebookCommentIconContainer:hover {
  cursor: pointer;
}
.GuidebookAttachModalImageComment textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  resize: none;
}
.GuidebookAttachModalSendIconContainer {
  background: #FCE000;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.GuidebookAttachModalSendIconContainer:hover {
  cursor: pointer;
}
.GuidebookAttachModalAddCommentContainer {
  display: flex;
  align-items: center;
}
.GuidebookModalImage_Active:hover {
  cursor: url(/Containers/Guidebook/icons/cursor_comment.svg), auto;
}
.GuidebookModalImage {
  margin-top: 6px;
}
div.GuidebookModalSelect {
  width: fit-content !important;
  height: 24px !important;
  border-radius: 4px !important;
}
div.GuidebookModalSelect .ant-select {
  height: 24px !important;
  width: fit-content !important;
  border-radius: 4px !important;
}
div.GuidebookModalSelect .ant-select .ant-select-selector {
  height: 24px !important;
  width: fit-content !important;
  border-radius: 4px !important;
}
div.GuidebookModalSelect .ant-select .ant-select-selector .ant-select-selection-search {
  height: 24px !important;
  width: fit-content !important;
  border-radius: 4px !important;
}
div.GuidebookModalSelect .ant-select .ant-select-selector .ant-select-selection-search input {
  height: 24px !important;
  width: fit-content !important;
  border-radius: 4px !important;
}
div.GuidebookModalSelect .ant-select-selector .ant-select-selection-item {
  font-family: Gilroy !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
div.GuidebookModalSelect .ant-select-selector:not(.ant-select-disabled):hover {
  border-color: #D7D7D7 !important;
}
div.GuidebookModalSelect div.ant-select-open:not(.ant-select-disabled) div.ant-select-selector,
div.GuidebookModalSelect div.ant-select-open:not(.ant-select-disabled) div.ant-select-selector:hover {
  border-color: #D7D7D7 !important;
}
div.GuidebookModalSelect_consideration .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
  background: #FFF4E6 !important;
}
div.GuidebookModalSelect_consideration .ant-select-open:not(.ant-select-disabled) div.ant-select-selector span.ant-select-selection-item {
  color: #FD7E14 !important;
}
div.GuidebookModalSelect_consideration .ant-select-open .ant-select-disabled div.ant-select-selector {
  background: #FFF4E6 !important;
}
div.GuidebookModalSelect_consideration .ant-select-open .ant-select-disabled div.ant-select-selector span.ant-select-selection-item {
  color: #FD7E14 !important;
}
div.GuidebookModalSelect_consideration .ant-select div.ant-select-selector {
  background: #FFF4E6 !important;
}
div.GuidebookModalSelect_consideration .ant-select div.ant-select-selector span.ant-select-selection-item {
  color: #FD7E14 !important;
}
div.GuidebookModalSelect_consideration .ant-select-arrow svg path {
  fill: #FD7E14 !important;
}
div.GuidebookModalSelect_consideration .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
  color: #FD7E14 !important;
}
div.GuidebookModalSelect_rejected .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
  background: #FFF5F5 !important;
}
div.GuidebookModalSelect_rejected .ant-select-open:not(.ant-select-disabled) div.ant-select-selector span.ant-select-selection-item {
  color: #FA5252 !important;
}
div.GuidebookModalSelect_rejected .ant-select-open .ant-select-disabled div.ant-select-selector {
  background: #FFF5F5 !important;
}
div.GuidebookModalSelect_rejected .ant-select-open .ant-select-disabled div.ant-select-selector span.ant-select-selection-item {
  color: #FA5252 !important;
}
div.GuidebookModalSelect_rejected .ant-select div.ant-select-selector {
  background: #FFF5F5 !important;
}
div.GuidebookModalSelect_rejected .ant-select div.ant-select-selector span.ant-select-selection-item {
  color: #FA5252 !important;
}
div.GuidebookModalSelect_rejected .ant-select-arrow svg path {
  fill: #FA5252 !important;
}
div.GuidebookModalSelect_rejected .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
  color: #FA5252 !important;
}
div.GuidebookModalSelect_approved .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
  background: #e1fce5 !important;
}
div.GuidebookModalSelect_approved .ant-select-open:not(.ant-select-disabled) div.ant-select-selector span.ant-select-selection-item {
  color: #40C057 !important;
}
div.GuidebookModalSelect_approved .ant-select-open .ant-select-disabled div.ant-select-selector {
  background: #e1fce5 !important;
}
div.GuidebookModalSelect_approved .ant-select-open .ant-select-disabled div.ant-select-selector span.ant-select-selection-item {
  color: #40C057 !important;
}
div.GuidebookModalSelect_approved .ant-select div.ant-select-selector {
  background: #e1fce5 !important;
}
div.GuidebookModalSelect_approved .ant-select div.ant-select-selector span.ant-select-selection-item {
  color: #40C057 !important;
}
div.GuidebookModalSelect_approved .ant-select-arrow svg path {
  fill: #40C057 !important;
}
div.GuidebookModalSelect_approved .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
  color: #40C057 !important;
}
.GuidebookModalSelectDropDown_consideration .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
  color: #FD7E14 !important;
}
.GuidebookModalSelectDropDown_consideration .ant-select-item-option-content {
  color: #FD7E14;
}
.GuidebookModalSelectDropDown_rejected .ant-select-item-option-content {
  color: #FA5252;
}
.GuidebookModalSelectDropDown_approved .ant-select-item-option-content {
  color: #40C057;
}
.GuidebookModalSelectDropDown .ant-select-item-option-content {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.GuidebookModalSelectDropDown {
  padding: 8px 12px !important;
}
.GuidebookCommentIconContainer svg path {
  fill: black;
}
.GuidebookCommentsIconContainer {
  display: flex;
  gap: 12px;
}
.GuidebookAttachModalImageCommentSubWrapper {
  display: flex;
  flex-direction: row;
}
.GuidebookDetailsIcon {
  min-width: 14px;
}
.GuidebookDetailsIcon:hover {
  cursor: pointer;
}
.GuidebookDetailsIcon:hover path {
  fill-opacity: 0.4;
}
.StatusDateDefaultWrapper {
  display: flex;
  flex-direction: row;
}
.GuideBookSwitchPhotoWrapper {
  display: flex;
}
.GuideBookSwitchPhotoWrapper .SwitchComponentLabel {
  display: none;
}
.GuideBookSwitchPhotoWrapper .SwitchComponentWrapper {
  margin: 0px 6px;
}
.GuideBookSwitchPhotoText {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
}
.GuideBookSwitchPhotoTextInactive {
  color: #868E96;
}
.GuideBookFilterLarge .MainFilterWrapper {
  width: 500px;
}
.GuideBookFilterLarge .MainPageFilterButtonWrapperActive {
  right: 485px;
  transition: none !important;
}
.GuideBookFilterLarge .MainPageFilterButtonWrapperUnActive {
  transition: all 300ms ease;
}
.GuideBookFilter .MainPageFilterButtonWrapperActive {
  transition: none !important;
}
.SmsContentTabs {
  width: 100%;
}
.SmsContentTitleRight {
  margin-left: auto;
}
.SmsContentTitleWrapper .TitleWrapper {
  padding-bottom: 16px;
}
.SmsContentTabsItem {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 13px;
  color: #868E96;
}
.SmsContentTabsItemActive {
  color: #212529;
}
.SmsContentTabsActiveRestriction {
  margin-right: 20px;
  background: #FCE000;
  height: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.SmsContentTabsRestriction {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
}
.SmsWrapper {
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
}
.SmsWrapper .TableColumnWrapper {
  width: auto !important;
}
.SmsContentTitleWrapper {
  display: flex;
  max-width: 900px;
}
.SmsContentTitleWrapper .TitleWrapper {
  width: auto !important;
  margin-right: 8px;
}
.CreateSms_inputs {
  padding-right: 40px;
  max-width: 840px;
}
.CreateSms_inputs .ant-select,
.CreateSms_inputs .ant-input,
.CreateSms_inputs .ant-picker-input,
.CreateSms_inputs .ant-picker-input input,
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
  font-weight: 500;
}
.CreateSms_inputs .RadioGroupOption {
  height: unset;
  padding-bottom: 16px;
}
.CreateSms_inputs .RadioGroupOption:last-of-type {
  padding-bottom: 8px;
}
.CreateSms_inputs .RadioWrapper label {
  align-items: flex-start;
}
.CreateSms_inputs .TimePickerComponentWrapper .ant-picker {
  height: 48px;
}
.CreateSms_inputs .PeriodRadio {
  display: flex;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper {
  flex: 0 1 95px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  border-color: #CED4DA;
  background: #F8F9FA;
  color: #868E96;
  padding-left: 0;
  padding-right: 0;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #FCE000 !important;
  box-shadow: inset 0 0 0 1px #FCE000;
  background: #ffffff;
  color: #212529;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:focus,
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:active {
  color: #212529;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #FCE000;
}
.CreateSms_inputs .PeriodRadio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: inset 0 0 0 1px #FCE000;
}
.CreateSms_infoBlock {
  display: flex;
  max-width: 703px;
  background: #E7F5FF;
  border: 1px solid #228BE6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
  color: #228BE6;
}
.CreateSms_infoBlock svg {
  margin-right: 8px;
}
.CreateSms_step {
  margin-bottom: 24px;
}
.CreateSms_stepTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  font-weight: 700;
  display: flex;
  margin-bottom: 13px;
}
.CreateSms_stepTitle span {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.CreateSms_stepTitle span:first-of-type {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background: #15AABF;
  border-radius: 4px;
  color: #FFFFFF;
  margin-right: 8px;
}
.CreateSms_stepTitle .TooltipComponentTargetWrapper {
  margin-left: 7px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.CreateSms_stepTitle .TooltipComponentTargetWrapper svg {
  width: 20px;
  height: 20px;
}
.CreateSms_stepTitle_inactive {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  font-weight: 700;
  display: flex;
  margin-bottom: 13px;
}
.CreateSms_stepTitle_inactive span {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.CreateSms_stepTitle_inactive span:first-of-type {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background: #15AABF;
  border-radius: 4px;
  color: #FFFFFF;
  margin-right: 8px;
}
.CreateSms_stepTitle_inactive .TooltipComponentTargetWrapper {
  margin-left: 7px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.CreateSms_stepTitle_inactive .TooltipComponentTargetWrapper svg {
  width: 20px;
  height: 20px;
}
.CreateSms_stepTitle_inactive span:first-of-type {
  background: #868E96;
}
.CreateSms_inputWrapper {
  max-width: 500px;
}
.CreateSms_inputWrapper .ant-select-clear {
  right: 32px;
}
.CreateSms_inputWrapper textarea.ant-input {
  padding: 12px 16px;
  resize: none;
}
.CreateSms_inputWrapper textarea.ant-input.CreateSms_customText {
  height: 98px !important;
}
.CreateSms_inputWrapper_wide {
  width: 800px;
  max-width: 100%;
}
.CreateSms_inputInfo {
  display: block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  font-style: italic;
  margin-bottom: 24px;
}
span.CreateSms_inputLabel,
label.CreateSms_inputLabel {
  display: block;
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  font-weight: 500;
  line-height: 18px;
  margin: 13px 0 6px;
}
label.CreateSms_inputLabel_withTooltip {
  display: flex;
  align-items: center;
}
label.CreateSms_inputLabel_withTooltip .TooltipComponentTargetWrapper {
  position: static;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CreateSms_templateInput,
.CreateSms_subTemplateInput {
  max-width: 480px;
}
.CreateSms_inputPhonesWrapper {
  margin-top: 18px;
}
.CreateSms_inputPhonesWrapper .CreateSms_error_inputPhoneTag,
.CreateSms_inputPhonesWrapper .CreateSms_inputPhoneTag {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 4px 5px 12px;
  border-radius: 8px;
  border: none;
  margin-bottom: 8px;
}
.CreateSms_inputPhonesWrapper .CreateSms_error_inputPhoneTag .ant-tag-close-icon,
.CreateSms_inputPhonesWrapper .CreateSms_inputPhoneTag .ant-tag-close-icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.CreateSms_inputPhonesWrapper .CreateSms_inputPhoneTag {
  background: #F4F4F4;
}
.CreateSms_inputPhonesWrapper .CreateSms_error_inputPhoneTag {
  background: #FFE3E3;
  color: #212529;
}
.CreateSms_inputPhonesWrapper .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  height: 25px;
}
.CreateSmsWrapper {
  padding: 24px 24px 32px;
  border: 1px solid #e9ecef;
}
.CreateSmsWrapper .CreateSms_inputPhonesWrapper,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector {
  height: 150px !important;
}
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select-clear {
  top: 16px;
  right: 16px;
}
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector {
  align-content: flex-start;
}
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector .ant-select-selection-search input,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector .ant-select-selection-search {
  height: 25px !important;
}
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  margin-bottom: 8px;
}
.CreateSms_phoneWrapper {
  padding: 25px 24px 32px;
  flex: 0 0 408px;
  width: 408px;
  height: 845px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  position: sticky;
  top: 24px;
}
.CreateSms_phoneBody {
  background: url("/Components/icons/iphone_empty.svg") top center no-repeat;
  height: 360px;
  position: relative;
}
.CreateSmsWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}
.CreateSms_phoneInner {
  position: absolute;
  top: 119px;
  left: 25px;
  right: 16px;
  height: 240px;
  overflow-y: auto;
}
.CreateSms_phoneTime {
  margin-top: 27px;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
  font-size: 9px;
  color: #909093;
}
.CreateSms_input {
  max-width: 480px;
  position: relative;
  margin-bottom: 5px;
}
.CreateSms_input .TooltipComponentTargetWrapper {
  position: absolute;
  right: -28px;
  top: 38px;
}
.CreateSms_input .TooltipComponentTargetWrapper svg {
  width: 20px;
  height: 20px;
}
.CreateSms_input .TimePickerComponentWrapper .ant-picker {
  border-radius: 8px;
}
.CreateSms_input .InputWrapper + .TooltipComponentTargetWrapper {
  position: absolute;
  right: -28px;
  top: 51px;
}
.CreateSms_inputShort .CreateSms_inputInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateSms_inputShort .InputWrapper,
.CreateSms_inputShort .DatePickerWrapper {
  max-width: 230px;
  flex: 0 1 calc(50% - 8px);
}
.CreateSms_inputDoubleShort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateSms_inputDoubleShort .CreateSms_inputDoubleInner {
  width: 230px;
  max-width: calc(50% - 8px);
}
.CreateSms_inputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 480px;
}
.CreateSms_inputContainer .CreateSms_input:not(.CreateSms_inputShort_single) {
  max-width: 480px;
  flex: 0 1 480px;
}
.CreateSms_inputShort_single {
  max-width: 230px;
  flex: 0 1 calc(50% - 8px);
}
.CreateSms_inputShort_single .CreateSms_inputInner {
  display: block;
}
.CreateSms_inputShort_single .CreateSms_inputInner .InputWrapper,
.CreateSms_inputShort_single .CreateSms_inputInner .DatePickerWrapper {
  width: 100%;
  max-width: unset;
}
.ant-select-dropdown .ant-select-item-option.CreateSms_SelectDropDownCustom {
  padding-top: 6px;
  padding-bottom: 6px;
}
.CreateSms_free_text {
  display: inline-flex;
  align-items: center;
}
.CreateSms_free_text svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 1px;
}
.CreateSms_error {
  color: red;
}
.CreateSms_inputError {
  border-color: red;
}
.ErrorSelectWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
}
.ErrorSelectWrapper .ant-select-selector {
  border-color: #e80012 !important;
}
.CreateSms_divider {
  border: 0;
  border-top: 1px solid #DEE2E6;
  margin-bottom: 24px;
}
.SmsCancelBtn {
  background: #F1F3F5 !important;
  border: none !important;
  border-radius: 16px;
}
.SmsPhoneDropdown {
  display: none !important;
}
.CreateSms_phoneCalc {
  min-height: 204px;
  padding: 1px;
}
.CreateSms_phoneCalc .GraySpinComponent {
  height: 200px;
}
.CreateSms_phoneCalcRow,
.CreateSms_phoneCalcTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 24px;
}
.CreateSms_phoneCalcRow span,
.CreateSms_phoneCalcTotal span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.HistoryTableComment {
  max-width: 200px;
  width: 200px;
}
.SmsHistoryColumnLargeText {
  width: 256px;
}
.HistoryTableCommentModal {
  width: 416px;
}
@media screen and (max-width: 920px) {
  .HistoryTableCommentModal {
    width: auto;
    max-height: 400px;
    overflow-y: auto;
  }
}
span.CreateSms_phoneCalcRowLabel {
  font-weight: 400;
}
span.CreateSms_phoneCalcRowValue {
  font-weight: 700;
}
.CreateSms_phoneCalcTotal {
  border-top: 1px dashed #E9ECEF;
  padding: 12px 0;
}
.CreateSms_phoneCalcTotal span {
  font-weight: 700;
}
span.Createms_phoneCalcTotalRowValue {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
}
span.Createms_phoneCalcTotalRowValue .Createms_RubleSigne {
  margin-left: 5px;
}
.CreateSms_phoneBottomText {
  background: #F4F4F4;
  border-radius: 8px;
  padding: 8px 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
}
.CreateSms_phoneBottomText a {
  color: #228BE6;
  font-weight: 700;
  display: block;
  margin-top: 8px;
}
.CreateSms_phoneMessage {
  margin: 5px 25px 15px 15px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  opacity: 0.9;
  padding: 6px 10px;
  background: #E9E9EB;
  border-radius: 17px;
  position: relative;
  z-index: 1;
}
.CreateSms_phoneMessage:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -2px;
  left: -4px;
  background-image: url(/Components/icons/sms_tail.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.HistorySmsTableWithHeader .ant-table-thead tr th {
  font-weight: 500 !important;
  word-spacing: normal;
  border-bottom: none;
}
.HistorySmsTableWithHeader .ButtonIconWrapper .ant-btn {
  display: flex;
  align-items: center;
}
th.SmsHistoryColumn:nth-child(6) {
  background: #EBFBEE !important;
}
th.SmsHistoryColumn:nth-child(7) {
  background: #EBFBEE !important;
}
th.SmsHistoryColumn:nth-child(8) {
  background: #EBFBEE !important;
}
th.SmsHistoryColumn:nth-child(9),
th.SmsHistoryColumn:nth-child(10) {
  word-spacing: 999px !important;
}
th.SmsHistoryColumn:nth-child(9),
th.SmsHistoryColumn:nth-child(10),
th.SmsHistoryColumn:nth-child(11),
th.SmsHistoryColumn:nth-child(12) {
  background: #EBFBEE !important;
}
th.SmsHistoryColumn:nth-child(13) {
  background: #EBFBEE !important;
}
.SmsHistoryColumn .TableSearchSelect {
  max-width: 100%;
}
.SMSCreateModal .ModalWrapper {
  border-radius: 16px;
  padding: 48px 56px;
  max-height: unset;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 0 96px rgba(0, 0, 0, 0.16);
}
.SMSCreateModal .ModalContent {
  max-height: calc(100vh - 232px);
  overflow-y: auto;
  width: 100%;
  background: none;
  box-shadow: unset;
  border-radius: 0;
}
.SMSCreateModal .SMSCreateModalWrapper {
  background: #FFFFFF;
  border-radius: 16px;
}
.SMSCreateModal .ModalCloseWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
}
.SMSCreateModal .ModalCloseWrapper svg {
  width: 30px;
  height: 30px;
}
.SMSCreateModalWrapper {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
}
.SMSCreateModal_title {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
  margin-bottom: 8px;
}
.SMSCreateModal_ContentRow {
  padding: 16px 0 10px;
  border-bottom: 1px solid #E9ECEF;
}
.SMSCreateModal_label {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  font-weight: 500;
  color: #868E96;
}
.SMSCreateModal_info,
.SMSCreateModal_Price,
.SMSCreateModal_btns {
  display: flex;
}
.SmsModal_value {
  margin-left: 5px;
}
.SMSCreateModal_Price {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  margin: 56px 0 24px;
  align-items: flex-end;
}
.SMSCreateModal_PriceValue {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
  margin-left: 5px;
}
.SMSCreateModal_btns .ButtonDefaultWrapper {
  margin-left: 16px;
}
.SMSCreateModal_btns .ButtonDefaultWrapper button {
  padding-left: 27px;
  padding-right: 27px;
}
.SMSCreateModal_btns .ButtonPrimaryWrapper button {
  width: 200px;
}
.SMSSuccessModal .ModalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SMSCreate_SuccessWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -50px;
  height: 849px;
  max-height: calc(100vh - 232px);
  justify-content: center;
}
.SMSCreate_SuccessTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
  text-align: center;
  margin-top: 48px;
}
.SmsDateTimeCell {
  display: flex;
}
.SmsDateTimeCell .SmsTime {
  margin-left: 10px;
  color: #adb5bd;
}
.SmsStatusCell {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
  width: 120px;
  max-width: 120px;
}
.HistorySmsTableBottom {
  display: flex !important;
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 12px 22px;
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  font-weight: 500;
}
.HistorySmsTableBottom .PaginationWrapper {
  padding-top: 0;
  font-weight: 500;
}
.HistorySmsTableBottom .PaginationWrapper .ant-pagination-item a {
  padding: 0;
}
.HistorySmsTableBottom_Right,
.HistorySmsTable_Show {
  display: flex;
  align-items: center;
}
.HistorySmsTable_Show {
  margin-left: 15px;
}
.HistorySmsTable_Show .SelectWrapper {
  margin-left: 15px;
  height: 32px !important;
  max-width: 100% !important;
}
.HistorySmsTable_Show .SelectWrapper .ant-select,
.HistorySmsTable_Show .SelectWrapper .ant-select .ant-select-selector {
  height: 32px !important;
}
.HistorySmsTable_Show .SelectWrapper .ant-select-selector {
  background: #F8F9FA !important;
  border: 1px solid #CED4DA !important;
}
.CreateSms_pseudoTooltipWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 7px;
}
.CreateSms_pseudoTooltipWrapper svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}
.CreateSms_pseudoTooltip {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
  transform-origin: 50% 0;
  transform: scale(0.9);
  opacity: 0;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 41px;
  right: -90px;
  user-select: text;
  pointer-events: none;
  z-index: -10;
}
.CreateSms_pseudoTooltipHelper {
  display: none;
  position: absolute;
  top: 0;
  left: -90px;
  right: -90px;
  height: 200px;
  z-index: 2;
}
.CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltipHelper {
  display: block;
}
.CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltip {
  opacity: 1;
  transform: scale(1);
  pointer-events: unset;
  z-index: 1030;
}
.CreateSms_pseudoTooltipArrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.CreateSms_pseudoTooltipArrowContent {
  box-shadow: 2px 2px 5px rgb(0 0 0%);
  transform: translateY(11px) rotate(-135deg);
  position: absolute;
  top: 10px;
  left: -7px;
  width: 34px;
  height: 34px;
  background: #343A40;
  border-radius: 2px;
}
.CreateSms_pseudoTooltipInner {
  border-radius: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
  background-color: #343A40;
  padding: 8px 11px;
  width: 200px;
  color: #fff;
}
.CreateSms_pseudoTooltipInner ul {
  list-style-type: "-";
  padding-left: 10px;
  margin-bottom: 0;
}
.CreateSms_pseudoTooltipInner li {
  padding-left: 5px;
}
.CreateSms_pseudoTooltipInner p {
  margin-bottom: 0;
}
div.ButtonPrimaryWrapper .ant-btn.CreateSms_applyButton {
  width: 200px;
}
div.ButtonPrimaryWrapper .ant-btn.CreateSms_applyButton span {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.SmsWrapper .InputWrapper .ant-input-prefix {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
  color: #868E96;
}
.ant-select-dropdown.SMS_statusOptions .ant-select-item {
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
}
.SelectWrapper.SMS_statusSelected {
  font-size: 12px;
  max-width: 140px;
  width: 140px;
}
.InputWrapper .ant-input-suffix {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #868E96;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 1680px) {
  .CreateSms_inputWrapper_wide {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .CreateSmsWrapper {
    display: block;
  }
  .CreateSms_inputs {
    padding-right: 0;
  }
  .CreateSms_phoneWrapper {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .SmsContentTitleWrapper .TitleDescription .TitleText span {
    font-family: 'Gilroy' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #212529;
  }
  .SmsContentPageTop .TitleText {
    margin-left: 0;
  }
  .HistorySmsTableBottom {
    padding-left: 16px;
    padding-right: 16px;
  }
  .PaginationWrapper div .ant-pagination .ant-pagination-item {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}
@media screen and (max-width: 920px) {
  .SmsContentPageTop {
    padding-left: 16px;
    padding-right: 16px;
  }
  .SMSCreateModal .ModalWrapper {
    height: auto;
    min-height: 347px;
    width: calc(100vw - 32px) !important;
    left: 16px !important;
  }
  .SMSCreateModal .ModalContent {
    max-height: calc(100vh - 100px);
  }
  .SMSCreate_SuccessWrapper {
    top: auto;
    height: 349px;
  }
  .SMSCreate_SuccessWrapper svg {
    height: 187px;
    width: 184px;
  }
  .SMSCreate_SuccessTitle {
    font-family: 'Gilroy' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #212529;
    margin-top: 30px;
  }
  .SMSCreateModal .ModalWrapper {
    padding: 24px 17px;
  }
  .SMSCreateModal .ModalCloseWrapper {
    top: 0;
    right: 0;
  }
  .SMSCreateModal_title {
    font-family: 'Gilroy' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #212529;
  }
  .SMSCreateModalWrapper {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #212529;
  }
  .SMSCreateModal_label {
    font-size: 12px;
  }
  .SMSCreateModal_ContentRow {
    padding-bottom: 8px;
  }
  .SMSCreateModal_Price {
    margin: 17px 0;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #212529;
  }
  .SMSCreateModal_PriceValue {
    font-family: 'Gilroy' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #212529;
    line-height: 16px;
  }
  .HistorySmsTableWithHeader {
    margin-left: 16px;
    margin-right: 16px;
  }
  .CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltipHelper {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .SmsContentTitleWrapper {
    display: block;
    padding-bottom: 16px;
  }
  .CreateSms_input .TooltipComponentTargetWrapper {
    right: 0;
    top: 0;
  }
  .HistorySmsTableBottom {
    display: block;
  }
  .HistorySmsTableBottom .PaginationWrapper {
    place-items: start;
    margin-bottom: 16px;
  }
  .SMSCreateModal_btns .ButtonPrimaryWrapper button {
    width: auto;
  }
}
@media screen and (max-width: 500px) {
  .CreateSms_pseudoTooltipWrapper {
    position: static;
  }
  .CreateSms_stepTitle_CustomTooltip {
    position: relative;
  }
  .CreateSms_pseudoTooltip {
    right: auto;
    left: calc(100vw - 228px);
  }
}
@media screen and (max-width: 450px) {
  .SmsContentTitleWrapper .TitleDescription .TitleText span {
    margin-left: 0 !important;
  }
}
.SmsHistoryHeaderGroupContainer {
  display: inline-flex;
  gap: 5px;
}
.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th.SmsHistoryHeaderGroupCellOrdered {
  background-color: #C5F6FA !important;
}
.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th.SmsHistoryHeaderGroupCellDone {
  background-color: #D3F9D8 !important;
}
.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th.SmsHistoryHeaderGroupCell {
  height: 40px;
  max-height: 40px;
}
.SmsHistoryColumnRequestedDate,
.SmsHistoryColumnRequestedStatus {
  width: 165px;
  min-width: 165px;
}
.SmsHistoryColumnRequestedDateDone {
  width: 200px;
  min-width: 200px;
}
.CreateSms_address {
  display: block;
  margin-top: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  color: var(--Text-Link, #228BE6);
}
.CreateSms_calendarIcon svg {
  position: relative;
  top: 1px;
}
.CreateSms_RangePickerContainer {
  margin-top: 12px;
}
.CreateSms_RangePickerContainer .RangePickerWrapper .ant-picker.ant-picker-range {
  height: 48px;
}
.CreateSms_RangePickerContainer .ant-picker-range > .ant-picker-input {
  max-width: 100px;
}
.TableHeaderCupisDateWidth {
  width: 50px !important;
}
.TableHeaderCupisCurrencyWidth {
  width: 120px !important;
}
.TableHeaderCupisPersentWidth {
  width: 80px !important;
}
.TableHeaderCupisWidth {
  width: 140px !important;
}
.CupisInsideTable {
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  margin-top: 12px;
  width: fit-content;
}
.CupisInsideTitle {
  text-align: center;
  padding: 13px 0;
  height: 48px;
}
.CupisInsideContentWrapper {
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.CupisInsideSubtitle,
.CupisInsideTitle {
  border-right: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;
}
.CupisInsideSubtitle {
  white-space: pre;
}
.CupisInsideFirstLeft {
  border-top-left-radius: 8px;
}
.CupisInsideNoRightBorder {
  border-right: 0 !important;
}
.ButtonTextSecondCupis {
  font-weight: 500 !important;
  width: 166px;
  height: 48px;
  text-align: center;
  place-items: center;
  display: grid;
}
.CupisInsideValue,
.CupisInsideBonusValue {
  text-align: center;
  place-items: center;
  display: grid;
  border-right: 1px solid #DEE2E6;
  padding: 6px 0;
}
.CupisInsideValue,
.CupisInsideBonusColumn {
  width: 166px;
}
.CupisInsideTableBonus {
  width: 332px;
}
.CupisInsideTableGuests {
  width: 368px;
}
.CupisInsideSubtitleGuests,
.CupisInsideGuestsColumn {
  width: 184px;
}
.CupisInsideTableBonus .CupisInsideTitle,
.CupisInsideSubtitleBonus {
  background: #FFF4E6;
}
.CupisInsideTableGuests .CupisInsideTitle,
.CupisInsideSubtitleGuests {
  background: #E6FCF5;
}
.CupisInsideTableDetail .CupisInsideTitle,
.CupisInsideSubtitleDetail {
  background: #F3F0FF;
}
.CupisInsideLastTop {
  border-top-right-radius: 8px;
}
.CupisInsideLastBottom {
  border-bottom-right-radius: 8px !important;
}
@media screen and (max-width: 920px) {
  .CupisInsideTableBonus,
  .CupisInsideTableDetail,
  .CupisInsideTableGuests {
    width: 310px !important;
  }
  .CupisInsideGuestsColumn,
  .CupisInsideBonusColumn {
    width: 155px;
  }
  .CupisInsideSubtitleDetail {
    width: 103.3px;
  }
  .CupisInsideFirstLeft {
    border-top-right-radius: 8px;
  }
  .CupisInsideLastTop {
    border-top-right-radius: 0 !important;
  }
  .CupisInsideMobileNoRightBorder {
    border-right: 0 !important;
  }
  .CupisInsideLastMobileBottom {
    border-bottom-left-radius: 8px !important;
  }
}
.TablePaginationPageSizeWrapper {
  margin-left: auto;
}
.CupisTitleTextDescription {
  margin-left: 7px;
  padding-top: 5.5px;
}
.ReportHallPDFWrapper {
  height: calc(100vh - 216px);
}
.ReportHallTitleTextDescription {
  margin-left: 7px;
  padding-top: 5.5px;
}
.ReportHallPDFMobile .FileItemWrapper {
  padding-left: 32px;
  width: calc(100vw - 64px) !important;
  background: #F1F3F5 !important;
}
.ReportHallPDFMobile .FileItemWrapper .FileItemText .FileItemTextName {
  max-width: calc(100vw - 180px) !important;
}
.GuestsBalancePage .ComponentDashboardItem {
  margin-top: 0;
}
.GuestsBalancePage .ModalContent,
.GuestsBalancePage .ModalFormContent {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 13px rgba(0, 0, 0, 0.1);
}
.GuestsBalancePage .ant-table-tbody .ant-table-row > td:first-child {
  line-height: 140%;
  letter-spacing: 0.3px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}
.GuestsBalancePage .ant-table-tbody .ant-table-row:hover {
  cursor: auto !important;
}
.GuestsBalancePage .ant-table-tbody > tr.ant-table-row:hover > td,
.GuestsBalancePage .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
  cursor: auto !important;
}
.GuestsBalancePage .DashboardItemLabel {
  white-space: pre;
}
.GuestBalanceTitle {
  margin-left: 25px;
}
@media screen and (max-width: 1900px) {
  .GuestBalance_filterOpen .TableHeaderModal .ModalWrapper {
    left: auto;
    right: 10px;
  }
}
@media screen and (max-width: 1300px) {
  .GuestsBalancePage .ant-table table {
    min-width: 1000px !important;
  }
}
@media screen and (min-width: 921px) {
  .GuestsBalancePage .ant-table-tbody .ant-table-row > td:first-child,
  .GuestsBalancePage .ant-table-container table > thead > tr th:first-child {
    padding-left: 32px !important;
  }
  .GuestsBalancePage .ant-table-tbody .ant-table-row > td:last-child,
  .GuestsBalancePage .ant-table-container table > thead > tr th:last-child {
    padding-right: 32px !important;
  }
}
@media screen and (max-width: 920px) {
  .GuestBalanceTitleWrapper .TitleDescription {
    flex-direction: row;
    align-items: center;
    padding-left: 9px;
    padding-right: 12px;
  }
  .GuestsBalancePage .ComponentDashboardWrapper {
    padding: 0 16px;
  }
  .GuestsBalancePage .ant-table table {
    min-width: 100% !important;
  }
}
.guestsBalanceFreeBetCountIcon {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.guestsBalance_freeBetCountClickable {
  cursor: pointer;
}
.FreeBetsDetailDescription {
  margin-top: 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
}
.OfficialSmsTitleWrapper .TitleTooltip {
  top: 2px;
}
.OfficialSmsTable .ant-table-tbody .ant-table-row:hover {
  cursor: default;
}
.OfficialSmsTable .ant-table-column-sort {
  background-color: #ffffff;
}
.OfficialSmsTable .ant-table-column-has-sorters .ant-table-column-sorters,
.OfficialSmsTable .ant-table-thead > tr > th {
  background-color: #E3FAFC !important;
  min-width: 124px;
}
.OfficialSmsTable .ant-table-tbody .ant-table-row > td:first-child,
.OfficialSmsTable .ant-table-container table > thead > tr th:first-child {
  padding-left: 32px !important;
}
.WikiContainer {
  padding: 100px 77px;
}
.WikiContainer .ant-breadcrumb-link {
  font-family: Gilroy;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #1C7ED6 !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.WikiContainer .ant-breadcrumb li:last-child a {
  color: #CED4DA !important;
}
.WikiContainer .ant-breadcrumb li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
.WikiContainer summary:hover {
  cursor: pointer;
}
.WikiSearchSelect {
  margin-top: 24px;
  margin-bottom: 62px;
  max-width: 785px;
}
.WikiSearchSelect .ant-select-arrow {
  transform: none !important;
}
.WikiSearchSelect .ant-select-clear {
  margin-right: 4%;
}
@media screen and (max-width: 921px) {
  .WikiContainer {
    padding: 62px 16px;
  }
  .WikiSearchSelect {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
.WikiDashboardCard {
  display: flex;
  flex: 0 0 32%;
  padding: 0 10px 10px 0;
  margin-bottom: 15px;
}
.WikiDashboardCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 24px;
}
.WikiDashboardCardLinksContainer {
  margin-top: 8px;
}
.WikiDashboardCardLinksContainer div {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-align: left;
}
.WikiDashboardCardLinksContainer a {
  color: black;
}
.WikiDashboardCardLinksContainer a:hover {
  color: black !important;
  opacity: 90;
}
.WikiDashboardCardLinksContainer a:hover div {
  color: black !important;
}
.WikiDashboardIcon {
  margin-right: 21px;
  width: 55px;
}
.WikiReportContainer {
  background: #fff !important;
}
.WikiReportContainer .MainPageContentWrapper {
  padding: 0 !important;
  background: #fff !important;
}
.WikiReportContainer .BaseLayoutContentFooter {
  background: #fff !important;
}
.WikiContainerDetails {
  padding-top: 31px !important;
  max-width: 1080px;
}
.WikiMarkdownContainer {
  margin-top: 24px;
}
.WikiMarkdownContainer h1 {
  margin-bottom: 10px !important;
}
.WikiFilterWrapper {
  padding: 32px 31px;
}
.WikiSearchSelectFilter {
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}
.WikiSearchSelectFilter .ant-select-clear {
  margin-right: 10%;
}
.WikiFilterLinkContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.WikiFilterLink:hover {
  cursor: pointer;
  color: #1C7ED6;
}
.WikiLinksList a {
  color: #1C7ED6;
}
.WikiListSubheader {
  margin-top: 32px;
  margin-bottom: 14px;
}
.BaseLayoutLeftMenuContent_Wiki {
  max-height: calc(100vh - 200px);
}
.WikiGroupHeader {
  margin-bottom: 17px;
}
.fig img {
  width: 100% !important;
  height: auto !important;
}
@media screen and (min-width: 768px) {
  .frame {
    width: 50% !important;
  }
}
@media screen and (min-width: 1024px) {
  .frame {
    width: 25% !important;
  }
}
@media (max-width: 767px) {
  .WikiDashboardCard {
    flex: 0 0 100%;
  }
  .WikiSearchSelectFilter {
    margin-top: 15px !important;
  }
}
@media (max-width: 919px) {
  .ant-breadcrumb {
    margin-top: 15px !important;
  }
}
.MainFilterMobileCloseWiki {
  top: 15px;
}
.WikiHomeBreadcrumb {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}
.WikiDashboardCardName {
  font-weight: 700 !important;
}
.WikiFilterLink_Active {
  color: #1C7ED6 !important;
}
.SubtitleFirstTextWikiFilter {
  margin-bottom: 6px;
  font-weight: 700 !important;
}
.WikiMarkdownContainer {
  /*   Раскрывающийся список  */
  /*   Контейнер с изображением  */
  /*   Стандартный текст  */
  /*   Маркированный список первого уровня  */
  /*   Маркированный список второго уровня  */
  /*   Нумерованный список  */
  /*   Отступ без маркера  */
  /*   Выступ в маркированном списке  */
  /*   Блок Внимание!  */
  /*   Блок Примечание */
  /*   Интерлиньяж  */
  /*   Выравнивание изображений по центру  */
  /*   Рамка для изображений  */
  /*   Заголовок первого уровня  */
  /*   Заголовок четвертого уровня  */
  /*   Адаптивность изображений  */
  /*   Основной текст  */
}
.WikiMarkdownContainer details > summary {
  max-width: 100%;
  height: 25px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #1C7ED6;
  flex: none;
  order: 0;
  flex-grow: 0;
  list-style: none;
}
.WikiMarkdownContainer details {
  position: relative;
}
.WikiMarkdownContainer details summary::before {
  content: url(/Containers/Wiki/icons/icon_cl.png);
  position: absolute;
  right: 0px;
}
.WikiMarkdownContainer details[open] summary::before {
  content: url(/Containers/Wiki/icons/icon_op.png);
  position: absolute;
  right: 0px;
}
.WikiMarkdownContainer .container {
  border: none;
}
.WikiMarkdownContainer .container img {
  display: inline;
  vertical-align: middle;
}
.WikiMarkdownContainer p {
  line-height: 1.5;
}
.WikiMarkdownContainer .disc {
  padding: 0;
  margin-left: 30px;
  line-height: 200%;
}
.WikiMarkdownContainer .circle {
  padding: 0;
  margin-left: 35px;
  line-height: 200%;
}
.WikiMarkdownContainer ol {
  padding: 0;
  margin-left: 30px;
  line-height: 24px;
}
.WikiMarkdownContainer ul {
  padding: 0;
  margin-left: 20px;
  line-height: 200%;
}
.WikiMarkdownContainer .test1 {
  margin-left: 15px;
}
.WikiMarkdownContainer .outline1 {
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  max-width: 100%;
  background: #FFF5F5;
  border-radius: 16px;
  flex: none;
  order: 2;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.WikiMarkdownContainer .outline2 {
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  max-width: 100%;
  background: #F4F4F4;
  border-radius: 16px;
  flex: none;
  order: 2;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.WikiMarkdownContainer .dline {
  line-height: 1.5;
}
.WikiMarkdownContainer .fig img {
  margin: 0 auto;
}
.WikiMarkdownContainer .frame {
  box-sizing: border-box;
  height: 696px;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 15px;
}
.WikiMarkdownContainer h1 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #212529;
  left: 100px;
  margin-bottom: 15px;
}
.WikiMarkdownContainer h4 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 39px;
  color: #212529;
  left: 100px;
  margin-bottom: 15px;
}
.WikiMarkdownContainer hr {
  height: 1px;
  background: #DEE2E6;
  flex: none;
  order: 0;
  border: none;
  margin-bottom: 16px;
  margin-top: 16px;
}
.WikiMarkdownContainer .mv-100 {
  max-width: 100%;
}
.WikiMarkdownContainer body {
  height: 24px;
  background-color: #FFFFFF;
  /* Subtitle 2 */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  align-items: center;
  letter-spacing: 0.2px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.SportboomChartTypePickerItem {
  min-width: 220px;
  display: flex;
  padding: 10px 16px;
  border: 1px solid #51CF66;
  border-radius: 8px;
}
.SportboomChartTypePickerRow {
  margin: 0 24px;
  gap: 12px;
}
.SportboomChartTypePickerCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 4px;
}
.SportboomChartTypePickerItemDefault:hover {
  cursor: pointer;
  opacity: 0.8;
}
.SportboomChartWrapper {
  margin-top: 16px;
  padding: 24px;
  min-height: 282px;
  background-color: #fff;
  border-top: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.SportboomChartTypePicker {
  padding: 32px 0;
  background-color: #fff;
  border-bottom: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: grid;
  place-items: center;
}
.SportboomInOutTooltip span {
  width: 376px;
  white-space: normal !important;
}
.SportboomChartSkeletonWrapper {
  width: 100% !important;
  height: 282px !important;
  display: block !important;
}
.SportboomChartSkeletonWrapper span {
  width: 100% !important;
  height: 282px !important;
}
@media screen and (max-width: 920px) {
  .SportboomInOutTooltip span {
    width: calc(100vw - 112px);
    min-width: 320px;
    white-space: normal !important;
  }
  .SportboomChartTypeMobilePicker {
    width: calc(100vw - 32px);
  }
  .SportboomChartTypePickerItem {
    padding: 0 16px !important;
    height: 48px;
    width: 50% !important;
  }
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem div span {
    color: #868E96 !important;
  }
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem {
    min-width: auto !important;
    width: 50% !important;
  }
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:first-child,
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(3) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(2),
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(4) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.TableConfigModalWrapper {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 32px 24px;
  width: 384px;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 184px;
}
.TableConfigModalClose {
  margin-left: auto;
}
.TableConfigModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}
.TableConfigModalListTitle {
  justify-content: space-between;
}
.TableConfigModalListItemView {
  margin-left: auto;
}
.TableConfigModalApply button {
  width: 100%;
}
.TableConfigModalTemplateAdd {
  margin: 24px 16px 16px 16px;
}
.DraggableItem .TableConfigModalListItem {
  border-radius: 2px;
  background: #F1F3F5 !important;
}
.TableConfigModalTemplateListItem div {
  justify-content: space-between;
}
.TableConfigModalTemplateListItem div svg path {
  fill: #ADB5BD;
}
.SportboomConfigRemoveModal {
  padding: 32px;
}
.SportboomConfigRemoveModal .SportboomConfigRemoveModalButtons {
  margin-left: auto;
}
.SportboomConfigRemoveModalButtonsRemove path {
  fill: #fff !important;
}
.SportboomClearFilterModal {
  padding: 32px 24px;
  width: 384px;
}
.SportboomClearFilterModalList {
  max-height: 400px;
  overflow-y: auto;
}
.SportboomClearFilterCount {
  width: 24px;
  height: 24px;
  place-items: center;
  display: grid;
  background: #FCE000;
  border-radius: 24px;
}
.SportboomFilterClearDescription p {
  margin-bottom: 0 !important;
}
.SportboomClearFilterDisabled .TooltipComponentTargetWrapper {
  cursor: not-allowed;
  pointer-events: none !important;
}
.SportboomTableDetailWrapper {
  margin: 56px 24px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.SportboomTableDetail {
  gap: 16px;
}
.SportboomTableDetail div {
  margin-top: 8px;
}
.SportboomTableDetailInfo {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 920px) {
  .SportboomTableDetailInfo {
    flex-direction: column;
  }
  .SportboomCompareWrapper {
    bottom: 48px;
    left: calc(50vw - 160px) !important;
  }
  .SportboomTableDetailMobileTabs {
    margin-bottom: 8px;
  }
  .SportboomTableDetailMobileTabs .TabsComponentWrapper {
    margin-left: 0 !important;
  }
  .SportboomTableDetailMobileTabs .TabsComponentWrapper .TabsComponentItemsWrapper > div .TabsComponentItem {
    padding-bottom: 8px !important;
  }
  .SportboomTableDetailMobileDelete button {
    width: 100%;
  }
}
.SportboomTableColumnDataWithGap {
  gap: 4px;
}
.SportboomTableColumnDataWithGap svg {
  margin-top: 4px;
}
.SportboomTableStatus,
.SportboomTableStatusWin,
.SportboomTableStatusWinActive,
.SportboomTableStatusLoose,
.SportboomTableStatusLooseActive,
.SportboomTableStatusInGame,
.SportboomTableStatusInGameActive,
.SportboomTableStatusRedeem,
.SportboomTableStatusRedeemActive,
.SportboomTableStatusReturnBet,
.SportboomTableStatusReturnBetActive {
  width: 112px;
  height: 24px;
  padding: 6px;
  display: grid;
  place-items: center;
  border-radius: 4px;
}
.SportboomTableFirstItem {
  padding-left: 16px;
}
.SportboomTableFirstItemMargin {
  margin-left: 16px;
}
.SportboomCompareWrapper {
  position: fixed;
  bottom: 48px;
  left: 50vw;
  z-index: 2;
}
.SportboomTableActiveRow .ant-table-selection-column {
  border-left: 4px solid #FCE000 !important;
  z-index: 10 !important;
}
.SportboomTable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-table-expanded-row-fixed {
  overflow: inherit !important;
}
.SportboomTable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-table-expanded-row-fixed div .GridCenter {
  position: sticky;
  width: 370px;
  left: 40%;
}
.SportboomTableRow .ant-table-selection-column {
  z-index: 10 !important;
}
.SportboomCompareItem,
.SportboomCompareApply,
.SportboomCompareCancel {
  padding: 11px 24px;
  border-radius: 8px;
  cursor: pointer;
}
.SportboomTableInsideEmpty {
  display: grid;
  place-items: center;
  padding: 18px 0;
}
.SportboomInsideTableWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  border: 0 !important;
  border-radius: 0 !important;
}
.SportboomInsideTableWrapper .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-placeholder .ant-table-cell {
  background-color: #E9ECEF !important;
}
.SportboomInsideTableWrapper {
  border-top: 1px solid #CED4DA !important;
}
.SportboomInsideTableWrapper.BorderNone {
  border-top: none !important;
}
.SportboomInsideTableRow {
  padding-left: 20px !important;
}
.SportboomInsideTableRow:not(.Detail) .ant-table-cell {
  background: #E9ECEF !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #CED4DA !important;
}
.SportboomInsideTableRow.BorderNone .ant-table-cell {
  border: none !important;
}
.SportboomInsideTableActiveRow td:first-child {
  border-left: 4px solid #CED4DA !important;
}
.SportboomInsideTableActiveRow td:first-child div {
  width: 4px !important;
}
@media screen and (max-height: 850px) {
  .SportTypeTableFilterHeader .TableHeaderModal .ModalWrapper {
    top: 24px !important;
  }
}
.SportboomWalletCellWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.SportboomWalletCellWrapper div {
  color: #228BE6 !important;
}
.SportboomWalletCellWrapper svg {
  width: 20px;
  height: 20px;
}
.SportboomWalletCellWrapper svg path {
  fill: #228BE6 !important;
}
.SportboomWalletCellWrapper:hover {
  opacity: 0.5;
}
.SportBoomSideWalletModalContainer {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.SportBoomMobileTitle {
  margin-bottom: 12px;
}
.SportboomTableMobileItem {
  border: 1px solid #CED4DA;
  border-radius: 8px;
}
.SportboomTableMobileListPagination {
  margin: 8px -8px -32px -8px;
  padding-bottom: 112px;
}
.SportboomTableMobileItemBetStart {
  margin-left: auto;
}
.SportboomTableMobileItemArrow {
  border-radius: 8px;
  padding: 0 9px;
  height: 32px;
  margin-left: auto;
  transition: transform 500ms ease;
}
.SportboomTableMobileItemArrow svg {
  margin-top: 12px;
}
.SportboomTableMobileItemArrow svg path {
  fill: #495057;
}
.SportboomTableMobileItemArrowClose {
  transform: rotate(0deg);
}
.SportboomTableMobileItemArrowOpen {
  transform: rotate(180deg);
}
.SportboomTableMobileItemDetailStatus {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.SportboomTableMobileItemMain {
  padding: 14px 14px 12px 14px;
  border-top-left-radius: 4px;
  margin-left: 4px;
}
.SportboomTableMobileItemMainActiveDetailClose {
  border-bottom-left-radius: 4px;
}
.SportboomTableMobileItemDetail {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 14px 14px 18px;
}
.SportboomTableMobileItemMainActive {
  border-left: 4px solid #FCE000;
  margin-left: 0 !important;
}
.SportboomTableMobileItemDetailActive {
  border-left: 4px solid #CED4DA;
  padding-left: 14px !important;
}
.SportboomDetailWrapper {
  margin-top: 32px;
}
.SportboomDetailWrapper .SportboomDetailLabel {
  padding-bottom: 16px;
}
.SportboomContentWrapper {
  margin-top: 16px;
}
.SportboomContentSwitcherWrapper {
  margin-top: 32px;
  position: relative;
}
.SportboomContentSwitcherWrapper .SportboomContentSwitcherMargin {
  margin-left: auto;
}
.SportboomContentSwitcherWrapper .SportboomContentTableConfig {
  border: 1px solid #CED4DA;
  border-radius: 8px;
  padding: 12px 16px;
}
.SportboomContentSwitcherWrapper .SportboomContentTableConfig .SportboomContentTableConfigCount {
  padding: 3px 6px;
  height: 22px;
  background: #15AABF;
  border-radius: 24px;
}
.SportboomContentTableConfig:hover {
  cursor: pointer;
  opacity: 0.8;
}
@media screen and (max-width: 920px) {
  .SportboomDetailWrapper .SportboomDetailLabel {
    padding-left: 16px;
  }
  .SportboomContentSwitcherHide {
    display: none !important;
  }
  .SportboomContentSwitcherWrapper {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.DetailTooltipContentWrapper {
  width: 328px;
  padding: 12px;
}
.DetailTooltipContentItem {
  width: 128px;
}
.DetailTooltipContentTitle {
  width: 250px;
}
.SportboomDetailBetSumModal {
  padding: 32px 24px;
  width: 500px;
}
.SportboomDetailBetSumModal .ButtonPrimaryWrapper button {
  width: 100%;
}
.SportboomDetailBetSumModalTable {
  border: 1px solid #CED4DA;
  border-radius: 8px;
}
.SportboomDetailBetSumModalTableItem {
  width: 50%;
  padding: 8px 16px;
}
.SportboomDetailBetSumModalTable .SportboomDetailBetSumModalTableItem:first-child {
  border-right: 1px solid #CED4DA;
}
@media screen and (max-width: 920px) {
  .SportboomDetailBetSumModal {
    width: 100vw !important;
  }
  .SportboomDetailBetSumModalTable .SportboomDetailBetSumModalTableItem:nth-child(2) .SubtitleSecondText {
    padding-top: 12px;
  }
  div.SportBoomDateTimeCell {
    padding-left: 0 !important;
  }
}
.SportBoomWalletModalButtonsContainer {
  display: flex;
  gap: 8px;
}
.SportBoomWalletModalRiskProfileContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}
.SportBoomWalletModalRiskProfileContainer .TooltipComponentTargetWrapper,
.SportBoomWalletModalRiskProfileContainer svg {
  width: 16px !important;
  height: 16px !important;
}
.SportBoomWalletModalContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.SportBoomWalletModalButtonRoger button {
  background: #F1F3F5 !important;
  border-color: #F1F3F5 !important;
}
span.SportBoomWalletModalCellWalletId div {
  color: #212529 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
span.SportBoomWalletModalCellWalletId svg {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
span.SportBoomWalletModalCellWalletId svg path {
  fill: #212529 !important;
}
span.SportBoomWalletModalCellWalletId:hover {
  opacity: unset !important;
}
.SportboomContentWrapper .NewTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  min-height: 100% !important;
}
.SportBoomDateTimeCell {
  display: flex;
  flex-direction: column;
}
.SportBoomDateTimeCell .SportBoomDateTimeCell_time {
  color: #868E96 !important;
}
.SportBoomWalletModalRiskProfileContainerWithHistory {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.SportBoomWalletModalRiskProfileContainerWithHistory {
  flex-flow: wrap;
}
.SportBoomWalletModalRiskProfileContainerWithHistoryOld {
  color: #868E96 !important;
}
.SportBoomWalletModalRiskProfileContainerWithHistoryNew {
  color: #37B24D !important;
}
.SportBoomWalletModalEmptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
}
.SportBoomWalletModalEmptyWrapper svg {
  width: 160px;
  height: 160px;
}
.SportBoomWalletModalContainer_empty .SportBoomWalletModalButtonsContainer {
  justify-content: center;
}
.SportBoomWalletModalContainer_empty {
  margin-top: 32px !important;
}
.SportBoomWalletModal_mobile .SportBoomWalletModalContainer_empty {
  margin-bottom: 12px !important;
}
.SportBoomWalletModal_mobile {
  padding-left: 16px;
  padding-right: 16px;
}
.SportBoomWalletModalEmptyWrapper_title {
  margin-bottom: 8px;
}
.SportBoomWalletModalEmptyWrapper_subtitle {
  color: #868E96 !important;
}
@media screen and (min-width: 921px) {
  .SportBoomWalletModal .ModalFormContent {
    min-width: 677px;
  }
}
.SportBoomWalletModal .ModalFormContent {
  padding: 32px;
}
.SportBoomWalletModal_mobile .ModalFormContent {
  padding: 20px 16px !important;
}
.SportBoomWalletModal_mobile .ModalFormContent .SportBoomWalletModalContainer {
  gap: 16px;
  margin-top: 12px;
}
.SportBoomWalletModal .GraySpinComponent {
  height: 200px;
  padding-top: 32px;
}
.SportBoomWalletModal_empty .ModalFormContent {
  padding-top: 0 !important;
}
.SportBoomWalletModalMobileTable {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;
  border: 1px solid #CED4DA;
  min-width: 100%;
}
.SportBoomWalletModalMobileTable th:not(:last-child),
.SportBoomWalletModalMobileTable td:not(:last-child) {
  border-right: 1px solid #CED4DA;
}
.SportBoomWalletModalMobileTable > tr:not(:last-child) > th,
.SportBoomWalletModalMobileTable > tbody > tr:not(:last-child) > td,
.SportBoomWalletModalMobileTable > tr:not(:last-child) > td,
.SportBoomWalletModalMobileTable > tr:not(:last-child) > th,
.SportBoomWalletModalMobileTable > tbody:not(:last-child) {
  border-bottom: 1px solid #CED4DA;
}
.SportBoomWalletModalMobileTable td {
  padding: 8px 16px;
}
.SportBoomWalletModalMobileTableCellTitle {
  color: #868E96 !important;
  white-space: nowrap;
}
.SportBoomWalletModalMobileTableCell {
  display: flex;
  flex-direction: column;
}
.SportBoomWalletModalMobileTableCellValue {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}
.SportBoomWalletModalMobileTableCellValue .SportBoomWalletModalMobileTableCellValueInner {
  white-space: nowrap;
}
.SportBoomWalletModalMobileTableCellValue .TooltipComponentTargetWrapper svg {
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TooltipComponentWrapper_SportBoomToolTipWrapper {
  z-index: 9999999999999999999 !important;
}
.SportBoomMobileBack {
  margin-top: 16px;
  margin-bottom: 19.5px;
}
.SportboomContentWrapper_modal-opened .Overlay {
  z-index: 9;
}
.SportBoomWalletModalCellWalletId,
.SportBoomDateTimeCell {
  padding-left: 12px !important;
}
.SportBoomCustomFilter {
  padding-top: 24px;
  gap: 8px;
}
@media screen and (max-width: 920px) {
  .SportboomMainTitle {
    margin-left: 0 !important;
  }
}
.InsurancesTable table {
  width: 1398px !important;
  min-width: 1398px !important;
}
.InsurancesTable .ant-table-tbody .ant-table-row:hover {
  cursor: default;
}
.InsurancesTable .ant-table-column-sort {
  background-color: #ffffff;
}
.InsurancesTable .ant-table-column-has-sorters .ant-table-column-sorters,
.InsurancesTable .ant-table-thead > tr > th {
  background-color: #E3FAFC !important;
  min-width: 124px;
}
.InsurancesTable .ant-table-tbody .ant-table-row > td:first-child,
.InsurancesTable .ant-table-container table > thead > tr th:first-child {
  padding-left: 32px !important;
}
.InsurancesTable td.ant-table-cell {
  vertical-align: top;
}
.InsurancesTable td.ant-table-cell:first-child {
  font-weight: 700;
}
.InsurancesTable td.ant-table-cell:nth-last-child(2) {
  vertical-align: middle;
}
.InsurancesDateTimeCell {
  display: flex;
}
.InsurancesDateTimeCell .InsurancesTime {
  margin-left: 10px;
  color: #868E96;
}
.InsurancesMaxPayout {
  font-weight: 700;
}
.InsurancesMore {
  color: #1C7ED6;
  cursor: pointer;
}
.InsurancesDetailWrapper {
  padding: 40px 24px;
}
.InsurancesDetailTable {
  margin-top: 16px;
}
.InsurancesDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
.InsuranceStatusFinished,
.InsuranceStatusPending,
.InsuranceStatusCanceled {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  width: 112px;
  height: 27px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.InsuranceStatusFinished {
  color: #495057 !important;
  background-color: #F1F3F5;
}
.InsuranceStatusCanceled {
  color: #FA5252 !important;
  background-color: #FFF5F5;
}
.InsuranceStatusPending {
  color: #37B24D !important;
  background-color: #EBFBEE;
}
.InsurancesDetailWrapper .InsuranceDatesDescription .ContentPeriodDescription {
  display: none;
}
.InsurancesGamesList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 13px;
}
.InsurancesGamesItem,
.InsurancesMore {
  display: flex;
  align-items: center;
}
.InsurancesGamesItem {
  height: 24px;
}
.InsurancesGamesItem img {
  margin-right: 6px;
  width: 24px;
}
div.InsurancesWinnersButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 143px;
  height: 32px;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  cursor: pointer;
}
div.InsurancesWinnersButton svg {
  margin-right: 8px;
}
div.InsurancesWinnersButton span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}
div.InsurancesWinnersButton:hover {
  border: 1px solid #CED4DA;
  background: #FFF;
}
.InsurancesWinnersEmpty {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  color: #868E96;
}
.InsurancesCellInner {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.InsurancesDetailWrapper span.HeadlineSecondText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.InsurancesDetailWrapper .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
  border-left: 1px solid #E9ECEF;
}
.InsurancesDetailWrapper .ant-table-container table > thead > tr:first-child th:nth-last-child(2) {
  border-top-right-radius: 8px;
}
.InsurancesDetailWrapper .ant-table-container table > thead > tr:first-child th {
  border-top: 1px solid #E9ECEF;
}
.InsurancesDetailWrapper .ant-table-tbody .ant-table-row > td:first-child {
  border-left: 1px solid #E9ECEF;
}
.InsurancesDetailWrapper .ant-table-tbody .ant-table-row > td:last-child {
  border-right: 1px solid #E9ECEF;
}
.InsurancesDetailWrapper .ant-table-header {
  border-top-right-radius: 8px;
  border-right: 1px solid #E9ECEF;
}
.InsurancesDetailSubtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.InsurancesDetailSubtitle .InsuranceStatusFinished,
.InsurancesDetailSubtitle .InsuranceStatusCanceled,
.InsurancesDetailSubtitle .InsuranceStatusPending {
  width: auto;
  background: none;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  height: 30px;
  display: flex;
  align-items: center;
}
.InsurancesDetailSubtitle .ContentPeriodWrapper {
  padding-top: 0;
}
.InsurancesDetailSubtitle .ContentPeriod {
  padding-left: 0;
  margin-top: 0;
}
.InsurancesDetailText,
.InsurancesDetailText .ContentPeriodMain,
.InsurancesDetailText .ContentPeriodSeparatorHideTime,
.InsurancesDetailSeparator {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  color: #868E96;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0;
  white-space: nowrap;
}
.InsurancesDetailSeparator {
  padding: 0 10px;
}
.InsurancesDetailTable .ant-table-tbody .ant-table-row:hover {
  cursor: default;
}
.InsurancesDetailTable tr th.ant-table-cell {
  white-space: nowrap !important;
}
.InsurancesDetailTable table td:first-child,
.InsurancesDetailTable table thead th:first-child {
  padding-left: 24px !important;
}
.ContentPeriodWrapper {
  margin-left: 0 !important;
}
@media screen and (max-width: 920px) {
  .InsurancesTitleWrapper .ContentPeriodWrapper {
    flex-direction: row !important;
  }
  .InsurancesDetailHeader {
    padding-left: 16px;
  }
  .InsurancesDetailWrapper {
    padding: 70px 5px 40px;
  }
  div.TitleText {
    margin-left: 0 !important;
  }
  .ContentPeriodDescription {
    padding-left: 0 !important;
  }
  .ModalRightDetailContent .InsurancesDetailTable table {
    min-width: 350px;
    table-layout: auto !important;
  }
  .ModalRightDetailContent .InsurancesDetailTable table col:last-child {
    width: 1px !important;
  }
  .ModalRightDetailContent .InsurancesDetailTable table tr th.ant-table-cell {
    white-space: pre !important;
  }
  .ModalRightDetailContent .InsurancesDetailTable table tr th.ant-table-cell:last-child {
    padding: 0 !important;
  }
  .ModalRightDetailContent .InsurancesDetailTable table td {
    white-space: nowrap;
  }
  .InsurancesTable table {
    width: auto !important;
    min-width: unset !important;
  }
  .InsurancesTable .InsurancesGamesList {
    min-width: 360px;
  }
  .InsurancesTable .InsurancesGamesList:empty {
    min-width: unset;
  }
  .InsurancesDetailSeparator {
    padding: 0 5px;
  }
  .ModalRightDetail .ModalRightDetailClose svg {
    top: 40px !important;
  }
  .InsurancesDetailSubtitle .ContentPeriodMain {
    color: #868E96 !important;
  }
}
.TopThreePlaceWrapper {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 4px;
  padding: 4px 0;
}
.TopTreeGameImg {
  height: 24px;
  width: 24px;
}
.RaceTableColumnMainTitle {
  text-align: left;
}
.RaceDateTimeCell {
  display: flex;
  flex-direction: column;
}
.RaceDateTimeCell .FlexRow {
  align-items: center;
}
.RaceGameCell_Container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.RaceGameCell_Container_Element_Text {
  white-space: nowrap;
}
.RaceExpandedTableCell {
  padding: 12px 0;
}
.RaceExpandedTableCell .RaceWinnersEmpty {
  margin-left: 20px;
  min-width: 145px;
  max-width: 145px;
}
.RaceGamesTable {
  margin-top: 24px;
}
.RaceModalDetailWrapper {
  display: flex;
  flex-direction: column;
}
.RaceTableModal {
  margin-top: 20px;
}
.RaceStatus {
  padding: 6px 12px;
  border-radius: 4px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RaceStatus__cancelled {
  color: #FA5252;
  background-color: #FFF5F5;
}
.RaceStatus__pending {
  color: #40C057;
  background-color: #EBFBEE;
}
.RaceStatus__failed,
.RaceStatus__finished,
.RaceStatus__waiting {
  color: #495057;
  background-color: #F1F3F5;
}
.RaceCellId {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.RaceCellId .TooltipComponentTargetWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.RaceExpandedTableCell__Text {
  color: #495057 !important;
}
.RaceErrContainer,
.HistoryErrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.RaceErrContainer svg {
  margin-bottom: 24px;
}
.RaceErrContainer .SubtitleSecondText {
  white-space: pre-wrap;
  text-align: center;
}
.RaceErrContainerTextMain {
  margin-bottom: 8px;
}
.RaceExpandedTableCell__Winners {
  justify-content: end;
}
.RaceExpandedTableRow {
  align-items: center;
  margin-left: 50px;
}
.RaceExpandedTableCell_LocalContainer {
  max-width: 114px !important;
  min-width: 114px;
}
.RaceExpandedTableCell_StatusContainer {
  max-width: 323px !important;
  min-width: 323px;
}
.RaceExpandedTableCell_Empty {
  max-width: 603px !important;
  min-width: 603px;
}
.RaceDetailWrapper {
  padding: 32px;
}
.RaceGamesList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 13px;
}
.RaceGamesItem,
.RaceMore {
  display: flex;
  align-items: center;
}
.RaceGamesItem {
  height: 24px;
}
.RaceGamesItem img {
  margin-right: 6px;
  width: 24px;
}
.RaceCellInner {
  display: flex;
  align-items: center;
  min-height: 40px;
}
div.RaceWinnersButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 143px;
  height: 32px;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  cursor: pointer;
}
div.RaceWinnersButton svg {
  margin-right: 8px;
}
div.RaceWinnersButton:hover {
  border: 1px solid #CED4DA;
  background: #FFFFFF;
}
.RaceWinnersEmpty {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  color: #868E96;
}
.RaceMore {
  color: #1C7ED6;
  cursor: pointer;
}
.RaceDetailSubtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.RaceDetailSubtitle .ContentPeriodWrapper {
  padding-top: 0;
}
.RaceDetailSubtitle .ContentPeriod {
  padding-left: 0;
  margin-top: 0;
}
.RaceDetailSubtitle .ContentPeriodMain {
  color: #868E96 !important;
}
.RaceDetailText,
.RaceDetailText .ContentPeriodMain,
.RaceDetailText .ContentPeriodSeparatorHideTime,
.RaceDetailSeparator {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
  color: #868E96;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0;
  white-space: nowrap;
}
.RaceDetailText .ContentPeriodSeparatorHideTime {
  margin: 0 5px;
}
.RaceModalEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-direction: column;
  margin-top: 20%;
}
.RaceModalEmpty svg {
  width: 200px;
  height: 200px;
}
.RaceModalEmpty .RaceModalEmptyText {
  text-align: center;
  color: #495057 !important;
}
.RaceGamesTable__InfoTab .ant-table-row .ant-table-cell:nth-child(2),
.RaceGamesTable__InfoTab .ant-table-thead .ant-table-cell:nth-child(2) {
  padding-left: 0 !important;
}
.RaceGamesTable__HistoryTab .ant-table-thead > tr > th {
  height: 30px !important;
}
.RaceGamesTable__HistoryTab .ant-table-thead tr:nth-child(1) .ant-table-cell:nth-child(1),
.RaceGamesTable__HistoryTab .ant-table-row .ant-table-cell:nth-child(1) {
  padding-left: 32px !important;
}
.RaceGamesTable__HistoryTab .ant-table-thead tr:nth-child(2) .ant-table-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.RaceGamesTable__HistoryTab .ant-table-thead tr:nth-child(1) th:nth-child(3),
.RaceGamesTable__HistoryTab .ant-table-thead tr:nth-child(1) th:nth-child(4) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.RaceExpanded_WinnerCell {
  padding-left: 4px;
}
.MainPageContentWrapper_nominals {
  width: 100% !important;
}
.NominalsContentWrapper {
  margin-top: 24px;
  padding-bottom: 54px;
  max-width: 1400px;
}
.NominalsTableTime {
  color: #868E96;
}
.NominalsTableDate {
  color: #0D0F10;
}
.NominalsTableCommentWrapper {
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NominalsTableCommentContainer {
  display: flex;
  flex-direction: row;
}
.NominalsTableCommentWrapperMore {
  text-transform: lowercase;
  color: #1C7ED6;
  align-self: end;
}
.NominalsTableCommentWrapperMore:hover {
  cursor: pointer;
  opacity: 0.5;
}
.HistoryModalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.HistoryModal {
  padding: 32px !important;
}
.HistoryModalContainer__body {
  word-wrap: break-word;
}
.HistoryModalContainer__footer__button {
  width: 96px;
  height: 40px;
}
.ChangeTabTennisContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.ChangeTabTennisContainer_item {
  width: 200px;
  height: 56px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px #E9ECEF solid;
}
.ChangeTabTennisContainer_item:hover {
  cursor: pointer;
  border: 1px #FCE000 solid;
  outline: 1px solid #FCE000;
}
.ChangeTabTennisContainer_item__active {
  border: 1px #FCE000 solid;
  outline: 1px solid #FCE000;
}
.ChangeTabTennisContainer_tooltip {
  width: 100%;
  height: fit-content;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  border-radius: 8px;
  background: #E7F5FF;
  margin: 16px 0;
  padding: 12px;
}
.ChangeTabTennisContainer_tooltip_text {
  white-space: pre-line;
  color: #228BE6 !important;
}
.ChangeTabTennisContainer_tooltip_text b {
  font-weight: 700 !important;
}
.NominalsTablesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ChangeTabColWrapper {
  display: flex;
  flex-direction: column;
  height: 96px;
  margin-left: 20px;
}
.NominalsBetTitle {
  margin-left: 20px;
}
.ChangeTabColWrapper_TitleImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.ChangeTabColWrapper_TitleImageContainer svg {
  width: 20px;
  height: 20px;
}
.ChangeTabColWrapper_TitleImageContainer_Text-secondary {
  margin-top: 20px;
  color: #868E96 !important;
}
.ChangeTabColNominalChange_Wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.InputWrapper input.ant-input.ChangeTabColNominalChange_Input {
  min-width: 80px !important;
  max-width: 80px !important;
  height: 32px !important;
}
.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max {
  color: #FD7E14 !important;
}
.ChangeTab_Nominals-mock-title {
  margin-top: 16px;
  padding-bottom: 16px !important;
}
.ChangeTab_Nominals-mock-title .TitleDescription .TitleText span {
  font-size: 16px !important;
}
.ChangeTab_Nominals-mock-title .TitleTooltip {
  margin-left: 4px;
}
.ChangeTab_Nominals-mock-title .TitleTooltip .TooltipComponentTargetWrapper svg {
  width: 16px;
  height: 16px;
}
.NominalsBetTable .ant-table-thead tr th {
  background-color: #FFF4E6 !important;
}
.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0 !important;
}
.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper .ant-table-content {
  border-radius: 0 !important;
  border: 0 !important;
}
.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper .ant-table-thead {
  border-radius: 0 !important;
}
.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 0 !important;
}
.NominalsBetTableWrapper {
  width: 100%;
}
.NominalsTemplateCardWrapper {
  width: 692px;
  height: 132px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #E9ECEF;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.NominalsTemplateCardWrapper_Modal {
  padding: 0;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #E9ECEF;
  height: 93px;
}
.NominalsTemplateCardWrapper_MinMax {
  height: 280px;
}
.NominalsTemplateCardWrapper_Modal_MinMax {
  height: 237px;
}
.NominalsTemplateCardContainer {
  padding-bottom: 54px;
}
.NominalsTemplateCard_TitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.NominalsTemplateCard_LeftTitle,
.NominalsTemplateCard_RightTitle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.NominalsTemplateCard_LeftTitle .CheckboxWrapper,
.NominalsTemplateCard_RightTitle .CheckboxWrapper {
  align-items: center;
}
.NominalsTemplateCard_NominalsRow {
  display: flex;
  justify-content: space-between;
}
.NominalsTemplateCard_Cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.NominalsTemplateCard_Cell_Title,
.NominalsTemplateCard_Cell_SubTitle_Currency,
.NominalsTemplateCard_NumbersTable_MinMaxHead,
.NominalsTemplateCard_NumbersTable_Cell_Currency {
  color: #868E96 !important;
}
.NominalsTemplateCard_Cell_SubTitle_Container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.NominalsTemplateCard_Cell_SubTitle_Currency_Max {
  font-weight: 400 !important;
}
.NominalsTemplateCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.NominalsSendButton {
  margin-top: 16px;
}
.NominalsTemplateCard_NumbersTable {
  table-layout: fixed;
}
.NominalsTemplateCard_NumbersTable_Cell_Currency_Wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}
.NominalsTemplateCard_NumbersTable_MinMaxHead {
  white-space: nowrap;
}
.NominalsTemplateCard_NumbersTable_Numbers {
  background-color: #F8F9FA;
}
.NominalsModalContainer {
  min-width: 739px;
  height: fit-content;
  background: #FFFFFF;
  padding: 46px 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
}
.NominalsModalSuccessContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.NominalsModalSuccessContainer button {
  width: 240px;
}
.NominalsModalSuccessTitle {
  white-space: pre;
  text-align: center;
}
.NominalsTemplateCardWrapper_Active {
  border-color: var(--lemon-main, #FCE000);
  outline: 1px solid var(--lemon-main, #FCE000);
}
.ChangeTabWrapper .ant-table-row .ant-table-cell {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ChangeTabWrapper .ErrorInputWrapper .ErrorText {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  line-height: 1.5;
}
.NominalsColumns_GameCell {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.NominalsResultContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.NominalsContentWrapper .ModalContent {
  max-height: 90vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (max-width: 768px) {
  .ChangeTabColWrapper {
    margin-left: 0 !important;
  }
  .ChangeTabWrapper > *:not(.ChangeTabTennisContainer) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .ChangeTabTennisContainer {
    margin-left: 16px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
  }
  .ChangeTabTennisContainer::-webkit-scrollbar {
    display: none;
  }
  .ChangeTabTennisContainer_item {
    margin-right: 10px;
    min-width: 136px;
    width: 136px;
    min-height: 48px;
    height: 48px;
  }
  .ChangeTabTennisContainer_tooltip {
    max-width: 92%;
    border: 1px solid #228BE6;
  }
  .NominalsTemplateCard_NominalsRow {
    flex-wrap: wrap !important;
    gap: 10px !important;
    justify-content: initial !important;
  }
  .NominalsTemplateCard_TitleContainer {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
  .NominalsTemplateCardWrapper {
    height: 100%;
    gap: 12px;
    padding: 16px;
  }
  .NominalsModalContainer {
    min-width: 100% !important;
    padding: 24px 16px;
  }
  .NominalsModalContainer .HeadlineFirstText {
    font-size: 20px !important;
    line-height: normal !important;
  }
  .NominalsTemplateCardWrapper_Modal {
    width: 100% !important;
    border-radius: 16px;
    background: #F8F9FA;
    padding: 16px;
  }
  .NominalsTemplateCardWrapper_Modal .MobileTableCardRowItemTitle {
    padding-right: 8px;
    background: #F8F9FA;
    z-index: 9;
  }
  .NominalsTemplateCardWrapper_Modal .NominalsTemplateCard_Cell_SubTitle_Container {
    padding-left: 8px;
    background: #F8F9FA;
    z-index: 9;
  }
  .NominalsResultContainer {
    gap: 4px;
  }
  .ModalContent::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .GridCenter div.ModalWrapper {
    padding-left: 16px;
    padding-right: 16px;
    width: 100% !important;
  }
}
.MobileTableCardRow {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.MobileTableCardRowItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.MobileTableCardRowItem .InputWrapper {
  max-width: 80px !important;
  width: 80px !important;
  min-width: 80px !important;
}
.MobileTableCardRowItem__WithDots:before {
  content: '';
  position: absolute;
  top: 63%;
  left: 0;
  right: 0;
  height: 2px;
  background: repeating-linear-gradient(90deg, #CED4DA, #CED4DA 2px, transparent 2px, transparent 4px);
  transform: translateY(-50%);
  margin: 0 10px;
  z-index: 8;
}
.MobileTableCardContainer {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
}
.MobileTableCardContainer .MobileTableCardRowItemTitle {
  padding-right: 8px;
}
.MobileTableCardContainer .ChangeTabColNominalChange_Wrapper,
.MobileTableCardContainer .MobileTableCardRowItemTitle {
  z-index: 9;
  background: #FFFFFF;
}
.MobileTableCardContainer .ChangeTabColNominalChange_Wrapper {
  flex-direction: row !important;
  align-items: center;
  padding-left: 8px;
}
.MobileTableCardRowItem_TitleRow {
  height: 20px;
}
.MobileTableCardRowItem_TitleRow .ChangeTabColWrapper {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.MobileTableCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.MobileTableCardLimitsToggleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
}
.MobileTableCardLimitsToggleContainer:hover {
  cursor: pointer;
}
.MobileTableCardLimitsToggleContainer svg {
  margin-top: 3px;
}
.MobileTableCardLimitsToggleContainer .MobileTableCardLimitsToggleContainer_Text {
  color: #228BE6 !important;
}
.MobileTableCardLimitsToggleContainer_shown svg {
  transform: rotate(180deg);
}
.MobileTableCardLimitsSwitchContainer {
  display: flex;
  width: 100%;
  max-width: 311px;
  min-width: 311px;
  padding: 2px;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  border-radius: 5px;
  background: #F4F4F4;
  margin-top: 8px;
}
.MobileTableCardLimitsSwitchContainer .MobileTableCardLimitsSwitchItem {
  display: flex;
  height: 28px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid transparent;
  width: 153px;
}
.MobileTableCardLimitsSwitchContainer .MobileTableCardLimitsSwitchItem_active {
  border-radius: 4px;
  border: 0.5px solid #E9ECEF;
  background: var(--white-100, #FFF);
}
.MobileTableCardRowMinMax {
  display: none;
}
.MobileTableCardRowMinMax__visible {
  display: flex;
}
.NominalsTemplateCardsContainer .ant-carousel {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
}
.NominalsTemplateCardsContainer .ant-carousel .NominalsTemplateCardWrapper {
  max-width: 100%;
}
.NominalsTemplateCardsContainer .ant-carousel .slick-dots li {
  background: #CED4DA;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}
.NominalsTemplateCardsContainer .ant-carousel .slick-dots li button {
  background: #CED4DA;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}
.NominalsTemplateCardsContainer .ant-carousel .slick-dots li .slick-active {
  background: #0D0F10 !important;
}
.NominalsTemplateCardsContainer .ant-carousel .slick-dots li .slick-active button {
  background: #0D0F10 !important;
}
.NominalsTemplateCardsContainer .ant-carousel .slick-dots-bottom {
  bottom: -15px !important;
}
.NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile {
  margin-bottom: -4px;
  color: #868E96 !important;
}
.NominalStatus {
  width: 112px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.NominalStatus_processing {
  color: #FD7E14;
  background: #FFF4E6;
}
.NominalStatus_completed {
  color: #37B24D;
  background: #EBFBEE;
}
.NominalStatus_canceled {
  color: #FA5252;
  background: #FFF5F5;
}
.NominalsContentWrapper .ModalContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.NominalsTemplateCard_NumbersTable_Numbers .NominalsTemplateCard_NumbersTable_Cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
.NominalsTemplateCard_NumbersTable_Cell_Currency {
  font-size: 14px !important;
}
.NominalsTemplateCard_NumbersTable {
  border-spacing: 0 8px;
  position: relative;
}
.NominalsTemplateCard_NumbersTable tbody {
  border-bottom: 8px solid transparent;
}
.NominalsTemplateCard_NumbersTable_Numbers {
  position: relative;
}
.NominalsTemplateCard_NumbersTable_Numbers::before,
.NominalsTemplateCard_NumbersTable_Numbers::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 24px;
  height: 32px;
  background-color: #F8F9FA;
}
.NominalsTemplateCard_NumbersTable_Numbers::before {
  left: -24px;
}
.NominalsTemplateCard_NumbersTable_Numbers::after {
  right: -24px;
}
.ChangeTabWrapper .Overlay {
  z-index: 9;
}
.NominalsTemplateCardWrapper_Modal .ChangeTabColWrapper_TitleImageContainer {
  margin-top: 0;
}
.NominalsModal .ModalCloseWrapper {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.NominalsModal .ModalCloseWrapper svg {
  width: 28px;
  height: 28px;
}
.NominalsTemplateCard_RightTitle .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #E9ECEF !important;
  border-color: #E9ECEF !important;
}
.PayrollFilesModalWrapper .ModalFormContent .ModalTitleWrapper .ModalFormClose {
  margin-top: 4px;
}
.PayrollFilesModalContentWrapper {
  margin-top: 12px;
}
.PayrollFilesItemWrapper {
  border-radius: 8px;
}
.PayrollFilesItemButtons {
  display: none !important;
}
.PayrollFilesItemButtons .HoverItem a svg path {
  fill: #868E96 !important;
}
.PayrollFilesItemWrapper:hover {
  background-color: #F1F3F5 !important;
}
.PayrollFilesItemWrapper:hover .PayrollFilesItemButtons {
  display: inherit !important;
}
.PayrollFilesItemWrapper > .FlexRow > .FlexColumn > .TextDefault {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
.PayrollClearButtonCount {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  margin-top: 2px;
  border-radius: 50%;
}
.PayrollClearModalButton {
  width: 100% !important;
}
.PayrollClearModalButton span {
  font-weight: 600 !important;
}
.PayrollTableFiles:hover {
  cursor: pointer;
}
.PayrollTableFiles:hover span {
  color: #1C7ED6 !important;
}
.PayrollTableFiles:hover svg path {
  fill: #1C7ED6 !important;
}
.PayrollTableEditItem > div > svg:hover {
  cursor: pointer;
}
.PayrollTableEditItem > div > svg:hover path {
  fill: #1C7ED6 !important;
}
.PayrollStatusTableColumn {
  width: 120px;
  border-radius: 4px;
}
.PayrollStatusTableComment {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: default !important;
}
.PayrollStatus-pending {
  background-color: #FFF4E6;
}
.PayrollStatus-pending span {
  color: #FD7E14 !important;
}
.PayrollStatus-pending .PayrollStatusTableComment {
  background-color: #FFE8CC;
}
.PayrollStatus-pending .PayrollStatusTableComment svg path {
  fill: #FF922B;
}
.PayrollStatus-pending .PayrollStatusTableComment svg circle {
  stroke: #FF922B;
}
.PayrollStatus-pending .PayrollStatusTableComment:hover svg path {
  fill: #FD7E14;
}
.PayrollStatus-pending .PayrollStatusTableComment:hover svg circle {
  stroke: #FD7E14;
}
.PayrollStatus-success {
  background-color: #EBFBEE;
}
.PayrollStatus-success span {
  color: #40C057 !important;
}
.PayrollStatus-success .PayrollStatusTableComment {
  background-color: #D3F9D8;
}
.PayrollStatus-success .PayrollStatusTableComment svg path {
  fill: #51CF66 !important;
}
.PayrollStatus-success .PayrollStatusTableComment svg circle {
  stroke: #51CF66 !important;
}
.PayrollStatus-denied {
  background-color: #FFF5F5;
}
.PayrollStatus-denied span {
  color: #FA5252 !important;
}
.PayrollStatus-denied .PayrollStatusTableComment {
  background-color: #FFE3E3;
}
.PayrollStatus-denied .PayrollStatusTableComment svg path {
  fill: #FF6B6B !important;
}
.PayrollStatus-denied .PayrollStatusTableComment svg circle {
  stroke: #FF6B6B !important;
}
.PayrollStatus-failed {
  background-color: #FFF0F6;
}
.PayrollStatus-failed span {
  color: #E64980 !important;
}
.PayrollStatus-failed .PayrollStatusTableComment {
  background-color: #FFDEEB;
}
.PayrollStatus-failed .PayrollStatusTableComment svg path {
  fill: #F06595 !important;
}
.PayrollStatus-failed .PayrollStatusTableComment svg circle {
  stroke: #F06595 !important;
}
.PayrollStatus-delivered {
  background-color: #E3FAFC;
}
.PayrollStatus-delivered span {
  color: #15AABF !important;
}
.PayrollStatus-delivered .PayrollStatusTableComment {
  background-color: #C5F6FA;
}
.PayrollStatus-delivered .PayrollStatusTableComment svg path {
  fill: #22B8CF !important;
}
.PayrollStatus-delivered .PayrollStatusTableComment svg circle {
  stroke: #22B8CF !important;
}
.PayrollStatus-new {
  background-color: #F1F3F5;
}
.PayrollStatus-new span {
  color: #495057 !important;
}
.PayrollStatus-new .PayrollStatusTableComment {
  background-color: #E9ECEF;
}
.PayrollStatus-new .PayrollStatusTableComment svg path {
  fill: #868E96 !important;
}
.PayrollStatus-new .PayrollStatusTableComment svg circle {
  stroke: #868E96 !important;
}
.PayrollTableEditItem svg path {
  fill: #868E96 !important;
}
.PayrollTableRow > td {
  padding: 8px 12px !important;
}
.PayrollModalWrapper {
  padding: 16px 16px 10px 16px;
}
.PayrollModalBottom {
  border-top: 1px solid #DEE2E6;
  margin-bottom: -24px;
}
.UploadComponentFormError,
.UploadComponentFormWrapper {
  width: 58px;
}
.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
  position: absolute;
  width: 360px;
}
.UploadComponentFormWrapper > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
  position: absolute;
  width: 360px;
  display: none !important;
}
.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > span > .ant-upload > span > .UploadAttachmentsButton > button {
  border-color: #FA5252 !important;
}
.PayrollModalFormFirstRow > .ant-form-item {
  width: 100%;
}
.PayrollModalFormSecondRow > .ant-form-item {
  width: 100%;
}
@media screen and (max-width: 920px) {
  .PayrollModalWrapper {
    padding: 0;
    margin-top: -16px;
  }
  .PayrollModalFormFirstRow > .ant-form-item {
    width: 100%;
  }
  .PayrollModalFormSecondRow > .ant-form-item {
    width: 100%;
  }
  .PayrollModalContentWrapper {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin-right: -16px;
    padding-right: 10px;
  }
  .PayrollModalBottom > .ButtonPrimaryWrapper > button {
    width: 100%;
  }
}
.PayrollModalCloseApprove {
  padding-top: 13px !important;
}
.PayrollModalClose {
  position: absolute;
  right: 24px;
  top: 24px;
}
.PayrollsTitleButtons .ButtonFadedWrapper button {
  padding-top: 10px !important;
}
.PayrollsTitleButtons > .ButtonDefaultWrapper > button {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.PayrollsTitleButtons > div > .ButtonDefaultWrapper button,
.PayrollsTitleButtons > div > .ButtonFadedWrapper button,
.PayrollsTitleButtons > .ButtonPrimaryWrapper button {
  height: 40px !important;
}
@media screen and (min-width: 1280px) {
  .PayrollsTitleButtons {
    padding-left: 16px;
  }
}
.QiwiStatusTableColumn {
  width: 80px;
  border-radius: 4px;
}
.QiwiStatusTableComment {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: default !important;
}
.OrderQiwiTableComment {
  height: 24px;
}
.QiwiStatus-pending {
  background-color: #FFF4E6;
}
.QiwiStatus-pending span {
  color: #FD7E14 !important;
}
.QiwiStatus-pending .QiwiStatusTableComment {
  background-color: #FFE8CC;
}
.QiwiStatus-pending .QiwiStatusTableComment svg path {
  fill: #FF922B;
}
.QiwiStatus-pending .QiwiStatusTableComment svg circle {
  stroke: #FF922B;
}
.QiwiStatus-pending .QiwiStatusTableComment:hover svg path {
  fill: #FD7E14;
}
.QiwiStatus-pending .QiwiStatusTableComment:hover svg circle {
  stroke: #FD7E14;
}
.QiwiStatus-approved {
  background-color: #EBFBEE;
}
.QiwiStatus-approved span {
  color: #40C057 !important;
}
.QiwiStatus-approved .QiwiStatusTableComment {
  background-color: #D3F9D8;
}
.QiwiStatus-approved .QiwiStatusTableComment svg path {
  fill: #51CF66 !important;
}
.QiwiStatus-approved .QiwiStatusTableComment svg circle {
  stroke: #51CF66 !important;
}
.QiwiStatus-rejected {
  background-color: #FFF5F5;
}
.QiwiStatus-rejected span {
  color: #FA5252 !important;
}
.QiwiStatus-rejected .QiwiStatusTableComment {
  background-color: #FFE3E3;
}
.QiwiStatus-rejected .QiwiStatusTableComment svg path {
  fill: #FF6B6B !important;
}
.QiwiStatus-rejected .QiwiStatusTableComment svg circle {
  stroke: #FF6B6B !important;
}
.QiwiStatus-canceled {
  background-color: #FFF4E6;
}
.QiwiStatus-canceled span {
  color: #FD7E14 !important;
}
.QiwiStatus-canceled .QiwiStatusTableComment {
  background-color: #FFE8CC;
}
.QiwiStatus-canceled .QiwiStatusTableComment svg path {
  fill: #FF922B !important;
}
.QiwiStatus-canceled .QiwiStatusTableComment svg circle {
  stroke: #FF922B !important;
}
.QiwiStatus-new {
  background-color: #F1F3F5;
}
.QiwiStatus-new span {
  color: #495057 !important;
}
.QiwiStatus-new .QiwiStatusTableComment {
  background-color: #E9ECEF;
}
.QiwiStatus-new .QiwiStatusTableComment svg path {
  fill: #868E96 !important;
}
.QiwiStatus-new .QiwiStatusTableComment svg circle {
  stroke: #868E96 !important;
}
.QiwiModalWrapper {
  max-height: 648px;
  overflow-y: auto;
  padding: 16px 32px 16px 16px;
  margin-right: -18px;
}
@media screen and (max-width: 920px) {
  .QiwiModalWrapper {
    max-height: calc(100vh - 120px);
    padding: 0 8px 0 0 !important;
  }
  .QiwiModalHallsItemRowSelect {
    width: 100%;
  }
}
.QiwiModalFieldsRow > div,
.QiwiModalHallsItemRow,
.QiwiModalHallsItemRow > div,
.QiwiModalHallsItemRow > div > .ant-form-item {
  width: 100%;
}
.QiwiModalHallsItemWrapper {
  padding: 16px 24px 0 24px;
  border-radius: 8px;
  background: #F4F4F4;
}
.ant-form-item-has-error .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content > .QiwiModalHallsItemSumCommission {
  display: none !important;
  background-color: #2f54eb !important;
}
.ant-form-item-has-error .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content .InputMaskWrapper > .ant-input-affix-wrapper {
  border-color: #ff4d4f;
}
.QiwiCancelModalButtons > .ButtonFadedWrapper > button {
  padding-top: 14px !important;
}
.QiwiOrderNewButton .ButtonPrimaryWrapper button {
  height: 40px !important;
}
@media screen and (min-width: 921px) {
  .QiwiOrderNewButton {
    position: absolute;
    right: 0;
  }
}
@media screen and (max-width: 920px) {
  .QiwiOrderNewButton {
    margin: 0 0 16px 26px;
  }
}
.PaymentFilesModalWrapper .ModalFormContent .ModalTitleWrapper .ModalFormClose {
  margin-top: 4px;
}
.PaymentFilesModalContentWrapper {
  margin-top: 12px;
}
.PaymentFilesItemWrapper {
  border-radius: 8px;
}
.PaymentFilesItemButtons {
  display: none !important;
}
.PaymentFilesItemButtons .HoverItem a svg path {
  fill: #868E96 !important;
}
.PaymentFilesItemWrapper:hover {
  background-color: #F1F3F5 !important;
}
.PaymentFilesItemWrapper:hover .PaymentFilesItemButtons {
  display: inherit !important;
}
.PaymentFilesItemWrapper > .FlexRow > .FlexColumn > .TextDefault {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.PaymentClearButtonCount {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  margin-top: 2px;
  border-radius: 50%;
}
.PaymentClearModalButton {
  width: 100% !important;
}
.PaymentClearModalButton span {
  font-weight: 600 !important;
}
.PaymentTableFiles:hover {
  cursor: pointer;
}
.PaymentTableFiles:hover span {
  color: #1C7ED6 !important;
}
.PaymentTableFiles:hover svg path {
  fill: #1C7ED6 !important;
}
.PaymentStatusTableColumn {
  width: 120px;
  border-radius: 4px;
}
.PaymentStatusTableComment {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.PaymentsStatus-paid {
  background-color: #EBFBEE;
}
.PaymentsStatus-paid span {
  color: #40C057 !important;
}
.PaymentsStatus-paid .PaymentStatusTableComment {
  background-color: #D3F9D8;
}
.PaymentsStatus-paid .PaymentStatusTableComment svg path {
  fill: #51CF66 !important;
}
.PaymentsStatus-paid .PaymentStatusTableComment svg circle {
  stroke: #51CF66 !important;
}
.PaymentsStatus-canceled {
  background-color: #FFF5F5;
}
.PaymentsStatus-canceled span {
  color: #FA5252 !important;
}
.PaymentsStatus-canceled .PaymentStatusTableComment {
  background-color: #FFE3E3;
}
.PaymentsStatus-canceled .PaymentStatusTableComment svg path {
  fill: #FF6B6B !important;
}
.PaymentsStatus-canceled .PaymentStatusTableComment svg circle {
  stroke: #FF6B6B !important;
}
.PaymentsStatus-failed {
  background-color: #FFF0F6;
}
.PaymentsStatus-failed span {
  color: #E64980 !important;
}
.PaymentsStatus-failed .PaymentStatusTableComment {
  background-color: #FFDEEB;
}
.PaymentsStatus-failed .PaymentStatusTableComment svg path {
  fill: #F06595 !important;
}
.PaymentsStatus-failed .PaymentStatusTableComment svg circle {
  stroke: #F06595 !important;
}
.PaymentsStatus-accepted {
  background-color: #FFF4E6;
}
.PaymentsStatus-accepted span {
  color: #FD7E14 !important;
}
.PaymentsStatus-accepted .PaymentStatusTableComment {
  background-color: #FFE8CC;
}
.PaymentsStatus-accepted .PaymentStatusTableComment svg path {
  fill: #FF922B !important;
}
.PaymentsStatus-accepted .PaymentStatusTableComment svg circle {
  stroke: #FF922B !important;
}
.PaymentsStatus-new {
  background-color: #F1F3F5;
}
.PaymentsStatus-new span {
  color: #495057 !important;
}
.PaymentsStatus-new .PaymentStatusTableComment {
  background-color: #E9ECEF;
}
.PaymentsStatus-new .PaymentStatusTableComment svg path {
  fill: #868E96 !important;
}
.PaymentsStatus-new .PaymentStatusTableComment svg circle {
  stroke: #868E96 !important;
}
.PaymentTableEditItem svg path {
  fill: #868E96 !important;
}
.PaymentTableEditItem:hover {
  cursor: pointer;
  opacity: 0.5;
}
.PaymentsTableCommentWrapper {
  max-width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.PaymentsTableCommentWrapper_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.PaymentsTableCommentContainer {
  display: flex;
  flex-direction: row;
}
.PaymentsTableCommentWrapperMore {
  text-transform: lowercase;
  color: #1C7ED6;
  align-self: end;
}
.PaymentsTableCommentWrapperMore:hover {
  cursor: pointer;
  opacity: 0.5;
}
.PaymentsPopover_hidden {
  display: none;
}
.PaymentModalWrapper {
  padding: 0 16px 16px 16px;
}
.PaymentModalBottom {
  border-top: 1px solid #DEE2E6;
  margin-bottom: -24px;
}
.UploadComponentFormError,
.UploadComponentFormWrapper {
  width: 58px;
}
.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
  position: absolute;
  width: 360px;
}
.UploadComponentFormWrapper > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
  position: absolute;
  width: 360px;
  display: none !important;
}
.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > span > .ant-upload > span > .UploadAttachmentsButton > button {
  border-color: #FA5252 !important;
}
.PaymentModalFormFirstRow > .ant-form-item {
  width: 50%;
}
.PaymentModalFormSecondRow > .ant-form-item {
  width: 100%;
}
@media screen and (max-height: 799px), screen and (max-width: 920px) {
  .PaymentModalWrapper {
    padding: 0;
    margin-top: -16px;
  }
  .PaymentModalFormFirstRow > .ant-form-item {
    width: 100%;
  }
  .PaymentModalFormSecondRow > .ant-form-item {
    width: 100%;
  }
  .PaymentModalContentWrapper {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    margin-right: -16px;
    padding-right: 10px;
  }
  .PaymentModalBottom > .ButtonPrimaryWrapper > button {
    width: 100%;
  }
}
.PaymentModalRangePicker .RangePickerWrapper .ant-picker {
  min-height: 48px;
  max-height: 48px;
}
.PaymentDivider {
  margin: 8px 0;
}
.PaymentSpace {
  padding: 0 8px 4px;
}
.PaymentTitleButtons .ButtonFadedWrapper button {
  padding-top: 12px !important;
  display: flex;
  justify-content: center;
}
.PaymentTitleButtons .ButtonFadedWrapper button span {
  font-weight: 600 !important;
}
.PaymentTitleButtons > .ButtonDefaultWrapper > button {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.PaymentTitleButtons > div > .ButtonDefaultWrapper button,
.PaymentTitleButtons > div > .ButtonFadedWrapper button,
.PaymentTitleButtons > .ButtonPrimaryWrapper button {
  height: 40px !important;
}
@media screen and (min-width: 1280px) {
  .PaymentTitleButtons {
    padding-left: 16px;
  }
}
.PaymentGiftIcon {
  width: 20px;
  height: 20px;
}
.PaymentTriggerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.NotifyStatusTableConfirmModalButton {
  height: 48px !important;
}
.NotifyStatusTableConfirmModalButton > span {
  padding-top: 6px;
}
.NotifyStatusTableConfirmModalApprove > button {
  padding: 0 24px !important;
}
.NotifyStatusTableWrapper {
  max-height: calc(100vh - 355px);
  height: calc(100vh - 355px);
  margin-bottom: 32px;
  overflow-y: auto;
}
.NotifyStatusTableStatus {
  width: 88px;
  height: 27px;
  border-radius: 4px;
}
.NotifyStatusTableEmail {
  max-width: 194px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
}
.NotifyStatusTableButtons {
  border-top: 1px solid #F1F3F5;
}
.NotifyStatusTableButtonsSvgItem div {
  color: #0D0F10 !important;
}
.NotifyStatusTableButtonsSvgItem > span {
  padding-top: 3px !important;
}
.NotifyStatusTableButtonsSvgItem > span,
.NotifyStatusTableButtonsAddItem > span {
  font-weight: 600 !important;
}
.NotifyStatusTableButtonsItem:hover button .NotifyStatusTableButtonsSvgItem div {
  color: #0D0F10 !important;
}
@media screen and (max-width: 920px) {
  .NotifyStatusTableContent {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  .NotifyStatusTableContent::-webkit-scrollbar {
    display: none !important;
  }
}
.StatusNotifyUserCrudTitle > .ButtonDefaultWrapper > button {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.StatusNotifyUserCrudTitle > .ButtonDefaultWrapper > button svg path {
  fill: #0D0F10;
}
.StatusNotifyUserCrudContent {
  height: calc(100vh - 176px);
}
.StatusNotifyUserCrudButtons {
  border-top: 1px solid #F1F3F5;
}
.StatusNotifyModal > .ModalRightDetail > .ModalRightDetailClose {
  top: 12px;
}
.StatusNotifyHallPicker {
  max-width: 760px;
  overflow-x: auto;
}
.StatusNotifyHallPickerPending .StatusNotifyHallPickerItem {
  background-color: #F1F3F5 !important;
  cursor: auto !important;
}
.StatusNotifyHallPickerItem {
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  padding: 12px 16px;
}
.StatusNotifyHallPickerItem:hover {
  cursor: pointer;
  border-color: #FCE000;
}
.StatusNotifyHallPickerItemActive {
  border-color: #FCE000 !important;
}
@media screen and (max-width: 920px) {
  .StatusNotifyHallPicker::-webkit-scrollbar {
    display: none !important;
  }
}
.HistoryCustomFilter_Range {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
}
.HistoryBetCell {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  max-width: 250px;
}
.HistoryBetCell > div {
  flex: 0 0 calc(10% - 4px);
  position: relative;
}
.HistoryBetCell_NumberGreen {
  color: #37B24D;
}
.HistoryBetsDateTimeCell {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.HistoryBetsDateTimeCellWrapper {
  display: flex;
  flex-direction: column;
}
.HistoryBetsDateTimeCellDate_Time {
  color: #868E96 !important;
}
.HistoryBetsDetailContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.HistoryBetsDetailContentContainer .DetailTableComponentWrapper {
  margin-bottom: 8px;
}
.HistoryBetRed {
  color: #F03E3E !important;
}
.HistoryBetsCustomFilter .ant-input-prefix {
  color: #ADB5BD !important;
}
.HistoryBetsDevelopment {
  color: #868E96 !important;
}
.HistoryErrContainer .HistoryErrSubContainer {
  max-width: 100%;
  width: 800px;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}
.HistoryErrContainer .HistoryErrSubContainer div {
  white-space: pre-line;
  text-align: center;
}
.HistoryErrContainer .HistoryErrSubContainer .SubtitleSecondText {
  color: #868E96 !important;
}
.HistoryBetsMore {
  white-space: nowrap;
  color: #1C7ED6;
}
.HistoryBetCell > div:not(:last-child)::after {
  content: ',';
}
.HistoryBetsPeriod {
  display: flex !important;
  align-items: center !important;
}
.HistoryBetsPeriod .ContentPeriod {
  padding-left: 0;
}
@media screen and (max-width: 910px) {
  div.HistoryBetsPeriod {
    flex-direction: row !important;
  }
}
.HistoryCustomFilter_RangeWin {
  padding-top: 8px;
}
.HistoryLiveCell {
  color: #868E96 !important;
}
.HistoryLiveCellWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.RedCircleHistory {
  width: 4px;
  height: 4px;
  background-color: #FA5252;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #FFC9C9;
}
.RegistrationCheckMobileTableWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 16px;
}
.RegistrationCheckMobileTableHeader {
  border-top-right-radius: 16px;
  border-bottom: 1px solid #E9ECEF;
  border-top-left-radius: 16px;
  padding: 14px 20px;
}
.RegistrationCheckMobileTableContent {
  padding: 15px 20px 20px 20px;
}
.RegistrationCheckMobileTableContent div {
  width: 90px;
}
.PromotionWrapper .ComponentDashboardItem {
  margin-top: 0px;
  margin-bottom: 24px;
}
.PromotionWrapperInActive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 150px);
}
@media screen and (max-width: 920px) {
  .PromotionWrapper .TitleWrapper {
    padding-bottom: 16px !important;
  }
  .PromotionWrapper .ComponentDashboardWrapper {
    padding: 0 16px;
    gap: 16px;
  }
  .PromotionWrapper .ComponentDashboardItem {
    margin-bottom: 16px;
  }
  .PromotionWrapper .PaginationTableComponentWrapper .ant-table-content table {
    background: #ffffff;
    border-top: 1px solid #E9EAEA;
    border-radius: 8px;
  }
  .PromotionWrapper .PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
    border-right: none !important;
    border-top: none !important;
  }
}
.BetBonusEmptyDataWrapper {
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.BetBonusFilter .ant-picker-range-separator {
  padding: 0px 4px;
}
.BetBonusFilter .ant-picker-range > .ant-picker-input {
  max-width: 95px;
}
.BetBonusFilter .RangePickerWrapper .ant-picker {
  padding: 10px !important;
}
.PromotionWrapper .ComponentDashboardItem {
  margin-top: 0px;
  margin-bottom: 24px;
}
.BetBonusFilter .ant-picker-range-separator {
  padding: 0px 4px;
}
.BetBonusFilter .ant-picker-range > .ant-picker-input {
  max-width: 95px;
}
.BetBonusFilter .RangePickerWrapper .ant-picker {
  padding: 10px !important;
}
.BetBonusEmptyDataWrapper {
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 920px) {
  .PromotionWrapper .TitleWrapper {
    padding-bottom: 16px !important;
  }
  .PromotionWrapper .ComponentDashboardWrapper {
    padding: 0 16px;
    gap: 16px;
  }
  .PromotionWrapper .ComponentDashboardItem {
    margin-bottom: 16px;
  }
  .PromotionWrapper .PaginationTableComponentWrapper .ant-table-content table {
    background: #ffffff;
    border-top: 1px solid #E9EAEA;
    border-radius: 8px;
  }
  .PromotionWrapper .PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
    border-right: none !important;
    border-top: none !important;
  }
}
.SportsBroadcast__InfoWrapper {
  border-radius: 8px;
}
.SportsBroadcast__InfoWrapper .SportsBroadcast__InfoWrapper__Icon {
  margin-top: 2px;
}
.SportsBroadcast__InfoWrapper .SportsBroadcast__InfoWrapper__Icon svg path {
  fill: #228BE6;
}
@media screen and (max-width: 920px) {
  .SportsBroadcast__InfoWrapper__Icon {
    min-width: 20px;
  }
}
@media screen and (min-width: 921px) {
  .SportsBroadcast__ModalWrapper {
    padding: 0 16px;
  }
  .SportsBroadcast__ModalWrapper_FormItem_Button > .ant-form-item {
    margin-bottom: 16px !important;
  }
}
.SportsBroadcast__ModalWrapper_FormItem .ant-form-item {
  margin-bottom: 18px !important;
}
@media screen and (max-width: 920px) {
  .SportsBroadcast__ModalWrapper {
    margin-top: -24px;
  }
  .SportsBroadcast__ModalWrapper .HeadlineFirstText {
    padding-bottom: 14px !important;
  }
  .SportsBroadcast__ModalWrapper_FormItem > .ant-form-item {
    margin-bottom: 14px !important;
  }
  .SportsBroadcast__ModalWrapper_FormItem_Button > .ant-form-item {
    margin-bottom: 0 !important;
  }
}
.SportsBroadcast__TitleButton .ButtonPrimaryWrapper button {
  height: 40px;
}
@media screen and (max-width: 920px) {
  .SportsBroadcast__TitleButton {
    margin-left: -10px;
    padding-top: 12px;
  }
}
.SportsBroadcast__Table__Cell__Lock {
  width: 20px;
}
.Geoservices {
  gap: 16px;
}
.Geoservices_InformationWrapper {
  background-color: #E7F5FF;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.Geoservices_InformationWrapper__Rules {
  display: flex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
  color: #228BE6;
}
.Geoservices_InformationWrapper__Rules span {
  font-weight: 700;
}
.Geoservices_InformationWrapper__Info {
  color: #228BE6;
  font-weight: 700;
}
.Geoservices_InformationWrapper__Button {
  background-color: #F1F3F5 !important;
  border: transparent !important;
}
.Geoservices_InformationWrapper__Button .ant-btn.ant-btn-default {
  background: transparent !important;
}
.Geoservices_Photos {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.Geoservices_Photos__Info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
.Geoservices_Photos__Info_Icon path {
  fill: #868E96;
}
.Geoservices_Photos__Info_Text {
  color: #868E96;
}
.Geoservices_Photos__Info_Text-Valid {
  color: #40C057;
}
.Geoservices_Photos__Upload {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.Interior {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.UploadComponent__Uploaded .ant-upload.ant-upload-select-picture-card {
  border: none;
}
.UploadComponent__Delete {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(33, 37, 41, 0.75);
  cursor: pointer;
}
.UploadComponent__Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.UploadComponent__Image {
  max-width: 180px !important;
  max-height: 120px;
  display: block;
  width: 100% !important;
  object-fit: cover;
  border-radius: 8px;
}
.UploadComponent .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 8px;
  min-width: 180px;
}
.UploadComponent .ant-select .ant-select-single.ant-select-selector {
  align-items: center;
}
.UploadComponent .ant-upload {
  flex-direction: column;
  min-width: 180px;
  border-radius: 8px;
  min-height: 120px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: #212529;
}
.UploadComponent .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
.UploadComponent > .ant-upload-select-picture-card {
  margin: 0 !important;
}
.RulesPopup .ant-modal-content {
  width: 800px;
  border-radius: 8px;
}
.RulesPopup .ant-modal-content .ant-modal-body {
  padding: 40px;
}
.RulesPopup .ant-modal-content ul {
  margin-bottom: 0;
  padding-left: 15px;
}
@media screen and (max-width: 920px) {
  .RulesPopup__Wrapper {
    overflow-y: scroll;
    max-height: 78vh;
    padding-left: 2px;
  }
  .RulesPopup ::-webkit-scrollbar {
    width: 4px;
  }
  .RulesPopup ::-webkit-scrollbar-thumb {
    background: #CED4DA;
    border-radius: 8px;
  }
  .RulesPopup ::-webkit-scrollbar-track {
    background: transparent;
  }
  .RulesPopup .ant-modal-content {
    max-width: 100%;
    max-height: 716px;
  }
  .RulesPopup .ant-modal-content .ant-modal-body {
    padding: 24px 16px 24px 14px;
  }
  .Geoservices {
    gap: 8px;
  }
  .Geoservices button {
    margin-left: 16px;
  }
  .Geoservices_Photos {
    padding: 16px;
  }
  .Geoservices_Photos__Upload {
    gap: 15px;
  }
  .Geoservices_Photos__Upload .SelectWrapper {
    margin-bottom: 4px;
  }
  .Geoservices_InformationWrapper {
    flex-direction: column;
    gap: 16px;
  }
  .UploadComponent .ant-upload {
    min-width: 164px;
    min-height: 109px;
  }
  .UploadComponent .ant-select .ant-select-selector {
    width: 164px !important;
    min-width: unset;
  }
}
.Geoservices_ConfirmPopup__Button {
  background-color: #F1F3F5 !important;
  border: none;
}
.Geoservices_ConfirmPopup__Button span {
  font-family: 'Gilroy', sans-serif;
  font-size: 16px !important;
}
.Geoservices_ConfirmPopup__Delete {
  background-color: #FA5252 !important;
}
.Geoservices_ConfirmPopup__Delete span {
  color: #FFFFFF !important;
}
.Geoservices_ConfirmPopup__Footer {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 8px;
}
.Geoservices_ConfirmPopup .ant-modal-content {
  max-width: 480px;
  border-radius: 16px;
}
.Geoservices_ConfirmPopup .ant-modal-content .ant-modal-body {
  padding: 32px;
}
.Geoservices_ConfirmPopup .ant-modal-content ul {
  margin-bottom: 0;
  padding-left: 15px;
}
.GuestFiltersTableTdPlaceTop {
  position: absolute;
  top: 16px;
}
.GuestFiltersTableGameImg {
  width: 24px;
  height: 24px;
}
.AccessCardsItem {
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
  width: 289px;
  padding: 16px;
}
.AccessCardsItemFullName {
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
  display: flex;
  column-gap: 8px;
  line-height: normal;
}
.AccessCardsItemLogin {
  word-break: break-word;
}
.AccessCardsItemHalls {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AccessCardsItemStatus {
  height: 28px;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 2px;
}
.AccessCardsItemStatus_active {
  background: #EBFBEE;
  color: #37B24D;
}
.AccessCardsItemStatus_paused {
  background: #FFF5F5;
  color: #F03E3E;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.AccessCardsItemStatus_resuming {
  background: #FFF4E6;
  color: #F76707;
}
.AccessCardsItemStatusTooltip {
  height: 28px;
  background: #FFE3E3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.AccessCardsItemButton {
  padding: 8px;
  background: #F1F3F5;
  border-radius: 4px;
  height: 36px;
  width: 36px;
}
.AccessCardsItemButtonDelete svg path {
  fill: #212529;
}
.AccessCardsItemButton:hover {
  opacity: 0.8;
  cursor: pointer;
}
@media screen and (max-width: 920px) {
  .AccessCardsItem {
    width: calc(100vw - 32px);
    margin-left: 16px;
  }
}
.RemoveAccessButtons .ButtonNotifiGray button {
  width: 108px;
  padding: 0 !important;
}
.RemoveAccessButtons .ButtonNotifiRed button {
  width: 140px;
  padding: 0 !important;
}
.AccessEmptyCarsList {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
}
.AccessCardsTooltipIconWrapper {
  position: relative;
  top: 2px;
}
.AccessModalContent {
  border-radius: 8px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
  padding: 40px 32px;
}
@media screen and (min-width: 921px) {
  .AccessModalContentColumn {
    width: 33%;
  }
}
.AccessDashboardSelectItemWrapper {
  background: #FFFFFF !important;
}
.AccessDashboardSelectItemWrapper .ant-select-item-option-state {
  display: none;
}
.AccessDashboardSelectItemWrapper .ant-select-item-option-content div .CheckboxWrapper {
  margin-top: 0 !important;
}
.AccessDashboardSelectItemWrapper .ant-select-item-option-content div .CheckboxWrapper .CheckboxText {
  padding-left: 0 !important;
}
.AccessDashboardSelectItemWrapper .ant-select-item-option-content div div {
  margin-top: 3px;
}
.AccessDashboardTag {
  padding: 6px 12px;
  border-radius: 8px;
  background: #F4F4F4;
}
.AccessDashboardTag > div {
  height: 20px;
}
.AccessDashboardSelectWrapper .ant-select .ant-select-selector .ant-select-selection-overflow {
  gap: 8px;
}
.CrudAccessMenuWrapper {
  border-radius: 8px;
  border: 1px solid #CED4DA;
  align-content: flex-start;
  height: 100%;
}
.CrudAccessMenuWrapper .ant-tree-checkbox.ant-tree-checkbox-checked.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-image: url(/Components/icons/check/checkbox-disabled_20.svg) !important;
}
.CrudAccessMenuWrapper .ant-tree-checkbox.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #E9ECEF !important;
  border: none !important;
  background-repeat: no-repeat;
  background-position: center;
}
.AccessMenuTagsWrapper {
  border-radius: 8px;
  background: #F4F4F4;
  padding: 6px 12px;
  height: 32px;
}
.CustomTemplateNameRadio {
  display: flex !important;
  flex-direction: row;
  margin-top: -8px !important;
}
.MenuTagsItemLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.AccessMenuMobileWrapper {
  height: 48px !important;
}
.AccessMenuMobileWrapper .AccessMenuMobileSelectWrapper {
  height: 48px !important;
}
.AccessMenuMobileWrapper .AccessMenuMobileSelectWrapper .ant-select {
  height: 48px !important;
}
.AccessMenuMobileWrapper .AccessMenuMobileSelectWrapper .ant-select .ant-select-selector {
  height: 48px !important;
}
.AccessCrudMenuTags {
  height: 100%;
}
.AccessCrudPasswordSend {
  margin-top: -4px;
}
.AccessCrudPasswordSend .ant-form-item .ant-form-item-row .ant-form-item-control-input {
  min-height: 32px !important;
}
.AccessDashboardIcon {
  height: 20px;
  width: 20px;
}
.AccessModalInputGroup {
  margin-bottom: 24px;
}
.AccessModalInputGroup .ant-form-item {
  margin-bottom: 6px;
}
.ant-select-item.ant-select-item-group:has(.AccessDividerWrapper) {
  color: #ADB5BD !important;
  min-height: 11px;
  padding-top: 0;
  padding-bottom: 0;
}
.AccessDividerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AccessDividerInner {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 11px;
  font-size: 11px;
}
.AccessDividerInner:before,
.AccessDividerInner:after {
  background-color: #CED4DA !important;
  content: '';
  position: absolute;
  width: calc(50% - 40px);
  height: 1px;
  top: 5px;
}
.AccessDividerInner:before {
  left: -12px;
}
.AccessDividerInner:after {
  right: -12px;
}
.AccessModalNewPosition {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 4px;
}
.AccessModalNewPosition svg {
  display: none;
}
.ant-select-dropdown .AccessModalNewPosition svg {
  display: block;
}
.AccessModalFormWrapper {
  padding: 12px 12px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AccessModalButtonContainer {
  flex: 0 0 90px;
}
.AccessModalButtonContainer .ant-btn-primary .ant-btn,
.AccessModalButtonContainer .ButtonPrimaryWrapper .ant-btn {
  height: 40px;
  width: 100%;
  padding: 0;
}
.AccessModalInputContainer {
  flex: 0 0 calc(100% - 102px);
}
.AccessModalInputContainer .InputWrapper > .ant-input,
.AccessModalInputContainer .InputWrapper > .ant-input-number,
.AccessModalInputContainer .InputWrapper > .ant-input-affix-wrapper,
.AccessModalInputContainer .InputWrapper .ant-input-number-input {
  height: 40px !important;
}
.AccessModalUnemployedCheckbox.ant-checkbox-wrapper-disabled span .ant-checkbox-inner {
  background-color: #E9ECEF !important;
}
.AccessModalWrapper {
  min-height: auto !important;
  padding-bottom: 32px !important;
}
.AccessBaseTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  white-space: pre-wrap;
}
@media screen and (min-width: 921px) {
  .AccessAddButton {
    margin-left: auto;
  }
}
@media screen and (max-width: 920px) {
  .AccessAddButton {
    margin: 16px 0 0 16px;
  }
  .AccessBaseTitle {
    width: fit-content !important;
    margin-left: 16px;
  }
}
.YandexStatusTableColumn {
  width: 120px;
  border-radius: 4px;
}
.YandexStatusTableComment {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.YandexStatus-uploaded {
  background-color: #EBFBEE;
}
.YandexStatus-uploaded span {
  color: #40C057 !important;
}
.YandexStatus-uploaded .YandexStatusTableComment {
  background-color: #D3F9D8;
}
.YandexStatus-uploaded .YandexStatusTableComment svg path {
  fill: #51CF66 !important;
}
.YandexStatus-uploaded .YandexStatusTableComment svg circle {
  stroke: #51CF66 !important;
}
.YandexStatus-absent {
  background-color: #FFF5F5;
}
.YandexStatus-absent span {
  color: #FA5252 !important;
}
.YandexStatus-absent .YandexStatusTableComment {
  background-color: #FFE3E3;
}
.YandexStatus-absent .YandexStatusTableComment svg path {
  fill: #FF6B6B !important;
}
.YandexStatus-absent .YandexStatusTableComment svg circle {
  stroke: #FF6B6B !important;
}
.YandexContentTitleWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 16px !important;
}
.YandexContentTitleWrapper.YandexContentTitleWrapperExcel {
  padding-bottom: 0 !important;
}
.YandexContentTitleWrapper .TitleTooltip {
  margin-left: 5px;
}
.YandexContentTitleWrapper .TitleDescription {
  min-height: 48px;
}
.YandexContentTitleWrapper .ButtonDefaultWrapper .ant-btn,
.YandexContentTitleWrapper .ButtonPrimaryWrapper .ant-btn {
  width: 141px;
  height: 40px;
}
@media screen and (max-width: 994px) {
  .YandexContentTitleWrapper .TitleText {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 920px) {
  .YandexContentTitleWrapper {
    min-height: 95px;
    align-items: flex-start;
  }
  .YandexContentTitleWrapper .TitleText {
    margin-bottom: 16px;
  }
  .YandexContentTitleWrapper .TitleWrapper {
    padding-bottom: 8px !important;
  }
  .YandexContentTitleWrapper .TitleRightAdditional {
    margin-left: 0 !important;
  }
}
.YandexMainPageContentWrapper .MailingContentTabsItem {
  letter-spacing: 0.2px;
}
.YandexMainPageContentWrapper .StatusText {
  font-family: "Gilroy", sans-serif !important;
}
.YandexMainPageContentWrapper .MailingContentTabsRestriction {
  margin-bottom: 24px;
}
.YandexMainPageContentWrapper .MainPageContentRestrictions {
  margin-left: 0;
}
.YandexMainPageContentWrapper .YandexContentTitleWrapperExcel .TitleDescription .MailingContentTitleRight {
  margin-left: 12px;
}
.YandexMainPageContentWrapper .MainPageContentWrapper {
  padding: 0 !important;
  background: transparent !important;
}
.YandexMainPageContentWrapper .NewTableComponentWrapper {
  max-height: 80vh;
  overflow-y: auto;
}
.YandexMainPageContentWrapper .PaginationTableComponentWrapper .PaginationTableComponentTotalRow td.ant-table-cell {
  background-color: transparent !important;
}
@media screen and (max-width: 920px) {
  .YandexMainPageContentWrapper .MailingContentTabs {
    padding-left: 16px;
  }
  .YandexMainPageContentWrapper .MailingContentTabsRestriction {
    margin-bottom: 12px;
  }
}
.YandexFileName {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.YandexFileName > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.YandexFileName svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.YandexFileName svg path {
  fill: #868E96;
}
.YandexPayrollTableEditItem {
  min-width: 20px;
}
.YandexPayrollTableEditItem svg path {
  fill: #868E96;
}
.YandexFileAttachedModal .FileItemWrapper {
  width: 100%;
  margin: 16px 0 0 0;
}
.YandexFileAttachedModal .FileItemTextSize {
  font-size: 12px;
}
.YandexFileAttachedModal .ModalFormClose svg {
  width: 24px;
  height: 24px;
}
.YandexModalAddFile .ModalFormContent {
  padding: 40px;
}
.YandexModalAddFile .ant-picker {
  height: 48px !important;
}
@media screen and (max-width: 920px) {
  .YandexModalAddFile .ModalFormContent {
    min-height: 440px;
    padding: 24px 16px;
  }
  .YandexModalAddFile .PaymentModalContentWrapper {
    overflow: unset;
  }
  .YandexModalAddFile .ant-form-item {
    margin-bottom: 16px !important;
  }
  .YandexModalAddFile .PaymentModalBottom {
    border: none;
    margin-top: 0;
  }
  .YandexModalAddFile .ButtonPrimaryWrapper {
    order: 2;
  }
  .YandexModalAddFile .YandexModalFileDescription {
    order: 1;
  }
}
.YandexFileAbsent svg path {
  fill: #FA5252 !important;
}
.YandexFileAbsent svg circle {
  stroke: #FA5252 !important;
}
.YandexFileAbsentError {
  color: #FA5252;
}
.YandexReport_Table_Column .TitleTooltip {
  top: 3px !important;
  margin-left: 4px !important;
}
.YandexDescription .NewDashboard {
  padding: 16px 0 0 0;
}
.YandexDescription .NewDashboard_Item {
  padding: 16px;
}
.YandexDescription .QiwiModalHeader {
  padding-top: 12px;
}
.YandexDescription .QiwiModalTableHeader,
.YandexDescription .QiwiModalTableBody {
  margin-left: 8px;
  margin-right: 8px;
}
.YandexDescription .QiwiModalTableHeaderItem,
.YandexDescription .QiwiModalTableRowItem {
  flex-basis: 50%;
}
.YandexDescription .ModalCloseWrapper {
  position: absolute;
  top: 10px;
  right: -16px;
}
.YandexDescription .ModalCloseWrapper svg {
  visibility: hidden;
}
.YandexDescription .ModalCloseWrapper:before,
.YandexDescription .ModalCloseWrapper:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 24px;
  background: #868E96;
  top: 10px;
  right: -2px;
}
.YandexDescription .ModalCloseWrapper:before {
  transform: rotate(45deg);
}
.YandexDescription .ModalCloseWrapper:after {
  transform: rotate(-45deg);
}
.YandexDetailRowClickable {
  cursor: pointer !important;
}
.YandexDetailWrapper {
  padding: 40px 24px;
}
.YandexDetailTable {
  margin-top: 16px;
}
.YandexDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
.YandexAddressColumnItem {
  display: block !important;
}
.YandexAddressColumnItem div {
  display: inline;
}
.YandexDescriptionDetailsItem div {
  display: inline;
}
.YandexDescriptionDetailsItemToLeft div {
  padding-left: 0px !important;
}
@media screen and (max-width: 920px) {
  .YandexDescription .NewDashboard {
    margin-left: 0;
    padding-bottom: 16px;
  }
  .YandexDescription div.ModalWrapper,
  .YandexDescription div.ModalFormWrapper {
    left: 16px !important;
    right: 16px !important;
    width: auto !important;
  }
  .YandexDescription .QiwiModalHeader {
    width: auto !important;
    padding-top: 24px;
  }
  .YandexDescription .ModalCloseWrapper {
    right: 22px;
  }
  .YandexDescription .QiwiModalTableHeader,
  .YandexDescription .QiwiModalTableBody {
    margin-left: 16px;
    margin-right: 16px;
  }
  .YandexDescription .QiwiModalWrapper {
    margin-right: 0;
    padding: 0 !important;
  }
  .YandexDescription .QiwiModalTableBody {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 920px) {
  .YandexWrapper {
    margin: 0 16px;
  }
  .YandexTitleExel {
    margin-top: -8px;
    margin-bottom: 8px;
  }
}
.BaseLayoutWrapper {
  background-color: #1C1C1E;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.BaseLayoutWrapperWiki {
  background-color: #4A5057 !important;
}
.BaseLayoutContentWrapper {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
}
.BaseLayoutLeftMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.BaseLayoutLeftMenuWiki {
  background-color: #4A5057 !important;
}
.BaseLayoutLeftMenuWikiWrapperText {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.19984514px;
  text-align: left;
  margin-left: 12px;
  color: #FFFFFF;
}
.BaseLayoutLeftMenuWikiWrapperRoot {
  display: flex;
  flex-direction: column;
  margin: 31px 32px;
}
.BaseLayoutLeftMenuWikiWrapper {
  display: flex;
  align-items: center;
}
.BaseLayoutLeftMenuWikiWrapper:hover {
  cursor: pointer;
}
.BaseLayoutLeftMenuWikiWrapperBackButton {
  margin-bottom: 30px;
  width: 100%;
  background-color: #4A5057 !important;
  color: #FFFFFF;
}
.BaseLayoutLeftMenuWikiWrapperBackButton span {
  color: #FFFFFF !important;
}
.BaseLayoutContentFooterMobile,
.BaseLayoutContentFooter {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: #808889;
  padding: 8px 0;
}
.BaseLayoutContentFooterMobile {
  font-size: 15px;
}
.BaseLayoutContentFooter {
  font-size: 15px !important;
  background: #F4F4F4;
}
.BaseLayoutContentFooter {
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 920px) {
  .BaseLayoutContentWrapper {
    width: 100vw;
  }
  .BaseLayoutContentWrapper::-webkit-scrollbar {
    display: none !important;
  }
  .BaseLayoutLeftMenu {
    max-height: 100vh;
    width: 100vw !important;
    overflow-y: auto;
  }
  .MobileMenuCloseWrapper {
    margin-top: 48px;
    margin-left: auto;
    margin-right: 32px;
  }
  .BaseLayoutLeftMenu::-webkit-scrollbar {
    width: 8px;
    background-color: #1C1C1E;
    border-radius: 20px;
  }
  .BaseLayoutLeftMenu::-webkit-scrollbar:horizontal {
    height: 8px;
    background-color: #8E8E93;
    border-radius: 20px;
  }
  .BaseLayoutLeftMenu::-webkit-scrollbar-thumb {
    background-color: #8E8E93;
    border-radius: 20px;
    margin-right: 4px;
  }
  .BaseLayoutLeftMenu::-webkit-scrollbar-track {
    background-color: #1C1C1E;
  }
  .BaseLayoutLogOutIcon {
    margin-bottom: 120px !important;
  }
}
@media screen and (min-width: 921px) {
  .MobileMenuCloseWrapper,
  .BaseLayoutContentFooterMobile {
    display: none !important;
  }
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .BaseLayoutLeftMenu {
    width: 256px;
  }
  .BaseLayoutLogoWrapper {
    margin-right: 14px !important;
  }
  .BaseLayoutContentWrapper {
    width: calc(100vw - 256px);
  }
  .BaseLayoutMenuAccordionItem,
  .BaseLayoutMenuAccordionHeader div {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1441px) {
  .BaseLayoutLeftMenu {
    width: 320px;
  }
  .BaseLayoutContentWrapper {
    width: calc(100vw - 320px);
  }
}
.BaseLayoutLogoWrapper {
  margin: 32px 32px 24px 32px;
  display: flex;
  flex-direction: column;
}
.BaseLayoutClickableLogo {
  cursor: pointer;
}
.BaseLayoutLogoDescription {
  padding-top: 6px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  opacity: 0.75;
}
.BaseLayoutLogOutIcon:hover {
  cursor: pointer;
  opacity: 1;
}
.BaseLayoutMenuAccordionHeader .ant-collapse-header {
  opacity: 0.8;
}
.BaseLayoutMenuAccordionHeader .ant-collapse-header:hover {
  opacity: 1;
}
.BaseLayoutMenuAccordionHeader .ant-collapse-header:hover svg path {
  fill: #ffffff;
}
.BaseLayoutLeftMenuRectangle {
  height: 1px;
  background: #2C2C2E;
  margin: 18px 32px;
}
.BaseLayoutMenuAccordion {
  padding: 0 16px;
}
.BaseLayoutMenuAccordionHeader .ant-collapse-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.BaseLayoutMenuAccordionHeader .ant-collapse-content .ant-collapse-content-box {
  padding: 0 !important;
}
.BaseLayoutMenuAccordionItem,
.BaseLayoutMenuAccordionHeader div {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 19px !important;
  color: #FFFFFF !important;
}
.BaseLayoutMenuAccordionContent {
  padding-left: 16px;
}
.BaseLayoutLeftMenuItem {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.75;
  padding: 8px 0;
  height: 56px;
}
.BaseLayoutLeftMenuItem svg {
  margin-top: 8px;
  margin-left: 32px;
}
.BaseLayoutLeftMenuItem span {
  padding: 8px 12px;
}
.BaseLayoutLeftMenuItem .BaseLayoutLeftMenuItemWithoutIcons {
  padding-left: 32px !important;
}
.BaseLayoutLeftMenuItem svg path {
  fill: #fff;
}
.BaseLayoutLeftMenuItem:hover {
  cursor: pointer;
  color: #F8E800;
  opacity: 1;
}
.BaseLayoutLeftMenuItem:hover .BaseLayoutLeftMenuItemWithoutIcons {
  cursor: pointer;
  color: #F8E800;
  opacity: 1;
}
.BaseLayoutLeftMenuItem:hover svg path {
  fill: #F8E800;
}
.BaseLayoutLeftMenuItem:hover > span > a {
  color: #F8E800;
}
.BaseLayoutLeftMenuItemActive {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #F8E800;
  padding: 8px 0;
  height: 56px;
}
.BaseLayoutLeftMenuItemActive span a {
  color: #F8E800 !important;
}
.BaseLayoutLeftMenuItemActive .BaseLayoutLeftMenuItemWithoutIcons {
  color: #F8E800;
  opacity: 1;
}
.BaseLayoutLeftMenuItemActive .BaseLayoutLeftMenuItemActiveMarker {
  width: 4px;
  background-color: #F8E800;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.BaseLayoutLeftMenuItemActive .BaseLayoutLeftMenuItemActiveMarkerAccordion {
  margin-left: -15px;
}
.BaseLayoutLeftMenuItemActive .BaseLayoutLeftMenuItemWithoutIconsAccordion {
  margin-left: 15px;
}
.BaseLayoutLeftMenuItemActive span {
  padding: 8px 12px;
}
.BaseLayoutLeftMenuItemActive .BaseLayoutLeftMenuItemWithoutIcons {
  padding-left: 28px !important;
}
.BaseLayoutLeftMenuItemActive svg {
  margin-top: 8px;
  margin-left: 28px;
}
.BaseLayoutLeftMenuItemActive svg path {
  fill: #F8E800;
}
.BaseLayoutLogOutIcon {
  opacity: 0.75;
  margin: 0 32px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.BaseLayoutLogOutIcon svg path {
  fill: #fff;
}
.BaseLayoutLogOutIcon span {
  padding-left: 12px;
  vertical-align: center;
  text-align: center;
}
.AccordionItemUp {
  transform: rotate(-180deg);
  /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}
.AccordionItemDown {
  transform: rotate(0deg);
  /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}
.FlexRow,
.SportboomCompareWrapper,
.DetailTableComponentRowSmallWrapper {
  display: flex;
  flex-direction: row;
}
.FlexRow.JustifyEnd {
  justify-content: flex-end;
}
.FlexColumn,
.SportboomTableMobileItemDetailStatus {
  display: flex;
  flex-direction: column;
}
.FlexWrap {
  flex-wrap: wrap;
}
.GridCenter,
.SuperwinListItem .SuperwinListMainRow {
  display: grid;
  place-items: center;
}
.ErrorTextWrapper {
  max-height: 84px;
  overflow-y: auto;
}
.ErrorText {
  font-family: Lato, sans-serif;
  font-size: 10px;
  color: #e80012;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
div .ContentPeriodWrapper {
  align-items: baseline;
  padding-top: 4px;
  flex-direction: row !important;
}
div .ContentPeriodWrapper .CalendarIconDescription {
  position: relative;
  top: 4px;
}
div .ContentPeriodWrapper .ContentPeriodMain {
  /* 22.4px */
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}
div .ContentPeriodWrapper .ContentPeriodTime {
  color: #808185 !important;
}
.ContentPeriod {
  padding-left: 4px;
  margin-top: -2px;
  letter-spacing: 0.1px;
  align-items: baseline;
}
.ContentPeriodMain {
  padding-left: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}
.ContentPeriodTime {
  padding: 0 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
}
.ContentPeriodSeparatorHideTime {
  padding-left: 4px;
}
@media screen and (max-width: 1600px) {
  .ContentPeriodWrapper {
    margin-left: 8px;
  }
}
@media screen and (max-width: 920px) {
  .ContentPeriodMain {
    color: #0D0F10 !important;
  }
  .ContentPeriodTime {
    color: #212529 !important;
  }
  .ContentPeriodDescription {
    padding-bottom: 4px;
    color: #868E96 !important;
  }
}
.CalendarIconDescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CalendarIconDescription svg rect {
  fill: #FFFFFF !important;
}
.CalendarPopupOverlayWrapper .ant-popover-content .ant-popover-inner {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
.CalendarPopupWrapper {
  border: none;
}
.CalendarPopupWrapper .ant-picker-panel {
  border-top: none !important;
  padding-top: 32px;
}
.CalendarPopupOverlayWrapper .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 32px !important;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr > th {
  text-transform: uppercase;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #868E96;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody tr > td {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  height: 40px;
  width: 40px;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-selected {
  background: #FCE000;
  border-radius: 8px;
  border: none !important;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-today .ant-picker-calendar-date-today::before {
  border: none !important;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-disabled {
  background: #f5f5f5;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-selected .ant-picker-cell-inner {
  background: #FCE000 !important;
}
.CalendarPopupHeader {
  justify-content: space-between;
}
.CalendarPopupHeaderArrow {
  width: 32px;
}
.CalendarPopupHeaderArrow:hover {
  cursor: pointer;
  opacity: 0.6;
}
.CalendarPopupHeaderArrowDisabled svg path {
  fill: #CED4DA !important;
}
.CalendarPopupHeaderTitle {
  text-transform: capitalize;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #212529;
  place-items: center;
}
.CalendarPopupPickers {
  padding-bottom: 24px;
  padding-top: 16px;
}
.CalendarPopupPickersLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #868E96;
}
.CalendarPopupDatePicker {
  border-bottom: 1px solid #CED4DA !important;
}
.CalendarPopupPickersTime,
.CalendarPopupDatePicker > .ant-input {
  padding-left: 0 !important;
}
.CalendarPopupPickersTime .ant-picker-input input {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.CalendarPopupPickersTimeDropdown {
  top: 516px !important;
}
.CalendarPopupPickersTimeDropdown .ant-picker-panel-container {
  border-radius: 8px;
}
.ant-picker-panel .ant-picker-time-panel .ant-picker-content > .ant-picker-time-panel-column > li {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.ant-picker-panel .ant-picker-time-panel .ant-picker-content > .ant-picker-time-panel-column > .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #FCE000 !important;
}
.CalendarPopupPickersTimeDropdown .ant-picker-panel-container .ant-picker-panel .ant-picker-footer {
  display: none !important;
}
.CalendarPopupButtons,
.CalendarPopupPickers {
  justify-content: space-between;
}
.CalendarPopupPickers > div,
.CalendarPopupButtons > div > button {
  width: 124px;
}
.HallPickerHeader {
  padding-bottom: 10px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.HallPickerHeaderCounter {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-left: 4px;
  opacity: 0.2;
}
.HallPickerHeaderReset {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  margin-left: auto;
}
.HallPickerHeaderReset:hover {
  cursor: pointer;
  opacity: 0.8;
}
.HallPickerModalHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
  padding: 26px 0;
  border-bottom: 1px solid #E5E5EA;
}
@media screen and (min-width: 921px) {
  .HallPickerModalHeader {
    min-width: 544px;
  }
}
.HallPickerModalSearchWrapper {
  padding: 24px 35px 24px 24px;
}
.HallPickerModalSearch {
  padding-bottom: 24px;
}
.HallPickerModalSearch .ant-input-affix-wrapper {
  background-color: #fff !important;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E5E5EA;
  padding: 12px !important;
}
.HallPickerModalSearch input {
  background-color: #fff;
}
.HallPickerModalSearch > .ant-input-affix-wrapper:hover {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}
.HallPickerModalSearch > .ant-input:focus,
.HallPickerModalSearch > .ant-input-focused,
.HallPickerModalSearch > .ant-input-affix-wrapper-focused,
.HallPickerModalSearch > .ant-input:focus,
.HallPickerModalSearch > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}
.HallPickerModalSearch #placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #AAABAD;
  width: 100%;
}
.HallPickerCheckboxWrapper {
  max-height: calc(100vh - 25%);
  margin-bottom: 16px;
}
.HallPickerApplyWrapper {
  padding: 18px 0 20px 0;
  opacity: 0;
  transition: opacity 500ms ease;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.HallPickerApplyWrapperActive {
  background: #F8E800;
  opacity: 1;
}
.HallPickerApplyWrapperActive:hover {
  cursor: pointer;
  opacity: 0.9;
}
.HallPickerFilterCheckboxAllWrapper {
  display: flex;
}
.HallPickerFilterCheckboxGroupWrapper {
  display: flex !important;
}
.HallPickerFilterCheckboxGroupWrapper .CheckboxWrapper > .CheckboxText {
  padding-top: 2px;
}
.HallPickerFilterCheckboxAllLabel {
  padding-top: 3px;
}
.HallPickerFilterCheckboxGroupWrapper,
.HallPickerFilterCheckboxAllLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
}
.HallPickerFilterCheckboxGroupWrapper {
  display: flex;
  flex-direction: column;
  max-height: 165px;
  overflow-y: auto;
}
.HallPickerFilterRadioGroupWrapper {
  padding-left: 2px;
}
.HallPickerFilterCheckboxGroupWrapper label,
.HallPickerFilterCheckboxAllWrapper label {
  padding-bottom: 16px;
}
.HallPickerFilterCheckboxGroupWrapper label:last-child {
  border-bottom: none !important;
}
.HallPickerFilterCheckboxGroupShowAll {
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #007AFF;
}
.HallPickerModalContentWrapper {
  height: 400px;
  overflow-y: auto;
  margin-right: 4px;
  margin-bottom: 24px;
  padding: 0 24px;
}
.HallPickerModalList {
  gap: 24px;
}
@media screen and (min-width: 921px) {
  .HallPickerModalItem {
    width: 145px;
  }
}
@media screen and (max-width: 920px) {
  .HallPickerModalItem {
    width: 110px;
  }
}
.HallPickerModalItem {
  padding: 18px 0 18px 24px;
  height: 56px;
  border: 1px solid #D5D5D6;
  border-radius: 8px;
}
.HallPickerModalItemDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8E8E93;
  padding-top: 2px;
}
.HallPickerModalItemLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #2B2D33;
  padding-left: 6px;
}
.HallPickerModalItem:hover {
  cursor: pointer;
  background-color: #F8F8F8;
}
.HallPickerModalActiveItem {
  border: none !important;
  background-color: #FEE600 !important;
}
.HallPickerModalDisabledItem {
  background-color: #F8F8F8;
}
.HallPickerModalDisabledItem:hover {
  cursor: not-allowed !important;
}
.HallPickerModalActiveItem:hover {
  background-color: #FEE600 !important;
  cursor: not-allowed !important;
}
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .HallPickerWrapper {
    margin: 32px 0 !important;
  }
}
.HallPickerWrapper {
  background: #3A3A3C;
  border-radius: 44px;
  padding: 12px 16px 10px 16px;
  margin: 32px;
  display: inline-flex !important;
}
.HallPickerWrapper span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 0 8px;
}
.HallPickerWrapper:hover {
  cursor: pointer;
  opacity: 0.9;
}
.HallPickerMoreWrapper {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: right;
  color: #FFFFFF;
  opacity: 0.75;
  padding-top: 4px;
}
.HallPickerPoint {
  background: #FEE600;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
}
.HallPickerArrowWrapper {
  margin-left: 40px;
}
.ModalWrapper,
.ModalFormWrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}
.ModalWrapper {
  max-height: 600px;
}
.ModalFormTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.ModalFormWrapper {
  max-height: calc(100vh - 64px);
}
@media screen and (max-width: 920px) {
  .ModalWrapper,
  .ModalFormWrapper {
    left: 8px !important;
    width: calc(100vw - 16px) !important;
  }
  .ModalContent,
  .ModalFormContent {
    width: calc(100vw - 16px) !important;
  }
  .ModalMarginWrapper {
    width: calc(100vw - 32px) !important;
    left: 16px !important;
  }
  .ModalMarginWrapper .ModalContent {
    width: calc(100vw - 32px) !important;
  }
}
.ModalContent,
.ModalFormContent {
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
}
.ModalCloseWrapper {
  margin: 14px;
}
.ModalCloseWrapper:hover {
  cursor: pointer;
}
.Overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  top: 0;
  left: 0;
}
.OverlayFilterTable {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 8;
  top: 0;
  left: 0;
}
.ModalFormContent {
  padding: 24px;
  width: 100%;
}
.ModalTitleWrapper {
  justify-content: space-between;
}
.ModalTitleWrapper .ModalFormClose:hover {
  cursor: pointer;
}
.ModalRightDetail {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  max-width: 100vw;
  height: 100vh;
  transition: width 500ms ease;
  background: #FFFFFF;
  border-radius: 16px 0px 0px 16px;
}
.ModalRightDetail .ModalRightDetailClose {
  position: relative;
}
.ModalRightDetail .ModalRightDetailClose svg {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
}
.ModalRightDetail .ModalRightDetailClose svg path {
  fill: #212529;
}
.ModalRightDetail .ModalRightDetailClose svg:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ModalFormBigClose {
  position: absolute;
  right: 24px;
  top: 24px;
}
@media screen and (max-width: 920px) {
  .ModalRightDetail {
    width: 100% !important;
  }
  .ModalRightDetailContent {
    overflow-x: auto;
  }
  .ModalRightDetailContent table {
    min-width: 840px;
  }
}
.MiddleHeight .ant-btn {
  height: 40px !important;
}
.ButtonPrimary span {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}
.ButtonDefaultWrapper .ant-btn,
.ButtonPrimaryWrapper .ant-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
  text-shadow: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 8px;
}
.ButtonDefaultWrapper .ant-btn span,
.ButtonPrimaryWrapper .ant-btn span {
  font-weight: 600;
  font-size: 14px;
  color: #1D1F21;
}
.ButtonDefaultWrapper .ant-btn:disabled,
.ButtonPrimaryWrapper .ant-btn:disabled {
  border: none;
  background: #ADB5BD !important;
  cursor: not-allowed;
}
.ButtonDefaultWrapper .ant-btn:disabled span,
.ButtonPrimaryWrapper .ant-btn:disabled span {
  color: #0D0F10 !important;
}
.ButtonDefaultWrapper .ant-btn {
  background: #fff;
  border-color: #CED4DA;
}
.ButtonDefaultWrapper .ant-btn:not([disabled]):hover,
.ButtonDefaultWrapper .ant-btn:not([disabled]):focus,
.ButtonDefaultWrapper .ant-btn:not([disabled]):active {
  border-color: #666;
}
.ButtonDefaultWrapper .ant-btn.ButtonDefaultDisabled,
.ButtonDefaultWrapper .ant-btn[disabled],
.ButtonDefaultWrapper .ant-btn.ButtonDefaultDisabled:hover,
.ButtonDefaultWrapper .ant-btn[disabled]:hover,
.ButtonDefaultWrapper .ant-btn.ButtonDefaultDisabled:focus,
.ButtonDefaultWrapper .ant-btn[disabled]:focus,
.ButtonDefaultWrapper .ant-btn.ButtonDefaultDisabled:active,
.ButtonDefaultWrapper .ant-btn[disabled]:active {
  background: #FAFAF9 !important;
  border-color: #AEAEB2 !important;
  cursor: not-allowed;
}
.ant-btn-primary .ant-btn,
.ButtonPrimaryWrapper .ant-btn {
  background: #F8E800;
  border-color: #F8E800;
}
.ant-btn-primary .ant-btn:hover,
.ButtonPrimaryWrapper .ant-btn:hover,
.ant-btn-primary .ant-btn:focus,
.ButtonPrimaryWrapper .ant-btn:focus,
.ant-btn-primary .ant-btn:active,
.ButtonPrimaryWrapper .ant-btn:active {
  border-color: #F5D400;
  background: #F5D400;
}
.ant-btn-primary .ant-btn:not([disabled]):hover,
.ButtonPrimaryWrapper .ant-btn:not([disabled]):hover,
.ant-btn-primary .ant-btn:not([disabled]):focus,
.ButtonPrimaryWrapper .ant-btn:not([disabled]):focus,
.ant-btn-primary .ant-btn:not([disabled]):active,
.ButtonPrimaryWrapper .ant-btn:not([disabled]):active {
  border-color: #F5D400;
  background: #F5D400;
}
.ant-btn-primary .ant-btn.ButtonPrimaryDisabled,
.ButtonPrimaryWrapper .ant-btn.ButtonPrimaryDisabled,
.ant-btn-primary .ant-btn[disabled],
.ButtonPrimaryWrapper .ant-btn[disabled],
.ant-btn-primary .ant-btn.ButtonPrimaryDisabled:hover,
.ButtonPrimaryWrapper .ant-btn.ButtonPrimaryDisabled:hover,
.ant-btn-primary .ant-btn[disabled]:hover,
.ButtonPrimaryWrapper .ant-btn[disabled]:hover,
.ant-btn-primary .ant-btn.ButtonPrimaryDisabled:focus,
.ButtonPrimaryWrapper .ant-btn.ButtonPrimaryDisabled:focus,
.ant-btn-primary .ant-btn[disabled]:focus,
.ButtonPrimaryWrapper .ant-btn[disabled]:focus,
.ant-btn-primary .ant-btn.ButtonPrimaryDisabled:active,
.ButtonPrimaryWrapper .ant-btn.ButtonPrimaryDisabled:active,
.ant-btn-primary .ant-btn[disabled]:active,
.ButtonPrimaryWrapper .ant-btn[disabled]:active {
  background: #F8E800;
  border-color: #F8E800;
  opacity: 0.48;
  cursor: not-allowed;
}
.ButtonIconOuter {
  position: relative;
}
.ButtonIconWrapper {
  position: relative;
}
.ButtonIconWrapper svg {
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 10px;
  width: 20px;
  height: 20px;
}
.ButtonIconWrapper .ant-btn {
  background: #fff;
  border: 1px solid #f8e800;
  text-shadow: none;
  border-radius: 8px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  height: 40px;
  padding-left: 36px;
  display: block;
}
.ButtonIconWrapper .ant-btn:not([disabled]):hover,
.ButtonIconWrapper .ant-btn:not([disabled]):focus,
.ButtonIconWrapper .ant-btn:not([disabled]):active {
  border: 1px solid #FFDD2D;
  box-shadow: 0 0 0 1px #f8e800;
  color: #000;
}
.ButtonIconWrapper .ant-btn.ButtonIconDisabled,
.ButtonIconWrapper .ant-btn.ButtonFadedDisabled,
.ButtonIconWrapper .ant-btn[disabled],
.ButtonIconWrapper .ant-btn.ButtonIconDisabled:hover,
.ButtonIconWrapper .ant-btn.ButtonFadedDisabled:hover,
.ButtonIconWrapper .ant-btn[disabled]:hover,
.ButtonIconWrapper .ant-btn.ButtonIconDisabled:focus,
.ButtonIconWrapper .ant-btn.ButtonFadedDisabled:focus,
.ButtonIconWrapper .ant-btn[disabled]:focus,
.ButtonIconWrapper .ant-btn.ButtonIconDisabled:active,
.ButtonIconWrapper .ant-btn.ButtonFadedDisabled:active,
.ButtonIconWrapper .ant-btn[disabled]:active {
  background: #FAFAF9;
  border: none;
  opacity: 0.48;
  cursor: not-allowed;
}
.ButtonIconWrapper .ant-btn.ButtonIcon_excel {
  border: none;
  background: no-repeat 2px center #E9FAC8;
  box-shadow: none;
  padding: 4px 16px 4px 36px;
  min-width: 119px;
}
.ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):hover,
.ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):focus,
.ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):active {
  background-color: #D8F5A2;
  border: none;
  box-shadow: none;
  color: #000;
}
.ButtonIconWrapper .ant-btn.ButtonIcon_excel span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  font-weight: 500;
}
.ButtonIconWrapper .ant-btn.ButtonIcon_basic {
  border: 1px solid grey;
  background: no-repeat 2px center white;
  box-shadow: none;
  padding: 4px 16px 4px 36px;
}
.ButtonIconWrapper .ant-btn.ButtonIcon_basic:not([disabled]):hover,
.ButtonIconWrapper .ant-btn.ButtonIcon_basic:not([disabled]):focus,
.ButtonIconWrapper .ant-btn.ButtonIcon_basic:not([disabled]):active {
  background-color: white;
  border: 1px solid grey;
  box-shadow: 0 0 0 1px grey;
  color: #000;
}
.ButtonFadedWrapper .ant-btn {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1C1C1E;
  background: #FAFAF9;
  border: 1px solid #E5E5EA !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border-radius: 8px;
  height: 48px;
  padding: 16px;
}
.ButtonFadedWrapper .ant-btn:not([disabled]):focus,
.ButtonFadedWrapper .ant-btn:not([disabled]):active {
  color: #1C1C1E;
  background: #FAFAF9;
  border: 1px solid #E5E5EA !important;
}
.ButtonFadedWrapper .ant-btn:not([disabled]):hover {
  color: #1C1C1E;
  opacity: 0.8;
  background: #E5E5EA;
}
.ButtonFadedWrapper .ant-btn.ButtonFadedDisabled,
.ButtonFadedWrapper .ant-btn[disabled],
.ButtonFadedWrapper .ant-btn.ButtonFadedDisabled:hover,
.ButtonFadedWrapper .ant-btn[disabled]:hover,
.ButtonFadedWrapper .ant-btn.ButtonFadedDisabled:focus,
.ButtonFadedWrapper .ant-btn[disabled]:focus,
.ButtonFadedWrapper .ant-btn.ButtonFadedDisabled:active,
.ButtonFadedWrapper .ant-btn[disabled]:active {
  color: #1C1C1E;
  background: #FAFAF9;
  border: 1px solid #E5E5EA !important;
  opacity: 0.48;
  cursor: not-allowed;
}
.ButtonGreenWrapper .ant-btn {
  background: #EBFBEE;
  border: none !important;
  border-radius: 8px;
  box-shadow: none !important;
  text-shadow: none !important;
  height: 40px;
}
.ButtonGreenWrapper .ant-btn svg path {
  fill: #37B24D;
}
.ButtonGreenWrapper .ant-btn span {
  padding-left: 8px;
  padding-top: 4px;
  color: #37B24D !important;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.ButtonGreenWrapper .ant-btn:not([disabled]):focus,
.ButtonGreenWrapper .ant-btn:not([disabled]):active {
  color: #40C057;
}
.ButtonGreenWrapper .ant-btn:not([disabled]):hover {
  color: #37B24D;
  opacity: 0.9 !important;
  background: #EBFBEE;
}
.ButtonGreenWrapper .ant-btn.ButtonGreenDisabled,
.ButtonGreenWrapper .ant-btn[disabled],
.ButtonGreenWrapper .ant-btn.ButtonGreenDisabled:hover,
.ButtonGreenWrapper .ant-btn[disabled]:hover,
.ButtonGreenWrapper .ant-btn.ButtonGreenDisabled:focus,
.ButtonGreenWrapper .ant-btn[disabled]:focus,
.ButtonGreenWrapper .ant-btn.ButtonGreenDisabled:active,
.ButtonGreenWrapper .ant-btn[disabled]:active {
  background: #EBFBEE;
  border: none;
  opacity: 0.48;
  cursor: not-allowed;
}
.ButtonNotifiWrapper .ant-btn,
.ButtonNotifiBlue .ant-btn {
  border: 1px solid #CED4DA !important;
  border-radius: 8px;
  box-shadow: none !important;
  text-shadow: none !important;
  height: 48px;
  padding: 4px 16px;
}
.ButtonNotifiWrapper .ant-btn div span,
.ButtonNotifiBlue .ant-btn div span {
  padding: 5px 12px 0 12px;
}
.ButtonNotifiWrapper .ant-btn:not([disabled]):focus,
.ButtonNotifiBlue .ant-btn:not([disabled]):focus,
.ButtonNotifiWrapper .ant-btn:not([disabled]):active,
.ButtonNotifiBlue .ant-btn:not([disabled]):active {
  color: #868E96;
  background: #F8F9FA;
}
.ButtonNotifiWrapper .ant-btn:not([disabled]):hover,
.ButtonNotifiBlue .ant-btn:not([disabled]):hover {
  border-color: #868E96 !important;
}
.ButtonNotifiWrapper .ant-btn.ButtonGreenDisabled,
.ButtonNotifiBlue .ant-btn.ButtonGreenDisabled,
.ButtonNotifiWrapper .ant-btn[disabled],
.ButtonNotifiBlue .ant-btn[disabled],
.ButtonNotifiWrapper .ant-btn.ButtonGreenDisabled:hover,
.ButtonNotifiBlue .ant-btn.ButtonGreenDisabled:hover,
.ButtonNotifiWrapper .ant-btn[disabled]:hover,
.ButtonNotifiBlue .ant-btn[disabled]:hover,
.ButtonNotifiWrapper .ant-btn.ButtonGreenDisabled:focus,
.ButtonNotifiBlue .ant-btn.ButtonGreenDisabled:focus,
.ButtonNotifiWrapper .ant-btn[disabled]:focus,
.ButtonNotifiBlue .ant-btn[disabled]:focus,
.ButtonNotifiWrapper .ant-btn.ButtonGreenDisabled:active,
.ButtonNotifiBlue .ant-btn.ButtonGreenDisabled:active,
.ButtonNotifiWrapper .ant-btn[disabled]:active,
.ButtonNotifiBlue .ant-btn[disabled]:active {
  background: #F8F9FA;
  opacity: 0.48;
  cursor: not-allowed;
}
.ButtonNotifiActive .ant-btn {
  border: 1px solid #FCE000 !important;
}
.ButtonPadding,
.ButtonNotifiBlue .ant-btn,
.ButtonNotifiGreen .ant-btn,
.ButtonNotifiRedBorder .ant-btn,
.ButtonNotifiRed .ant-btn,
.ButtonNotifiGray .ant-btn {
  padding: 0 48px;
}
.ButtonNotifiBlue {
  color: #2f54eb !important;
}
.ButtonNotifiBlue .ant-btn div span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #228BE6;
}
.ButtonNotifiGreen .ant-btn {
  color: #fff !important;
  background-color: #51CF66 !important;
  border: none !important;
}
.ButtonNotifiGreen .ant-btn div span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #fff !important;
}
.ButtonNotifiRedBorder .ant-btn {
  color: #FA5252 !important;
  background-color: #fff !important;
  border: 1px solid #FA5252 !important;
}
.ButtonNotifiRedBorder .ant-btn div span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #FA5252 !important;
}
.ButtonNotifiRedBorder .ant-btn:not([disabled]):hover {
  opacity: 0.8 !important;
  border-color: #FA5252 !important;
}
.ButtonNotifiRed .ant-btn {
  color: #fff !important;
  background-color: #FA5252 !important;
  border: none !important;
}
.ButtonNotifiRed .ant-btn div span {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #fff !important;
}
.ButtonNotifiRed:hover {
  opacity: 0.8;
}
.MobileBackButtonWrapper svg {
  margin-top: 1px;
}
.MobileBackButtonWrapper .MobileBackButtonSpan {
  padding-left: 4px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #228BE6;
}
.ButtonOutlinedWrapper .ant-btn {
  border: 1px solid #228BE6 !important;
  border-radius: 8px;
  box-shadow: none !important;
  text-shadow: none !important;
  height: 48px;
  padding: 11px 20px;
  background: transparent;
}
.ButtonOutlinedWrapper .ant-btn span {
  color: #228BE6 !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  font-family: 'Gilroy', sans-serif;
}
.ButtonOutlinedWrapper .ant-btn div span {
  padding: 5px 12px 0 12px;
}
.ButtonOutlinedWrapper .ant-btn:not([disabled]):focus,
.ButtonOutlinedWrapper .ant-btn:not([disabled]):active {
  color: #868E96;
  background: #F8F9FA;
}
.ButtonOutlinedWrapper .ant-btn:not([disabled]):hover {
  border-color: #868E96 !important;
}
.ButtonOutlinedWrapper .ant-btn.ButtonGreenDisabled,
.ButtonOutlinedWrapper .ant-btn[disabled],
.ButtonOutlinedWrapper .ant-btn.ButtonGreenDisabled:hover,
.ButtonOutlinedWrapper .ant-btn[disabled]:hover,
.ButtonOutlinedWrapper .ant-btn.ButtonGreenDisabled:focus,
.ButtonOutlinedWrapper .ant-btn[disabled]:focus,
.ButtonOutlinedWrapper .ant-btn.ButtonGreenDisabled:active,
.ButtonOutlinedWrapper .ant-btn[disabled]:active {
  background: #F8F9FA;
  opacity: 0.48;
  cursor: not-allowed;
}
.Blue900Color {
  color: #1864AB !important;
}
.Blue800Color {
  color: #1971C2 !important;
}
.Blue700Color {
  color: #1C7ED6 !important;
}
.Blue600Color,
.LinkText,
.IncreaseModalLimitText,
.LinkText,
.SportboomTableMobileItemBetNumber,
.SportboomTableMobileItemBetNumber {
  color: #228BE6 !important;
}
.Blue500Color {
  color: #339AF0 !important;
}
.Blue400Color {
  color: #4DABF7 !important;
}
.Blue300Color {
  color: #74C0FC !important;
}
.Blue200Color {
  color: #A5D8FF !important;
}
.Blue100Color {
  color: #D0EBFF !important;
}
.Blue10Color {
  color: #E7F5FF !important;
}
.Blue900Background {
  background-color: #1864AB !important;
}
.Blue800Background {
  background-color: #1971C2 !important;
}
.Blue700Background {
  background-color: #1C7ED6 !important;
}
.Blue600Background {
  background-color: #228BE6 !important;
}
.Blue500Background {
  background-color: #339AF0 !important;
}
.Blue400Background {
  background-color: #4DABF7 !important;
}
.Blue300Background {
  background-color: #74C0FC !important;
}
.Blue200Background {
  background-color: #A5D8FF !important;
}
.Blue100Background {
  background-color: #D0EBFF !important;
}
.Blue10Background,
.IncreaseModalLimit {
  background-color: #E7F5FF !important;
}
.Blue900Border {
  border-color: #1864AB !important;
}
.Blue800Border {
  border-color: #1971C2 !important;
}
.Blue700Border {
  border-color: #1C7ED6 !important;
}
.Blue600Border {
  border-color: #228BE6 !important;
}
.Blue500Border {
  border-color: #339AF0 !important;
}
.Blue400Border {
  border-color: #4DABF7 !important;
}
.Blue300Border {
  border-color: #74C0FC !important;
}
.Blue200Border {
  border-color: #A5D8FF !important;
}
.Blue100Border {
  border-color: #D0EBFF !important;
}
.Blue10Border {
  border-color: #E7F5FF !important;
}
.Cyan900Color {
  color: #0B7285 !important;
}
.Cyan800Color {
  color: #0C8599 !important;
}
.Cyan700Color {
  color: #1098AD !important;
}
.Cyan600Color {
  color: #15AABF !important;
}
.Cyan500Color {
  color: #22B8CF !important;
}
.Cyan400Color {
  color: #3BC9DB !important;
}
.Cyan300Color {
  color: #66D9E8 !important;
}
.Cyan200Color {
  color: #99E9F2 !important;
}
.Cyan100Color {
  color: #C5F6FA !important;
}
.Cyan10Color {
  color: #E3FAFC !important;
}
.Cyan900Background {
  background-color: #0B7285 !important;
}
.Cyan800Background {
  background-color: #0C8599 !important;
}
.Cyan700Background {
  background-color: #1098AD !important;
}
.Cyan600Background {
  background-color: #15AABF !important;
}
.Cyan500Background {
  background-color: #22B8CF !important;
}
.Cyan400Background {
  background-color: #3BC9DB !important;
}
.Cyan300Background {
  background-color: #66D9E8 !important;
}
.Cyan200Background {
  background-color: #99E9F2 !important;
}
.Cyan100Background {
  background-color: #C5F6FA !important;
}
.Cyan10Background,
.RegistrationCheckMobileTableHeader {
  background-color: #E3FAFC !important;
}
.Cyan900Border {
  border-color: #0B7285 !important;
}
.Cyan800Border {
  border-color: #0C8599 !important;
}
.Cyan700Border {
  border-color: #1098AD !important;
}
.Cyan600Border {
  border-color: #15AABF !important;
}
.Cyan500Border {
  border-color: #22B8CF !important;
}
.Cyan400Border {
  border-color: #3BC9DB !important;
}
.Cyan300Border {
  border-color: #66D9E8 !important;
}
.Cyan200Border {
  border-color: #99E9F2 !important;
}
.Cyan100Border {
  border-color: #C5F6FA !important;
}
.Cyan10Border {
  border-color: #E3FAFC !important;
}
.Grape900Color {
  color: #862E9C !important;
}
.Grape800Color {
  color: #9C36B5 !important;
}
.Grape700Color {
  color: #AE3EC9 !important;
}
.Grape600Color {
  color: #BE4BDB !important;
}
.Grape500Color {
  color: #CC5DE8 !important;
}
.Grape400Color {
  color: #DA77F2 !important;
}
.Grape300Color {
  color: #E599F7 !important;
}
.Grape200Color {
  color: #EEBEFA !important;
}
.Grape100Color {
  color: #F3D9FA !important;
}
.Grape10Color {
  color: #F8F0FC !important;
}
.Grape900Background {
  background-color: #862E9C !important;
}
.Grape800Background {
  background-color: #9C36B5 !important;
}
.Grape700Background {
  background-color: #AE3EC9 !important;
}
.Grape600Background {
  background-color: #BE4BDB !important;
}
.Grape500Background {
  background-color: #CC5DE8 !important;
}
.Grape400Background {
  background-color: #DA77F2 !important;
}
.Grape300Background {
  background-color: #E599F7 !important;
}
.Grape200Background {
  background-color: #EEBEFA !important;
}
.Grape100Background {
  background-color: #F3D9FA !important;
}
.Grape10Background {
  background-color: #F8F0FC !important;
}
.Grape900Border {
  border-color: #862E9C !important;
}
.Grape800Border {
  border-color: #9C36B5 !important;
}
.Grape700Border {
  border-color: #AE3EC9 !important;
}
.Grape600Border {
  border-color: #BE4BDB !important;
}
.Grape500Border {
  border-color: #CC5DE8 !important;
}
.Grape400Border {
  border-color: #DA77F2 !important;
}
.Grape300Border {
  border-color: #E599F7 !important;
}
.Grape200Border {
  border-color: #EEBEFA !important;
}
.Grape100Border {
  border-color: #F3D9FA !important;
}
.Grape10Border {
  border-color: #F8F0FC !important;
}
.Gray900Color {
  color: #212529 !important;
}
.Gray800Color {
  color: #343A40 !important;
}
.Gray700Color {
  color: #495057 !important;
}
.Gray600Color,
.SportboomTableMobileItemBetStart,
.DetailTableComponentRowItemLabel {
  color: #868E96 !important;
}
.Gray500Color {
  color: #ADB5BD !important;
}
.Gray400Color {
  color: #CED4DA !important;
}
.Gray300Color {
  color: #DEE2E6 !important;
}
.Gray200Color {
  color: #E9ECEF !important;
}
.Gray100Color {
  color: #F1F3F5 !important;
}
.Gray10Color {
  color: #F8F9FA !important;
}
.Gray900Background {
  background-color: #212529 !important;
}
.Gray800Background {
  background-color: #343A40 !important;
}
.Gray700Background {
  background-color: #495057 !important;
}
.Gray600Background {
  background-color: #868E96 !important;
}
.Gray500Background,
.SportboomTableStatusInGameActive {
  background-color: #ADB5BD !important;
}
.Gray400Background,
.SportboomTableMobileItemArrowOpen {
  background-color: #CED4DA !important;
}
.Gray300Background {
  background-color: #DEE2E6 !important;
}
.Gray200Background,
.SportboomTableInsideEmpty,
.SportboomTableMobileItemDetail {
  background-color: #E9ECEF !important;
}
.Gray100Background,
.SportboomTableStatusInGame,
.SportboomCompareCancel,
.SportboomTableMobileItemPending .SportboomTableMobileItem,
.SportboomTableMobileItemArrowClose,
.NotifyStatusTableButtons .ButtonNotifiWrapper button {
  background-color: #F1F3F5 !important;
}
.Gray10Background,
.SportboomCompareCancel:hover,
.PayrollFilesItemWrapper,
.PaymentFilesItemWrapper,
.ButtonNotifiGray .ant-btn {
  background-color: #F8F9FA !important;
}
.Gray900Border {
  border-color: #212529 !important;
}
.Gray800Border {
  border-color: #343A40 !important;
}
.Gray700Border {
  border-color: #495057 !important;
}
.Gray600Border {
  border-color: #868E96 !important;
}
.Gray500Border {
  border-color: #ADB5BD !important;
}
.Gray400Border,
.ButtonNotifiGray .ant-btn,
.DetailTableComponentFirstRow .DetailTableComponentRowItem,
.DetailTableComponentRowSmallWrapper {
  border-color: #CED4DA !important;
}
.Gray300Border {
  border-color: #DEE2E6 !important;
}
.Gray200Border {
  border-color: #E9ECEF !important;
}
.Gray100Border {
  border-color: #F1F3F5 !important;
}
.Gray10Border {
  border-color: #F8F9FA !important;
}
.Green900Color {
  color: #2B8A3E !important;
}
.Green800Color {
  color: #2F9E44 !important;
}
.Green700Color {
  color: #37B24D !important;
}
.Green600Color,
.SportboomTableStatusLoose {
  color: #40C057 !important;
}
.Green500Color {
  color: #51CF66 !important;
}
.Green400Color {
  color: #69DB7C !important;
}
.Green300Color {
  color: #8CE99A !important;
}
.Green200Color {
  color: #B2F2BB !important;
}
.Green100Color {
  color: #D3F9D8 !important;
}
.Green10Color {
  color: #EBFBEE !important;
}
.Green900Background {
  background-color: #2B8A3E !important;
}
.Green800Background {
  background-color: #2F9E44 !important;
}
.Green700Background {
  background-color: #37B24D !important;
}
.Green600Background {
  background-color: #40C057 !important;
}
.Green500Background {
  background-color: #51CF66 !important;
}
.Green400Background,
.SportboomTableStatusLooseActive {
  background-color: #69DB7C !important;
}
.Green300Background {
  background-color: #8CE99A !important;
}
.Green200Background {
  background-color: #B2F2BB !important;
}
.Green100Background {
  background-color: #D3F9D8 !important;
}
.Green10Background,
.SportboomTableStatusLoose {
  background-color: #EBFBEE !important;
}
.Green900Border {
  border-color: #2B8A3E !important;
}
.Green800Border {
  border-color: #2F9E44 !important;
}
.Green700Border {
  border-color: #37B24D !important;
}
.Green600Border {
  border-color: #40C057 !important;
}
.Green500Border {
  border-color: #51CF66 !important;
}
.Green400Border {
  border-color: #69DB7C !important;
}
.Green300Border {
  border-color: #8CE99A !important;
}
.Green200Border {
  border-color: #B2F2BB !important;
}
.Green100Border {
  border-color: #D3F9D8 !important;
}
.Green10Border {
  border-color: #EBFBEE !important;
}
.Indigo900Color {
  color: #364FC7 !important;
}
.Indigo800Color {
  color: #3B5BDB !important;
}
.Indigo700Color {
  color: #4263EB !important;
}
.Indigo600Color {
  color: #4C6EF5 !important;
}
.Indigo500Color {
  color: #5C7CFA !important;
}
.Indigo400Color {
  color: #748FFC !important;
}
.Indigo300Color {
  color: #91A7FF !important;
}
.Indigo200Color {
  color: #BAC8FF !important;
}
.Indigo100Color {
  color: #DBE4FF !important;
}
.Indigo10Color {
  color: #EDF2FF !important;
}
.Indigo900Background {
  background-color: #364FC7 !important;
}
.Indigo800Background {
  background-color: #3B5BDB !important;
}
.Indigo700Background {
  background-color: #4263EB !important;
}
.Indigo600Background {
  background-color: #4C6EF5 !important;
}
.Indigo500Background {
  background-color: #5C7CFA !important;
}
.Indigo400Background {
  background-color: #748FFC !important;
}
.Indigo300Background {
  background-color: #91A7FF !important;
}
.Indigo200Background {
  background-color: #BAC8FF !important;
}
.Indigo100Background {
  background-color: #DBE4FF !important;
}
.Indigo10Background {
  background-color: #EDF2FF !important;
}
.Indigo900Border {
  border-color: #364FC7 !important;
}
.Indigo800Border {
  border-color: #3B5BDB !important;
}
.Indigo700Border {
  border-color: #4263EB !important;
}
.Indigo600Border {
  border-color: #4C6EF5 !important;
}
.Indigo500Border {
  border-color: #5C7CFA !important;
}
.Indigo400Border {
  border-color: #748FFC !important;
}
.Indigo300Border {
  border-color: #91A7FF !important;
}
.Indigo200Border {
  border-color: #BAC8FF !important;
}
.Indigo100Border {
  border-color: #DBE4FF !important;
}
.Indigo10Border {
  border-color: #EDF2FF !important;
}
.Lemon900Color {
  color: #8A7500 !important;
}
.Lemon800Color,
.SportboomTableStatusReturnBet {
  color: #AA9200 !important;
}
.Lemon700Color {
  color: #C9AE00 !important;
}
.Lemon600Color,
.DetailTooltipContentTitle {
  color: #E4C800 !important;
}
.Lemon500Color {
  color: #FCE000 !important;
}
.Lemon400Color {
  color: #FFED37 !important;
}
.Lemon300Color {
  color: #FFF450 !important;
}
.Lemon200Color {
  color: #FFF450 !important;
}
.Lemon100Color {
  color: #FFFB9F !important;
}
.Lemon10Color {
  color: #FFFFBB !important;
}
.Lemon900Background {
  background-color: #8A7500 !important;
}
.Lemon800Background {
  background-color: #AA9200 !important;
}
.Lemon700Background,
.SportboomTableStatusReturnBetActive {
  background-color: #C9AE00 !important;
}
.Lemon600Background {
  background-color: #E4C800 !important;
}
.Lemon500Background,
.SportboomCompareApply,
.PayrollClearButtonCount,
.PaymentClearButtonCount {
  background-color: #FCE000 !important;
}
.Lemon400Background,
.SportboomCompareApply:hover {
  background-color: #FFED37 !important;
}
.Lemon300Background {
  background-color: #FFF450 !important;
}
.Lemon200Background {
  background-color: #FFF450 !important;
}
.Lemon100Background {
  background-color: #FFFB9F !important;
}
.Lemon10Background,
.SportboomTableStatusReturnBet {
  background-color: #FFFFBB !important;
}
.Lemon900Border {
  border-color: #8A7500 !important;
}
.Lemon800Border {
  border-color: #AA9200 !important;
}
.Lemon700Border {
  border-color: #C9AE00 !important;
}
.Lemon600Border {
  border-color: #E4C800 !important;
}
.Lemon500Border {
  border-color: #FCE000 !important;
}
.Lemon400Border {
  border-color: #FFED37 !important;
}
.Lemon300Border {
  border-color: #FFF450 !important;
}
.Lemon200Border {
  border-color: #FFF450 !important;
}
.Lemon100Border {
  border-color: #FFFB9F !important;
}
.Lemon10Border {
  border-color: #FFFFBB !important;
}
.Lime900Color {
  color: #5C940D !important;
}
.Lime800Color {
  color: #66A80F !important;
}
.Lime700Color {
  color: #74B816 !important;
}
.Lime600Color {
  color: #82C91E !important;
}
.Lime500Color {
  color: #94D82D !important;
}
.Lime400Color {
  color: #A9E34B !important;
}
.Lime300Color {
  color: #C0EB75 !important;
}
.Lime200Color {
  color: #D8F5A2 !important;
}
.Lime100Color {
  color: #E9FAC8 !important;
}
.Lime10Color {
  color: #F4FCE3 !important;
}
.Lime900Background {
  background-color: #5C940D !important;
}
.Lime800Background {
  background-color: #66A80F !important;
}
.Lime700Background {
  background-color: #74B816 !important;
}
.Lime600Background {
  background-color: #82C91E !important;
}
.Lime500Background {
  background-color: #94D82D !important;
}
.Lime400Background {
  background-color: #A9E34B !important;
}
.Lime300Background {
  background-color: #C0EB75 !important;
}
.Lime200Background {
  background-color: #D8F5A2 !important;
}
.Lime100Background {
  background-color: #E9FAC8 !important;
}
.Lime10Background {
  background-color: #F4FCE3 !important;
}
.Lime900Border {
  border-color: #5C940D !important;
}
.Lime800Border {
  border-color: #66A80F !important;
}
.Lime700Border {
  border-color: #74B816 !important;
}
.Lime600Border {
  border-color: #82C91E !important;
}
.Lime500Border {
  border-color: #94D82D !important;
}
.Lime400Border {
  border-color: #A9E34B !important;
}
.Lime300Border {
  border-color: #C0EB75 !important;
}
.Lime200Border {
  border-color: #D8F5A2 !important;
}
.Lime100Border {
  border-color: #E9FAC8 !important;
}
.Lime10Border {
  border-color: #F4FCE3 !important;
}
.Orange900Color {
  color: #D9480F !important;
}
.Orange800Color {
  color: #E8590C !important;
}
.Orange700Color {
  color: #F76707 !important;
}
.Orange600Color {
  color: #FD7E14 !important;
}
.Orange500Color {
  color: #FF922B !important;
}
.Orange400Color {
  color: #FFA94D !important;
}
.Orange300Color {
  color: #FFC078 !important;
}
.Orange200Color {
  color: #FFD8A8 !important;
}
.Orange100Color {
  color: #FFE8CC !important;
}
.Orange10Color {
  color: #FFF4E6 !important;
}
.Orange900Background {
  background-color: #D9480F !important;
}
.Orange800Background {
  background-color: #E8590C !important;
}
.Orange700Background {
  background-color: #F76707 !important;
}
.Orange600Background {
  background-color: #FD7E14 !important;
}
.Orange500Background {
  background-color: #FF922B !important;
}
.Orange400Background {
  background-color: #FFA94D !important;
}
.Orange300Background {
  background-color: #FFC078 !important;
}
.Orange200Background {
  background-color: #FFD8A8 !important;
}
.Orange100Background {
  background-color: #FFE8CC !important;
}
.Orange10Background,
.OrangeHeaderTable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  background-color: #FFF4E6 !important;
}
.Orange900Border {
  border-color: #D9480F !important;
}
.Orange800Border {
  border-color: #E8590C !important;
}
.Orange700Border {
  border-color: #F76707 !important;
}
.Orange600Border {
  border-color: #FD7E14 !important;
}
.Orange500Border {
  border-color: #FF922B !important;
}
.Orange400Border {
  border-color: #FFA94D !important;
}
.Orange300Border {
  border-color: #FFC078 !important;
}
.Orange200Border {
  border-color: #FFD8A8 !important;
}
.Orange100Border {
  border-color: #FFE8CC !important;
}
.Orange10Border {
  border-color: #FFF4E6 !important;
}
.Pink900Color {
  color: #A61E4D !important;
}
.Pink800Color {
  color: #C2255C !important;
}
.Pink700Color {
  color: #D6336C !important;
}
.Pink600Color {
  color: #E64980 !important;
}
.Pink500Color {
  color: #F06595 !important;
}
.Pink400Color {
  color: #F783AC !important;
}
.Pink300Color {
  color: #FAA2C1 !important;
}
.Pink200Color {
  color: #FCC2D7 !important;
}
.Pink100Color {
  color: #FFDEEB !important;
}
.Pink10Color {
  color: #FFF0F6 !important;
}
.Pink900Background {
  background-color: #A61E4D !important;
}
.Pink800Background {
  background-color: #C2255C !important;
}
.Pink700Background {
  background-color: #D6336C !important;
}
.Pink600Background {
  background-color: #E64980 !important;
}
.Pink500Background {
  background-color: #F06595 !important;
}
.Pink400Background {
  background-color: #F783AC !important;
}
.Pink300Background {
  background-color: #FAA2C1 !important;
}
.Pink200Background {
  background-color: #FCC2D7 !important;
}
.Pink100Background {
  background-color: #FFDEEB !important;
}
.Pink10Background {
  background-color: #FFF0F6 !important;
}
.Pink900Border {
  border-color: #A61E4D !important;
}
.Pink800Border {
  border-color: #C2255C !important;
}
.Pink700Border {
  border-color: #D6336C !important;
}
.Pink600Border {
  border-color: #E64980 !important;
}
.Pink500Border {
  border-color: #F06595 !important;
}
.Pink400Border {
  border-color: #F783AC !important;
}
.Pink300Border {
  border-color: #FAA2C1 !important;
}
.Pink200Border {
  border-color: #FCC2D7 !important;
}
.Pink100Border {
  border-color: #FFDEEB !important;
}
.Pink10Border {
  border-color: #FFF0F6 !important;
}
.Red900Color {
  color: #C92A2A !important;
}
.Red800Color {
  color: #E03131 !important;
}
.Red700Color {
  color: #F03E3E !important;
}
.Red600Color,
.SportboomTableStatusWin {
  color: #FA5252 !important;
}
.Red500Color {
  color: #FF6B6B !important;
}
.Red400Color {
  color: #FF8787 !important;
}
.Red300Color {
  color: #FFA8A8 !important;
}
.Red200Color {
  color: #FFC9C9 !important;
}
.Red100Color {
  color: #FFE3E3 !important;
}
.Red10Color {
  color: #FFF5F5 !important;
}
.Red900Background {
  background-color: #C92A2A !important;
}
.Red800Background {
  background-color: #E03131 !important;
}
.Red700Background {
  background-color: #F03E3E !important;
}
.Red600Background {
  background-color: #FA5252 !important;
}
.Red500Background {
  background-color: #FF6B6B !important;
}
.Red400Background,
.SportboomTableStatusWinActive {
  background-color: #FF8787 !important;
}
.Red300Background {
  background-color: #FFA8A8 !important;
}
.Red200Background {
  background-color: #FFC9C9 !important;
}
.Red100Background {
  background-color: #FFE3E3 !important;
}
.Red10Background,
.SportboomTableStatusWin {
  background-color: #FFF5F5 !important;
}
.Red900Border {
  border-color: #C92A2A !important;
}
.Red800Border {
  border-color: #E03131 !important;
}
.Red700Border {
  border-color: #F03E3E !important;
}
.Red600Border {
  border-color: #FA5252 !important;
}
.Red500Border {
  border-color: #FF6B6B !important;
}
.Red400Border {
  border-color: #FF8787 !important;
}
.Red300Border {
  border-color: #FFA8A8 !important;
}
.Red200Border {
  border-color: #FFC9C9 !important;
}
.Red100Border {
  border-color: #FFE3E3 !important;
}
.Red10Border {
  border-color: #FFF5F5 !important;
}
.Teal900Color {
  color: #087F5B !important;
}
.Teal800Color {
  color: #099268 !important;
}
.Teal700Color {
  color: #0CA678 !important;
}
.Teal600Color {
  color: #12B886 !important;
}
.Teal500Color {
  color: #20C997 !important;
}
.Teal400Color {
  color: #38D9A9 !important;
}
.Teal300Color {
  color: #63E6BE !important;
}
.Teal200Color {
  color: #96F2D7 !important;
}
.Teal100Color {
  color: #C3FAE8 !important;
}
.Teal10Color {
  color: #E6FCF5 !important;
}
.Teal900Background {
  background-color: #087F5B !important;
}
.Teal800Background {
  background-color: #099268 !important;
}
.Teal700Background {
  background-color: #0CA678 !important;
}
.Teal600Background {
  background-color: #12B886 !important;
}
.Teal500Background {
  background-color: #20C997 !important;
}
.Teal400Background {
  background-color: #38D9A9 !important;
}
.Teal300Background {
  background-color: #63E6BE !important;
}
.Teal200Background {
  background-color: #96F2D7 !important;
}
.Teal100Background {
  background-color: #C3FAE8 !important;
}
.Teal10Background {
  background-color: #E6FCF5 !important;
}
.Teal900Border {
  border-color: #087F5B !important;
}
.Teal800Border {
  border-color: #099268 !important;
}
.Teal700Border {
  border-color: #0CA678 !important;
}
.Teal600Border {
  border-color: #12B886 !important;
}
.Teal500Border {
  border-color: #20C997 !important;
}
.Teal400Border {
  border-color: #38D9A9 !important;
}
.Teal300Border {
  border-color: #63E6BE !important;
}
.Teal200Border {
  border-color: #96F2D7 !important;
}
.Teal100Border {
  border-color: #C3FAE8 !important;
}
.Teal10Border {
  border-color: #E6FCF5 !important;
}
.Violet900Color {
  color: #5F3DC4 !important;
}
.Violet800Color {
  color: #6741D9 !important;
}
.Violet700Color {
  color: #7048E8 !important;
}
.Violet600Color,
.SportboomTableStatusRedeem {
  color: #7950F2 !important;
}
.Violet500Color {
  color: #845EF7 !important;
}
.Violet400Color {
  color: #9775FA !important;
}
.Violet300Color {
  color: #B197FC !important;
}
.Violet200Color {
  color: #D0BFFF !important;
}
.Violet100Color {
  color: #E5DBFF !important;
}
.Violet10Color {
  color: #F3F0FF !important;
}
.Violet900Background {
  background-color: #5F3DC4 !important;
}
.Violet800Background {
  background-color: #6741D9 !important;
}
.Violet700Background {
  background-color: #7048E8 !important;
}
.Violet600Background {
  background-color: #7950F2 !important;
}
.Violet500Background {
  background-color: #845EF7 !important;
}
.Violet400Background,
.SportboomTableStatusRedeemActive {
  background-color: #9775FA !important;
}
.Violet300Background {
  background-color: #B197FC !important;
}
.Violet200Background {
  background-color: #D0BFFF !important;
}
.Violet100Background {
  background-color: #E5DBFF !important;
}
.Violet10Background,
.SportboomTableStatusRedeem {
  background-color: #F3F0FF !important;
}
.Violet900Border {
  border-color: #5F3DC4 !important;
}
.Violet800Border {
  border-color: #6741D9 !important;
}
.Violet700Border {
  border-color: #7048E8 !important;
}
.Violet600Border {
  border-color: #7950F2 !important;
}
.Violet500Border {
  border-color: #845EF7 !important;
}
.Violet400Border {
  border-color: #9775FA !important;
}
.Violet300Border {
  border-color: #B197FC !important;
}
.Violet200Border {
  border-color: #D0BFFF !important;
}
.Violet100Border {
  border-color: #E5DBFF !important;
}
.Violet10Border {
  border-color: #F3F0FF !important;
}
.Yellow900Color {
  color: #E67700 !important;
}
.Yellow800Color {
  color: #F08C00 !important;
}
.Yellow700Color {
  color: #F59F00 !important;
}
.Yellow600Color {
  color: #FAB005 !important;
}
.Yellow500Color {
  color: #FCC419 !important;
}
.Yellow400Color {
  color: #FFD43B !important;
}
.Yellow300Color {
  color: #FFE066 !important;
}
.Yellow200Color {
  color: #FFEC99 !important;
}
.Yellow100Color {
  color: #FFF3BF !important;
}
.Yellow10Color {
  color: #FFF9DB !important;
}
.Yellow900Background {
  background-color: #E67700 !important;
}
.Yellow800Background {
  background-color: #F08C00 !important;
}
.Yellow700Background {
  background-color: #F59F00 !important;
}
.Yellow600Background {
  background-color: #FAB005 !important;
}
.Yellow500Background {
  background-color: #FCC419 !important;
}
.Yellow400Background {
  background-color: #FFD43B !important;
}
.Yellow300Background {
  background-color: #FFE066 !important;
}
.Yellow200Background {
  background-color: #FFEC99 !important;
}
.Yellow100Background {
  background-color: #FFF3BF !important;
}
.Yellow10Background {
  background-color: #FFF9DB !important;
}
.Yellow900Border {
  border-color: #E67700 !important;
}
.Yellow800Border {
  border-color: #F08C00 !important;
}
.Yellow700Border {
  border-color: #F59F00 !important;
}
.Yellow600Border {
  border-color: #FAB005 !important;
}
.Yellow500Border {
  border-color: #FCC419 !important;
}
.Yellow400Border {
  border-color: #FFD43B !important;
}
.Yellow300Border {
  border-color: #FFE066 !important;
}
.Yellow200Border {
  border-color: #FFEC99 !important;
}
.Yellow100Border {
  border-color: #FFF3BF !important;
}
.Yellow10Border {
  border-color: #FFF9DB !important;
}
.Shark800Color {
  color: #212529 !important;
}
.Shark500Color,
.SportboomTableStatusInGame,
.ButtonNotifiGray .ant-btn,
.ButtonNotifiGray .ant-btn div span {
  color: #0D0F10 !important;
}
.Shark800Background {
  background-color: #212529 !important;
}
.Shark500Background {
  background-color: #0D0F10 !important;
}
.Shark800Border {
  border-color: #212529 !important;
}
.Shark500Border {
  border-color: #0D0F10 !important;
}
.White800Color {
  color: #F4F4F4 !important;
}
.White100Color,
.SportboomTableStatusWinActive,
.SportboomTableStatusLooseActive,
.SportboomTableStatusInGameActive,
.SportboomTableStatusRedeemActive,
.SportboomTableStatusReturnBetActive,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig .SportboomContentTableConfigCount {
  color: #FFFFFF !important;
}
.White800Background {
  background-color: #F4F4F4 !important;
}
.White100Background,
.SuperwinsGameListWrapper,
.SportboomTableMobileItem,
.SportboomTableMobileListPagination,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig,
.TooltipComponentModal .ant-popover-content .ant-popover-inner {
  background-color: #FFFFFF !important;
}
.White800Border {
  border-color: #F4F4F4 !important;
}
.White100Border {
  border-color: #FFFFFF !important;
}
.BBYellowColor {
  color: #F8E800 !important;
}
.BBRed500Color {
  color: #FF0025 !important;
}
.BBYellowBackground {
  background-color: #F8E800 !important;
}
.BBRedBackground {
  background-color: #FF0025 !important;
}
.BBYellowBorder {
  border-color: #F8E800 !important;
}
.BBRed500Border {
  border-color: #FF0025 !important;
}
.MarginAuto {
  margin: auto;
}
.MarginLeftAuto {
  margin-left: auto;
}
.MarginRightAuto {
  margin-right: auto;
}
.MarginTopAuto {
  margin-top: auto;
}
.MarginBottomAuto {
  margin-bottom: auto;
}
.Padding2 {
  padding: 2px;
}
.PaddingLeft2 {
  padding-left: 2px;
}
.PaddingRight2 {
  padding-right: 2px;
}
.PaddingTop2 {
  padding-top: 2px;
}
.PaddingBottom2 {
  padding-bottom: 2px;
}
.Margin2 {
  margin: 2px;
}
.MarginLeft2,
.SportboomTableActiveRow .ant-table-selection-column .ant-checkbox-wrapper {
  margin-left: 2px;
}
.MarginRight2 {
  margin-right: 2px;
}
.MarginTop2,
.SportboomTableInsideEmpty div svg {
  margin-top: 2px;
}
.MarginBottom2 {
  margin-bottom: 2px;
}
.Gap2 {
  gap: 2px;
}
.Padding4 {
  padding: 4px;
}
.PaddingLeft4 {
  padding-left: 4px;
}
.PaddingRight4,
.TableConfigModalListItem {
  padding-right: 4px;
}
.PaddingTop4,
.SportboomTableMobileItemBetNumber,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig span {
  padding-top: 4px;
}
.PaddingBottom4 {
  padding-bottom: 4px;
}
.Margin4 {
  margin: 4px;
}
.MarginLeft4 {
  margin-left: 4px;
}
.MarginRight4 {
  margin-right: 4px;
}
.MarginTop4 {
  margin-top: 4px;
}
.MarginBottom4 {
  margin-bottom: 4px;
}
.Gap4 {
  gap: 4px;
}
.Padding6 {
  padding: 6px;
}
.PaddingLeft6,
.PayrollStatusTableComment,
.QiwiStatusTableComment,
.PaymentStatusTableComment,
.YandexStatusTableComment {
  padding-left: 6px;
}
.PaddingRight6,
.PayrollStatusTableComment,
.QiwiStatusTableComment,
.PaymentStatusTableComment,
.YandexStatusTableComment {
  padding-right: 6px;
}
.PaddingTop6,
.SportboomTableMobileItemBetStart,
.PayrollStatusTableColumn span,
.PayrollStatusTableComment,
.QiwiStatusTableColumn span,
.QiwiStatusTableComment,
.PaymentStatusTableColumn span,
.PaymentStatusTableComment,
.YandexStatusTableColumn span,
.YandexStatusTableComment {
  padding-top: 6px;
}
.PaddingBottom6 {
  padding-bottom: 6px;
}
.Margin6 {
  margin: 6px;
}
.MarginLeft6 {
  margin-left: 6px;
}
.MarginRight6 {
  margin-right: 6px;
}
.MarginTop6,
.TableConfigModalClose,
.SportboomTableMobileItemDetailStatus {
  margin-top: 6px;
}
.MarginBottom6 {
  margin-bottom: 6px;
}
.Gap6,
.TableConfigModalTemplateWrapper {
  gap: 6px;
}
.Padding8,
.PayrollFilesItemWrapper,
.PaymentFilesItemWrapper {
  padding: 8px;
}
.PaddingLeft8 {
  padding-left: 8px;
}
.PaddingRight8 {
  padding-right: 8px;
}
.PaddingTop8 {
  padding-top: 8px;
}
.PaddingBottom8,
.DetailTooltipContentTitle {
  padding-bottom: 8px;
}
.Margin8 {
  margin: 8px;
}
.MarginLeft8,
.SportboomTableRow .ant-table-selection-column .ant-checkbox-wrapper,
.SportboomTableMobileWrapper {
  margin-left: 8px;
}
.MarginRight8,
.SportboomTableMobileWrapper {
  margin-right: 8px;
}
.MarginTop8 {
  margin-top: 8px;
}
.MarginBottom8 {
  margin-bottom: 8px;
}
.Gap8,
.TableConfigModalListItem,
.SportboomConfigRemoveModal .SportboomConfigRemoveModalButtons,
.SportboomTableInsideEmpty div,
.SportboomContentSwitcherWrapper .SportboomContentTableConfig {
  gap: 8px;
}
.Padding10 {
  padding: 10px;
}
.PaddingLeft10 {
  padding-left: 10px;
}
.PaddingRight10 {
  padding-right: 10px;
}
.PaddingTop10,
.TableConfigModalList {
  padding-top: 10px;
}
.PaddingBottom10 {
  padding-bottom: 10px;
}
.Margin10 {
  margin: 10px;
}
.MarginLeft10 {
  margin-left: 10px;
}
.MarginRight10 {
  margin-right: 10px;
}
.MarginTop10 {
  margin-top: 10px;
}
.MarginBottom10 {
  margin-bottom: 10px;
}
.Gap10 {
  gap: 10px;
}
.Padding12 {
  padding: 12px;
}
.PaddingLeft12,
.PayrollStatusTableColumn span,
.QiwiStatusTableColumn span,
.PaymentStatusTableColumn span,
.YandexStatusTableColumn span {
  padding-left: 12px;
}
.PaddingRight12 {
  padding-right: 12px;
}
.PaddingTop12 {
  padding-top: 12px;
}
.PaddingBottom12,
.StatusNotifyHallPicker {
  padding-bottom: 12px;
}
.Margin12 {
  margin: 12px;
}
.MarginLeft12 {
  margin-left: 12px;
}
.MarginRight12 {
  margin-right: 12px;
}
.MarginTop12 {
  margin-top: 12px;
}
.MarginBottom12 {
  margin-bottom: 12px;
}
.Gap12,
.TableConfigModalList,
.TableConfigModalTemplateAddForm {
  gap: 12px;
}
.Padding14 {
  padding: 14px;
}
.PaddingLeft14 {
  padding-left: 14px;
}
.PaddingRight14 {
  padding-right: 14px;
}
.PaddingTop14 {
  padding-top: 14px;
}
.PaddingBottom14 {
  padding-bottom: 14px;
}
.Margin14 {
  margin: 14px;
}
.MarginLeft14 {
  margin-left: 14px;
}
.MarginRight14 {
  margin-right: 14px;
}
.MarginTop14 {
  margin-top: 14px;
}
.MarginBottom14 {
  margin-bottom: 14px;
}
.Gap14 {
  gap: 14px;
}
.Padding16 {
  padding: 16px;
}
.PaddingLeft16 {
  padding-left: 16px;
}
.PaddingRight16 {
  padding-right: 16px;
}
.PaddingTop16 {
  padding-top: 16px;
}
.PaddingBottom16 {
  padding-bottom: 16px;
}
.Margin16,
.TableConfigModalTemplateAddForm {
  margin: 16px;
}
.MarginLeft16 {
  margin-left: 16px;
}
.MarginRight16 {
  margin-right: 16px;
}
.MarginTop16,
.TableConfigModalTemplateWrapper {
  margin-top: 16px;
}
.MarginBottom16 {
  margin-bottom: 16px;
}
.Gap16,
.SportboomConfigRemoveModal,
.SportboomTableDetailInfo,
.SportboomCompareWrapper,
.SportboomTableMobileItemDetailStatus {
  gap: 16px;
}
.Padding18 {
  padding: 18px;
}
.PaddingLeft18 {
  padding-left: 18px;
}
.PaddingRight18 {
  padding-right: 18px;
}
.PaddingTop18 {
  padding-top: 18px;
}
.PaddingBottom18 {
  padding-bottom: 18px;
}
.Margin18 {
  margin: 18px;
}
.MarginLeft18 {
  margin-left: 18px;
}
.MarginRight18 {
  margin-right: 18px;
}
.MarginTop18 {
  margin-top: 18px;
}
.MarginBottom18 {
  margin-bottom: 18px;
}
.Gap18 {
  gap: 18px;
}
.Padding20 {
  padding: 20px;
}
.PaddingLeft20 {
  padding-left: 20px;
}
.PaddingRight20 {
  padding-right: 20px;
}
.PaddingTop20 {
  padding-top: 20px;
}
.PaddingBottom20 {
  padding-bottom: 20px;
}
.Margin20 {
  margin: 20px;
}
.MarginLeft20 {
  margin-left: 20px;
}
.MarginRight20 {
  margin-right: 20px;
}
.MarginTop20 {
  margin-top: 20px;
}
.MarginBottom20 {
  margin-bottom: 20px;
}
.Gap20 {
  gap: 20px;
}
.Padding24,
.StatusNotifyUserCrudButtons {
  padding: 24px;
}
.PaddingLeft24 {
  padding-left: 24px;
}
.PaddingRight24 {
  padding-right: 24px;
}
.PaddingTop24,
.TableConfigModalListWrapper,
.StatusNotifyHallPicker {
  padding-top: 24px;
}
.PaddingBottom24 {
  padding-bottom: 24px;
}
.Margin24 {
  margin: 24px;
}
.MarginLeft24,
.StatusNotifyHallPicker {
  margin-left: 24px;
}
.MarginRight24 {
  margin-right: 24px;
}
.MarginTop24,
.TableConfigModalApply {
  margin-top: 24px;
}
.MarginBottom24 {
  margin-bottom: 24px;
}
.Gap24 {
  gap: 24px;
}
.Padding32 {
  padding: 32px;
}
.PaddingLeft32 {
  padding-left: 32px;
}
.PaddingRight32 {
  padding-right: 32px;
}
.PaddingTop32 {
  padding-top: 32px;
}
.PaddingBottom32 {
  padding-bottom: 32px;
}
.Margin32 {
  margin: 32px;
}
.MarginLeft32 {
  margin-left: 32px;
}
.MarginRight32 {
  margin-right: 32px;
}
.MarginTop32 {
  margin-top: 32px;
}
.MarginBottom32 {
  margin-bottom: 32px;
}
.Gap32,
.SportboomContentSwitcherWrapper {
  gap: 32px;
}
.Padding40 {
  padding: 40px;
}
.PaddingLeft40 {
  padding-left: 40px;
}
.PaddingRight40 {
  padding-right: 40px;
}
.PaddingTop40 {
  padding-top: 40px;
}
.PaddingBottom40 {
  padding-bottom: 40px;
}
.Margin40 {
  margin: 40px;
}
.MarginLeft40 {
  margin-left: 40px;
}
.MarginRight40 {
  margin-right: 40px;
}
.MarginTop40 {
  margin-top: 40px;
}
.MarginBottom40 {
  margin-bottom: 40px;
}
.Gap40 {
  gap: 40px;
}
.Padding64 {
  padding: 64px;
}
.PaddingLeft64 {
  padding-left: 64px;
}
.PaddingRight64 {
  padding-right: 64px;
}
.PaddingTop64 {
  padding-top: 64px;
}
.PaddingBottom64 {
  padding-bottom: 64px;
}
.Margin64 {
  margin: 64px;
}
.MarginLeft64 {
  margin-left: 64px;
}
.MarginRight64 {
  margin-right: 64px;
}
.MarginTop64 {
  margin-top: 64px;
}
.MarginBottom64 {
  margin-bottom: 64px;
}
.Gap64 {
  gap: 64px;
}
.FullSize {
  width: 100%;
  height: 100%;
}
.FullSizeWidth {
  width: 100%;
}
.FullSizeHeight {
  height: 100%;
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Semibold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Bold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 900;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Extrabold.otf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}
body {
  margin: 0 !important;
  font-family: Lato, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  min-width: 375px !important;
  color: #1d1f21;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
  font-family: Gilroy, sans-serif;
}
.App h1,
.App .Title-h1 {
  font-size: 30px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;
}
.App h2 {
  font-size: 24px;
}
.App h3 {
  font-size: 20px;
}
.App h4 {
  font-size: 16px;
}
.App img {
  display: block;
  max-width: 100%;
}
.App span {
  font-family: Lato, sans-serif;
}
.App input {
  font-family: Lato, sans-serif !important;
  font-weight: 500;
}
.App input::placeholder {
  color: #ADB5BD !important;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
iframe {
  display: none !important;
}
.HeadlineFirstText,
.NominalsModalSuccessTitle,
.NominalsTemplateCard_NumbersTable_Cell_Currency {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
}
.HeadlineSecondText,
.SportBoomMobileTitle,
.RaceErrContainerTextMain,
.HistoryModalContainer__header {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.HeadlineThirdText,
.DetailTooltipContentTitle,
.SportBoomWalletModalEmptyWrapper_title {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextOne,
.TextOneBold,
.SuperwinListItem .SuperwinListMainRow,
.SportBoomWalletModalEmptyWrapper_subtitle,
.HistoryBetsDevelopment,
.HistoryLiveCell,
.TextOneBold,
.ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
  font-family: 'Lato' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.TextOneBold {
  font-weight: 700 !important;
}
.TextTwo,
.TextTwoBold,
.TextTwoBold {
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}
.TextTwoBold {
  font-weight: 700 !important;
}
.SubtitleFirstText,
.WikiDashboardCardName,
.SubtitleFirstTextWikiFilter {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.SubtitleSecondText,
.SportBoomWalletModalMobileTableCellValue .SportBoomWalletModalMobileTableCellValueInner,
.HistoryModalContainer__body,
.ContentPeriodDescription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
}
.BodyFirstText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}
.BodySecondText,
.SportboomTableMobileItemBetNumber,
.ChangeTabTennisContainer_title,
.ChangeTab_Nominals-mock-title .TitleDescription .TitleText span,
.NominalsTemplateCard_Title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
}
.ChartDecodingText,
.ButtonNotifiGray .ant-btn div span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #212529;
}
.TextDefault,
.ChangeTabTennisContainer_tooltip_text,
.ChangeTabColWrapper_TitleImageContainer_Text-secondary,
.ChangeTabColNominalChange_Title,
.NominalsTemplateCard_RightTitle,
.HistoryBetsDateTimeCellDate {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold,
.SportboomWalletCellWrapper div,
.ChangeTabColWrapper_TitleImageContainer_Text,
.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max,
.NominalsTemplateCard_Cell_SubTitle_Value,
.MobileTableCardLimitsToggleContainer .MobileTableCardLimitsToggleContainer_Text,
.NominalsTemplateCard_NumbersTable_Cell_Value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
  color: #212529;
}
.Text1,
.RaceExpandedTableCell__Text,
.RaceDetailSubtitle,
.Geoservices_Photos__Info_Text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold,
.SportboomWalletCellWrapper div,
.ChangeTabColWrapper_TitleImageContainer_Text,
.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max,
.NominalsTemplateCard_Cell_SubTitle_Value,
.MobileTableCardLimitsToggleContainer .MobileTableCardLimitsToggleContainer_Text,
.NominalsTemplateCard_NumbersTable_Cell_Value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}
.TableHeaderText,
.StaticDashboardHallsSingleHall,
.StaticDashboardHallsSingleHall_number {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.StatusText,
.DashboardItemDetailsButton,
.SportboomTableStatus,
.RaceStatus,
.NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile,
.NominalStatus,
.SportboomTableStatusWin,
.SportboomTableStatusWinActive,
.SportboomTableStatusLoose,
.SportboomTableStatusLooseActive,
.SportboomTableStatusInGame,
.SportboomTableStatusInGameActive,
.SportboomTableStatusRedeem,
.SportboomTableStatusRedeemActive,
.SportboomTableStatusReturnBet,
.SportboomTableStatusReturnBetActive {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.TableHeader,
.TableHeader > span,
.NominalsTemplateCard_NumbersTable_Numbers .NominalsTemplateCard_NumbersTable_Cell,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th,
thead tr th.ant-table-cell,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.CashboxTableHeaderItem {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}
.CaptionText,
.SportboomTableMobileItemBetStart,
.RaceModalEmpty .RaceModalEmptyText,
.NominalsTemplateCard_Cell_Title,
.NominalsTemplateCard_Cell_SubTitle_Currency,
.NominalsTemplateCard_NumbersTable_MinMaxHead,
.NominalsTemplateCard_NumbersTable_Cell_Currency,
.MobileTableCardContainer .MobileTableCardRowItemTitle,
.MobileTableCardContainer .ChangeTabColWrapper_TitleImageContainer_Text-secondary {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
}
.CaptionTextBold,
.MobileTableCardLimitsSwitchContainer .MobileTableCardLimitsSwitchItem {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #212529;
}
.OverlineText,
.SportBoomWalletModalMobileTableCellTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
}
.Overline {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #212529;
}
.ButtonTextFirst,
.ant-picker-ok > button.ant-btn > span:after {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.ButtonTextSecond,
div.RaceWinnersButton span,
.PayrollsTitleButtons .ButtonFadedWrapper button span,
.PaymentTitleButtons .ButtonFadedWrapper button span,
.ButtonNotifiWrapper .ant-btn div span,
.ButtonNotifiBlue .ant-btn div span,
.ButtonOutlinedWrapper .ant-btn div span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}
.TextLabelText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
}
.Rectangle {
  height: 1px;
  background: #E5E5EA;
  width: 100%;
}
.Uppercase {
  text-transform: uppercase;
}
.HideScroll::-webkit-scrollbar {
  display: none !important;
}
.CapitalizeText {
  text-transform: capitalize;
}
.HoverItem:hover {
  cursor: pointer;
  opacity: 0.8;
}
.TextAlignCenter {
  text-align: center;
}
.TextUnderline {
  text-decoration: underline;
}
.RelativePosition {
  position: relative;
}
.HideBlock {
  display: none !important;
}
.ButtonSecondary {
  background-color: #F1F3F5 !important;
  border: transparent !important;
}
.DisplayNoneElement {
  display: none !important;
}
.TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 8px !important;
}
.ant-table-wrapper {
  overflow-x: auto;
}
.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th {
  background-color: #E6F6F8 !important;
  padding: 12px !important;
}
.ant-table-column-has-sorters .ant-table-column-sorters {
  padding: 0 !important;
}
thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
  color: #15AABF !important;
}
.TableColumn {
  padding: 12px !important;
  word-spacing: normal !important;
}
.ant-table-column-sorter-inner .active svg path {
  fill: #15AABF !important;
}
.ant-table-column-sorter-inner svg:not(.active) svg path {
  fill: #B4CDCF !important;
}
.ant-table-thead > tr > th {
  word-spacing: 999px;
  height: 64px;
}
.TableHeaderWithoutSpacing {
  word-spacing: 0 !important;
  white-space: nowrap;
}
.ant-table-content {
  border-top: 1px solid #E9EAEA !important;
  border-right: 1px solid #E9EAEA !important;
  border-left: 1px solid #E9EAEA !important;
  border-radius: 8px;
}
.ant-table-thead > tr > th:first-child {
  padding-left: 44px !important;
}
.ant-table-cell::before {
  display: none !important;
}
.ant-table-tbody .ant-table-row > td {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 12px;
}
.ant-table-tbody .ant-table-row:hover {
  cursor: pointer;
}
.TableComponentTotalRow > td {
  font-weight: bold !important;
}
.ant-table-expanded-row,
.ant-table-row-selected,
.ant-table-expanded-row td,
.ant-table-row-selected td {
  background-color: #fff !important;
}
.ant-table-expanded-row > td {
  padding: 0 !important;
  overflow-x: auto;
}
.ant-table-expanded-row-fixed {
  margin: 0 !important;
  width: 100% !important;
}
.ant-table-expanded-row > td .ant-table-expanded-row-fixed {
  padding: 0 !important;
  min-width: 1394px;
}
.TableItemNegativeValue {
  color: #FF3B30 !important;
}
.ant-table-body::-webkit-scrollbar {
  display: none !important;
}
@media screen and (max-width: 920px) {
  .TableColumnWrapper,
  .ReportTableContentWrapper {
    width: calc(100vw - 10px);
  }
  .ant-table-row-expand-icon-cell,
  .ant-table-cell-fix-left {
    width: 20px !important;
    padding: 0 !important;
  }
  .ant-table-content::-webkit-scrollbar {
    display: none !important;
  }
}
.OrangeHeaderTable .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}
.OrangeHeaderTable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}
.TableComponentTopScrollWrapper {
  overflow-x: scroll;
  height: 10px;
}
.TableSearchInput {
  width: 205px;
}
.TableSearchSelect {
  width: 205px;
}
.TableSearchClearIcon {
  margin-left: 2px;
}
.TableSearchClearIcon:hover,
.TableSearchTitle:hover {
  cursor: pointer;
}
.TableSearchClearIcon {
  margin-left: 6px;
}
.TableSearchTitleActive {
  color: #15AABF;
}
.TableBoldValue {
  font-weight: 700 !important;
}
.TableColumnNormalSpacing {
  word-spacing: normal !important;
}
.TableHeaderHelpWrapper {
  padding-left: 4px;
}
.TableSearchSelect .ant-select-clear {
  right: 35px;
}
.TableItemMaxWidth {
  width: max-content !important;
  padding-right: 8px;
}
.FromToDatesItem .DatePickerWrapper {
  width: 268px;
}
.ant-picker-ok > button.ant-btn {
  height: 48px;
  border-radius: 8px;
  border: none !important;
}
.ant-picker-ok > button.ant-btn > span {
  font-size: 0;
}
.ant-picker-ok > button.ant-btn > span:after {
  content: "Выбрать";
  font-size: 16px;
  /* original font size */
}
@media screen and (max-height: 710px) {
  .PeriodTableFilterHeader .TableHeaderModal .ModalWrapper {
    top: 24px !important;
  }
}
.CheckListFilterScroll {
  max-height: 220px;
  overflow-y: auto;
}
.TableFilterSortCheckBoxWrapper {
  width: 132px;
}
.TableFilterSortCheckBoxWrapper span {
  margin-top: -2px;
  color: #0D0F10 !important;
}
.TableFilterSortCheckBoxWrapper:hover {
  cursor: pointer;
}
.TableActionsModalTrigger {
  position: relative;
  margin-left: auto;
  cursor: pointer;
}
.TableActionsModalTriggerActive {
  border-radius: 4px;
  background: #F1F3F5;
  width: 28px;
  height: 28px;
  padding: 4px;
}
.TableActionsModalTriggerActive svg path {
  fill: black;
}
.TableActionsModal {
  position: fixed;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 18px;
}
.TableActionsModalHidden {
  display: none;
}
.TableActionsModalActive {
  display: block;
}
.TableCard,
.TableCardCollapsible {
  margin-bottom: 8px;
  width: 342px;
  max-width: 100%;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  background: #FFFFFF;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}
.TableCard {
  padding: 16px 16px 0;
}
.TableCard,
.TableCardCollapsibleInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.TableCardRow {
  flex: 0 0 calc(50% - 12px);
  margin-bottom: 16px;
}
.TableCardRowFullWidth {
  flex: 0 0 100%;
}
.TableCardsContainer {
  padding: 58px 16px 16px;
  max-height: 100vh;
  overflow-y: auto;
}
.TableCardKey {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
  color: #868E96;
  margin-bottom: 2px;
}
.TableCardCollapsible .ant-collapse,
.TableCardCollapsible .ant-collapse-content,
.TableCardCollapsible .ant-collapse > .ant-collapse-item {
  background: transparent;
  border: none;
}
.TableCardCollapsible .ant-collapse-header {
  padding: 18px 16px;
  border-bottom: 1px solid #E9ECEF;
}
.TableCardCollapsible .ant-collapse-content-box {
  border-bottom: 1px solid #E9ECEF;
}
.TableCardCollapsible .ant-collapse-arrow {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #F1F3F5;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.TableCardCollapsibleHeader {
  position: relative;
  padding-left: 8px;
}
.NewTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  min-height: unset;
}
.WithoutFiltersHeader .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  min-height: auto !important;
}
.ant-table-wrapper {
  overflow-x: auto;
}
.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th {
  background-color: #E3FAFC !important;
  padding: 12px !important;
}
.ant-table-column-has-sorters .ant-table-column-sorters {
  padding: 0 !important;
}
thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
  color: #15AABF !important;
}
.TableColumn {
  padding: 12px !important;
  word-spacing: normal !important;
}
.ant-table-column-sorter-inner .active svg path {
  fill: #15AABF !important;
}
.ant-table-column-sorter-inner svg:not(.active) svg path {
  fill: #B4CDCF !important;
}
.ant-table-column-sort {
  background-color: #FFFFFF !important;
}
.ant-table-cell-row-hover {
  background: #fafafa !important;
}
.TableHeaderFirst {
  padding-left: 16px;
}
.ant-table-thead > tr > th {
  word-spacing: 999px;
  height: 56px;
  color: #212529 !important;
}
.TableHeaderWithoutSpacing {
  word-spacing: 0 !important;
}
.PaginationTableComponentWrapper,
.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper,
.WithoutPaginationTableComponentWrapper {
  width: 100%;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading {
  width: 100%;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 8px 8px 0 0 !important;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 7px !important;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 7px !important;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  border-top: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-radius: 8px 8px 0 0;
}
.PaginationTableComponentWrapper::-webkit-scrollbar {
  border-right: 1px solid red;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12) !important;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.12) !important;
}
.WithoutPaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 8px !important;
}
.TableWithSelected .ant-spin-nested-loading .ant-spin-container .ant-table-scroll-horizontal .ant-table-container .ant-table-content table thead tr .ant-table-selection-column .ant-table-selection {
  margin-left: 8px;
}
.PaginationTableComponentTotalRow > td {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
  background-color: #FFF4E6 !important;
  font-weight: 700 !important;
}
.ant-table-thead > tr > th:first-child {
  padding-left: 44px !important;
}
.ant-table-cell::before {
  display: none !important;
}
.ant-table-tbody .ant-table-row > td {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
  padding: 12px;
  height: 56px;
}
.ant-table-tbody .ant-table-row:hover {
  cursor: pointer;
}
.TableComponentTotalRow > td {
  font-weight: bold !important;
}
.ant-table-expanded-row,
.ant-table-row-selected,
.ant-table-expanded-row td,
.ant-table-row-selected td {
  background-color: #fff !important;
}
.ant-table-expanded-row > td {
  padding: 0 !important;
  overflow-x: auto;
}
.ant-table-expanded-row-fixed {
  margin: 0 !important;
  width: 100% !important;
}
.ant-table-expanded-row > td .ant-table-expanded-row-fixed {
  padding: 0 !important;
  min-width: 1394px;
}
.ant-table-row:has(.ant-table-row-expand-icon-cell):has(span):has(div):has(.TableRowItemUp) > td {
  border-bottom: 0 !important;
}
.TableItemNegativeValue {
  color: #FF3B30 !important;
}
.ant-table-row-expand-icon-cell,
.ant-table-cell-fix-left {
  width: 20px !important;
  max-width: 20px !important;
  padding: 0 !important;
}
.ant-table-body::-webkit-scrollbar {
  display: none !important;
}
@media screen and (max-width: 920px) {
  .TableColumnWrapper,
  .ReportTableContentWrapper {
    width: calc(100vw - 10px);
  }
  .ant-table-row-expand-icon-cell,
  .ant-table-cell-fix-left {
    width: 20px !important;
    padding: 0 !important;
  }
  .ant-table-content::-webkit-scrollbar {
    display: none !important;
  }
}
.OrangeHeaderTable .PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}
.TableComponentTopScrollWrapper {
  overflow-x: scroll;
  height: 10px;
}
.TableSearchInput {
  width: 205px;
}
.TableSearchSelect {
  width: 205px;
}
.TableSearchClearIcon {
  margin-left: 2px;
}
.TableSearchClearIcon:hover,
.TableSearchTitle:hover {
  cursor: pointer;
}
.TableSearchClearIcon {
  margin-left: 6px;
}
.TableSearchTitleActive {
  color: #15AABF;
}
.TableBoldValue {
  font-weight: 700 !important;
}
.TableColumnNormalSpacing {
  word-spacing: normal !important;
}
.TableHeaderHelpWrapper {
  padding-left: 4px;
}
.TableSearchSelect .ant-select-clear {
  right: 35px;
}
.ReportTableTotalArrowItem svg path {
  fill: #FD7E14;
}
.TableHeaderTitle_Active {
  color: #15AABF !important;
}
.TableHeaderTitle {
  display: flex;
  align-items: center;
  word-spacing: normal !important;
  white-space: pre;
  transition: color 0.25s ease;
}
div.TableHeaderTitle {
  cursor: pointer;
}
div.TableHeaderTitle:hover {
  color: #15AABF;
}
div.TableHeaderTitle:hover svg path {
  fill: #15AABF;
}
.TableHeaderSortIcon {
  position: relative;
  top: -1px;
  margin-left: 2px;
}
.TableHeaderSortIcon path {
  transition: fill 0.25s ease;
  fill: #ADB5BD;
}
.TableHeaderModal {
  word-spacing: normal;
}
.TableHeaderModal .ModalWrapper {
  width: 384px;
  max-width: 384px;
}
.TableHeaderModal .ModalContent {
  padding: 32px 24px;
  max-width: 100%;
}
.TableHeaderModal .CheckboxWrapper {
  align-items: center;
  margin-bottom: 8px;
}
.TableHeaderModal .SelectWrapper {
  margin-bottom: 18px;
}
.TableHeaderModal .SelectWrapper .ant-select-selection-item,
.TableHeaderModal .SelectWrapper .ant-select-item.ant-select-item-option {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
}
.TableHeaderModal .CheckboxText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.TableHeaderModal .ant-slider {
  margin-bottom: 5px;
}
.TableHeaderModal .ButtonPrimary,
.TableHeaderModal .ButtonPrimaryDisabled {
  width: 100%;
}
.TableHeaderModal .ButtonPrimaryWrapper .ant-btn span {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #212529;
}
.TableHeaderModalTitle {
  white-space: nowrap;
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
  margin-bottom: 15px;
}
.TableHeaderModalSubTitle {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  margin-bottom: 6px;
}
.TableHeaderModalCloseIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;
}
.TableHeaderModal_inputInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.TableHeaderModal_inputInner .InputWrapper {
  width: 160px;
}
.TableHeaderModal_inputInner .InputWrapper .ant-input {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
  color: #212529;
}
.SliderComponentLegend {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  color: #868E96;
  margin-bottom: 27px;
}
@media screen and (min-width: 921px) {
  .TableHeaderModal .ModalWrapper {
    position: absolute;
    top: 64px;
    left: 0;
    transform: none;
  }
  th:first-child .TableHeaderModal .ModalWrapper,
  th:nth-child(2) .TableHeaderModal .ModalWrapper {
    left: 10px;
    right: auto;
  }
  .ant-table-thead tr th:nth-last-child(2) div .TableHeaderModal .ModalWrapper,
  .ant-table-thead tr th:last-child div .TableHeaderModal .ModalWrapper {
    right: 100px !important;
    left: auto !important;
  }
  th:last-child .TableHeaderModal .ModalWrapper {
    left: auto;
    right: 10px;
  }
  .ant-table-ping-left .TableHeaderModal .ModalWrapper,
  .ant-table-ping-right .TableHeaderModal .ModalWrapper,
  .ant-table-ping-left th:first-child .TableHeaderModal .ModalWrapper,
  .ant-table-ping-right th:first-child .TableHeaderModal .ModalWrapper,
  .ant-table-ping-left th:nth-child(2) .TableHeaderModal .ModalWrapper,
  .ant-table-ping-right th:nth-child(2) .TableHeaderModal .ModalWrapper {
    position: fixed;
    left: 256px !important;
    right: 0 !important;
    top: 237px;
    margin: auto;
  }
}
@media screen and (max-width: 1600px) and (min-width: 921px) {
  .TableHeaderModal .ModalWrapper {
    left: auto;
    right: 10px;
  }
}
@media screen and (max-width: 920px) {
  .TableHeaderModal .ModalWrapper,
  .TableHeaderModal .ModalFormWrapper {
    right: 0;
    margin: auto;
  }
  .TableHeaderModal .ModalWrapper {
    transform: none;
    top: 192px;
  }
}
@media screen and (max-width: 384px) {
  .TableHeaderModal .ModalWrapper {
    max-width: 100%;
  }
  .TableHeaderModal .ModalWrapper,
  .TableHeaderModal .ModalFormWrapper {
    margin: 0;
  }
  .TableHeaderModal .ModalContent {
    padding: 32px 20px;
  }
}
.WithMinHeight .ant-table-content {
  min-height: 500px;
}
.FilterButtonsWrapper {
  display: flex;
  justify-content: space-between;
}
.FilterButtonsWrapper .ButtonFadedWrapper {
  flex: 0 0 119px;
}
.FilterButtonsWrapper .ButtonFadedWrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.FilterButtonsWrapper .ButtonPrimaryWrapper {
  flex: 0 0 199px;
}
.FilterButtonsWrapper .ButtonPrimaryWrapper:only-child {
  flex: 0 0 100%;
}
.FilterButtonsWrapper button {
  width: 100%;
}
.TableFilterSortWrapper .CheckboxWrapper .ant-checkbox-wrapper {
  height: 28px;
}
.TableColumnSortASC svg path:nth-child(2) {
  fill: #15AABF !important;
}
.TableColumnSortDESC svg path:nth-child(1) {
  fill: #15AABF !important;
}
.TableComponentWrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content > table > tbody > tr:hover,
.NewTableComponentWrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-content > table > tbody > tr:hover {
  cursor: default;
}
.ExpandableTableRow:hover {
  cursor: pointer !important;
}
.EmptyTableTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  padding-top: 22px;
}
.EmptyTableLabel {
  padding-top: 8px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #212529;
  white-space: pre-wrap;
}
.EmptyTablePendingSpin {
  height: 248px;
}
.ButtonMiniExcel {
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  background: #E9FAC8;
  border-radius: 8px;
  width: fit-content;
}
.ButtonMiniExcelOnlyIcon {
  background: none !important;
}
.ButtonMiniExcel:hover {
  cursor: pointer;
  opacity: 0.8;
}
.ButtonMiniExcelDisabled:hover {
  cursor: not-allowed;
}
.NewDashboard {
  padding: 16px 8px;
  margin-left: -8px;
  overflow: auto;
}
.NewDashboard_ItemWithMore {
  padding: 16px 16px 14px 16px !important;
}
.NewDashboard_Item {
  position: relative;
  min-width: 224px;
  padding: 16px;
  border-radius: 16px;
}
.NewDashboard_Item_Title {
  height: 40px;
}
.NewDashboard_Item_InfoWrapper {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 8px 2px 8px;
  border-radius: 0 16px;
  background: rgba(255, 255, 255, 0.5);
}
.NewDashboard_Item_InfoWrapper .TooltipComponentTargetWrapper svg path {
  fill: #868E96;
}
.NewDashboard_Item_Loading {
  position: relative;
}
.NewDashboard_Item_Loading .ant-skeleton .ant-skeleton-button {
  width: 224px;
  height: 132px;
  padding: 16px;
  border-radius: 16px;
  background-image: linear-gradient(99deg, #E9ECEF 10.65%, rgba(233, 236, 239, 0.2) 51.69%, #E9ECEF 89.6%);
}
.Dashboard_Item_Loading_Wrapper {
  position: absolute;
  top: 0;
  width: 224px;
  height: 132px;
  padding: 16px;
}
.Dashboard_Item_Loading_Icon {
  width: 40px;
  height: 40px;
  background: #DEE2E6 !important;
  border-radius: 8px;
}
.Dashboard_Item_Loading_Label {
  width: 140px;
  height: 14px;
  border-radius: 4px;
  background: #DEE2E6;
}
.Dashboard_Item_Loading_Description {
  width: 192px;
  height: 29px;
  border-radius: 4px;
  background: #DEE2E6;
}
.NewDashboard::-webkit-scrollbar {
  display: none !important;
}
.NewDashboard_Item_ValueSkeleton,
.NewDashboard_Item_Value {
  width: 100% !important;
  height: 28px;
}
.NewDashboard_Item_Title {
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}
.NewDashboard_Item_Clickable:hover {
  cursor: pointer;
  transform: scale(1.05);
  transform-origin: center;
}
.NewDashboard_Item_MoreButton {
  border-radius: 8px;
  border: 1px solid #ADB5BD;
  padding: 7px 14px;
  margin-top: -1.5px;
}
.NewDashboard_Item_MoreButton:hover {
  cursor: pointer;
}
.ComponentDashboardWrapper {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  overflow-x: auto;
  gap: 24px;
}
.ComponentDashboardWrapper::-webkit-scrollbar {
  display: none !important;
}
.ComponentDashboardItem {
  margin-top: 12px;
  margin-bottom: 12px;
  max-height: fit-content !important;
}
@media screen and (min-width: 921px) {
  .ComponentDashboardItemClickable:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .ComponentDashboardItem {
    width: 224px;
    min-height: 112px;
    min-width: 224px;
    padding: 11px 9px 16px 14px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }
  .DashboardItemLabelPosition {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 920px) {
  .ComponentDashboardItem {
    padding: 16px 9px 16px 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 205px;
    min-height: 112px;
  }
  .ComponentDashboardWrapper {
    padding: 0 5px;
  }
  .DashboardItemLabelPosition {
    display: flex;
    flex-direction: row !important;
  }
}
.DashboardItemCurrencyContainer {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1680px) {
  .ComponentDashboardItem {
    width: 224px;
    min-height: 112px;
  }
  span.DashboardItemLabelValue,
  span.DashboardItemLabelCurrency {
    font-size: 24px;
    line-height: 30px;
  }
  .DashboardItemLabel {
    font-size: 14px !important;
    line-height: 120% !important;
  }
  .DashboardItemIcon {
    width: 48px !important;
  }
}
.DashboardItemLabel {
  max-width: 142px;
  padding: 0 20px 0 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  align-items: center;
  letter-spacing: 0.2px;
  color: #2B2D33;
}
.DashboardItemLabel__Date {
  color: #868E96;
}
.DashboardItemLabelTight {
  max-width: 110px !important;
}
span.DashboardItemLabelValue,
span.DashboardItemLabelCurrency {
  padding-left: 4px;
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #000000;
}
.DashboardItemLabelValueDecimal {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  letter-spacing: -0.2px;
  color: #000000;
  padding-top: 16px;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 24px !important;
}
.DashboardItemDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: #8E8E93;
}
.DashboardItemInfoWrapper {
  width: 16px;
  margin-left: auto;
  position: relative;
  align-self: flex-start;
}
.DashboardItemInfoWrapper .TooltipComponentTargetWrapper {
  position: relative;
  top: -2px;
  right: 1px;
}
.DashboardItemLabelContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 14px;
}
.DashboardItemIconWrapper {
  width: 40px;
  min-width: 40px;
}
.DashboardItemIcon {
  width: 100%;
}
.DashboardItemMoreButton {
  padding: 3px 12px;
  margin: 2px 0 2px 16px;
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
.DashboardItemMoreButton:hover,
.ComponentDashboardItemClickable:hover {
  opacity: 0.8;
  cursor: pointer;
}
.FileItemWrapper {
  width: 240px;
  padding: 8px;
  background: #F8F9FA;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.FileItemText {
  padding-left: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.408px;
  padding-top: 2px;
}
.FileItemTextName {
  color: #212529;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
}
.FileItemTextSize {
  padding-top: 3px;
  color: #868E96;
}
.FileItemDelete,
.FileItemDownload {
  display: none;
  margin-left: auto;
}
.FileItemDelete {
  margin-top: 12px;
}
.FileItemDelete,
.FileItemDownload {
  margin-top: 6px;
}
.FileItemWrapper:hover > .FileItemDelete,
.FileItemWrapper:hover > .FileItemDownload {
  display: inherit !important;
}
.FileItemDelete:hover,
.FileItemDownload:hover {
  cursor: pointer;
}
.FileItemDelete:hover svg path,
.FileItemDownload:hover svg path {
  fill: black;
}
@media screen and (max-width: 920px) {
  .FileItemWrapper {
    width: 150px;
  }
  .FileItemTextName {
    max-width: 80px;
  }
  .FileItemDownload,
  .FileItemDelete {
    display: inherit !important;
  }
}
.MinifiedFileComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px 2px 12px;
  border-radius: 16px;
  width: fit-content;
}
.MinifiedFileComponentName {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}
.MinifiedFileComponentLink svg {
  height: 10px;
  width: 13px;
  fill: #FFFFFF;
  margin-left: 7px;
}
.MinifiedFileComponentJpg {
  background: #BE4BDB;
}
.MinifiedFileComponentPng {
  background: #7950F2;
}
.MinifiedFileComponentPdf {
  background: #E64980;
}
.UploadAttachmentsIcon {
  display: grid;
  place-items: center;
}
.UploadAttachmentsButton {
  margin-bottom: 6px;
}
.UploadAttachmentsButton > button {
  padding: 4px 11px !important;
}
.CheckboxWrapper,
.HallPickerFilterCheckboxGroupWrapper,
.MainFilterCheckboxGroupWrapper,
.GamesListFilterCheckboxGroupWrapper {
  display: inline-flex;
}
.CheckboxWrapper .ant-checkbox-checked .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
  background: transparent url(/Components/CheckBox/check.svg) no-repeat 70% 50%;
}
.CheckboxWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner:after {
  display: none !important;
}
.CheckboxWrapper .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-inner,
.ant-table-cell .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background: none;
  border-color: #C7C7CC;
  border-radius: 4px;
}
.CheckboxWrapper .ant-checkbox-inner:after,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-inner:after,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-inner:after,
.MainFilterCheckboxGroupWrapper .ant-checkbox-inner:after,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-inner:after,
.ant-table-cell .ant-checkbox-inner:after {
  display: none;
  width: 7px;
  height: 11px;
}
.CheckboxWrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-table-cell .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.CheckboxWrapper .ant-checkbox:hover .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox:hover .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox:hover .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox:hover .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox:hover .ant-checkbox-inner,
.ant-table-cell .ant-checkbox:hover .ant-checkbox-inner,
.CheckboxWrapper .ant-checkbox-input:focus + .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-input:focus + .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-input:focus + .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox-input:focus + .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-table-cell .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #F8E800;
}
.CheckboxWrapper .ant-checkbox-checked .ant-checkbox-inner,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner,
.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F8E800;
  border-color: #F8E800;
}
.CheckboxWrapper .ant-checkbox-checked::after,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked::after,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked::after,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked::after,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked::after,
.ant-table-cell .ant-checkbox-checked::after {
  border-color: #F8E800;
}
.CheckboxWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked .ant-checkbox-inner:after,
.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner:after,
.CheckboxWrapper .ant-checkbox-checked:hover .ant-checkbox-inner:after,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-checked:hover .ant-checkbox-inner:after,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-checked:hover .ant-checkbox-inner:after,
.MainFilterCheckboxGroupWrapper .ant-checkbox-checked:hover .ant-checkbox-inner:after,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-checked:hover .ant-checkbox-inner:after,
.ant-table-cell .ant-checkbox-checked:hover .ant-checkbox-inner:after {
  border-right-color: #1D1F21;
  border-bottom-color: #1D1F21;
}
.CheckboxWrapper .ant-checkbox-wrapper-disabled,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-wrapper-disabled,
.MainFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled,
.ant-table-cell .ant-checkbox-wrapper-disabled,
.CheckboxWrapper .ant-checkbox-wrapper-disabled + .CheckboxText,
.HallPickerFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled + .CheckboxText,
.SideFilterCheckBoxGroupWrapper .ant-checkbox-wrapper-disabled + .CheckboxText,
.MainFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled + .CheckboxText,
.GamesListFilterCheckboxGroupWrapper .ant-checkbox-wrapper-disabled + .CheckboxText,
.ant-table-cell .ant-checkbox-wrapper-disabled + .CheckboxText {
  opacity: 0.5;
}
.CheckboxText {
  font-size: 15px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 1px !important;
  background-color: #C7C7CC !important;
}
.DatePickerWrapper .ant-picker,
.RangePickerWrapper .ant-picker {
  border-radius: 8px;
  height: 44px;
  width: 100%;
  padding: 10px 16px !important;
}
.DatePickerWrapper .ant-picker:hover,
.RangePickerWrapper .ant-picker:hover {
  border: 1px solid #212529 !important;
}
.DatePickerWrapper .ant-picker-suffix,
.RangePickerWrapper .ant-picker-suffix {
  margin-left: auto;
  color: #808889;
}
.DatePickerWrapper .ant-picker-focused,
.DatePickerWrapper .ant-picker-focused.ant-picker:hover,
.RangePickerWrapper .ant-picker-focused.ant-picker:hover,
.RangePickerWrapper .ant-picker-focused {
  border: 1px solid #212529 !important;
  box-shadow: none !important;
}
.RangePickerWrapper .ant-picker .ant-picker-disabled:hover {
  border-color: #e6e6e6 !important;
}
.RangePickerWrapper .ant-picker-range .ant-picker-active-bar {
  background: #212529;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #0d0d0d !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #F8E800;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: #FCE000 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #F8E800 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #F8E800 !important;
}
.ant-picker-header button {
  font-weight: bold !important;
}
.ant-picker-panel-container {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05) !important;
}
.ant-picker-today-btn {
  color: #0d0d0d !important;
}
.ant-picker-header-view button:hover,
.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: #1066f2 !important;
}
.RangePickerWrapper .ant-picker-disabled {
  opacity: 0.5;
}
.ant-picker-dropdown {
  z-index: 9999 !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: rgba(248, 232, 0, 0.35) !important;
}
.ErrorPickerWrapper .ant-picker,
.ErrorRangePickerWrapper .ant-picker-range {
  border-color: #e80012 !important;
}
.ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
  margin-bottom: -16px;
}
.ant-picker-input > input {
  color: #1d1f21;
}
.ant-picker-range {
  display: flex !important;
  flex-direction: row !important;
}
.ant-picker-range > .ant-picker-input {
  display: inline-block !important;
  max-width: 80px;
}
.ant-picker-range > .ant-picker-input input {
  text-align: center !important;
}
.TimePickerComponentWrapper .ant-picker {
  width: 100%;
  border: 1px solid #D1D1D6;
  box-sizing: border-box;
  border-radius: 8px;
  height: 44px;
}
.TimePickerComponentWrapper .ant-picker-focused,
.TimePickerComponentWrapper .ant-picker-focused .ant-picker:hover,
.TimePickerComponentWrapper .ant-picker-focused .ant-picker:hover,
.TimePickerComponentWrapper .ant-picker-focused,
.TimePickerComponentWrapper .ant-picker:hover {
  border-color: #212529 !important;
  box-shadow: none !important;
}
.TimePickerComponentPopupWrapper .ant-btn-primary {
  background-color: #FCE000 !important;
  color: #0d0d0d !important;
}
.DatePickerSeparatorWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
@media screen and (max-width: 920px) {
  .ant-picker-panels {
    flex-direction: column !important;
  }
}
.RangePickerMiddleWrapper > .ant-picker {
  height: 40px !important;
}
.DatePickerWrapperGreyBackground .ant-picker {
  background-color: #f8f9fa !important;
}
.RadioWrapper label {
  display: flex;
}
.RadioWrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
  border-color: #AAB4B5;
  background: #fff;
}
.RadioWrapper .ant-radio-inner:after {
  top: 9px;
  left: 9px;
}
.RadioWrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #0d0d0d;
}
.RadioWrapper .ant-radio-checked .ant-radio-inner {
  background-color: #F8E800;
  border-color: #F8E800;
}
.RadioWrapper .ant-radio-disabled .ant-radio-inner {
  border-color: rgba(217, 217, 217, 0.5) !important;
  background-color: #f6f6fb !important;
  opacity: 0.6;
}
.RadioWrapper .ant-radio-wrapper:hover .ant-radio,
.RadioWrapper .ant-radio:hover .ant-radio-inner,
.RadioWrapper .ant-radio-input:focus + .ant-radio-inner {
  border-color: #F8E800;
}
.RadioGroupOption {
  padding-bottom: 8px;
  height: 34px;
}
@media screen and (max-width: 920px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
.InputWrapper > .ant-input,
.InputWrapper > .ant-input-number,
.InputWrapper > .ant-input-affix-wrapper,
.InputWrapper .ant-input-number-input {
  height: 48px !important;
  border-radius: 8px !important;
}
.InputWrapper > .ant-input::placeholder,
.InputWrapper > .ant-input-number::placeholder,
.InputWrapper > .ant-input-affix-wrapper::placeholder,
.InputWrapper .ant-input-number-input::placeholder {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ADB5BD !important;
}
.InputMiddleWrapper > .ant-input-affix-wrapper {
  height: 40px !important;
  padding: 10px 12px !important;
}
.InputWrapper > .ant-input-affix-wrapper {
  padding: 12px !important;
}
.InputWrapper > .ant-input-affix-wrapper > .ant-input::placeholder {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #ADB5BD !important;
}
.InputWrapper > .ant-input:hover,
.InputWrapper > .ant-input-affix-wrapper:hover,
.InputWrapper > .ant-input-number:hover {
  border-color: #212529 !important;
}
.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-focused,
.InputWrapper > .ant-input-affix-wrapper-focused,
.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 0 #212529 !important;
  border-color: #212529 !important;
}
.InputWrapper .ant-input-disabled {
  color: #ADB5BD !important;
}
.InputWrapper .ant-input-prefix {
  margin-right: 10px;
}
.ViewInputWrapper .ant-input-affix-wrapper-disabled,
.ViewInputWrapper .ant-input-disabled {
  color: #1D1F21 !important;
  background-color: white !important;
}
.InputWrapper .ant-input-number-handler-up {
  border-top-right-radius: 4px;
}
.InputWrapper .ant-input-number-handler-down {
  border-bottom-right-radius: 4px;
}
.InputWrapper .ant-input-number-handler-wrap {
  border-radius: 0 4px 4px 0;
}
.InputWrapper .ant-input-number-handler:hover {
  background: #f8e800;
}
.InputWrapper .ant-input-number-handler:hover .anticon {
  color: #1D1F21;
}
.InputLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #868E96;
  padding-bottom: 6px;
}
.ErrorInputWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
  margin-bottom: -16px;
}
.ErrorInputWrapper .ant-input,
.ErrorInputWrapper .ant-input-affix-wrapper {
  border-color: #e80012 !important;
}
.InputWrapperGreyBackground .ant-input,
.InputWrapperGreyBackground .ant-input-affix-wrapper,
.InputWrapperGreyBackground .ant-input-number {
  background-color: #f8f9fa !important;
}
.InputWrapperControlsHidden input::-webkit-outer-spin-button,
.InputWrapperControlsHidden input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.InputWrapperControlsHidden input[type=number] {
  -moz-appearance: textfield;
}
.InputNumberClearWrapper {
  position: relative;
}
.InputNumberClearWrapper .InputNumberClear {
  width: 100%;
}
.InputNumberClearWrapper .InputNumberClear .ant-input-number-handler-wrap {
  display: none !important;
}
.InputNumberClearWrapper .InputNumberClearSuffix {
  position: absolute;
  top: 12px;
  right: 12px;
}
.InputNumberClearWrapper .InputNumberClear .ant-input-number-input-wrap .ant-input-number-input {
  width: calc(100% - 32px) !important;
}
.ErrorsContainer {
  display: flex;
  flex-direction: column;
}
.EditorWrapper {
  position: relative !important;
}
.EditorWrapperOverlay {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #000;
  opacity: 0.1;
  border-radius: 8px;
  z-index: 5;
  margin: 1px;
}
.public-DraftEditorPlaceholder-root,
.EditorWrapperOverlayPlaceholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #868E96;
}
.EditorWrapperOverlayPlaceholder {
  color: #ADB5BD !important;
}
.EditorWrapperOverlayPlaceholder {
  padding: 28px 16px;
}
.RichEditor-hidePlaceholder .EditorWrapper .EditorTextArea .DraftEditor-root .public-DraftEditorPlaceholder-root {
  display: none !important;
}
.EditorTextArea {
  background: #FFFFFF !important;
  border: 1px solid #CED4DA;
  border-radius: 8px !important;
  padding: 14px 16px 100px 16px !important;
  min-height: 160px !important;
  max-height: 440px !important;
  overflow-y: auto !important;
}
.EditorErrorTextArea {
  border: 1px solid #e80012;
}
.EditorToolbar {
  background: #FFFFFF !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
  padding: 8px 8px 2px 8px !important;
  position: absolute !important;
  bottom: 16px !important;
  left: 16px !important;
  z-index: 9 !important;
}
.rdw-option-wrapper > img {
  opacity: 0.4;
}
.rdw-option-wrapper,
.rdw-option-wrapper:hover {
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}
.rdw-option-active > img,
.rdw-option-wrapper:hover > img {
  opacity: 1;
}
.rdw-option-disabled:hover > img {
  opacity: 0.3 !important;
}
.rdw-option-disabled {
  cursor: default !important;
}
.rdw-link-modal {
  height: 225px !important;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
}
.rdw-link-modal-label {
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #868E96 !important;
}
.rdw-link-modal-input {
  height: auto !important;
  border-radius: 8px !important;
  padding: 8px !important;
}
.rdw-link-modal-target-option {
  display: none !important;
}
.rdw-link-modal-buttonsection .rdw-link-modal-btn:first-child {
  background: #F8E800 !important;
  border: none !important;
}
.rdw-link-modal-btn {
  padding: 8px 12px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  height: auto !important;
  width: auto !important;
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #212529 !important;
}
.rdw-link-modal-btn:hover {
  opacity: 0.7;
}
.EditorToolbarItemBlockType {
  width: 150px !important;
}
.EditorToolbarItemFontSize {
  width: 50px !important;
}
.EditorToolbarItemBlockType,
.EditorToolbarItemBlockType > ul,
.EditorToolbarItemFontSize,
.EditorToolbarItemFontSize > ul {
  padding: 2px 8px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}
.rdw-dropdown-optionwrapper > li {
  text-decoration: none !important;
  color: #868E96 !important;
  background: #fff !important;
}
.rdw-dropdown-optionwrapper > li:hover {
  color: #000 !important;
  background: #fff !important;
}
.EditorToolbarItemBlockType > a,
.EditorToolbarItemFontSize > a {
  text-decoration: none !important;
  color: #868E96 !important;
}
.EditorToolbarItemBlockType:hover > a,
.EditorToolbarItemFontSize:hover > a {
  color: #000 !important;
}
.EditorToolbarItemFontSize > ul {
  width: 50px !important;
  margin-left: -10px !important;
  margin-top: 6px !important;
}
.EditorToolbarItemBlockType > ul {
  width: 150px !important;
  margin-left: -10px !important;
  margin-top: 6px !important;
}
.rdw-dropdown-carettoopen,
.rdw-dropdown-carettoclose {
  display: none !important;
}
.rdw-colorpicker-modal {
  width: 240px !important;
  left: -90px !important;
  height: auto !important;
  padding: 2px 8px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}
.rdw-colorpicker-modal-style-label {
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #000 !important;
  border-color: #F8E800 !important;
  padding-top: 8px !important;
}
.EditorWrapperView {
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 14px 16px;
  max-height: 440px;
  overflow-y: auto;
  font-family: Lato, sans-serif !important;
}
@media screen and (max-width: 920px) {
  .EditorToolbar {
    overflow-x: auto;
    width: calc(100vw - 120px) !important;
  }
  .EditorToolbar,
  .EditorToolbar .rdw-inline-wrapper,
  .EditorToolbar .rdw-history-wrapper {
    flex-wrap: nowrap !important;
  }
  .EditorToolbar::-webkit-scrollbar {
    display: none !important;
  }
}
.EditorWrapperRoot {
  position: relative;
}
.PaginationWrapper {
  display: grid;
  place-items: center;
  padding-top: 24px;
}
.TablePaginationWrapper div,
.PaginationWrapper div {
  place-items: center;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-next,
.TablePaginationWrapper div .ant-pagination .ant-pagination-prev,
.PaginationWrapper div .ant-pagination .ant-pagination-next,
.PaginationWrapper div .ant-pagination .ant-pagination-prev {
  display: none;
}
.PaginationWrapperButtons {
  padding-top: 8px;
  margin: 0 8px;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item,
.PaginationWrapper div .ant-pagination .ant-pagination-item {
  height: 32px !important;
  min-width: 32px !important;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #212529 !important;
  padding-top: 6px;
  background: none;
  border: none;
  margin: 0 2px !important;
}
.ant-pagination-item-active {
  padding-top: 6px !important;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active {
  min-width: 32px !important;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #FCE000;
  box-sizing: border-box;
  border-radius: 4px;
  color: #212529 !important;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active a,
.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active:hover a,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active a,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active:hover a {
  color: #212529 !important;
}
.PaginationWrapper div .ant-pagination .ant-pagination-item:hover a {
  color: #212529 !important;
  opacity: 0.5;
}
.PaginationWrapperButtons:hover {
  cursor: pointer;
  opacity: 0.5;
}
.TablePaginationWrapper {
  background-color: #fff;
  padding: 12px;
  margin: 0 12px;
}
.TablePaginationLeftShadow::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-left: 1px solid #D1D1D6 !important;
}
.TablePaginationRightShadow {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-right: 1px solid #D1D1D6 !important;
}
.TablePaginationAllShadow {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12), inset -10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-right: 1px solid #D1D1D6 !important;
  border-left: 1px solid #D1D1D6 !important;
}
.TablePaginationShadowWrapper {
  background-color: #fff;
  border-bottom: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.TablePaginationPageSizeWrapper {
  margin-left: auto;
}
.TablePaginationPageSizeWrapper span {
  padding-right: 8px;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item:hover,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-next:hover,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-prev:hover {
  cursor: pointer;
  background: #F1F3F5;
  border-radius: 4px;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item:hover a,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-next:hover a,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-prev:hover a {
  color: #212529 !important;
}
.TablePaginationJump {
  line-height: inherit;
}
.TablePaginationWrapperButtons {
  padding: 8px 8px 0 8px;
}
.TablePaginationWrapperButtons:hover {
  cursor: pointer;
  background: #F1F3F5;
  border-radius: 4px;
}
@media screen and (max-width: 920px) {
  .TablePaginationWrapper {
    display: grid !important;
    place-items: center !important;
  }
  .TablePaginationWrapper .TablePaginationPageSizeWrapper {
    padding-top: 16px;
    padding-right: 16px;
    margin-left: 0 !important;
  }
}
.ant-select-multiple .ant-select-selector {
  max-height: 150px;
  overflow-y: auto;
  height: auto !important;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search input {
  height: auto !important;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
  left: 17px;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 5px !important;
  margin-inline-start: 5px !important;
}
.ant-select-multiple .ant-select-selector {
  padding: 4px 12px !important;
  height: auto !important;
  min-height: 48px !important;
}
.ViewSelectWrapper .ant-select-disabled {
  opacity: 1 !important;
}
.ViewSelectWrapper .ant-select-disabled .ant-select-selector {
  background-color: white !important;
}
.ViewSelectWrapper .ant-select-disabled .ant-select-selector .ant-select-selection-overflow > .ant-select-selection-overflow-item > span {
  color: #1D1F21 !important;
}
.SelectWrapper .ant-select,
.TreeSelectWrapper .ant-select {
  width: 100%;
}
.SelectWrapper .ant-select .ant-select-arrow,
.TreeSelectWrapper .ant-select .ant-select-arrow {
  transform: rotate(0deg);
  transition: transform 500ms ease;
}
.SelectWrapper .ant-select-selector,
.TreeSelectWrapper .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  border-radius: 8px !important;
  color: #808889 !important;
  border-color: #D7D7D7 !important;
  box-shadow: none !important;
  min-width: 80px;
}
.SelectWrapper .ant-select-selector:not(.ant-select-disabled):hover,
.TreeSelectWrapper .ant-select-selector:not(.ant-select-disabled):hover {
  border-color: #212529 !important;
}
.SelectWrapper .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item,
.TreeSelectWrapper .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
  color: #545454 !important;
}
.SelectWrapper .ant-select-selector .ant-select-selection-item,
.TreeSelectWrapper .ant-select-selector .ant-select-selection-item {
  color: #545454 !important;
}
.SelectWrapper .ant-select-arrow,
.TreeSelectWrapper .ant-select-arrow {
  color: #808889;
}
.SelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector,
.TreeSelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector,
.SelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector:hover,
.TreeSelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector:hover {
  border-color: #212529 !important;
  box-shadow: none !important;
}
.SelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector .ant-select-selection-item,
.TreeSelectWrapper .ant-select-open:not(.ant-select-disabled) .ant-select-selector .ant-select-selection-item {
  color: #1D1F21 !important;
}
.SelectWrapper .ant-select-open .ant-select-arrow,
.TreeSelectWrapper .ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
  transition: transform 500ms ease;
}
.SelectWrapper .ant-select-selection-placeholder,
.TreeSelectWrapper .ant-select-selection-placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ADB5BD !important;
}
.SelectWrapper.ant-select-disabled,
.TreeSelectWrapper.ant-select-disabled {
  opacity: 0.5;
}
.SelectGreyWrapper .SelectComponent .ant-select-selector {
  background: #F8F9FA !important;
}
.SelectGreyWrapper .ant-select-focused > .ant-select-selector {
  background: #FFF !important;
  border-color: #212529 !important;
}
.SelectGreyWrapperDisabled {
  opacity: 1;
}
.ErrorTreeSelectWrapper .ErrorText,
.ErrorSelectWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
}
.ErrorTreeSelectWrapper .ant-select-selector,
.ErrorSelectWrapper .ant-select-selector {
  border-color: #e80012 !important;
}
.ant-select-dropdown .ant-select-item-option-selected {
  background-color: #F4F4F4 !important;
  background: #F4F4F4 !important;
}
.ant-select-dropdown {
  border: 1px solid #D7D7D7;
  box-shadow: 0 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-top: -6px !important;
}
.ant-select-dropdown .ant-select-item-option:hover {
  background-color: #F4F4F4 !important;
}
.ant-select-dropdown .ant-select-item-option {
  padding: 10px 16px;
  font-weight: 500;
}
.ant-select-dropdown .ant-select-item-option:not(.ant-select-item-option-disabled) {
  color: #545454;
}
.ant-select-dropdown .ant-select-item-option.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #1D1F21;
}
.ant-select-dropdown .ant-select-item-option-selected {
  color: #1D1F21 !important;
  background-color: rgba(248, 232, 0, 0.2) !important;
  font-weight: 400 !important;
}
.ant-select-dropdown .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba(248, 232, 0, 0.2) !important;
}
.ant-select-selection-overflow-item > .ant-select-selection-item {
  background: #F4F4F4 !important;
  border-radius: 8px !important;
  padding: 0 8px !important;
}
.SelectLargeWrapper > .ant-select-single > .ant-select-selector {
  height: 50px !important;
  padding: 8px !important;
}
.SelectLargeWrapper .ant-select-selection-search-input {
  height: 48px !important;
}
.SelectWrapper {
  height: 48px !important;
}
.SelectWrapper .ant-select {
  height: 48px !important;
}
.SelectWrapper .ant-select .ant-select-selector {
  height: 48px !important;
}
.SelectWrapper .ant-select .ant-select-selector .ant-select-selection-search {
  height: 48px !important;
}
.SelectWrapper .ant-select .ant-select-selector .ant-select-selection-search input {
  height: 48px !important;
}
.SelectWrapperGreyBackground .ant-select-selector {
  background-color: #f8f9fa !important;
}
.SelectMiddleWrapper > .ant-select-single > .ant-select-selector {
  height: 40px !important;
  padding: 9px 12px !important;
}
.SelectMiddleWrapper > .ant-select-single > .ant-select-arrow {
  position: absolute;
  top: 20px;
  right: 12px;
}
.PaginationSelectSmallWrapper {
  height: 32px !important;
  width: 72px;
}
.PaginationSelectSmallWrapper .ant-select {
  height: 32px !important;
}
.PaginationSelectSmallWrapper .ant-select .ant-select-selector {
  height: 32px !important;
  width: 72px;
  padding: 6px 12px !important;
  border: 1px solid #CED4DA !important;
  border-radius: 4px !important;
}
.PaginationSelectSmallWrapper .ant-select .ant-select-selector .ant-select-selection-search {
  width: auto !important;
  height: 20px !important;
}
.PaginationSelectSmallWrapper .ant-select .ant-select-selection-item {
  padding-right: 0 !important;
}
.PaginationSelectSmallWrapper .ant-select .ant-select-arrow {
  margin-left: auto;
  padding-top: 0 !important;
  padding-right: 0 !important;
}
.PaginationSelectSmallWrapper .ant-select .ant-select-arrow {
  transform: rotate(0deg);
  transition: transform 500ms ease;
}
.SwitchComponentWrapper {
  display: flex;
  flex-direction: row;
}
.SwitchComponentWrapper .ant-switch-checked {
  background-color: #FCE000;
}
.SwitchComponentWrapper .ant-switch {
  height: 20px;
  width: 36px;
}
.SwitchComponentWrapper .ant-switch .ant-switch-handle {
  height: 16px;
}
.SwitchComponentLabel {
  padding-left: 8px;
  color: #2C2C2E;
}
.SwitchFilterWrapper {
  width: 100%;
  height: 40px;
}
.SwitchFilterWrapper .ant-switch {
  margin-left: auto;
  background-color: #E9ECEF;
}
.SwitchFilterWrapper .ant-switch-checked {
  background-color: #FCE000 !important;
}
.SwitchFilterComponentLabel {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.TooltipComponentTargetWrapper:hover {
  cursor: pointer;
}
.TooltipComponentWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2C2C2E;
  background-color: #343A40;
}
.TooltipComponentWrapper .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 8px 11px !important;
  max-width: 320px;
  color: #fff;
}
.InfoTooltipWrapper > .ant-tooltip-content > .ant-tooltip-inner {
  border-radius: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
}
.TooltipComponentWrapper .ant-popover-arrow-content::before {
  background: #343A40;
}
.TooltipComponentModal {
  padding-top: 0 !important;
}
.TooltipComponentModal .ant-popover-content .ant-popover-arrow {
  display: none !important;
}
.TooltipComponentModal .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  border-radius: 16px !important;
  max-width: 100% !important;
}
.ant-popover-placement-left .ant-popover-content .ant-popover-arrow {
  right: 4px !important;
}
.SliderComponentWrapper .ant-slider .ant-slider-rail,
.SliderComponentWrapper .ant-slider .ant-slider-track {
  height: 6px !important;
}
.ant-slider {
  margin: 0 !important;
}
.ant-slider-handle {
  top: 0;
  height: 20px !important;
  width: 20px !important;
  background: #FFDD2D !important;
  border: 3px solid #FFFFFF !important;
  box-sizing: border-box !important;
  margin-top: -3px !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2) !important;
}
.SliderComponentWrapper .ant-slider-disabled .ant-slider-handle,
.SliderComponentWrapper .ant-slider-disabled .ant-slider-dot {
  border-color: #fff !important;
}
.ant-slider-disabled .ant-slider-handle {
  background: #C7C7CC !important;
  border: 3px solid #FFFFFF !important;
}
.ant-slider-tooltip {
  background-color: #fff !important;
}
.SliderComponentValueWrapper {
  padding-bottom: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2D33;
}
.GraySpinComponent .ant-spin span svg path {
  fill: #E5E5EA;
}
.YellowSpinComponent .ant-spin span svg path {
  fill: #F8E800;
}
.UltimatePendingWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(13, 15, 16, 0.56);
  z-index: 9999;
  top: 0;
  left: 0;
}
.QiwiModalHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #0D0D0D;
  padding: 32px 0 24px 0;
  width: 448px;
}
.QiwiModalTableHeader {
  justify-content: space-between;
  padding: 18px 24px;
  border: 1px solid #E5E5EA;
  margin: 0 32px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.QiwiModalTableHeaderItem {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #2B2D33;
}
.QiwiModalTableRow {
  justify-content: space-between;
  padding: 18px 32px;
  border-bottom: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}
.QiwiModalTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.QiwiModalTableRowItem {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
}
.QiwiModalWrapper {
  padding-bottom: 16px;
}
.QiwiModalTableWrapper {
  overflow-y: auto;
  max-height: 460px;
}
.QiwiModalTableBody {
  margin: 0 32px 8px 32px;
}
.NotActiveFilterWrapper {
  display: grid;
  place-items: center;
  padding-top: 5%;
}
.NotActiveFilterContent {
  max-width: 504px;
}
.NotActiveFilterTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #000000;
  max-width: 800px;
  padding-bottom: 26px;
}
.NotActiveFilterDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #8E8E93;
  max-width: 470px;
  padding-top: 52px;
}
.NotActiveFilterDescription .TextDefault {
  /* 22.4px */
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  color: #808185;
}
@media screen and (max-width: 920px) {
  .NotActiveFilterWrapper {
    width: 100vw !important;
  }
  .NotActiveFilterTitle {
    width: calc(100vw - 64px);
    font-size: 18px !important;
  }
  .NotActiveFilterDescription {
    font-size: 14px;
    width: calc(100vw - 64px);
  }
  .NotActiveFilterIconWrapper svg {
    width: calc(100vw - 135px);
    height: calc(100vw - 135px);
  }
}
.MainPageContentWrapper {
  background: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 29.63%, #F4F4F4 100%) !important;
  min-height: calc(100vh - 50px);
}
.BaseLayoutContentFooter {
  margin-top: auto;
  margin-bottom: 20px;
}
.InfoFilterContent {
  padding: 12px 0 16px 0;
}
.InfoPanelWithoutShiftTypeSwitcher {
  padding-bottom: 20px;
  margin-left: auto;
}
.InfoPanelWithoutShiftTimeWrapper {
  padding-top: 8px;
}
.GmtSelectWrapper .SelectComponent .ant-select-selector .ant-select-selection-item {
  height: 48px;
}
.ant-select-selection-item .GmtSelectActiveValue {
  padding-top: 8px;
}
.GmtSelectActiveValue > div {
  line-height: 30px !important;
}
.CounterComponentWrapper {
  margin-top: 10px;
  padding: 4px;
  border-radius: 18px;
  background-color: #FF2D55;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  height: 22px;
}
.ant-notification {
  width: 488px !important;
  margin-right: 32px !important;
}
.NotificationToastWrapper {
  width: 488px !important;
  padding: 24px !important;
  border: 1px solid #DEE2E6 !important;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.16) !important;
  border-radius: 8px !important;
}
.NotificationToastWrapper {
  cursor: pointer;
}
.NotificationToastWrapper .ant-notification-notice-content .ant-notification-notice-with-icon > div {
  margin-left: 70px !important;
}
.NotificationToastTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #212529;
}
.NotificationToastTextField {
  max-width: 360px !important;
  max-height: 65px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.NotificationToastTheme {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
}
.NotificationToastText {
  padding-left: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #212529;
}
.DeveloperProfileWrapper {
  position: fixed;
  bottom: 16px;
  left: 340px;
}
@media screen and (max-width: 1440px) {
  .DeveloperProfileWrapper {
    left: 320px;
  }
}
.DeveloperComponentIconWrapper {
  background-size: 48px !important;
  width: 48px;
  height: 48px;
}
.DeveloperComponentIconWrapper-disabled {
  opacity: 0.7;
}
.DeveloperComponentIconWrapper-active:hover {
  cursor: pointer;
  opacity: 0.8;
}
.DeveloperLinksHeader {
  width: 100%;
  padding: 16px 32px;
  text-align: center;
  border-bottom: 1px solid #E9EAEA;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}
.DeveloperLinksWrapper {
  margin: 16px 48px;
}
.DeveloperProfileLinksWrapper {
  display: flex;
  flex-direction: column;
}
.DeveloperLinksItem {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 16px;
}
.DeveloperLinksItem a {
  text-decoration: none !important;
  color: #212529;
}
.DeveloperLinksItem a:hover {
  text-decoration: none !important;
  color: #212529;
}
.DeveloperLinksItem:hover {
  background: #F2F5F7;
  border-radius: 6px;
}
.DeveloperLinkText {
  font-weight: bold !important;
  font-style: italic;
}
.HourSelector {
  width: 120px !important;
  margin-right: 8px !important;
  height: 44px !important;
}
.HourSelector .ant-select-selector {
  padding: 0 !important;
  border: 1px solid #E9ECEF;
  border-radius: 8px !important;
  height: 44px !important;
}
.SelectHoursWrapper .ant-select-focused .ant-select-selector,
.HourSelector .ant-select-selector:focus,
.HourSelector .ant-select-selector:active,
.SelectHoursWrapper .ant-select-open .ant-select-selector,
.HourSelector .ant-select-selector:hover {
  box-shadow: none !important;
  border-color: #343A40 !important;
}
.SelectHoursWrapper .ant-select-open .ant-select-arrow svg path,
.SelectHoursWrapper .ant-select-focused .ant-select-arrow svg path,
.HourSelector:hover .ant-select-arrow svg path {
  fill: #343A40;
}
.HourSelector .ant-select-selection-search,
.HourSelector .ant-select-selection-item {
  padding: 6px 32px 10px 16px !important;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  color: #212529;
}
.HourSelectorDropdownWrapper {
  margin: 8px 0 !important;
  padding: 8px 0 !important;
  background: #FFFFFF !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
}
.HourSelectorDropdownWrapper div .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner > div {
  padding: 10px 16px !important;
}
.HourSelectorDropdownWrapper div .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner > div .ant-select-item-option-content {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.HourSelectorDropdownWrapper div .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner .ant-select-item-option-selected {
  background-color: #F1F3F5 !important;
}
.DateTimeDate {
  padding-left: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.DateTimeTime {
  opacity: 0.5;
  padding-left: 8px;
}
.TitleWrapper {
  width: 100% !important;
}
.TitleDescription {
  width: 100% !important;
}
.TitleText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  white-space: pre-wrap;
}
.TitleDescription .TitleText span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  color: #212529;
  white-space: pre-wrap;
}
.TitleWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
.TitleTooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 4px;
}
.TitleTooltip:hover {
  cursor: pointer;
  opacity: 0.8;
}
@media screen and (max-width: 1500px) {
  .TitleWrapper div div .TitleText {
    max-width: 400px;
  }
}
@media screen and (max-width: 1800px) {
  .TitleWrapper div div .TitleText {
    max-width: 400px;
  }
}
@media screen and (max-width: 910px) {
  .TitleWrapper {
    display: block !important;
  }
  .TitleText span {
    font-size: 24px;
    max-width: 320px !important;
  }
}
@media screen and (max-width: 920px) {
  .TitleWrapper {
    margin-left: 16px;
    max-width: 100vw;
    width: auto !important;
  }
  .TitleDescription {
    display: flex;
    flex-direction: column;
  }
  .TitleRightAdditional {
    margin-left: 10px !important;
  }
  .TitleDescription .TitleText span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }
  .TitleWrapper {
    padding-bottom: 12px !important;
  }
}
@media screen and (min-width: 921px) {
  .TitleDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (max-width: 994px) {
  .TitleText {
    width: 100% !important;
    min-width: fit-content !important;
    margin-left: 15px !important;
  }
}
.TitleBottomAdditionalDefault {
  margin-left: 7px;
  padding-top: 5.5px;
}
@media screen and (max-width: 920px) {
  .TabsComponentWrapper {
    max-width: calc(100vw - 32px);
    margin-left: 16px;
    overflow-x: auto;
  }
  .TabsComponentWrapper::-webkit-scrollbar {
    display: none !important;
  }
}
.TabsComponentItemsWrapper {
  width: 100%;
  min-width: max-content;
  overflow-x: auto;
  gap: 24px;
  border-bottom: 1px solid #DEE2E6;
}
.TabsComponentItem {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #868E96;
  cursor: pointer;
  padding-bottom: 11px;
}
.TabsComponentItemLine {
  background: #FCE000;
  height: 3px;
  visibility: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.TabsComponentActiveItemLine {
  visibility: visible !important;
}
.TabsComponentActiveItem {
  color: #212529 !important;
  cursor: auto !important;
}
.TabsComponentDisabledItem {
  cursor: not-allowed !important;
  color: #868E96;
}
.TableTimeDescription {
  color: #868E96;
  padding-left: 8px;
}
.SideFilterStatusesCounter {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-left: 4px;
  opacity: 0.2;
}
.SideFilterCheckBoxWrapper {
  max-height: calc(100vh - 25%);
  margin-bottom: 24px;
}
.SideFilterCheckBoxGroupWrapper {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;
}
.SideFilterCheckBoxGroupWrapper label {
  border-bottom: none !important;
  padding-bottom: 16px;
}
.SideFilterCheckBoxGroupWrapper label:last-child {
  border-bottom: none !important;
}
.SideFilterComponentWrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.SideFilterContentLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
.SideFilterField {
  padding-bottom: 16px;
}
.SideFilterField .SideFilterContentLabel {
  padding-bottom: 8px;
}
.SideFilterReset {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  padding-top: 3px;
  margin-left: 10px;
}
.SideFilterReset:hover {
  cursor: pointer;
  opacity: 0.7;
}
.ScrollTrigger {
  height: 10px;
  width: 100%;
}
.ScrollTriggerHide {
  display: none;
}
.DataSwitcherWrapper {
  display: flex;
  flex-direction: row;
}
.DataSwitcherTitle {
  padding-top: 13px;
  padding-right: 16px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3A3A3C;
}
.DataSwitcherButton {
  width: 40px;
  height: 40px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.DataSwitcherButtonDisabled {
  background: #FAFAF9 !important;
  border-color: #AEAEB2 !important;
}
.DataSwitcherButtonDisabled svg path {
  fill: #AEAEB2;
}
.DataSwitcherTable {
  border-radius: 5px 0 0 5px !important;
}
.DataSwitcherStatistic {
  border-radius: 0 5px 5px 0 !important;
}
.DataSwitcherActive {
  background: rgba(255, 221, 45, 0.2) !important;
  border: 1px solid #FFDD2D !important;
}
@media screen and (max-width: 920px) {
  .DataSwitcherTitle {
    display: none;
  }
  .DataSwitcherButton {
    height: 48px !important;
  }
}
.DetailTableComponentWrapper {
  background-color: #fff;
  border: 1px solid #CED4DA;
  border-radius: 8px;
}
.DetailTableComponentRowItem {
  padding: 22px 16px;
  width: 100%;
  border-right: 1px solid #CED4DA;
}
.DetailTableComponentRowItem div .TooltipComponentTargetWrapper {
  margin-left: 6px;
  margin-top: 2px;
}
.DetailTableComponentRowSmallWrapper .DetailTableComponentRowItem {
  width: fit-content;
}
.DetailTableComponentFirstRow .DetailTableComponentRowItem {
  border-bottom: 1px solid;
}
.DetailTableComponentRowLastItem {
  border-right: none !important;
}
.DetailTableComponentRowSmallWrapper {
  border: 1px solid;
  width: fit-content;
  border-radius: 8px;
}
.DetailTableComponentRowSmall {
  padding: 5px 16px;
}
.DetailComponentSmallSkeletonValue {
  height: 18px !important;
}
.DetailComponentSmallSkeletonValue .ant-skeleton-button {
  width: fit-content !important;
}
.DetailComponentSmallSkeletonLabel {
  margin-top: 2px;
  height: 18px !important;
}
.DetailComponentSmallSkeletonLabel .ant-skeleton-button {
  width: 94px !important;
}
.DetailComponentSkeletonValue .ant-skeleton-button {
  width: 172px !important;
  height: 25px !important;
}
.DetailComponentSkeletonLabel {
  margin-top: 2px;
}
.DetailComponentSkeletonLabel .ant-skeleton-button {
  width: 94px !important;
  height: 20px !important;
}
@media screen and (max-width: 920px) {
  .DetailTableComponentRowSmall {
    min-width: 190px;
  }
  .DetailTableComponentWrapper {
    max-width: calc(100vw - 32px);
    margin-left: 16px;
  }
  .DetailTableComponentRow {
    flex-direction: column !important;
  }
  .DetailTableComponentRowItem {
    width: 100%;
    border-right: none !important;
  }
  .DetailTableComponentRowSmallWrapper .DetailTableComponentRowItem {
    width: 100%;
  }
  .DetailTableComponentRowItem {
    border-bottom: 1px solid #CED4DA;
  }
  .DetailTableComponentLastRow .DetailTableComponentRowLastItem {
    border-bottom: none !important;
  }
  .DetailTableComponentRowSmallWrapper {
    flex-direction: column;
  }
}
.ChartPeriodWrapper {
  width: 230px;
}
.StaticDashboardModalContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.StaticDashboardHallsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.StaticDashboardHallsSingleHall {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #E9ECEF;
}
.StaticDashboardHallsSingleHall:hover {
  border: 2px solid #FCE000;
  cursor: pointer;
  padding: 11px 15px !important;
}
.StaticDashboardHallsSingleHall_active {
  border: 2px solid #FCE000 !important;
  padding: 11px 15px !important;
}
.StaticDashboardHallsSingleHall_number {
  color: #ADB5BD !important;
}
.StaticDashboardHallsSingleHall_ButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.StaticDashboardHallsSingleHall_ButtonsContainer button {
  height: 40px !important;
}
.StaticDashboardHallsSingleHall_CancelButton {
  background-color: #F1F3F5 !important;
  border: unset !important;
  width: 97px !important;
}
.StaticDashboardHallsSingleHall_CancelButton:hover {
  background-color: #E9ECEF !important;
}
.StaticDashboardHallsSingleHall_RedirectButton {
  width: 162px !important;
}
.StaticDashboardEmptyTableContainer {
  gap: 8px;
  padding: 24px;
  width: 575px;
}
.StaticDashboardEmptyTableContainer svg {
  width: 160px;
  height: 160px;
}
@media screen and (max-width: 920px) {
  .StaticDashboardModalContainerModal .ModalWrapper {
    left: -4% !important;
  }
  .StaticDashboardEmptyTableContainerChild {
    width: 50% !important;
  }
}
.StaticDashboardEmptyTableContainerChild {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Margin16ErrorZone,
.Margin16BottomZone {
  margin-bottom: 16px !important;
}
.Margin16ErrorZone .ant-row .ant-col > div .ant-form-item-explain .ant-form-item-explain-error {
  padding-bottom: 16px;
}
.PhotoPreviewModal__Content {
  padding: 0 !important;
}
.PhotoPreviewModal__ImageWrapper {
  background-color: #212529;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  position: relative;
}
.PhotoPreviewModal__ImageWrapper__Image {
  margin: 0 auto;
  height: 634px;
  max-height: calc(100% - 96px);
}
.PhotoPreviewModal__ImageWrapper__Close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.PhotoPreviewModal__Data {
  height: 96px;
  padding: 24px 32px;
}
.PhotoPreviewModal__Data__Download {
  margin-left: auto;
}
.PhotoPreviewModal__Data__Download .ButtonDefaultWrapper button {
  padding: 10px;
  margin-top: 2px;
  height: 40px;
}
.PhotoPreviewModal__Data__Download .ButtonDefaultWrapper button svg path {
  fill: black;
}
@media screen and (max-width: 920px) {
  .PhotoPreviewModal__ImageWrapper__Image {
    height: 250px;
  }
}
.ant-btn .DropdownButtonIcon {
  transition: 0.3s;
}
.ant-btn .DropdownButtonIcon.dropdown {
  transform: rotate(180deg);
}
.anticon-caret-down,
.ant-tree-switcher {
  display: none !important;
}
.ant-tree-checkbox .ant-tree-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 2px solid #CED4DA !important;
  border-radius: 4px;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #FCE000 !important;
  border-color: #FCE000 !important;
}
.ant-tree-checkbox .ant-tree-checkbox-inner:after {
  display: none !important;
}
.ant-tree-treenode-switcher-close .ant-tree-checkbox .ant-tree-checkbox-inner {
  background: transparent url(/Components/Tree/minus_outline_16.svg) no-repeat 70% 50%;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background: transparent url(/Components/Tree/minus_outline_16.svg) no-repeat 70% 50%;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #6DB3F2;
  background-image: url(/Components/CheckBox/check.svg) !important;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-node-selected {
  background-color: #fff !important;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #fff !important;
}
.ant-tree-checkbox-checked.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  border: 2px solid #CED4DA !important;
  background-color: #CED4DA !important;
}
.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background: none;
  border-color: #C7C7CC;
  border-radius: 4px;
}
.ant-checkbox-inner:after {
  display: none;
  width: 7px;
  height: 11px;
}
.ant-tree-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #F8E800;
}
.ant-tree-checkbox-checked .ant-checkbox-inner {
  background-color: #F8E800;
  border-color: #F8E800;
}
.ant-tree-checkbox-checked::after {
  border-color: #F8E800;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after,
.ant-tree-checkbox-checked:hover .ant-tree-checkbox-inner:after {
  border-right-color: #1D1F21;
  border-bottom-color: #1D1F21;
}
.ant-tree-checkbox-wrapper-disabled,
.ant-tree-checkbox-wrapper-disabled + .CheckboxText {
  opacity: 0.5;
}
.ant-tree-title span div {
  padding-top: 3px;
}
