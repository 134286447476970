.YandexFileAbsent {
    svg {
      path {
        fill: @red-600 !important
      }
      circle {
        stroke: @red-600 !important
      }
    }
}

.YandexFileAbsentError {
  color: @red-600;
}

.YandexReport_Table_Column .TitleTooltip {
  top: 3px !important;
  margin-left: 4px !important;
}

.YandexDescription {

  .NewDashboard{
    padding: 16px 0 0 0;
  }

  .NewDashboard_Item{
    padding: 16px;
  }

  .QiwiModalHeader{
    padding-top: 12px;
  }

  .QiwiModalTableHeader,
  .QiwiModalTableBody{
    margin-left: 8px;
    margin-right: 8px;
  }

  .QiwiModalTableHeaderItem,
  .QiwiModalTableRowItem{
    flex-basis: 50%;
  }

  .ModalCloseWrapper{
    position: absolute;
    top: 10px;
    right: -16px;

    svg{
      visibility: hidden;
    }

    &:before, &:after{
      content: '';
      position: absolute;
      height: 2px;
      width: 24px;
      background: @grey-4;
      top: 10px;
      right: -2px;
    }

    &:before{
      transform: rotate(45deg);
    }

    &:after{
      transform: rotate(-45deg);
    }
  }
}

.YandexDetailRowClickable {
  cursor: pointer !important;
}

.YandexDetailWrapper {
  padding: 40px 24px;
}

.YandexDetailTable {
  margin-top: 16px;
}

.YandexDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}

.YandexAddressColumnItem{
  display: block !important;

  div{
    display: inline;
  }

}

.YandexDescriptionDetailsItem{
  div{
    display: inline;
  }
}

.YandexDescriptionDetailsItemToLeft{
  div {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 920px)  {
  .YandexDescription {

    .NewDashboard {
      margin-left: 0;
      padding-bottom: 16px;
    }

    div.ModalWrapper, div.ModalFormWrapper{
      left: 16px !important;
      right: 16px !important;
      width: auto !important;
    }

    .QiwiModalHeader{
      width: auto !important;
      padding-top: 24px;
    }

    .ModalCloseWrapper{
      right: 22px;
    }

    .QiwiModalTableHeader,
    .QiwiModalTableBody{
      margin-left: 16px;
      margin-right: 16px;
    }

    .QiwiModalWrapper{
      margin-right: 0;
      padding: 0 !important;
    }

    .QiwiModalTableBody{
      margin-bottom: 24px;
    }
  }

}


