@import "./Modal/styles";
@import "YandexFilesTable/styles";
@import "./YandexHistory/styles";

@media screen and (max-width: 920px)  {
  .YandexWrapper {
    margin: 0 16px;
  }
  .YandexTitleExel {
    margin-top: -8px;
    margin-bottom: 8px;
  }

}

